import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link } from '@material-ui/core';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { Freebet } from './FreebetModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';

interface FreebetTableProps {
    items: Freebet[];
    onSelect? : (item : Freebet) => void;
}

const FreebetTable: React.FC<FreebetTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<Freebet>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<Freebet>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Id", renderValue: (item: Freebet) => (<Link component={RouterLink} to={"editFreebet/" + item.id}>{item.id}</Link>) },
        { id: 'freebetCampaignName', numeric: false, disablePadding: false, label: 'Campaign' },
        { id: 'freebetTypeName', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator' },
        { id: 'playerId', numeric: false, disablePadding: false, label: 'Player' },
        { id: 'operatorPlayerId', numeric: false, disablePadding: false, label: 'Player external id' },
        { id: 'playerNotes', numeric: false, disablePadding: false, label: 'Player notes' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: Freebet) => CreateRoundUtil.toGameDateString(item.createdAt) },
        { id: 'validFrom', numeric: false, disablePadding: false, label: 'Valid from', renderValue: (item: Freebet) => CreateRoundUtil.toGameDateString(item.validFrom) },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Valid to', renderValue: (item: Freebet) => CreateRoundUtil.toGameDateString(item.validTo) },
        { id: 'couponShareId', numeric: false, disablePadding: false, label: 'Coupon share' },
      ];
      setColumns(cols);
    }
    const onSelect = (item : Freebet) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
  
  return (
    <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='desc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} rowsPerPage={100}  />
    );
};
  
export default FreebetTable;
