import React, { useEffect, useState } from 'react';
import { Link, } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import DataTable, { DataTableColumn } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { Operator } from './AdminModels';

interface OperatorTableProps {
    items: Operator[];
    onSelect? : (item : Operator) => void;
}

const OperatorTable: React.FC<OperatorTableProps> = (props) => {
    const [selectedId, setSelectedId] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<Operator>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<Operator>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Operator Id", filterEnabled: true },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name', filterEnabled: true },
        { id: 'currency', numeric: false, disablePadding: false, label: 'Currency', filterEnabled: true },
        { id: 'internal', numeric: false, disablePadding: false, label: 'Internal' },
        { id: 'agentSyndicateActive', numeric: false, disablePadding: false, label: 'Agent Syndicate' },
        { id: 'rSystemActive', numeric: false, disablePadding: false, label: 'R System' },
        { id: 'advanceCouponsActive', numeric: false, disablePadding: false, label: 'Adv coupons' },
        { id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled' },
      ];
      setColumns(cols);
    }
    const onSelect = (item : Operator) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    return (
      <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect} rowsPerPage={25} />
    );
  };
  
  export default OperatorTable;
 