import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";
import { ListResponse } from "../api/ApiNgModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { QueryParams } from "../models/CouponShare";
import { Operator, OperatorSetting, OperatorSettingFilter, Setting } from "./AdminModels";

class AdminUtil {
    static async getOperatorSettings(params?: QueryParams) : Promise<GenericResponse<ListResponse<OperatorSetting>>> {
        var listResponse = await ApiNgUtil.getList<OperatorSetting>("operatorSettings", params);
        return listResponse;
    }
    static async getOperatorSettingsByName(settingName: string, pageSize: number, lastId?: number) : Promise<GenericResponse<ListResponse<OperatorSetting>>> {
        var filter : OperatorSettingFilter = {
            settingName: settingName,
            lastId: lastId
        };
        var params : QueryParams = {
            pagination: { page: 0, perPage: pageSize },
            filter: filter,
            //sort: { field: state.orderBy, order: state.orderDirection }
        }
        return await AdminUtil.getOperatorSettings(params);
    }
    static async getSettings(params?: QueryParams) : Promise<GenericResponse<ListResponse<Setting>>> {
        var listResponse = await ApiNgUtil.getList<Setting>("settings", params);
        return listResponse;
    }
    static async getOperators(params?: QueryParams) : Promise<GenericResponse<ListResponse<Operator>>> {
        var listResponse = await ApiNgUtil.getList<Operator>("operators", params);
        return listResponse;
    }
    
}
export default AdminUtil;