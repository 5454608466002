import React from 'react';
import { Route } from 'react-router-dom';
import SettlementComparison from "./custom-route/sc";
import Reports from './new-era/Reports/Reports';
import CouponView from './new-era/CouponView/CouponView';
import CreateRound from './new-era/CreateRound/CreateRound';
import RoundOverview from './new-era/RoundOverview/RoundOverview';
import CreateFakeRound from './new-era/CreateFakeRound/CreateFakeRound';
import SettleRound from './new-era/SettleRound/SettleRound';
import CreateFixture from './new-era/CreateFixture/CreateFixture';
import LeagueOverview from './new-era/LeagueOverview/LeagueOverview';
import OperatorSettingsPage from './new-era/Admin/OperatorSettingsPage';
import SettingsPage from './new-era/Admin/SettingsPage';
import Ticker2 from './new-era/Ticker2/Ticker2';
import OperatorsPage from './new-era/Admin/OperatorsPage';
import AgentOverview from './new-era/AgentOverview/AgentOverview';
import CreateSyndicate from './new-era/CreateSyndicate/CreateSyndicate';
import FreebetOverview from './new-era/Freebets/FreebetOverview';
import FreebetTypesPage from './new-era/Freebets/FreebetTypesPage';
import EditFreebetType from './new-era/Freebets/EditFreebetType';
import EditFreebetCampaign from './new-era/Freebets/EditFreebetCampaign';
import EditFreebet from './new-era/Freebets/EditFreebet';
import EditAgent from './new-era/AgentOverview/EditAgent';

export default [
    <Route exact path="/settlementComparison/:nFixtures/:roundId" component={SettlementComparison} />,
    <Route exact path="/ticker" component={Ticker2} />,
    <Route exact path="/reports" component={Reports} />,
    <Route exact path="/createRound" component={CreateRound} />,
    <Route exact path="/roundOverview" component={RoundOverview} />,
    <Route exact path="/couponView/:roundId/:couponId/:couponShareId" component={CouponView} />,
    <Route exact path="/createFakeRound" component={CreateFakeRound} />,
    <Route exact path="/settleRound/:roundId" component={SettleRound} />,
    <Route exact path="/createFixture" component={CreateFixture} />,
    <Route exact path="/leagueOverview" component={LeagueOverview} />,
    <Route exact path="/operatorSettings" component={OperatorSettingsPage} />,
    <Route exact path="/settings" component={SettingsPage} />,
    <Route exact path="/operators" component={OperatorsPage} />,
    <Route exact path="/agentOverview" component={AgentOverview} />,
    <Route exact path="/createSyndicate" component={CreateSyndicate} />,
    <Route exact path="/freebetOverview" component={FreebetOverview} />,
    <Route exact path="/freebetTypes" component={FreebetTypesPage} />,
    <Route exact path="/editFreebetType" component={EditFreebetType} />,
    <Route exact path="/editFreebetType/:freebetTypeId" component={EditFreebetType} />,
    <Route exact path="/editFreebetCampaign" component={EditFreebetCampaign} />,
    <Route exact path="/editFreebetCampaign/:freebetCampaignId" component={EditFreebetCampaign} />,
    <Route exact path="/editFreebet" component={EditFreebet} />,
    <Route exact path="/editFreebet/:freebetId" component={EditFreebet} />,
    <Route exact path="/editAgent" component={EditAgent} />,
    <Route exact path="/editAgent/:agentId" component={EditAgent} />,
];
