import React, { useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Link } from '@material-ui/core';
import { Draw, Fixture, RoundOverviewFixture } from "./RoundOverviewModels";
import { min } from "moment";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "./RoundOverviewUtil";
import DrawRulesDialog from "./DrawRulesDialog";
import { LeagueListItem } from "../CreateRound/CreateRoundModels";
import LeagueChooser from "./LeagueChooser";

interface ChooseLeagueDialogProps {
    open: boolean;
    openLabel?: string;
    onClose: (league: LeagueListItem | undefined) => void;
}
interface ChooseLeagueDialogState {
    leagueId: string;
    league: LeagueListItem | undefined;
}


const ChooseLeagueDialog: React.FC<ChooseLeagueDialogProps> = (props) => {
    const getDefaultFormState = () : ChooseLeagueDialogState => {
        return {
            leagueId: "",
            league: undefined
        };
    }
    
    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<ChooseLeagueDialogState>(getDefaultFormState());
    const [leagues, setLeagues] = useState<LeagueListItem[]>([]);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
 
    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        if (name === "leagueId") {
            if (!value || value.length <= 0) {
                //fs.leagueName = "";
                fs.league = undefined;
            } else {
                var league = leagues.find(it => it.id === value);
                fs.league = league;
            }
        }
        setFormState(fs);
    }
    const onLeagueChange = (league: LeagueListItem | undefined) =>{
        var leagueId = league ? league.id : "";
        setFormState({ 
            leagueId: leagueId,
            league: league
        });
    }

    const onOk = () => {
        const methodName = "OnOK";
        setSaveError("");
        if (!formState.leagueId || formState.leagueId.length <= 0) {
            setSaveError("Leauge is not specified.");
            return;
        }
        // var league = leagues.find(it => it.id === formState.leagueId);
        // if (!league) {
        //     setSaveError("Leauge was not found in array.");
        //     return;
        // }
        if (!formState.league) {
            setSaveError("Leauge is not set.");
            return;
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose(formState.league);
        }           
    };

    var valid = true;
    var leagueIdErr = "";
    if (formState.leagueId === ""){
        valid = false;
        leagueIdErr = "Required";
    } else if (!formState.league) {
        valid = false;
        leagueIdErr = "League not set";
    }

    var saveEnabled = (valid && saveStatus === 0);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    var openLabel = props.openLabel && props.openLabel.length > 0 ? props.openLabel : "Choose League";
    var openButton = (<Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    {openLabel}
            </Button>);

    return (
        <div>
            {openButton}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Choose League</DialogTitle>
            <DialogContent dividers>
                <LeagueChooser onChange={onLeagueChange} />
                {saveErrorBox} 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onOk} color="primary" disabled={!saveEnabled}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default ChooseLeagueDialog;
