import React from 'react';
import {
    Datagrid, TextField, NumberField, DateField, List,
    Edit, SimpleForm, ReferenceManyField, Pagination,
} from 'react-admin';

import AmountField from "../../fields/AmountField";
import {CouponReferenceField} from "../coupons";
import {CouponShareReferenceField} from "../couponShares";
import {AccountTransactionReferenceField} from '../accountTransactions';
import {TransactionalRequestExecutionsGrid} from '../transactionalRequestExecutions';
import {PlayerReferenceField} from "../players";

export const TransactionalRequestGrid = props => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="type" />
    <TextField source="status" />
    <TextField source="operatorId" />
    <AmountField source="amount"/>
    <AmountField source="operatorAmount"/>
    <NumberField source="nExecutions"/>
    <AccountTransactionReferenceField source={"accountTransactionId"} allowEmpty/>
    <CouponReferenceField source="couponId" allowEmpty/>
    <CouponShareReferenceField source="couponShareId" allowEmpty/>
    <PlayerReferenceField source={"playerId"}/>
  </Datagrid>
)


export const TransactionalRequestEdit = props => {
  return (<Edit {...props} > 
    <SimpleForm>
      <TextField source="id" />
      <DateField source="createdAt" showTime/>
      <TextField source="type" />
      <TextField source="status" />
      <TextField source="operatorId" />
      <AmountField source="amount"/>
      <AmountField source="operatorAmount"/>
      <NumberField source="nExecutions"/>
      <CouponReferenceField source="couponId" allowEmpty label="Coupon" addLabel={true}/>
      <CouponShareReferenceField source="couponShareId" allowEmpty label="Coupon Share" addLabel={true}/>
      <PlayerReferenceField source={"playerId"} label="Player" addLabel={true}/>
      <AccountTransactionReferenceField source={"accountTransactionId"} allowEmpty addLabel={true}/>

      <ReferenceManyField label={"Executions"} reference="transactionalRequestExecutions" target={"transactionalRequestId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
        <TransactionalRequestExecutionsGrid/>
      </ReferenceManyField>

    </SimpleForm>
  </Edit>);
  }


export const TransactionalRequestList = props => (
    <List sort={{ field: 'id', order: 'desc'}} {...props}>
      <TransactionalRequestGrid/>
    </List>
);
