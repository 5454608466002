import HorizontalForm from "./HorizontalForm";
import {DateTimeInput} from "../date-inputs";
import React from "react";
import {Labeled} from "react-admin";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    marginTop: '32px'
  }
})

const PeriodInput = ({from, to, label, ...props}) => {
  const classes = useStyles();
  return <Labeled className={classes.root} label={label}>
    <HorizontalForm {...props}>
      <DateTimeInput label={"From"} source={from} options={{clearable: true, margin: 'none'}}/>
      <DateTimeInput label={"To"} source={to} options={{clearable: true, margin: 'none'}}/>
    </HorizontalForm>
  </Labeled>;

}

PeriodInput.defaultProps = {
  addLabel: false
}

export default PeriodInput;
