import { CouponShare } from "../models/CouponShare";
import { Fixture, Round } from "../models/Round";
import { CouponDetail } from "../models/CouponDetail";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import React from "react";
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { getList, getListWithId, getObject } from "../api/dataProvider";
import { makeStyles } from "@material-ui/styles";

interface CouponShareParams {
    couponShareId: string;
    roundId: string;
    couponId: string;
}

const useStyles = makeStyles({
    root: {
        marginTop: "20px"
    },
    selected: {
        background: "lightgray"        
    },
    signHeader: {        
        width: "10px"
    },
});

function getSign(sign: string) {

    if (sign === "ONE") {
        return "1";
    }
    else if (sign === "TWO") {
        return "2"
    }

    return "X";
}

function CouponView() {

    let empty: CouponShare | undefined = undefined;
    let emptyRound: Round | undefined = undefined;
    let emptyCouponDetail: CouponDetail | undefined = undefined;
    let emptyFixtures: Fixture[] = [];

    const [couponShare, setCouponShare] = useState(() => empty)
    const [round, setRound] = useState(() => emptyRound)
    const [couponDetail, setCouponDetail] = useState(() => emptyCouponDetail)
    const [fixtures, setFixtures] = useState(() => emptyFixtures)
    const params: CouponShareParams = useParams();

    const classes = useStyles();


    useEffect(() => {

        getObject('couponShares/' + params.couponShareId)
            .then((resp) => {
                setCouponShare(resp.data);
            })

        getObject('rounds/' + params.roundId)
            .then((resp) => {
                setRound(resp.data);
                return resp.data;
            }).then((round) => {

                let ids: number[] = [];

                for (let i = 0; i < round.fixtures.length; i++) {
                    ids.push(round.fixtures[i].fixtureId);
                }
                getListWithId('fixtures', ids).then((resp) => {
                    setFixtures(resp.data);
                })
            }
            )

        getList('couponDetails', {
            pagination: { page: 0, perPage: 100 },
            filter: { "id": params.couponId },
            sort: { field: "id", order: "desc" }
        }).then((resp) => {
            let myData = resp.data;
            return myData;
        }).then((data) => {
            setCouponDetail(data[0]);
        })

    }, [params])


    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                className={classes.root}
            >
                <Grid item xs={12}>
                    <Grid container direction="column">
                        <Typography>Round: {params.roundId}</Typography>
                        <Typography>Coupon: {params.couponId}</Typography>
                        <Typography>Coupon share: {params.couponShareId}</Typography>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Game</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Sign</TableCell>
                                    <TableCell className={classes.signHeader}>1</TableCell>
                                    <TableCell className={classes.signHeader}>X</TableCell>
                                    <TableCell className={classes.signHeader}>2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    round?.fixtures.map((slimFixture, index) => {

                                        let fixture = fixtures.find(f => f.id === slimFixture.fixtureId);
                                        let correctSign = getSign(round.preliminaryCorrectRow.signs[index]);
                                        let selection = couponDetail?.roundCoupon?.bets[0]?.legs[index]?.signs;

                                        if (selection === undefined) {
                                            return (<TableRow key={'fixture' + index}></TableRow>)
                                        }

                                        let classHome = selection.includes("ONE") ? classes.selected : "";
                                        let classDraw = selection.includes("X") ? classes.selected : "";
                                        let classAway = selection.includes("TWO")  ? classes.selected : "";

                                        return (
                                            <TableRow key={'fixture' + index}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{fixture?.homeTeam?.teamName + " - " + fixture?.awayTeam?.teamName}</TableCell>
                                                <TableCell>{fixture?.homeTeam?.score + " - " + fixture?.awayTeam?.score}</TableCell>
                                                <TableCell>{correctSign}</TableCell>
                                                <TableCell className={classHome}>{"1"}</TableCell>
                                                <TableCell className={classDraw}>{"X"}</TableCell>
                                                <TableCell className={classAway}>{"2"}</TableCell>

                                            </TableRow>
                                        );
                                    }
                                    )

                                }
                            </TableBody>
                        </Table>

                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )

}

export default CouponView;
