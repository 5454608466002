import React from 'react';
import {
  Create, Edit, SimpleForm, TextInput, Datagrid, TextField, DateField, List,
  ReferenceInput, AutocompleteInput, ReferenceField, ArrayInput, SimpleFormIterator
} from 'react-admin';
import {PlayerReferenceInput} from "../players";

export const AgentCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="operatorId" />

          <ArrayInput source={`players`} label={'Players that can administer agent'}>
              <SimpleFormIterator>
                <PlayerReferenceInput source="playerId" label={'Player'}/>
              </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const AgentEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <TextInput source="name" />
            <TextInput source="operatorId" />
            <ArrayInput source={`players`} label={'Players that can administer agent'}>
              <SimpleFormIterator>
                <PlayerReferenceInput source="playerId" label={'Player'}/>
              </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    </Edit>
);

export const AgentList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <TextField source="name" />
        </Datagrid>
    </List>
);

export const AgentReferenceInput = props =>
    <ReferenceInput label={'Agent'}  reference="agents" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""}/>
    </ReferenceInput>;

export const AgentReferenceField = props =>
    <ReferenceField reference="agents" {...props} >
        <TextField source="name" />
    </ReferenceField>;
