import React, { useEffect, useState } from 'react';
import { Link, } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import DataTable, { DataTableColumn } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { OperatorSetting } from './AdminModels';

interface OperatorSettingTableProps {
    items: OperatorSetting[];
    onSelect? : (item : OperatorSetting) => void;
}

const OperatorSettingTable: React.FC<OperatorSettingTableProps> = (props) => {
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<OperatorSetting>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<OperatorSetting>[] = [
        { id: 'id', numeric: true, disablePadding: true, label: "Setting Id", filterEnabled: true },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator', filterEnabled: true },
        { id: 'settingName', numeric: false, disablePadding: false, label: 'Setting', filterEnabled: true },
        { id: 'settingValue', numeric: false, disablePadding: false, label: 'Value', filterEnabled: true },
        { id: 'validFrom', numeric: false, disablePadding: false, label: 'Valid from', renderValue: (item: OperatorSetting) => { return CreateRoundUtil.toGameDateString(item.validFrom); } },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: OperatorSetting) => { return CreateRoundUtil.toGameDateString(item.createdAt); } },
      ];
      setColumns(cols);
    }
    const onSelect = (item : OperatorSetting) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    return (
      <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect} rowsPerPage={25}  />
    );
  };
  
  export default OperatorSettingTable;
 