import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";
import { ListResponse } from "../api/ApiNgModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { QueryParams } from "../models/CouponShare";
import { GetLeaguesRequest, League, LeagueStat } from "./LeagueModels";

class LeagueUtil {
    static async getLeagues(params?: QueryParams) : Promise<GenericResponse<ListResponse<League>>> {
        var listResponse = await ApiNgUtil.getList<League>("leagues", params);
        if (!listResponse.success) {
            // if (apiResponse.status && apiResponse.status === 204) {
            //     //No data
            //     return CreateRoundUtil.createGenericResponse<League[]>(true, apiResponse.message, []);
            // }
            //return CreateRoundUtil.createGenericResponse<ListResponse<League>>(false, listResponse.message, null);
            return listResponse;
        }
        var leagues : League[] = [] 
        if (listResponse.item?.items) {
            leagues = listResponse.item?.items;
        }
        if (leagues) {
            for (var i = 0; i < leagues.length; i++) {
                var league = leagues[i];
                LeagueUtil.fixLeague(league);
            }
        }
        return listResponse;
    }
    static async updateLeague(id: number, updateJson: string) : Promise<GenericResponse<League>> {
        var resource = `leagues/${id}`;
        var apiResponse = await ApiNgUtil.invokeApi2(resource, InvokeApiMethods.PATCH(), updateJson);
        if (!apiResponse.success) {
            return CreateRoundUtil.createGenericResponse<League>(false, apiResponse.message, null);
        }
        var league : League | null = null; 
        if (apiResponse.json) {
            league = apiResponse.json;
        }
        if (league) {
            LeagueUtil.fixLeague(league);
        }
        return CreateRoundUtil.createGenericResponse<League>(true, apiResponse.message, league);
    }
    static fixLeague(league: League) {
        if (!league) {
            return;
        }
        var leagueName: string = league.name;
        var leagueLocation = "";
        var leagueTitle = league.name;
        var match = leagueName.match(CreateRoundUtil.leagueFormatPattern);
        if (match) {
            leagueName = match.groups?.name ?? league.name;
            leagueLocation = match.groups?.location ?? "";
            if (leagueName !== league.name && leagueLocation.length > 0) {
                leagueTitle = leagueLocation + " - " + leagueName;
            }
        }
        league.title = leagueTitle;
        league.location = leagueLocation;
        league.nameWithoutLocation= leagueName;        
    }
    static async getLeagueStat() : Promise<GenericResponse<LeagueStat>> {
        var response = await ApiNgUtil.getObject<LeagueStat>("leagues/stat");
        return response;
    }

}
export default LeagueUtil;