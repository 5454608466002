import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showNotification as showNotificationAction, refreshView as refreshViewAction } from 'react-admin';
import fetchApi from "../../fetchApi";
import PopupDialog from "../../components/PopupDialog";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { withStyles, lighten, darken } from '@material-ui/core/styles';

const styles = theme => {
    const getColor = theme.palette.type === 'light' ? darken : lighten;

    return {
        content: {
            color: getColor(theme.palette.warning.main, 0.6),
            border: `1px solid ${theme.palette.warning.main}`,
            '& $icon': {
                color: theme.palette.warning.main,
            },
        },
    }
}


class ScheduleRollbackButton extends Component {
    constructor(props) {
        super(props);

        this.state = {submitting: false, open: false}
    }
    handleConfirm = () => {
        const { record, showNotification, refreshView } = this.props;

        this.setState({submitting: true})
        fetchApi(record._links.scheduleRollback.href, {
            method: 'POST',
        }).then(() => {
            showNotification('Settlement rollback scheduled', 'info');

            refreshView();
            this.setState({submitting: false})
        }).catch((e) => {
            showNotification('Error scheduling settlement rollback', 'error');
            this.setState({submitting: false})
        });
    };

    handleClose = () => {
        this.setState({open: false})
    }

    handleOpen = e => {
        e.stopPropagation();
        this.setState({open: true})
    }

    render() {
        const {submitting, open} = this.state;

        return <div>
              <PopupDialog open={open} onOk={this.handleConfirm} onClose={this.handleClose} title={"Rollback settlement?"}>
                  <div className={this.props.classes.content}>
                      <div><ReportProblemOutlinedIcon/> This will reverse settlement and remove winnings from operator accounts</div>
                  </div>
              </PopupDialog>
            {this.props.record._links && this.props.record._links.scheduleRollback &&
                <Button variant={'contained'} color={'primary'} disabled={submitting} onClick={this.handleOpen}>Schedule Rollback</Button>
            }
            <span style={{visibility: !submitting ? 'hidden' : 'visible'}}><CircularProgress/></span>
            </div>;
    }
}

ScheduleRollbackButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    refreshView: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
})(withStyles(styles)(ScheduleRollbackButton))