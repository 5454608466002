import React from 'react';
import {
  Edit, SimpleForm, Datagrid, TextField, DateField, List,
  ReferenceInput, AutocompleteInput, ReferenceField
} from 'react-admin';
import {RoundReferenceField} from "../rounds";
import ScheduleRollbackButton from "./ScheduleRollbackButton";

export const SettlementEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <TextField source="status" />
            <RoundReferenceField source={'roundId'}/>
            <ScheduleRollbackButton/>
        </SimpleForm>
    </Edit>
);

export const SettlementGrid = props =>
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="status" />
    <RoundReferenceField source={'roundId'}/>
    <ScheduleRollbackButton/>
  </Datagrid>;


export const SettlementList = props => (
    <List {...props}>
      <SettlementGrid/>
    </List>
);

export const SettlementReferenceInput = props =>
    <ReferenceInput label={'Settlement'}  reference="settlements" {...props} >
        <AutocompleteInput optionText="id" emptyValue={""}/>
    </ReferenceInput>;

export const SettlementReferenceField = props =>
    <ReferenceField reference="settlements" {...props} >
        <TextField source="id" />
    </ReferenceField>;
