import React from "react";
import { useEffect, useState } from 'react';
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Button } from "@material-ui/core";
import { getObject } from "../api/dataProvider";
import { makeStyles } from "@material-ui/styles";
import { Bet } from "../models/CouponDetail";
import { RoundOverviewItem, Round, RoundOverviewFixture } from "../RoundOverview/RoundOverviewModels";
import { GenericResponse, LeagueListItem } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { RoundType } from "../models/RoundType";
import RofTitle from "../RoundOverview/RofTitle";
import ProPickDialog from "./ProPickDialog";
import { PoolXMoney } from "../models/CouponShare";

export interface CouponEditInfo {
    bet: Bet;
    rowCount: number;
    error: string;
    valid: boolean;
}
interface CouponEditProps {
    roundId: number;
    round: RoundOverviewItem;
    bet: Bet;
    onChange?: (legIndex: number, info: CouponEditInfo) => void;
}

const useStyles = makeStyles({
    root: {
        marginTop: "20px"
    },
    selected: {
        background: "lightgray"
    },
    signHeader: {        
        width: "10px"
    },
});

function getSign(sign: string) {

    if (sign === "ONE") {
        return "1";
    }
    else if (sign === "TWO") {
        return "2"
    }

    return "X";
}

interface CouponEditRoundState {
    roundId: number;
    round?: Round;
    roundType?: RoundType;
    fixtures: RoundOverviewFixture[];
    refreshCount: number;
    refreshError: string;
    operatorStakePerRow: PoolXMoney;
    operatorFeePerRow: PoolXMoney;
}
const CouponEdit: React.FC<CouponEditProps> = (props) => {


    const [roundState, setRoundState] = useState<CouponEditRoundState>({
        roundId: props.roundId,
        fixtures: [],
        refreshCount: 0,
        refreshError: "Not intialized",
        operatorStakePerRow: { amount: 0.1, currency: RoundOverviewUtil.CURRENCY_EUR},
        operatorFeePerRow:  { amount: 0.0, currency: RoundOverviewUtil.CURRENCY_EUR}
    });
    const [changeNo, setChangeNo] = useState<number>(0);

    const classes = useStyles();
    
    const getRoundState = async (): Promise<GenericResponse<CouponEditRoundState>> => {
        const METHOD_NAME = "GetRoundState";
        var rState : CouponEditRoundState = {
            roundId: props.roundId,
            fixtures: [],
            refreshCount: 0,
            refreshError: "",
            operatorStakePerRow: roundState.operatorStakePerRow,
            operatorFeePerRow: roundState.operatorFeePerRow
        };
        if (props.roundId <= 0) {
            rState.refreshError = "RoundId not specified.";
            return CreateRoundUtil.createGenericResponse(false, rState.refreshError, rState);
        }
        try {
           var roundResp = await RoundOverviewUtil.getRound(props.roundId.toString());
            if (!roundResp.success) {
                rState.refreshError = `GetRound error: ${roundResp.message}`;
                return CreateRoundUtil.createGenericResponse(false, rState.refreshError, rState);
            }
            var roundX = roundResp.item;
            if (!roundX) {
                rState.refreshError = "GetRound did not return round.";
                return CreateRoundUtil.createGenericResponse(false, "GetRound did not return round.", rState);
            } 
          rState.round = roundX;
          rState.refreshCount++;
    
            var roundTypeResp = await getObject("roundTypes?id=" + roundX.roundTypeId);
            var rt : RoundType | undefined = undefined;
            var rts = roundTypeResp.data?._embedded?.roundTypes;
            if (rts !== null && rts.length > 0) {
                rt = rts[0];
            }
            rState.roundType = rt;
            rState.refreshCount++;
    
    
            var leagues : LeagueListItem[] = [];

          var roFixtures = await RoundOverviewUtil.getRoundOverviewFixturesByRound(roundX, leagues, true);
          if (!roFixtures) {
            roFixtures = [];
          }
          rState.fixtures = roFixtures;
          rState.refreshCount += roFixtures.length;
          return CreateRoundUtil.createGenericResponse(true, "", rState);
        } catch (error) {
          console.error(`${METHOD_NAME} error.`, error);
          rState.refreshError = `${METHOD_NAME} error: ${error}.`;
          return CreateRoundUtil.createGenericResponse(false, rState.refreshError, rState);
        }
      };
      const fixLegs = (rState: CouponEditRoundState) : void => {
        if (rState.fixtures.length <= 0) {
            return;
        }
        var fixtures = rState.fixtures;
        var legs = props.bet.legs;
        if (legs.length === fixtures.length) {
            return;
        }
        if (legs.length > fixtures.length) {
            while (legs.length > fixtures.length) {
                legs.pop();
            }
        } else {
            while (legs.length < fixtures.length) {
                legs.push({
                    signs: []
                });
            }
        }        
    }
      const refreshRound = () => {
        getRoundState().then(resp => {
            if (resp.item) {
                fixLegs(resp.item);
                setRoundState(resp.item);
            } else {
                var error = resp.message;
                if (!error || error.length <= 0) {
                    error = "getRoundState did not return value.";
                }
                var rState : CouponEditRoundState = {
                    roundId: props.roundId,
                    fixtures: [],
                    refreshCount: 0,
                    refreshError: error,
                    operatorStakePerRow: roundState.operatorStakePerRow,
                    operatorFeePerRow: roundState.operatorFeePerRow
                };
            }
        });
      };

      useEffect(() => {
        refreshRound();
    }, []);

    const onCouponClick = (legIndex: number, sign: string) => {
        var leg = props.bet.legs[legIndex];
        if (!leg) {
            return;
        }
        if (leg.signs.length <= 0) {
            leg.signs.push(sign);
        } else {
            var signIndex = leg.signs.indexOf(sign);
            if (signIndex >= 0) {
                leg.signs.splice(signIndex, 1);
            } else {
                leg.signs.push(sign);
                leg.signs.sort((a: string, b: string) => {
                    var aIndex = RoundOverviewUtil.OUTCOME_SIGNS.indexOf(a);
                    var bIndex = RoundOverviewUtil.OUTCOME_SIGNS.indexOf(b);
                    return aIndex - bIndex;
                });
            }
        }
        if (props.onChange) {
            var rowCount = getRowCount(props.bet);
 
            var info : CouponEditInfo = {
                bet: props.bet,
                rowCount: rowCount,
                valid: rowCount > 0 ? true : false,
                error: rowCount > 0 ? "" : "Invalid coupon"
            }
            props.onChange(legIndex, info);
        }
        setChangeNo(changeNo + 1);
    };
    const getRowCount = (bet: Bet) : number => {
        if (!props.bet.legs) {
            return 0;
        }
        var rowCount = 1;
        for (var i = 0; i < props.bet.legs.length; i++) {
            var leg = props.bet.legs[i];
            if (!leg.signs || leg.signs.length <= 0) {
                return 0;
            }
            if (leg.signs.length > 1) {
                rowCount *= leg.signs.length;
            }
        }
        return rowCount;
    };

    const renderSign = (signIndex: number, fixture: RoundOverviewFixture) : JSX.Element => {
        var sign = RoundOverviewUtil.OUTCOME_FRIENDLY_SIGNS[signIndex];
        var n : number | undefined = undefined;
        var outcomes = fixture.draw?.outcomes;
        if (outcomes && outcomes.length > signIndex) {
            n = outcomes[signIndex].n;
        }
        return (<div>
            {sign}
            <Box fontSize={10} >
                {`(${n})`}
            </Box>
        </div>);
    };
    const onCloseProPickDlg = (bet?: Bet) => {
        if (!bet || !bet.legs || bet.legs.length !== props.bet.legs.length) {
            return;
        }
        props.bet.legs = bet.legs;
        if (props.onChange) {
            var rowCount = getRowCount(props.bet);
 
            var info : CouponEditInfo = {
                bet: props.bet,
                rowCount: rowCount,
                valid: rowCount > 0 ? true : false,
                error: rowCount > 0 ? "" : "Invalid coupon"
            }
            props.onChange(-1, info);
        }
        setChangeNo(changeNo + 1);
    };

    var rowCount = getRowCount(props.bet);
    var proPickDlg = null;
    if (roundState.round && roundState.fixtures && roundState.fixtures.length > 0) {
        proPickDlg = (<ProPickDialog open={false} openLabel="ProPick" onClose={onCloseProPickDlg}
            round={roundState.round} bet={props.bet} fixtures={roundState.fixtures}
            operatorStakePerRow={roundState.operatorStakePerRow} operatorFeePerRow={roundState.operatorFeePerRow}
            proPickName="X" itemDisplay="rowsPerShare" />);
    }
    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
                className={classes.root}
            >
                <Grid item xs={12}>
                    <Grid container direction="column">
                        <Typography>Round: {props.roundId}</Typography>
                        <Typography>Rows: {rowCount}</Typography>
                        {/* <Typography>Coupon: {params.couponId}</Typography> */}
                        {/* <Button onClick={e => onShowProPick()} variant="contained" color="secondary" size="small">ProPick</Button> */}
                        {proPickDlg}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Game</TableCell>
                                    <TableCell className={classes.signHeader}>1</TableCell>
                                    <TableCell className={classes.signHeader}>X</TableCell>
                                    <TableCell className={classes.signHeader}>2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    roundState.fixtures.map((fixtureX, index) => {

                                        let selection = props.bet.legs[index]?.signs;
                                        let fixture = fixtureX.fixture;

                                        if (selection === undefined) {
                                            return (<TableRow key={'fixture' + index}></TableRow>)
                                        }

                                        let classHome = selection.includes(RoundOverviewUtil.ONE_OUTCOME_SIGN) ? classes.selected : "";
                                        let classDraw = selection.includes(RoundOverviewUtil.X_OUTCOME_SIGN) ? classes.selected : "";
                                        let classAway = selection.includes(RoundOverviewUtil.TWO_OUTCOME_SIGN)  ? classes.selected : "";

                                        return (
                                            <TableRow key={'fixture' + index}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell><RofTitle fixture={fixtureX} editEnabled={false} /></TableCell>
                                                {/* <TableCell className={classHome} style={{cursor: "pointer"}} onClick={e => onCouponClick(index, RoundOverviewUtil.ONE_OUTCOME_SIGN)}>{RoundOverviewUtil.ONE_OUTCOME_FRIENDLY_SIGN}</TableCell>
                                                <TableCell className={classDraw} style={{cursor: "pointer"}} onClick={e => onCouponClick(index, RoundOverviewUtil.X_OUTCOME_SIGN)}>{RoundOverviewUtil.X_OUTCOME_FRIENDLY_SIGN}</TableCell>
                                                <TableCell className={classAway} style={{cursor: "pointer"}} onClick={e => onCouponClick(index, RoundOverviewUtil.TWO_OUTCOME_SIGN)}>{RoundOverviewUtil.TWO_OUTCOME_FRIENDLY_SIGN}</TableCell> */}
                                                <TableCell className={classHome} style={{cursor: "pointer"}} onClick={e => onCouponClick(index, RoundOverviewUtil.ONE_OUTCOME_SIGN)}>{renderSign(0, fixtureX)}</TableCell>
                                                <TableCell className={classDraw} style={{cursor: "pointer"}} onClick={e => onCouponClick(index, RoundOverviewUtil.X_OUTCOME_SIGN)}>{renderSign(1, fixtureX)}</TableCell>
                                                <TableCell className={classAway} style={{cursor: "pointer"}} onClick={e => onCouponClick(index, RoundOverviewUtil.TWO_OUTCOME_SIGN)}>{renderSign(2, fixtureX)}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>

                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )

}

export default CouponEdit;
