import React from "react";
import { useState } from 'react';
import { Button, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { getFirstDayThisWeekAsString, getFirstDayofYearOrStartAsString, getShortMonthName } from "../common/helpers/DateHelpers";
import {  formatToEuro, formatToPercent} from "../common/helpers/CurrencyHelpers";
import { SlcPerMonthData, getSlcPerMonthReportItems } from "../common/helpers/ApiHelper";

function SlcPerMonthReport() 
{
    let [strFromDate, setStrFromDate] = useState(() => getFirstDayofYearOrStartAsString('YYYY-MM-DD'))
    let [strToDate, setStrToDate] = useState(() => getFirstDayThisWeekAsString('YYYY-MM-DD'))

    const [slcReportData, setslcReportData] = useState(() => new SlcPerMonthData(0, 0, 0, 0, 0, 0, []))

    function loadData() 
    {
        getSlcPerMonthReportItems(strFromDate, strToDate).then((data) => 
        {
            setslcReportData(data);
        });
    }

    let slcPerMonthData: SlcPerMonthData = new SlcPerMonthData(0, 0, 0, 0, 0, 0, []);
    slcPerMonthData = slcReportData;

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <TextField
                        id="fromDate"
                        label="From:"
                        type="date"
                        defaultValue={strFromDate}
                        onChange={(e) => { setStrFromDate(e.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="toDate"
                        label="To:"
                        type="date"
                        defaultValue={strToDate}
                        onChange={(e) => { { setStrToDate(e.target.value) } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button variant="contained" onClick={() => { loadData() }}>Get</Button>
                    <SlcReportTable reportData={slcPerMonthData} />
                </Grid>
            </Grid>
        </Container>
    );
}

export default SlcPerMonthReport


function SlcReportTable({ reportData }: { reportData: SlcPerMonthData }) 
{
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Stake</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>ProfitLoss</TableCell>
                    <TableCell>Kickback</TableCell>
                    <TableCell>P/L after Kickback</TableCell>
                    <TableCell>External Turnover</TableCell>
                    <TableCell>Margin vs External</TableCell> 
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    reportData.slcPerMonthReportItems.map((round, index) => {
                        return (
                            <TableRow key={'row' + index}>
                                <TableCell key={'month' + index}>{getShortMonthName(round.monthIndex)}</TableCell>
                                <TableCell key={'stake' + index}>{formatToEuro(round.stake)}</TableCell>
                                <TableCell key={'result' + index}>{formatToEuro(round.result)}</TableCell>
                                <TableCell key={'profitLoss' + index}>{formatToEuro(round.profitLoss)}</TableCell>
                                <TableCell key={'kickback' + index}>{formatToEuro(round.kickback)}</TableCell>
                                <TableCell key={'plAfterKickback' + index}>{formatToEuro(round.plAfterKickback)}</TableCell>
                                <TableCell key={'externalTurnover' + index}>{formatToEuro(round.externalTurnover)}</TableCell>
                                <TableCell key={'externalTurnover' + index}>{formatToPercent(round.plAfterKickback/round.externalTurnover)}</TableCell>
                            </TableRow>);
                    })
                   
                }
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{formatToEuro(reportData.totalStake)}</TableCell>
                        <TableCell>{formatToEuro(reportData.totalResult)}</TableCell>
                        <TableCell>{formatToEuro(reportData.totalProfitLoss)}</TableCell>
                        <TableCell>{formatToEuro(reportData.totalKickback)}</TableCell>
                        <TableCell>{formatToEuro(reportData.totalProfitLossAfterKickback)}</TableCell>
                        <TableCell>{formatToEuro(reportData.totalExternalTurnover)}</TableCell>
                        <TableCell>{formatToPercent(reportData.totalProfitLossAfterKickback/reportData.totalExternalTurnover)}</TableCell>
                    </TableRow>
            </TableBody>

        </Table>
    );
}