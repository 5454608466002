import firebasePromise from "./firebase";

export function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}

const fetchAccessToken = async () => {
    const firebase = await firebasePromise;
    const currentUser = await getCurrentUser(firebase.auth());
    if (currentUser == null) {
        return null;
    }
    return await currentUser.getIdToken(false);
};

export const getAccessToken = async () => {
    return fetchAccessToken();
};

