import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { TextField, Button, Box, FormLabel, Container, IconButton, Tooltip, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { Title } from 'react-admin';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import { Fixture, Team } from '../RoundOverview/RoundOverviewModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { GenericResponse, LeagueListItem, LogEntry } from '../CreateRound/CreateRoundModels';
import DateTimePickerX, { DateTimePickerXValue, getDateTimePickerXValue } from './DateTimePickerX';
import ChooseLeagueDialog from '../RoundOverview/ChooseLeagueDialog';
import TeamInput from './TeamInput';


interface CreateFixtureState {
    startingAt: DateTimePickerXValue;
    homeTeam?: Team;
    awayTeam?: Team;
    league?: LeagueListItem
}



const CreateFixture: React.FC = () => {
    const getDefaultFormState = () : CreateFixtureState => {
        return {
            startingAt: getDateTimePickerXValue(CreateRoundUtil.getDefaultStartingAt())
        };
    }

    const history = useHistory();

    const [formState, setFormState] = useState<CreateFixtureState>(getDefaultFormState());
    const [fixtureId, setFixtureId] = useState<string>("");
    const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
    };
    const handleChange2 = (name: string, value : any) => {
        setFormState({ ...formState, [name]: value });
    };
    const onSaveError = (error: string) => {
        setSaveError(error);
        logEntries.push(CreateRoundUtil.createLogEntry(error, true));
        setLogEntries([...logEntries])
    };
  
    const addSaveInfo = (info: string) => {
          logEntries.push(CreateRoundUtil.createLogEntry(info, false));
          setLogEntries([...logEntries])
    };
    const addSaveError = (info: string) => {
        logEntries.push(CreateRoundUtil.createLogEntry(info, true));
        setLogEntries([...logEntries])
    };
    const onCreate = () => {
        
        // Add form submission logic here
        //console.log(formData);

        CreateRoundUtil.clearArray(logEntries);
        setLogEntries([...logEntries]);
        setSaveError("");

        var valid = true;
        var startingAt = formState.startingAt.dateTime ? formState.startingAt.dateTime : new Date();
        var homeTeamId = formState.homeTeam ? formState.homeTeam.id : "";
        var awayTeamId = formState.awayTeam ? formState.awayTeam.id : "";
        var leagueId = formState.league ? formState.league.id : "";

        if (!formState.startingAt.dateTime) {
            valid = false;
            addSaveError("'Starting at' is not specified or not valid.");
        } else if (formState.startingAt.dateTime <= new Date()) {
            valid = false;
            addSaveError("'Starting at' less than or equal to now.");
        }

        if (!homeTeamId || homeTeamId.length <= 0) {
            valid = false;
            addSaveError("'Home team' is not specified.");
        }
        if (!awayTeamId  || awayTeamId.length <= 0) {
            valid = false;
            addSaveError("'Away team' is not specified.");
        }
        if (valid && homeTeamId === awayTeamId){
            valid = false;
            addSaveError("'Home team' and Away team' are the same.");
        }
        if (!leagueId || leagueId.length <= 0) {
            valid = false;
            addSaveError("'League' is not specified.");
        }

        if (!valid) {
            return;
        }

        //Saving
        setSaveStatus(1);
        addSaveInfo("Input is OK, start saving");
        CreateRoundUtil.createFixture(leagueId, homeTeamId, awayTeamId, startingAt).then((resp) => {
            if (resp.success) {
                if (resp.item) {
                    setFixtureId(resp.item.id);
                }
                addSaveInfo("CreateFixture done [Id:" + resp.item?.id + "]");
                setSaveStatus(2);
            } else {
                addSaveError("CreateFixture error: " + resp.message + "]");
                setSaveStatus(0);
            }
        }).catch(error => {
            console.error('CreateFixture error!', error);
            onSaveError("CreateFixture error (handleSubmit): " + error);
            setSaveStatus(0);
        });
    };
    const onChangeStartingAt = (value: DateTimePickerXValue) => {
        setFormState({ ...formState, ["startingAt"]: value });
    };
    const onChangeHomeTeam = (team?: Team) => {
        setFormState({ ...formState, ["homeTeam"]: team });
    };
    const onChangeAwayTeam = (team?: Team) => {
        setFormState({ ...formState, ["awayTeam"]: team });
    };
    const onCloseLeagueDlg = (league?: LeagueListItem) => {
        if (!league) {
            return;
        }
        setFormState({ ...formState, ["league"]: league });
    };

    const onEditFixture = () => {
        history.push("fixtures/" + fixtureId);

    };


    var actionInfoSpan : any = "";
    if (fixtureId.length > 0 && saveStatus === 2) {
        if (saveError.length > 0) {
            actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{`Fixture was created with problem [Id:${fixtureId}]... ${saveError}`}</span>);
        } else {
            actionInfoSpan = (<span style={{ color: "blue", paddingLeft: "50" }}>&nbsp;{`Fixture was successfully created [Id:${fixtureId}].`}</span>);
        }
    } else if (saveError.length > 0) {
      actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{saveError}</span>);
    }
    // var defaultStartingAt = formState.startingAt ? formState.startingAt : CreateRoundUtil.getDefaultStartingAt();
    var editEnabled = (saveStatus <= 0);
    var saveEnabled = editEnabled;
    if (saveEnabled) {
        if (!formState.startingAt || !formState.homeTeam || !formState.awayTeam || !formState.league) {
            saveEnabled = false;
        }
    }
    var chooseLeagueDlg = null;
    if (editEnabled) {
        chooseLeagueDlg = (<ChooseLeagueDialog open={false} openLabel="Change" onClose={onCloseLeagueDlg} />);
    }
    return (
        <Container maxWidth="lg">
            <Title title="Create Fixture" />
                <Box paddingTop={2}>
                    <DateTimePickerX label="Starting at" value={formState.startingAt} disabled={!editEnabled} onChange={onChangeStartingAt} />
                </Box>
                <Box paddingTop={2}>
                    <TeamInput label="Home team" team={formState.homeTeam} disabled={!editEnabled} onChange={onChangeHomeTeam} />
                </Box>
                <Box paddingTop={2}>
                    <TeamInput label="Away team" team={formState.homeTeam} disabled={!editEnabled} onChange={onChangeAwayTeam} />
                </Box>
                <Box marginTop={1} display="flex" alignItems="center">
                <TextField
                    margin="dense"
                    name="leagueName"
                    value={formState.league ? formState.league.title : ""}
                    label="League"
                    helperText={formState.league ? formState.league.id : "Required"}
                    error={!formState.league}
                    InputProps={{ readOnly: true }}
                    style={{minWidth:350}}
                    />
                    <span style={{ padding: 2 }}></span>
                    {chooseLeagueDlg}
                </Box>
                <Box paddingTop={2}>
                    <Button variant="contained" color="primary" type="button" disabled={!saveEnabled} onClick={onCreate}>Create</Button>
                    {actionInfoSpan}
                </Box>
                    {fixtureId.length > 0 && saveStatus === 2
                    ? <Box paddingTop={2}>
                        <Button style={{textTransform:"none"}} color="secondary" onClick={(e) => onEditFixture()}>Edit Fixture</Button>
                    </Box>
                    : ""
                    }
            <Box paddingTop={2}>
                {logEntries.map((info) => (
                    <p style={{ color: CreateRoundUtil.getLogEntryColor(info.isError) }}>{info.message}</p>
                ))}
            </Box>
        </Container>
    );
};

export default CreateFixture;

