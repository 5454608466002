import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link } from '@material-ui/core';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { FreebetOverviewItem } from './FreebetModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';

interface FreebetOverviewTableProps {
    items: FreebetOverviewItem[];
    onSelect? : (item : FreebetOverviewItem) => void;
    onDeselect? : () => void;
}

const FreebetOverviewTable: React.FC<FreebetOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<FreebetOverviewItem>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<FreebetOverviewItem>[] = [
        { id: 'freebetCampaignId', numeric: false, disablePadding: true, label: "Campaign Id", renderValue: (item: FreebetOverviewItem) => (<Link component={RouterLink} to={"editFreebetCampaign/" + item.freebetCampaignId}>{item.freebetCampaignId}</Link>) },
        { id: 'freebetCampaignName', numeric: false, disablePadding: false, label: 'Campaign name' },
        { id: 'freebetTypeName', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'rowsPerShare', numeric: true, disablePadding: false, label: 'Rows per share' },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: FreebetOverviewItem) => CreateRoundUtil.toGameDateString(item.createdAt) },
        { id: 'maxNumberOfFreebets', numeric: true, disablePadding: false, label: 'Max Freebets' },
        { id: 'currentNumberOfFreebets', numeric: true, disablePadding: false, label: '#Freebets' },
        { id: 'validFrom', numeric: false, disablePadding: false, label: 'Valid from', renderValue: (item: FreebetOverviewItem) => CreateRoundUtil.toGameDateString(item.validFrom) },
        { id: 'validTo', numeric: false, disablePadding: false, label: 'Valid to', renderValue: (item: FreebetOverviewItem) => CreateRoundUtil.toGameDateString(item.validTo) },
        { id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled' },
      ];
      setColumns(cols);
    }
    const onSelect = (item : FreebetOverviewItem) => {
        setSelectedId(item.freebetCampaignId);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const onDeselect = () => {
      setSelectedId(0);
      if (props.onDeselect){
        props.onDeselect();
        return;
    }
  };

  return (
    <DataTable columns={columns} items={props.items} getId={it => it.freebetCampaignId} orderBy='freebetCampaignId' order='desc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect}   />
    );
};
  
export default FreebetOverviewTable;
