import React, { useState, useEffect } from 'react';
import { Title } from 'react-admin';
import { Container, TextField, Button, MenuItem, Box, InputLabel } from "@material-ui/core";
import { RoundType } from '../models/RoundType';
import { getList, getObject } from '../api/dataProvider';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { GenericResponse, LeagueListItem, LogEntry, SaveOutcomeParams, SaveRoundFixture, SaveRoundParams, TeamItem } from '../CreateRound/CreateRoundModels';
import { Fixture, Round } from '../RoundOverview/RoundOverviewModels';
import fetchApi from '../../fetchApi';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import { useHistory } from 'react-router-dom';
import MockopApiUtil from '../ExternalApi/MockopApiUtil';
import PlayerApiUtil from '../ExternalApi/PlayerApiUtil';
import { Bet, Leg, PlaceBetsInput } from '../ExternalApi/PlayerApiModels';

interface PlaceFakeBetsState {
  userName: string;
  password: string
}
interface PlaceFakeBetsProps {
  round: Round | null | undefined;
  roundType: RoundType | null | undefined;
}
const PlaceFakeBets: React.FC<PlaceFakeBetsProps> = (props) => {
  const [formData, setFormData] = useState<PlaceFakeBetsState>({
    userName: "",
    password: ""
  });
  const [userNameError, setUserNameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const [error, setError] = useState<string>("");
  const [authHeader, setAuthHeader] = useState<string>("");
  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
  const [saveError, setSaveError] = useState<string>("");
  const [saveStatus, setSaveStatus] = useState<number>(0);
  
  const history = useHistory();


  var roundTypeDefJson: string = ''
  var nFixtures: number = 0;
  var roundTypeName: string | null = null;
  var roundType = props.roundType;
  if (roundType) {
    roundTypeDefJson = JSON.stringify(roundType);
    nFixtures = roundType?.parameters.nFixtures;
    roundTypeName = roundType?.parameters?.name;
  }

  const onSaveError = (error: string) => {
    setSaveError(error);
    logEntries.push(CreateRoundUtil.createLogEntry(error, true));
    setLogEntries([...logEntries])
  };

  const addSaveInfo = (info: string) => {
    logEntries.push(CreateRoundUtil.createLogEntry(info, false));
    setLogEntries([...logEntries])
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //Returns number of bets placed
  const placeBets = async (userName: string, password: string): Promise<GenericResponse<number>> => {
    const METHOD_NAME = "PlaceFakeBets.placeBets";
    var placedCount = 0;
    try {
      var signInResp = await MockopApiUtil.signInMockopApi(userName, password);
      if (!signInResp.success) {
        return CreateRoundUtil.createGenericResponse<number>(false, "SignInMockopApi error: " + signInResp.message, placedCount);
      }
      var mockopSignIn = signInResp.item;
      if (!mockopSignIn || !mockopSignIn.token || mockopSignIn.token.length <= 0) {
        return CreateRoundUtil.createGenericResponse<number>(false, "SignInMockopApi failure: result/token was not returned.", placedCount);
      }
      var operatorPlayerId = mockopSignIn.playerId;
      addSaveInfo(`SignInMockop done [OperatorPlayerId:${operatorPlayerId}]`);

      var signInPlayerResp = await PlayerApiUtil.signInPlayerApi(mockopSignIn);
      if (!signInPlayerResp.success) {
        return CreateRoundUtil.createGenericResponse<number>(false, "SignInPlayerApi error: " + signInPlayerResp.message, placedCount);
      }
      var signInPlayerResult = signInPlayerResp.item;
      if (!signInPlayerResult || !signInPlayerResult.access_token || signInPlayerResult.access_token.length <= 0) {
        return CreateRoundUtil.createGenericResponse<number>(false, "SignInPlayerApi failure: result/token was not returned.", placedCount);
      }
      addSaveInfo("SignInPlayerApi done");

      var playerToken = signInPlayerResult.access_token;
      var getPlayerInfoResp = await PlayerApiUtil.getPlayerInfo(playerToken);
      if (!getPlayerInfoResp.success) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetPlayerInfo error: " + getPlayerInfoResp.message, placedCount);
      }
      var playerInfo = getPlayerInfoResp.item;
      if (!playerInfo) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetPlayerInfo failure: result was not returned.", placedCount);
      }
      var playerUrl = playerInfo?._links?.me?.href;
      if (!playerUrl) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetPlayerInfo failure: playerUrl (_links.me.href) was not returned.", placedCount);
      }
      var playerSearch = "/players/";
      var playerIdPos = playerUrl.indexOf(playerSearch);
      if (playerIdPos < 0) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetPlayerInfo failure: playerId could not extracted from playerUrl (_links.me.href).", placedCount);
      }
      playerIdPos += playerSearch.length;
      var playerId = playerUrl.substring(playerIdPos);
      if (playerId.endsWith("/")) {
        playerId = playerId.substring(0, playerId.length - 2);
      }
      addSaveInfo(`GetPlayerInfo [PlayerId:${playerId}]`);

      var roundId = props.round?.id;
      if (!roundId || roundId.length <= 0) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetRound failure: roundId was not specified.", placedCount);
      }
      var roundResp = await PlayerApiUtil.getPlayerRound(roundId, playerToken);
      if (!roundResp.success) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetPlayerRound(" + roundId + ") error: " + roundResp.message, placedCount);
      }
      var round = roundResp.item?._embedded?.round;
      if (!round) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetPlayerRound failure: result/round was not returned.", placedCount);
      }
      if (round.status !== RoundOverviewUtil.ACTIVE_STATUS) {
        return CreateRoundUtil.createGenericResponse<number>(false, `GetPlayerRound failure: round is not active [Status:${round.status}].`, placedCount);
      }
      addSaveInfo("GetPlayerRound done");

      var mockopPlayerResp = await MockopApiUtil.getMockopPlayerInfo(operatorPlayerId, mockopSignIn.token);
      if (!mockopPlayerResp.success) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetMockopPlayerInfo(" + operatorPlayerId + ") error: " + roundResp.message, placedCount);
      }
      var balance = mockopPlayerResp.item?.balance;
      if (!balance || !balance.currency || balance.currency.length <= 0) {
        return CreateRoundUtil.createGenericResponse<number>(false, "GetMockopPlayerInfo failure: result/balance.currency was not returned.", placedCount);
      }
      if (round?.operatorStakePerRow.currency !== balance.currency) {
        return CreateRoundUtil.createGenericResponse<number>(false, `GetMockopPlayerInfo failure: round.operatorStakePerRow.currency (${round?.operatorStakePerRow.currency}) differ from mockopPlayer.balance.currency (${balance.currency}).`, placedCount);
      }
      addSaveInfo(`GetMockopPlayerInfo [Currency:${balance.currency}]`);

      //Place 2 bets
      for (var iBet = 0; iBet < 2; iBet++) {
        var placeInput: PlaceBetsInput = {
          bets: [],
          feePerRow: round.operatorFeePerRow,
          roundId: round.id,
          stakeMultiplier: 1,
          stakePerRow: round.operatorStakePerRow
        };
        var bet: Bet = {
          system: {
            type: "MATHEMATICAL"
          },
          legs: []
        };
        if (iBet == 0) {
          //Bet #1, all X
          for (var iLeg = 0; iLeg < round.fixtures.length; iLeg++) {
            var leg: Leg = {
              signs: ["X"]
            };
            bet.legs.push(leg);
          }
        } else {
          //Bet #2, first 7 legs full cover, rest 2
          var fullCoverCount = Math.min(7, round.fixtures.length - 1);
          for (var iLeg = 0; iLeg < fullCoverCount; iLeg++) {
            var leg: Leg = {
              signs: ["ONE", "X", "TWO"]
            };
            bet.legs.push(leg);
          }
          for (var iLeg = fullCoverCount; iLeg < round.fixtures.length; iLeg++) {
            var leg: Leg = {
              signs: ["TWO"]
            };
            bet.legs.push(leg);
          }

        }
        placeInput.bets.push(bet);
        var placeBetsResp = await PlayerApiUtil.placeBets(placeInput, playerId, playerToken, true);
        if (!placeBetsResp.success) {
          return CreateRoundUtil.createGenericResponse<number>(false, `PlaceBets.${iBet} error: ${placeBetsResp.message}.`, placedCount);
        }
        var placeBetsResult = placeBetsResp.item;
        placedCount++;
        var placeBetsResultJson = placeBetsResult ? JSON.stringify(placeBetsResult) : "";
        addSaveInfo(`PlaceBets.${iBet} done... ${placeBetsResultJson}`);
      }
      return CreateRoundUtil.createGenericResponse<number>(true, "", placedCount);
    } catch (error) {
      console.error(METHOD_NAME + " error.", error);
      return CreateRoundUtil.createGenericResponse<number>(false, CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error"), placedCount);
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var valid = true;
    var userNameErr = "";
    var passwordErr = "";
    var saveErr = "";
    if (formData.userName.length <= 0) {
      valid = false;
      userNameErr = "Required";
    }
    if (formData.password.length <= 0 ) {
      valid = false;
      passwordErr = "Required";
    }

    setUserNameError(userNameErr);
    setPasswordError(passwordErr);
    CreateRoundUtil.clearArray(logEntries);
    setLogEntries([...logEntries]);
    setSaveError(saveErr);

    if (!valid) {
      return;
    }



    //Saving
    setSaveStatus(1);
    addSaveInfo("Start placing bets");
    placeBets(formData.userName, formData.password).then((placeBetsResp) => {
      var placedCount = placeBetsResp.item;
      if (placeBetsResp.success) {
        addSaveInfo("PlaceFakeBets done [Message:" + placeBetsResp.message + "] [PlacedCount:" + placedCount + "]");
      } else {
        onSaveError("PlaceFakeBets error [Message:" + placeBetsResp.message + "] [PlacedCount:" + placedCount + "]");
      }
      if (placedCount && placedCount > 0) {
        //Saved something
        setSaveStatus(2);
      } else {
        //Not saved
        setSaveStatus(0);
      }
    }).catch(error => {
      console.error('PlaceFakeBets error!', error);
      onSaveError("PlaceFakeBets error (handleSubmit): " + error);
      setSaveStatus(0);
    });
  };

  const onGotoRounds = () => {
    history.push('rounds');
  };
  const onEditRound = () => {
    history.push("rounds/" + props.round?.id);

  };
  const onGotoRoundOverview = () => {
    history.push('roundOverview');
  };

  var createButtonDisabled = (saveStatus > 0) ? true : false;
  
  var actionInfoSpan : any = "";
  if (saveStatus === 2) {
    actionInfoSpan = (<span style={{ color: "blue", paddingLeft: "50" }}>&nbsp;{"Fake bets were successfully placed."}</span>);
  } else if (saveError.length > 0) {
    actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{saveError}</span>);
  }

    return (
      <Container maxWidth="lg">
        <Title title="Place Fake Bets" />
        <Box>
        <TextField
            label="Round Type"
            name="roundTypeName"
            value={roundType?.parameters.name}
            helperText={"id:" + props.roundType?.id + "; " + props.roundType?.parameters.nFixtures.toString() + " fixtures"}
            InputProps={{
              readOnly: true,
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            label="Round"
            name="roundId"
            value={props.round?.id}
            helperText={"Open To:" + CreateRoundUtil.toGameDateString(props.round?.openTo) + "; Status:" + props.round?.status}
            InputProps={{
              readOnly: true,
            }}
          />
         </Box>
        <Box>
        <h2>Mockop credentials</h2>
        <form onSubmit={handleSubmit}>
          <Box width="250px">
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              name="userName"
              value={formData.userName}
              autoComplete="userName"
              autoFocus
              error={userNameError && userNameError.length > 0 ? true : false}
              helperText={userNameError}
              onChange={handleChange}
            />
          </Box>
          <Box width="250px">
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={formData.password}
              autoComplete="current-password"
              error={passwordError && passwordError.length > 0 ? true : false}
              helperText={passwordError}
              onChange={handleChange}
            />
          </Box>

          <Box paddingTop={2}>
            <Button type="submit" variant="contained" color="primary" disabled={createButtonDisabled}>Place</Button>
            {actionInfoSpan}
          </Box>
        </form>
        </Box>
        <Box paddingTop={2}>
          {logEntries.map((info) => (
            <p style={{ color: CreateRoundUtil.getLogEntryColor(info.isError) }}>{info.message}</p>
          ))}
        </Box>
      </Container>
    );
};

export default PlaceFakeBets;

