import React from "react";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { Operator, OperatorFilter } from "../Admin/AdminModels";
import { QueryParams } from "../models/CouponShare";
import AdminUtil from "../Admin/AdminUtil";
import ItemSelect from "./ItemSelect";
import { ListResponse } from "../api/ApiNgModels";

interface OperatorSelectProps {
    value: string;
    disabled?: boolean;
    filter?: (item: Operator) => boolean;
    onChange: (item: Operator | undefined) => void;
    allItemEnabled: boolean;
    helperText?: string;
    error?: boolean;
}
const OperatorSelect: React.FC<OperatorSelectProps> = (props) => {
      
    const getOperators = async (pageSize: number, lastId?: string) : Promise<GenericResponse<ListResponse<Operator>>> => {
        var filter : OperatorFilter = {
            lastId: lastId ? lastId : ""
        };
        var params : QueryParams = {
            pagination: { page: 0, perPage: pageSize },
            filter: filter
            //sort: { field: state.orderBy, order: state.orderDirection }
        }
        return await AdminUtil.getOperators(params);
    };


    const getId = (item?: Operator) : string => {
        return item ? item.id : "";
    };

    const onChange = (item?: Operator) : void => {
        if (props.onChange) {
            props.onChange(item);
        }
    };
  
    return (
        <ItemSelect<Operator, string>
            value={props.value}
            label="Operator"
            getId={getId}
            getIdString={getId}
            getName={getId}
            getItems={getOperators}
            idFromString={s => s ? s : ""}
            onChange={onChange}
            disabled={props.disabled}
            filter={props.filter}
            getInfoText={it => it ? `Id: ${it.id} | Name:  ${it.name}` : ""}
            allItemEnabled={props.allItemEnabled}
            helperText={props.helperText}
            error={props.error}
    />
    );
};

export default OperatorSelect;
