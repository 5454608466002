import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link } from '@material-ui/core';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { AgentPlayerOverviewItem } from './AgentModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { friendlyName } from '../common/helpers/RoundHelper';
import Ticker2Util from '../Ticker2/Ticker2Util';
import { formatToCurrency } from '../common/helpers/CurrencyHelpers';

interface AgentPlayerTableProps {
    items: AgentPlayerOverviewItem[];
    onSelect? : (item : AgentPlayerOverviewItem) => void;
}

const AgentPlayerTable: React.FC<AgentPlayerTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<AgentPlayerOverviewItem>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<AgentPlayerOverviewItem>[] = [
        { id: 'playerId', numeric: false, disablePadding: true, label: "Player", filterEnabled: true, renderValue: (item: AgentPlayerOverviewItem) => { return (<Link component={RouterLink} to={`players/${item.playerId}`}>{item.playerId}</Link>); } },
        { id: 'playerExternalId', numeric: false, disablePadding: false, label: 'External id', filterEnabled: true },
        { id: 'playerNotes', numeric: false, disablePadding: false, label: 'Notes', filterEnabled: true },
        { id: 'nSyndicates', numeric: true, disablePadding: false, label: '#Syndicates' },
        { id: 'nActiveSyndicates', numeric: true, disablePadding: false, label: '#Actives' },
        { id: 'nDraftSyndicates', numeric: true, disablePadding: false, label: '#Drafts' },
        { id: 'nShares', numeric: true, disablePadding: false, label: '#Shares' },
        { id: 'maxShares', numeric: true, disablePadding: false, label: '#Max shares' }
      ];
      setColumns(cols);
    }
    const onSelect = (item : AgentPlayerOverviewItem) => {
        setSelectedId(item.playerId);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
  
  return (
    <DataTable columns={columns} items={props.items} getId={it => it.playerId} orderBy='playerId' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect} rowsPerPage={25}  />
    );
};
  
export default AgentPlayerTable;
