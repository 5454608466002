import React from 'react';
import Box from '@material-ui/core/Box';
import { Fixture, RoundOverviewFixture } from './RoundOverviewModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import FixtureDialog from './FixtureDialog';

interface RofTitleProps {
  fixture: RoundOverviewFixture;
  editEnabled?: boolean;
  onChange?: (fixture? : Fixture) => void;
}

const RofTitle: React.FC<RofTitleProps> = (props) => {

  const onClose = (fixture? : Fixture) => {
    if (props.onChange && fixture){
      props.onChange(fixture);
    }
  };
  var fixture = props.fixture;
  var fixtureName : any = fixture.name;
  if (props.editEnabled) {
    fixtureName = (<FixtureDialog open={false} fixture={fixture} onClose={onClose} nameLinkEnabled={true} />)
  }

  return (
    <div>
      {fixtureName}
      <Box fontSize={11}>{CreateRoundUtil.toGameDateString(fixture.startingAt)} | {fixture.leagueName}</Box>
    </div>
  );
};
export default RofTitle;
