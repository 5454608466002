import React from 'react';
import Box from '@material-ui/core/Box';
import { RoundOverviewFixture } from './RoundOverviewModels';
import { Table, TableCell, TableRow } from '@material-ui/core';
import RoundOverviewUtil from './RoundOverviewUtil';

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  style: { width: '2em', height: '1.5em', margin:'2px' },
  borderColor: 'text.primary',
  //fontSize:24
};
const signProps = {
    bgcolor: 'lightgray'
  };
interface RofResultProps {
    fixture: RoundOverviewFixture;
}

const RofResult: React.FC<RofResultProps> = (props) => {
    var row = props.fixture;
    var homeScore = row.homeScore.toString();
    var awayScore = row.awayScore.toString();
    var statusText = row.status + " (" + row.providerStatus + ")";
    var statusColor = "";
    var resultText = null;
    var resultColor = "";
    var scoreProps = null;
    if (row.drawSign && row.drawSign.length > 0) {
      homeScore = "";
      awayScore = "";
      statusText = "Drawn";
      statusColor = "red";
      scoreProps = signProps;
    }

    if (row.result) {
      resultText = `Round.fixtureResult: ${row.result.sign} (${row.result.status})`;
      if (row.result.sign !== row.sign){
        resultColor = "red";
      } else {
        resultColor = "green"
      }
      // if (row.drawResult && row.drawResult.length > 0) {
      //   RoundOverviewUtil.sign
      // }
    }
    return (
      <div>
         <Box display="flex" padding={0} margin="0" >
            <Box border={1} textAlign="center" display="flex" justifyContent="center" {...defaultProps} {...scoreProps}>{homeScore}</Box>
            -<Box border={1} textAlign="center" {...defaultProps} {...scoreProps}>{awayScore}</Box>&nbsp;&nbsp;
            <Box border={1} textAlign="center" {...defaultProps} {...signProps}>{RoundOverviewUtil.getFriendlySign(row.sign)}</Box>
            <Box color={statusColor}>
              {statusText}
            </Box>
       </Box>
       <Box fontSize={10} color={resultColor}>{resultText}</Box>
       </div>
 /*        <Table>
        <TableRow>
            <TableCell>{row.homeScore}</TableCell>
            <TableCell>{row.awayScore}</TableCell>
            <TableCell>{row.sign}</TableCell>
        </TableRow>
       </Table> */
 /*    <Box display="flex" justifyContent="center">
      <Box border={1} {...defaultProps}>{row.homeScore}</Box>
      <Box border={1} {...defaultProps}>{row.awayScore}</Box>
      <Box border={1} {...defaultProps}>{row.sign}</Box>
    </Box>
 */  );
};
export default RofResult;
