import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from '@material-ui/core';

const VerboseSelectFormField = ({input: {onChange, value}, label, choices}) =>
  <FormControl>
    {label && <InputLabel>{label}</InputLabel>}
    <Select value={value} onChange={onChange}>
      {choices.map(c =>
        <MenuItem key={c.id} value={c.id}><ListItemText primary={c.name} secondary={c.desc}/></MenuItem>
      )}
    </Select>
  </FormControl>;

export default VerboseSelectFormField;