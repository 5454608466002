import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Tab, Tabs, Box, Typography, Button, TextField } from "@material-ui/core";
import { RoundType } from '../models/RoundType';
import { getList, getObject } from '../api/dataProvider';
import { Round, RoundOverviewItem } from "./RoundOverviewModels";
import RoundOverviewUtil from "./RoundOverviewUtil";
import RoundOverviewTable from "./RoundOverviewTable";
import { LeagueListItem } from "../CreateRound/CreateRoundModels";
import RoundOverviewGeneral from "./RoundOverviewGeneral";
import fetchApi from "../../fetchApi";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { formatToCurrency } from "../common/helpers/CurrencyHelpers";
import RoundOverviewTicker from "./RoundOverviewTicker";
import { TabPanel } from "../Components/TabPanel";

interface RoundOverviewState {
    fromInput: string;
    toInput: string;
    limitInput: string;
    from?: Date;
    to?: Date;
    limit?: number;
    valid: boolean;
    fromError: string;
    toError: string;
    limitError: string;
}


const RoundOverview: React.FC = () => {
    const [formState, setFormState] = useState<RoundOverviewState>({
        fromInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), -7)),
        toInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), 30)),
        limitInput: "25",
        from: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), -7)),
        to: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 30)),
        limit: 25,
        valid: true,
        fromError: "",
        toError: "",
        limitError: ""
    });

    const [actionError, setActionError] = useState<string>("");
    const [rounds, setRounds] = useState<RoundOverviewItem[]>([]);
    const [selectedRound, setSelectedRound] = useState<RoundOverviewItem>(RoundOverviewUtil.getEmptyRoundOverviewItem());
    const [selectedTab, setSelectedTab] = useState(() => 0)
    const [leagues, setLeagues] = useState<LeagueListItem[]>([]);
    const [env, setEnv] = useState<string>(RoundOverviewUtil.getEnv());
    const history = useHistory();


    useEffect(() => {
        var s = sessionStorage.getItem("RoundOverviewState");
        var fs = formState;
        if (s && s.length > 0) {
            var fs1 = JSON.parse(s);
            if (fs1) {
                checkFormState(fs1);
                if (fs1.valid) {
                    setFormState(fs1);
                    fs = fs1;
                }
            }
        }
        searchRounds("InitRounds", fs);
    }, []);

    const checkFormState = (fs: RoundOverviewState) => {
        var valid = true;
        var from = CreateRoundUtil.parseDateX(fs.fromInput);
        var to = CreateRoundUtil.parseDateX(fs.toInput);
        var limit = parseInt(fs.limitInput);
        var dateTime = from;
        var fromErr = "";
        var toErr = "";
        var limitErr = "";
        if (fs.fromInput.length > 0) {
            var dateNumber = from.getDate();
            if (isNaN(dateNumber)) {
                valid = false;
                fromErr = "Invalid date";
            } else {
                fs.from = from;
            }
        }
        else {
            valid = false;
            fromErr = "Required";
        }
        if (fs.toInput.length > 0) {
            var dateNumber = to.getDate();
            if (isNaN(dateNumber)) {
                valid = false;
                fromErr = "Invalid date";
            } else {
                fs.to = to;
            }
        }
        else {
            valid = false;
            fromErr = "Required";
        }
        if (fs.limitInput.length > 0) {
            if (isNaN(limit)) {
                valid = false;
                limitErr = "Invalid integer";
            } else if (limit < 1 || limit > 100) {
                valid = false;
                limitErr = "Integer in range 1-100";
            } else {
                fs.limit = limit;
            }
        }
        else {
            valid = false;
            limitErr = "Required";
        }

        fs.valid = valid;
        fs.fromError = fromErr;
        fs.toError = toErr;
        fs.limitError = limitErr;
    }

    const handleChange = (name: string, value:string) =>{
        var fs = {...formState, [name]: value};
        checkFormState(fs);
        setFormState(fs);
    }

    const searchRounds = (caller: string, fs: RoundOverviewState) : void => {
        setActionError("");
        RoundOverviewUtil.getRoundOverviews(fs.from, fs.to, fs.limit ? fs.limit : 25).then((resp) => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            if (resp.item?.items) {
                setRounds(resp.item.items);
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        })
    };

    const onSearch = () : void => {
        searchRounds("Search", formState);
        sessionStorage.setItem("RoundOverviewState", JSON.stringify(formState));
    };

    const onSelect = (item: RoundOverviewItem) => {
        setSelectedRound(item);
    };
    const onVisibleChange = (item: RoundOverviewItem) => {
        //alert("RoundOverview.onVisibleChange [id:" + item.id + "]");
        let saveRoundJson = JSON.stringify({
            visibleToPlayers: !item.visible
        });

    };
    const onPause = (item: RoundOverviewItem) => {
        alert("RoundOverview.onPause [id:" + item.id + "]");
    };
    const onActivate = (item: RoundOverviewItem) => {
        alert("RoundOverview.onActivate [id:" + item.id + "]");
    };
    const onCreateRound = () => {
        history.push('createRound');
    };
    const onCreateFakeRound = () => {
        history.push('createFakeRound');
    };
    var createFakeRoundButton = null;
    if (RoundOverviewUtil.isTestEnv(env)){
        createFakeRoundButton = (
            <Button 
                variant="contained"
                color="secondary"
                size="small"
                onClick={e => onCreateFakeRound()}>
                Create Fake Round
            </Button>
        );

    }
    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }
    var summaryBox = null;
    if (rounds) {
        var count = rounds.length;
        var betCount = 0;
        var turnover = 0;
        var extBetCount = 0;
        var extTurnover = 0;
        var extPL = 0;
        rounds.forEach(r => {
            if (r.betCount) {
                betCount += r.betCount;
            }
            if (r.turnover) {
                turnover += r.turnover;
            }
            if (r.extBetCount) {
                extBetCount += r.extBetCount;
            }
            if (r.extTurnover) {
                extTurnover += r.extTurnover;
            }
            if (r.extPL) {
                extPL += r.extPL;
            }
        }); 
        var currency = RoundOverviewUtil.CURRENCY_EUR;
        var summaryText = `Count: ${count} | Ext BetCount: ${extBetCount} of ${betCount} | Ext Turnover: ${formatToCurrency(extTurnover, currency)} of ${formatToCurrency(turnover, currency)}`;
        summaryText += ` | Ext PL: ${formatToCurrency(extPL, currency)}`;
        summaryBox = (<Box fontSize={12}>{summaryText}</Box>)
    }
    var roundInfoBox = null;
    if (selectedRound && selectedRound.id > 0) {
        var roundInfoText = `Round: ${selectedRound.id} | Type: ${selectedRound.roundTypeName} | Deadline: ${CreateRoundUtil.toGameDateString(selectedRound.deadline)}`;
        roundInfoBox = (<Box fontSize={12}>{roundInfoText}</Box>);
    }
    return (
        <Container maxWidth="xl">
            <Title title="Round Overview" />
            <Box paddingBottom={1}>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={e => onCreateRound()}>
                    Create
                </Button>&nbsp;
                {createFakeRoundButton}
                &nbsp;&nbsp;
                <TextField
                    type="date"
                    name="from"
                    value={formState.fromInput}
                    label="From"
                    InputLabelProps={{shrink: true}}
                    error={formState.fromError && formState.fromError.length > 0 ? true : false}
                    helperText={formState.fromError}
                    onChange={e => handleChange("fromInput", e.target.value)}
                />&nbsp;
                <TextField
                    type="date"
                    name="to"
                    value={formState.toInput}
                    label="To"
                    InputLabelProps={{shrink: true}}
                    error={formState.toError && formState.toError.length > 0 ? true : false}
                    helperText={formState.toError}
                    onChange={e => handleChange("toInput", e.target.value)}
                />&nbsp;
{/*                 <TextField
                    name="orderBy"
                    label="Order by"
                    value="RoundId asc"
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    InputProps={{ readOnly: true }}
                />&nbsp;
 */}                <TextField
                    name="limit"
                    label="Page size"
                    type="number"
                    value={formState.limitInput}
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    error={formState.limitError && formState.limitError.length ? true : false}
                    helperText={formState.limitError}
                    onChange={e => handleChange("limitInput", e.target.value)}
                />&nbsp;
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={!formState.valid}
                    onClick={e => onSearch()}>
                    Search
                </Button>&nbsp;
            </Box>
            {actionErrorBox}
            {summaryBox}
            <RoundOverviewTable rounds={rounds} onSelect={onSelect} onVisibleChange={onVisibleChange} onPause={onPause} onActivate={onActivate} />
            {roundInfoBox}
            <div>
                <Tabs value={selectedTab}
                    onChange={(ev, value) => { setSelectedTab(value) }}
                >
                    <Tab label="General" />
                    <Tab label="Ticker" />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <RoundOverviewGeneral round={selectedRound} leagues={leagues} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <RoundOverviewTicker round={selectedRound} />
                </TabPanel>
            </div>

        </Container>
    );
};

export default RoundOverview;
