import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Draw, RoundOverviewFixture } from "./RoundOverviewModels";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import RoundOverviewFixtureRow from './RoundOverviewFixtureRow';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
      padding: "none"
    },
});

interface RoundOverviewFixtureTableProps {
    fixtures: RoundOverviewFixture[];
    readOnly: boolean;
    onRefreshRound?: () => void;
}

const RoundOverviewFixtureTable: React.FC<RoundOverviewFixtureTableProps> = (props) => {
     const classes = useStyles();
  

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Game no</TableCell>
              <TableCell>Game</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Draw</TableCell>
              <TableCell>Will be drawn</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fixtures.map((row) => (
                <RoundOverviewFixtureRow fixture={row} readOnly={props.readOnly} onRefreshRound={props.onRefreshRound}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default RoundOverviewFixtureTable;
  


  