import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, Datagrid, TextField, DateField, List,
    ReferenceInput, AutocompleteInput, ReferenceField, Toolbar, SaveButton, BooleanInput
} from 'react-admin';
import {SeasonReferenceField} from "../seasons";

export const TeamCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="twitterHandle" />
            <BooleanInput source="twitterHandleLocked" />
        </SimpleForm>
    </Create>
);

const TeamEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);
export const TeamEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<TeamEditToolbar />}>
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <TextInput source="name" />
            <SeasonReferenceField source={"currentSeasonId"} allowEmpty addLabel={true}/>
            <TextInput source="twitterHandle" />
            <BooleanInput source="twitterHandleLocked" />
        </SimpleForm>
    </Edit>
);

export const TeamList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <TextField source="name" />
            <TextField source="providerId" />
            <SeasonReferenceField source={"currentSeasonId"} allowEmpty addLabel={true}/>
        </Datagrid>
    </List>
);

export const TeamReferenceInput = props =>
    <ReferenceInput label={'Team'}  reference="teams" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""}/>
    </ReferenceInput>;

export const TeamReferenceField = props =>
    <ReferenceField reference="teams" {...props} >
        <TextField source="name" />
    </ReferenceField>;
