import React from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import DateUtils from "@date-io/moment";
import {TextField} from '@material-ui/core';


const makeDateTimePicker = () => {
  const _makePicker = props => {
    const {input, meta, isRequired} = useInput(props);

    const onChange = date => {
      const e = date.nativeEvent;
      const path = e.path || (e.composedPath && e.composedPath());
      if (path && path[0]) {
        const myValue = path[0].value;
        const myDate = new Date(myValue);
        input.onChange(myDate);
        input.onBlur();
      } else {
        console.warn("Event path not available for date-inputs")
      }
    }

    const {
      options,
      label,
      source,
      resource,
      className      
    } = props;

    const { touched, error } = meta;

    const intlOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric'    
    };
    const nf = Intl.DateTimeFormat('sv-SV',intlOptions);
    const intlOptions2 = {
      hour: 'numeric', minute: 'numeric'
    };
    const nf2 = Intl.DateTimeFormat('sv-SV',intlOptions2);

    let myValue = input.value ? nf.format(new Date(input.value)) + "T" + nf2.format(new Date(input.value)): null;

    return (
      <div className="picker">        
        <TextField id="datetime-local" source={source} type="datetime-local" 
        margin="normal"
        {...options}
        label={<FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />}
        error={!!(touched && error)}
        helperText={touched && error}
        className={className}
        value={myValue}
        onChange={onChange} InputLabelProps={{ shrink: true}}  />
        
      </div>
    );
  }

  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  };

  _makePicker.defaultProps = {
    isRequired: false,
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: DateUtils,
      locale: undefined,
    },
  };
  return _makePicker;
};

export const DateInput = makeDateTimePicker();

export const DateTimeInput = makeDateTimePicker();