import React from 'react';
import {
  Edit, Datagrid, TextField, DateField, List,
  ReferenceInput, AutocompleteInput, ReferenceField, TabbedForm, FormTab, ReferenceManyField, Pagination
} from 'react-admin';
import {SeasonGrid, SeasonReferenceField} from "../seasons";
import {GameRoundGrid, GameRoundReferenceField} from "../gameRound";
import {FixtureGrid} from "../fixtures";

export const LeagueList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="createdAt" showTime/>
            <TextField source="providerId" />
            <SeasonReferenceField source={"currentSeasonId"} allowEmpty addLabel={true}/>
            <GameRoundReferenceField source={"currentGameRoundId"} allowEmpty addLabel={true}/>
        </Datagrid>
    </List>
);

export const LeagueEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label={'General'}>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" showTime/>
        <TextField source="providerId" />
        <SeasonReferenceField source={"currentSeasonId"} allowEmpty addLabel={true}/>
        <GameRoundReferenceField source={"currentGameRoundId"} allowEmpty addLabel={true}/>
      </FormTab>
      <FormTab label={'Seasons'}>
        <ReferenceManyField label={"Seasons"} reference="seasons" target={"leagueId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
          <SeasonGrid/>
        </ReferenceManyField>
      </FormTab>
      <FormTab label={'Game Rounds'}>
        <ReferenceManyField label={"Game Rounds"} reference="gameRounds" target={"leagueId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
          <GameRoundGrid/>
        </ReferenceManyField>
      </FormTab>
      <FormTab label={'Fixtures'}>
        <ReferenceManyField label={"Fixtures"} reference="fixtures" target={"leagueId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
          <FixtureGrid/>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const LeagueReferenceInput = props =>
    <ReferenceInput label={'League'}  reference="leagues" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""}/>
    </ReferenceInput>;

export const LeagueReferenceField = props =>
    <ReferenceField reference="leagues" {...props} >
        <TextField source="name" />
    </ReferenceField>;
