import { SyndicateOverviewItem } from "../AgentOverview/AgentModels";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import InvokeApiMethods, { InvokeApiOptions } from "../ExternalApi/ExternalApiModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { CreateSyndicatesRequest } from "./CreateSyndicateModels";

class CreateSyndicateUtil {
    static async createSyndicates(request: CreateSyndicatesRequest) : Promise<GenericResponse<SyndicateOverviewItem[]>> {
        var url = "syndicates/create";
        var json = JSON.stringify(request);
        var resp = await ApiNgUtil.invokeApi2(url, InvokeApiMethods.POST(), json);
        if (!resp.success) {
            return CreateRoundUtil.createGenericResponse<SyndicateOverviewItem[]>(false, resp.message, null);
        }
        if (!resp.json) {
            return CreateRoundUtil.createGenericResponse<SyndicateOverviewItem[]>(false, "No response", null);
        }

        //var success = resp.json.success && resp.json.success === true ? true : false;
        //var message = resp.json.message ?? "";
        //return CreateRoundUtil.createGenericResponse<number>(success, message, null);
        var items : SyndicateOverviewItem[] = resp.json;
        return CreateRoundUtil.createGenericResponse<SyndicateOverviewItem[]>(true, "", items);
    }
}
export default CreateSyndicateUtil;