import React, { useEffect } from "react";
import { useState } from 'react';
import { Button, Container, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { getFirstDayCurrentMonthAsString,  getTodayAsString } from "../common/helpers/DateHelpers";
import { MergedRoundAndResultsItem, TimeUnits, getUserReport } from "../common/helpers/ApiHelper";
import { TimeOperatorUserReportItem, TimeUserReport } from "./ReportInterfaces/UserReportItem";

import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLine, VictoryPie, VictoryTheme } from "victory";

function NewUsersReport() 
{
    let [strFromDate, setStrFromDate] = useState(() => getFirstDayCurrentMonthAsString('YYYY-MM-DD'))
    let [strToDate, setStrToDate] = useState(() => getTodayAsString('YYYY-MM-DD'))
    
    let [timeUnit, setTimeUnit] = useState(() => TimeUnits.Month)
    useEffect(() => { ReloadData() }, [timeUnit]);

    const [mergedRoundAndResultsItems, setMergedRoundAndResultsItems] = useState(() => new Array<MergedRoundAndResultsItem>())


    const [timeData, setTimeData] = useState(() => new TimeUserReport())
    let timeReportData: TimeUserReport = timeData;

    function loadData() 
    {
        let userTimeData = getUserReport(strFromDate, strToDate, timeUnit).then((userTimeData: TimeUserReport) =>
        {
            setTimeData(userTimeData);
        });

    }

    function ReloadData() 
    {
        // let timeData = getPerTimeUnitAndOperatorReportItemsFromData(mergedRoundAndResultsItems, timeUnit);
        // setTimeData(timeData);
    }

    


    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={3}>
                    <TextField
                        id="fromDate"
                        label="From:"
                        type="date"
                        defaultValue={strFromDate}
                        onChange={(e) => { setStrFromDate(e.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br />
                    <TextField
                        id="toDate"
                        label="To:"
                        type="date"
                        defaultValue={strToDate}
                        onChange={(e) => { { setStrToDate(e.target.value) } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={timeUnit}
                        onChange={(e) => {  
                                            //ReloadData(); 
                                            setTimeUnit(parseInt(e.target.value)); 
                                            
                                        }} 
                    >
                        <FormControlLabel value={TimeUnits.Month} control={<Radio />} label="Month" />
                        <FormControlLabel value={TimeUnits.Week} control={<Radio />} label="Week" />
                        <FormControlLabel value={TimeUnits.Day} control={<Radio />} label="Day" />
                    </RadioGroup>
                    
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => { loadData() }}>Get</Button>
                </Grid>
                <Grid item xs={12}>
                    <ReportTable reportData={timeReportData} />
                </Grid>

                <Grid item xs={12}>
                    <ReportLineGraph reportData={timeReportData} />
                </Grid>
                <Grid item xs={12}>
                    <ReportLineAccumulatedLineGraph reportData={timeReportData} />
                </Grid>
                <Grid item xs={12}>
                    <OperatorPieChart reportData={timeReportData} />
                </Grid>
                <Grid item xs={12}>
                    <OperatorGroupChart reportData={timeReportData} />
                </Grid>
            </Grid>
        </Container>
    );
}

function GetOperatorNewUsers(tunoverDict:{ [key: string]: TimeOperatorUserReportItem }, operatorId:string) : number
{
    let count = 0;
    let operatorItem = tunoverDict[operatorId];
    if(operatorItem != undefined)
    {
        count = operatorItem.count;
    }
    return count;
}

function ReportTable({ reportData }: { reportData: TimeUserReport }) 
{
    let operators = Object.keys(reportData.bookmakerLookup);
    let timeObjList = Object.values(reportData.timeDict)


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Total</TableCell>
                    {
                        operators.map((operatorId, index) => {
                            return (
                                <TableCell key={'operator' + index}>{operatorId}</TableCell>
                            );
                        })
                    }
                </TableRow>
            </TableHead>
             <TableBody>
                {
                    timeObjList.map((timeUnit, index) => {
                        return (
                            <TableRow key={'row' + index}>
                                <TableCell key={'time' + index}>{timeUnit.timeUnitName}</TableCell>
                                <TableCell key={'totNew' + index}>{timeUnit.count}</TableCell>
                                {
                                    operators.map((operatorId, index) => {
                                        return (
                                            <TableCell key={'operatorNew' + index}>{GetOperatorNewUsers(timeUnit.bookmakerDict, operatorId)}</TableCell>
                                        );
                                    })
                                }
                            </TableRow>);
                    })
                }
            <TableRow ></TableRow>
            <TableRow >
                <TableCell></TableCell>
                <TableCell>{reportData.count}</TableCell>
                {
                    operators.map((operatorId, i) => {
                        return (
                                <TableCell key={'operatorTot' + operatorId}>{reportData.GetTotalNumberOfNewBookmakerUsers(operatorId)}</TableCell>
                        );
                    })
                }
            </TableRow>
            </TableBody> 

        </Table>
    );
}
function ReportLineGraph({ reportData }: { reportData: TimeUserReport }) 
{
    if(reportData == undefined || reportData.GetNumUserPerTimeUnti() == undefined || reportData.GetNumUserPerTimeUnti().length == 0)
    {
        return (<div></div>);
    }
    return (
        <VictoryChart
            width={600}
            height={300}
            theme={VictoryTheme.material}
            maxDomain={{ y: reportData.GetMaxNumberOfUserPerTimeUnti() }}
            minDomain={{ y: 0 }}
        >
        <VictoryLine
            style={{
                data: {
                stroke: "#c43a31",
                strokeWidth: 2,
                strokeLinecap: "round"
                }
            }}

            data={reportData.GetNumUserPerTimeUnti()}
        />
        <VictoryAxis label="Time" fixLabelOverlap style={{
            axis: { stroke: "black", strokeWidth: 1 },
            axisLabel: { fontSize: 12, padding: 30 },
            grid: { strokeWidth: 0 },
            ticks: { stroke: "black", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 }
        }} />
        <VictoryAxis dependentAxis label="Number Of New Users" style={{
            axis: { stroke: "black", strokeWidth: 1 },
            axisLabel: { fontSize: 12, padding: 30 },
            grid: { strokeWidth: 0 },
            ticks: { stroke: "black", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 }
        }} />

    </VictoryChart>
    );
}

function ReportLineAccumulatedLineGraph({ reportData }: { reportData: TimeUserReport }) 
{
    if(reportData == undefined || reportData.GetNumUserPerTimeUnti() == undefined || reportData.GetNumUserPerTimeUnti().length == 0)
    {
        return (<div></div>);
    }
    return (
        <VictoryChart
            width={600}
            height={300}
            theme={VictoryTheme.material}
            maxDomain={{ y: reportData.GetMaxNumberOfUser() }}
            minDomain={{ y: 0 }}
        >
        <VictoryLine
            style={{
                data: {
                stroke: "#c43a31",
                strokeWidth: 2,
                strokeLinecap: "round"
                }
            }}

            data={reportData.GetAccumulatedNumUserPerTimeUnti()}
        />
        <VictoryAxis label="Time" fixLabelOverlap style={{
            axis: { stroke: "black", strokeWidth: 1 },
            axisLabel: { fontSize: 12, padding: 30 },
            grid: { strokeWidth: 0 },
            ticks: { stroke: "black", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 }
        }} />
        <VictoryAxis dependentAxis label="Number Of New Users Accumulated" style={{
            axis: { stroke: "black", strokeWidth: 1 },
            axisLabel: { fontSize: 12, padding: 30 },
            grid: { strokeWidth: 0 },
            ticks: { stroke: "black", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 }
        }} />

    </VictoryChart>
    );
}

function OperatorPieChart({ reportData }: { reportData: TimeUserReport }) 
{
    if(reportData == undefined || reportData.GetNumUserPerTimeUnti() == undefined || reportData.GetNumUserPerTimeUnti().length == 0)
    {
        return (<div></div>);
    }
    return (
        <VictoryPie
            width={600}
            height={600}
            theme={VictoryTheme.material}
            data={reportData.GetTotalNumUserPerOperator()}
        />
    );
}

function OperatorGroupChart({ reportData }: { reportData: TimeUserReport }) 
{
    if(reportData == undefined || reportData.GetNumUserPerTimeUnti() == undefined || reportData.GetNumUserPerTimeUnti().length == 0)
    {
        return (<div></div>);
    }
    let timeObjList = Object.values(reportData.timeDict)
    let operators = Object.keys(reportData.bookmakerLookup);
    return (
        <VictoryChart>
            <VictoryGroup offset={20}
                colorScale={"qualitative"}
            >
                {
                 operators.map((operatorId, index) => {
                        return (
                            <VictoryBar key={'row' + index} 
                            data={reportData.GetUsersForOperatorPerTimeUnitData(operatorId)}
                            />
                    )})
                }
   
            </VictoryGroup>
        </VictoryChart>
    );
}


export default NewUsersReport


