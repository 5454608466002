import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs, Box, Typography, Button, TextField } from "@material-ui/core";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { formatToCurrency } from "../common/helpers/CurrencyHelpers";
import { AgentTickerOverviewItem, OperatorTickerOverviewItem, OverviewCriteria, PlayerTickerOverviewItem, RoundTickerOverviewItem } from "./Ticker2Models";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import TickerTable from "./TickerTable";
import { TickerItem } from "../RoundOverview/RoundOverviewModels";
import Ticker2Util from "./Ticker2Util";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import TickerOverview from "./TickerOverview";
import RoundOverviewTable from "./RoundOverviewTable";
import OperatorOverviewTable from "./OperatorOverviewTable";
import PlayerOverviewTable from "./PlayerOverviewTable";
import AgentOverviewTable from "./AgentOverviewTable";
import { TabPanel } from "../Components/TabPanel";

interface Ticker2State {
    fromInput: string;
    toInput: string;
    limitInput: string;
    tickerLimitInput: string;
    from?: Date;
    to?: Date;
    limit?: number;
    tickerLimit?: number;
    valid: boolean;
    fromError: string;
    toError: string;
    limitError: string;
    tickerLimitError: string;
}
interface TickerItemState {
    type: string;
    id: string;
    items: TickerItem[];
    more: boolean;
    lastId: number;
    refreshing: boolean;
}

function checkFormState(fs: Ticker2State)  {
    var valid = true;
    var from = CreateRoundUtil.parseDateX(fs.fromInput);
    var to = CreateRoundUtil.parseDateX(fs.toInput);
    var limit = parseInt(fs.limitInput);
    var tickerLimit = parseInt(fs.tickerLimitInput);
    var dateTime = from;
    var fromErr = "";
    var toErr = "";
    var limitErr = "";
    var tickerLimitErr = "";
    if (fs.fromInput.length > 0) {
        var dateNumber = from.getDate();
        if (isNaN(dateNumber)) {
            valid = false;
            fromErr = "Invalid date";
        } else {
            fs.from = from;
        }
    }
    else {
        valid = false;
        fromErr = "Required";
    }
    if (fs.toInput.length > 0) {
        var dateNumber = to.getDate();
        if (isNaN(dateNumber)) {
            valid = false;
            fromErr = "Invalid date";
        } else {
            fs.to = to;
        }
    }
    else {
        valid = false;
        fromErr = "Required";
    }
    if (fs.limitInput.length > 0) {
        if (isNaN(limit)) {
            valid = false;
            limitErr = "Invalid integer";
        } else if (limit < 1 || limit > 100) {
            valid = false;
            limitErr = "Integer in range 1-100";
        } else {
            fs.limit = limit;
        }
    }
    else {
        valid = false;
        limitErr = "Required";
    }
    if (fs.tickerLimitInput.length > 0) {
        if (isNaN(tickerLimit)) {
            valid = false;
            tickerLimitErr = "Invalid integer";
        } else if (tickerLimit < 1 || tickerLimit > 1000) {
            valid = false;
            tickerLimitErr = "Integer in range 1-1000";
        } else {
            fs.tickerLimit = tickerLimit;
        }
    }
    else {
        valid = false;
        tickerLimitErr = "Required";
    }

    fs.valid = valid;
    fs.fromError = fromErr;
    fs.toError = toErr;
    fs.limitError = limitErr;
    fs.tickerLimitError = tickerLimitErr;
}

function getInitialState() : Ticker2State {
    var s = sessionStorage.getItem("TickerState");
    var fs : Ticker2State | undefined = undefined;
    if (s && s.length > 0) {
        try {
            var fs1: Ticker2State = JSON.parse(s);
            if (fs1) {
                checkFormState(fs1);
                if (fs1.valid) {
                    fs = fs1;
                }
            }
        } catch {

        }
    }
    if (!fs) {
        fs = {
            fromInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), -7)),
            toInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), 30)),
            limitInput: "25",
            tickerLimitInput: "100",
            from: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), -7)),
            to: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 30)),
            limit: 25,
            tickerLimit: 100,
            valid: true,
            fromError: "",
            toError: "",
            limitError: "",
            tickerLimitError: ""
        }
    }
    return fs;
};

var initialState = getInitialState();

function createCriteria(state: Ticker2State, createNo: number) : OverviewCriteria {
    var fs = state;
    return {
        pageSize: fs.limit ? fs.limit : 25,
        from:  fs.from ? fs.from : CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), -7)),
        to: fs.to ? fs.to : CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 30)),
        createNo: createNo
    }
};
function getInitialCriteria() : OverviewCriteria {
    return createCriteria(initialState, 1);
};
var initialCriteria = getInitialCriteria();

const Ticker2: React.FC = () => {


    const [formState, setFormState] = useState<Ticker2State>(initialState);
    const [criteria, setCriteria] = useState<OverviewCriteria>(initialCriteria);
    const [actionError, setActionError] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [tickerItemState, setTickerItemState] = useState<TickerItemState>({
        type: "",
        id: "",
        items: [],
        more: false,
        lastId: 0,
        refreshing: false
    });

    useEffect(() => {
        searchTicker("Init", formState);
    }, []);


    const handleChange = (name: string, value:string) =>{
        var fs = {...formState, [name]: value};
        checkFormState(fs);
        setFormState(fs);
    }

    const searchTicker = (caller: string, fs: Ticker2State) : void => {
        setActionError("");
        if (!formState.valid) {
            setActionError("Form state is not valid");
            return;
        }
        var crit : OverviewCriteria = {
            pageSize: formState.limit ? formState.limit : criteria.pageSize,
            from: formState.from ? formState.from : criteria.from,
            to: formState.to ? formState.to : criteria.to,
            createNo: criteria.createNo + 1
        };
        if (caller === "Search") {
            initialState = formState;
            initialCriteria = crit;    
        }
        setCriteria(crit);
        var tickerLimit = formState.tickerLimit ? formState.tickerLimit : crit.pageSize;
        var ts : TickerItemState = {
            type: "",
            id: "",
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        tickerItemState.refreshing = true;
        Ticker2Util.getTickerItems(tickerLimit, crit.from, crit.to, 0).then((resp) => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    ts.items = listResponse.items;
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }
           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally(() => {
            setTickerItemState(ts);
        });
    };

    const onSearch = () : void => {
        if (!formState.valid) {
            return;
        }
        searchTicker("Search", formState)
    };

    const onSelectRound = (item: RoundTickerOverviewItem) => {
        if (!item) {
            searchTicker("SelectRound", formState);
            return;
        }
        setActionError("");
        var tickerLimit = formState.tickerLimit ? formState.tickerLimit : criteria.pageSize;
        var ts : TickerItemState = {
            type: "round",
            id: item.roundId.toString(),
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        tickerItemState.refreshing = true;
        Ticker2Util.getRoundTickerItems(item.roundId, tickerLimit, undefined, undefined, 0).then(resp => {
            if (!resp.success) {
                setActionError(`SelectRound.getTickerItems error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    ts.items = listResponse.items;
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }
           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "SelectRound.getTickerItems error (2)"));
        }).finally(() => {
            setTickerItemState(ts);
        });
    };
    const onDeselectRound = () => {
        searchTicker("DeselectRound", formState);
    };

    const onSelectOperator = (item: OperatorTickerOverviewItem) => {
        if (!item) {
            searchTicker("SelectOperator", formState);
            return;
        }
        setActionError("");
        var tickerLimit = formState.tickerLimit ? formState.tickerLimit : criteria.pageSize;
        var ts : TickerItemState = {
            type: "operator",
            id: item.operatorId,
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        tickerItemState.refreshing = true;
        Ticker2Util.getOperatorTickerItems(item.operatorId, tickerLimit, criteria.from, criteria.to, 0).then(resp => {
            if (!resp.success) {
                setActionError(`SelectOperator.getTickerItems error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    ts.items = listResponse.items;
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }
           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "SelectOperaotr.getTickerItems error (2)"));
        }).finally(() => {
            setTickerItemState(ts);
        });
    };
    const onDeselectOperator = () => {
        //setSelectedRound(undefined);
        searchTicker("DeselectOperator", formState);
    };
    const onSelectPlayer = (item: PlayerTickerOverviewItem) => {
        if (!item) {
            searchTicker("SelectPlayer", formState);
            return;
        }
        setActionError("");
        var tickerLimit = formState.tickerLimit ? formState.tickerLimit : criteria.pageSize;
        var ts : TickerItemState = {
            type: "player",
            id: item.playerId.toString(),
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        tickerItemState.refreshing = true;
        Ticker2Util.getPlayerTickerItems(item.playerId, tickerLimit, criteria.from, criteria.to, 0).then(resp => {
            if (!resp.success) {
                setActionError(`SelectPlayer.getTickerItems error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    ts.items = listResponse.items;
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }
           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "SelectPlayer.getTickerItems error (2)"));
        }).finally(() => {
            setTickerItemState(ts);
        });
    };
    const onDeselectPlayer = () => {
        searchTicker("DeselectPlayer", formState);
    };
    const onSelectAgent = (item: AgentTickerOverviewItem) => {
        if (!item) {
            searchTicker("SelectAgent", formState);
            return;
        }
        setActionError("");
        var tickerLimit = formState.tickerLimit ? formState.tickerLimit : criteria.pageSize;
        var ts : TickerItemState = {
            type: "agent",
            id: item.agentId.toString(),
            items: [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        tickerItemState.refreshing = true;
        Ticker2Util.getAgentTickerItems(item.agentId, tickerLimit, criteria.from, criteria.to, 0).then(resp => {
            if (!resp.success) {
                setActionError(`SelectAgent.getTickerItems error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    ts.items = listResponse.items;
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "SelectAgent.getTickerItems error (2)"));
        }).finally(() => {
            setTickerItemState(ts);
        });
    };
    const onDeselectAgent = () => {
        searchTicker("DeselectAgent", formState);
    };
    const getMoreTicker = async () : Promise<GenericResponse<ListResponse<TickerItem>>> => {
        const METHOD_NAME = "getMoreTicker";
        try {
            var tickerLimit = formState.tickerLimit ? formState.tickerLimit : criteria.pageSize;
            switch (tickerItemState.type) {
                case "":
                    return Ticker2Util.getTickerItems(tickerLimit, criteria.from, criteria.to, tickerItemState.lastId);
                case "round":
                    return Ticker2Util.getRoundTickerItems(parseInt(tickerItemState.id), tickerLimit, undefined, undefined, tickerItemState.lastId);
                case "operator":
                    return Ticker2Util.getOperatorTickerItems(tickerItemState.id, tickerLimit, criteria.from, criteria.to, tickerItemState.lastId);
                case "player":
                    return Ticker2Util.getPlayerTickerItems(parseInt(tickerItemState.id), tickerLimit, criteria.from, criteria.to, tickerItemState.lastId);
                case "agent":
                    return Ticker2Util.getPlayerTickerItems(parseInt(tickerItemState.id), tickerLimit, criteria.from, criteria.to, tickerItemState.lastId);
            }
            return CreateRoundUtil.createGenericResponse<ListResponse<TickerItem>>(false, `Invalid type '${tickerItemState.type}'`, null);

        } catch (error) {
            console.error(`${METHOD_NAME} error [Type:${tickerItemState.type}].`, error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return CreateRoundUtil.createGenericResponse<ListResponse<TickerItem>>(false, message, null);
        }
    }
    const onMoreTicker = () : void => {
        if (tickerItemState.lastId <= 0) {
            setActionError("More ticker failure: LastId was not set");
            return;
        }
        setActionError("");
        var ts : TickerItemState = {
            type: tickerItemState.type,
            id: tickerItemState.id,
            items: tickerItemState.items,
            more: false,
            lastId: 0,
            refreshing: false
        }
        tickerItemState.refreshing = true;
        getMoreTicker().then(resp => {
            if (!resp.success) {
                setActionError(`MoreTicker.getTickerItems error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    for (const item of listResponse.items) {
                        ts.items.push(item);
                    }               
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }           
            }
            setTickerItemState(ts);
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "MoreTicker.getTickerItems error (2)"));
        }).finally(() => {
            tickerItemState.refreshing = false;
        });
    }
    const getRoundTickerOverviews = async (criteria: OverviewCriteria, lastId: string) : Promise<GenericResponse<ListResponse<RoundTickerOverviewItem>>> => {
        var lastId2 : number = lastId.length === 0 ? 0 : parseInt(lastId);
        if (isNaN(lastId2)) {
            lastId2 = 0;
        }
        return Ticker2Util.getRoundTickerOverviews(criteria.pageSize, criteria.from, criteria.to, lastId2);
    }
    const renderRoundOverviewTable = (items: RoundTickerOverviewItem[], onSelect: (item: RoundTickerOverviewItem) => void, onDeselect: () => void) : JSX.Element => {
        return (<RoundOverviewTable items={items} onSelect={onSelect} onDeselect={onDeselect} />);
    }
    const getOperatorTickerOverviews = async (criteria: OverviewCriteria, lastId: string) : Promise<GenericResponse<ListResponse<OperatorTickerOverviewItem>>> => {
        return Ticker2Util.getOperatorTickerOverviews(criteria.pageSize, criteria.from, criteria.to, lastId, true);
    }
    const renderOperatorOverviewTable = (items: OperatorTickerOverviewItem[], onSelect: (item: OperatorTickerOverviewItem) => void, onDeselect: () => void) : JSX.Element => {
        return (<OperatorOverviewTable items={items} onSelect={onSelect} onDeselect={onDeselect} />);
    }
    const getPlayerTickerOverviews = async (criteria: OverviewCriteria, lastId: string) : Promise<GenericResponse<ListResponse<PlayerTickerOverviewItem>>> => {
        var lastId2 : number = lastId.length === 0 ? 0 : parseInt(lastId);
        if (isNaN(lastId2)) {
            lastId2 = 0;
        }
        return Ticker2Util.getPlayerTickerOverviews(criteria.pageSize, criteria.from, criteria.to, lastId2, true);
    }
    const renderPlayerOverviewTable = (items: PlayerTickerOverviewItem[], onSelect: (item: PlayerTickerOverviewItem) => void, onDeselect: () => void) : JSX.Element => {
        return (<PlayerOverviewTable items={items} onSelect={onSelect} onDeselect={onDeselect} />);
    }
    const getAgentTickerOverviews = async (criteria: OverviewCriteria, lastId: string) : Promise<GenericResponse<ListResponse<AgentTickerOverviewItem>>> => {
        var lastId2 : number = lastId.length === 0 ? 0 : parseInt(lastId);
        if (isNaN(lastId2)) {
            lastId2 = 0;
        }
        return Ticker2Util.getAgentTickerOverviews(criteria.pageSize, criteria.from, criteria.to, lastId2, true);
    }
    const renderAgentOverviewTable = (items: AgentTickerOverviewItem[], onSelect: (item: AgentTickerOverviewItem) => void, onDeselect: () => void) : JSX.Element => {
        return (<AgentOverviewTable items={items} onSelect={onSelect} onDeselect={onDeselect} />);
    }
    
    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }
    var tickerLimit1 = formState.tickerLimit ? formState.tickerLimit : criteria.pageSize;
    var info = `Refreshing: ${tickerItemState.refreshing} | Type: ${tickerItemState.type} | Id: ${tickerItemState.id} | Count: ${tickerItemState.items.length} | LastId: ${tickerItemState.lastId} | More: ${tickerItemState.more}`;
    info += ` | PageSize: ${tickerLimit1}`;
    if (tickerItemState.type !== "round") {
        info += ` | From: ${criteria.from.toISOString()} | To: ${criteria.to.toISOString()}`;
    }

    var moreTickerBtn = null;
    if (tickerItemState.more && !tickerItemState.refreshing) {
        moreTickerBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={!formState.valid || tickerItemState.refreshing}
            onClick={e => onMoreTicker()}>
            More
        </Button>);
    }
    return (
        <Container maxWidth="xl">
            <Title title="Ticker" />
            <Box paddingBottom={1}>
                <TextField
                    type="date"
                    name="from"
                    value={formState.fromInput}
                    label="From"
                    InputLabelProps={{shrink: true}}
                    error={formState.fromError && formState.fromError.length > 0 ? true : false}
                    helperText={formState.fromError}
                    onChange={e => handleChange("fromInput", e.target.value)}
                />&nbsp;
                <TextField
                    type="date"
                    name="to"
                    value={formState.toInput}
                    label="To"
                    InputLabelProps={{shrink: true}}
                    error={formState.toError && formState.toError.length > 0 ? true : false}
                    helperText={formState.toError}
                    onChange={e => handleChange("toInput", e.target.value)}
                />&nbsp;
                <TextField
                    name="limit"
                    label="Page size(O)"
                    type="number"
                    value={formState.limitInput}
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    error={formState.limitError && formState.limitError.length ? true : false}
                    helperText={formState.limitError && formState.limitError.length ? formState.limitError : "Overview"}
                    onChange={e => handleChange("limitInput", e.target.value)}
                />&nbsp;
                <TextField
                    name="tickerLimit"
                    label="Page size(T)"
                    type="number"
                    value={formState.tickerLimitInput}
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    error={formState.tickerLimitError && formState.tickerLimitError.length ? true : false}
                    helperText={formState.tickerLimitError && formState.tickerLimitError.length ? formState.tickerLimitError : "Ticker"}
                    onChange={e => handleChange("tickerLimitInput", e.target.value)}
                />&nbsp;
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={!formState.valid || tickerItemState.refreshing}
                    onClick={e => onSearch()}>
                    Search
                </Button>&nbsp;
            </Box>
            {actionErrorBox}
            <div>
                <Tabs value={selectedTab}
                    onChange={(ev, value) => { setSelectedTab(value) }}
                >
                    <Tab label="Rounds" />
                    <Tab label="Operators" />
                    <Tab label="Players" />
                    <Tab label="Agents" />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    {/* <RoundOverview criteria={criteria} onSelect={onSelectRound} onDeselect={onDeselectRound} /> */}
                    <TickerOverview<RoundTickerOverviewItem> type="round" getId={item => item.roundId.toString()} getItems={getRoundTickerOverviews} criteria={criteria} onSelect={onSelectRound} onDeselect={onDeselectRound} renderTable={renderRoundOverviewTable} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    {/* <OperatorOverview criteria={criteria} onSelect={onSelectOperator} onDeselect={onDeselectOperator} /> */}
                    <TickerOverview<OperatorTickerOverviewItem> type="operator" getId={item => item.operatorId} getItems={getOperatorTickerOverviews} criteria={criteria} onSelect={onSelectOperator} onDeselect={onDeselectOperator} renderTable={renderOperatorOverviewTable} moreDisabled={true} />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    {/* <PlayerOverview criteria={criteria} onSelect={onSelectPlayer} onDeselect={onDeselectPlayer} /> */}
                    <TickerOverview<PlayerTickerOverviewItem> type="player" getId={item => item.playerId.toString()} getItems={getPlayerTickerOverviews} criteria={criteria} onSelect={onSelectPlayer} onDeselect={onDeselectPlayer} renderTable={renderPlayerOverviewTable} moreDisabled={true} />
                </TabPanel>
                <TabPanel value={selectedTab} index={3}>
                    {/* <AgentOverview criteria={criteria} onSelect={onSelectAgent} onDeselect={onDeselectAgent} /> */}
                    <TickerOverview<AgentTickerOverviewItem> type="agent" getId={item => item.agentId.toString()} getItems={getAgentTickerOverviews} criteria={criteria} onSelect={onSelectAgent} onDeselect={onDeselectAgent} renderTable={renderAgentOverviewTable} moreDisabled={true} />
                </TabPanel>
            </div>
            <Box fontSize={10}>
                {info}{moreTickerBtn}
            </Box>
            <TickerTable items={tickerItemState.items} />
        </Container>
    );
};

export default Ticker2;

