import React, { useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Tooltip } from '@material-ui/core';
import { Draw, RoundOverviewFixture } from "./RoundOverviewModels";
import { min } from "moment";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "./RoundOverviewUtil";

interface RofInfoDialogProps {
    openButtonLabel: string;
    fixture: RoundOverviewFixture;
    onClose?: () => void;
    allObject?: any;
    allObjectType?: string;
}


const RofInfoDialog: React.FC<RofInfoDialogProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    var openButtonLabel = props.openButtonLabel;
    if (!openButtonLabel || openButtonLabel.length <= 0){
        openButtonLabel = "Fixture info";
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    var typeName = "RoundOverviewFixture";
    if (props.allObject) {
        if (props.allObjectType && props.allObjectType.length > 0) {
            typeName = props.allObjectType
        }
    }
    var json = props.allObject ? JSON.stringify(props.allObject, null, 2) : JSON.stringify(props.fixture, null, 2);
    return (
        <div>
            <Tooltip title="Show Fixture info">
                <Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    {openButtonLabel}
                </Button>
            </Tooltip>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Fixture info</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Json representation of {typeName} object.
                </DialogContentText>
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Fixture"
                        name="fixtureName"
                        value={props.fixture.name}
                        helperText={CreateRoundUtil.toGameDateString(props.fixture.startingAt) + " | " + props.fixture.leagueName}
                        InputProps={{
                        readOnly: true,
                        }}
                        style={{width:400}}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        label="Draw"
                        name="drawId"
                        value={props.fixture.drawId}
                        helperText={`${props.fixture.draw?.adminPickedOutcome} (${props.fixture.draw?.status})`}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                <Box marginTop={2}>
                <TextField
                    defaultValue={json}
                    label="Info"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={15}
                    InputProps={{
                        readOnly: true,
                        }}
                />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default RofInfoDialog;
