import React from 'react';
import { BetweenDates, DateIntervals, Roundbetsandresults } from '../../models/Roundbetsandresults';
import { getList, getListByPost } from '../../api/dataProvider';
import { ReportRound } from '../../Reports/ReportInterfaces/ReportRound';
import { RoundResp } from '../../models/RoundsDto';
import { dateString, getDayIndexSince2020, getFirstDayOfThisWeek, getFirstDayThisWeek, getFirstDayThisWeekAsString, getMonthIndex, getMonthIndexSince2020, getShortMonthName, getWeekIndexSince2020, getWeekNumber, getWeekNumberSince2020 } from './DateHelpers';
import { TimeReportItem, TimeReport } from '../../Reports/ReportInterfaces/TimeReportItem';
import { PlayerResp } from '../../models/PlayersDto';
import { TimeUserReport, UserReportItem } from '../../Reports/ReportInterfaces/UserReportItem';


export async function getTransformAndMergeRoundsAndResult(strFromDate: string, strToDate: string): Promise<MergedRoundAndResultsItem[]>
{

    let betweenDates: BetweenDates = 
    {
        GreaterThanOrEqualTo: strFromDate + "T00:00:00+00:00",
        LessThanOrEqualTo: strToDate + "T00:00:00+00:00"
    };

    let dateInterval: DateIntervals = 
    {
        OpenToInterval: betweenDates
    };

    let roundDict: { [key: string]: ReportRound } = {};
    let mergedRoundAndResultsItem: MergedRoundAndResultsItem[] = [];


    let rounds = await getList('rounds',
    {
        pagination: { page: 0, perPage: 100000 },
        filter: { "column_operator_filters":[{"column":"open_to", "operator":"GREATER_THAN_OR_EQUAL", "comparison_value":betweenDates.GreaterThanOrEqualTo}, {"column":"open_to", "operator":"LESS_THAN_OR_EQUAL", "comparison_value":betweenDates.LessThanOrEqualTo}]},
    }) as RoundResp;

    rounds.data.forEach(element => 
    {
        let x = new ReportRound(element.id, element.parameters.name, element.openTo);
        roundDict[element.id] = x;
    });

    let roundResult = await getListByPost('extended/reports/roundbetsandresults', dateInterval) as Roundbetsandresults;

    roundResult.data.items.forEach(element => 
    {
                let deadline = new Date(2001,0,1);
                let type = "Undefined";
                let roundId = element.round_id;

                //Get the round infromation
                let round = roundDict[element.round_id];
                if (round != undefined) {
                    deadline = new Date(round.deadline);
                    type = round.type;
                }

                let operatorId = element.operator_id;
                let stake:number = parseFloat(element.unit_stake_currency_bet_sum.money.amount.toString());
                let result:number = parseFloat(element.unit_stake_currency_result_sum.money.amount.toString());

        mergedRoundAndResultsItem.push(new MergedRoundAndResultsItem(roundId, type , deadline, operatorId, stake, result));
         
    });
    return mergedRoundAndResultsItem;
}
export async function getSlcPerMonthReportItems(strFromDate: string, strToDate: string): Promise<SlcPerMonthData> 
{
    let data = await getTransformAndMergeRoundsAndResult(strFromDate, strToDate);
    return await getSlcPerMonthReportItemsFromData(data);
}
export async function getSlcPerMonthReportItemsFromData(data:MergedRoundAndResultsItem[]): Promise<SlcPerMonthData> {
 
    let slcReportDict: { [key: string]: SlcPerMonthReportItem } = {};

    let totalStake:number = 0;
    let totalResult:number = 0;
    let totalProfitLoss:number = 0;
    let totalKickback:number = 0;
    let totalProfitLossAfterKickback:number = 0;
    let totalExternalTurnover:number = 0;

    data.forEach(element => {

        let monthIndex = getMonthIndex(element.deadline);

        let monthData = slcReportDict[monthIndex];
        if (monthData == undefined) {         
            monthData = new SlcPerMonthReportItem(monthIndex, 0, 0, 0, 0, 0, 0);
            slcReportDict[monthIndex] = monthData;
        }
        if (element.operatorId == "EURO_OPERATOR" || element.operatorId == "MOCKOP") {
            let kickback = element.stake * 0.2;
            let plAfterKickback = element.result - element.stake + kickback;

            monthData.stake += element.stake;
            monthData.result += element.result;
            monthData.profitLoss += element.result - element.stake ;
            monthData.kickback += kickback;
            monthData.plAfterKickback += plAfterKickback;

            totalStake += element.stake;
            totalResult += element.result;
            totalProfitLoss += element.result - element.stake ;
            totalKickback += kickback;
            totalProfitLossAfterKickback += plAfterKickback;
        }
        else {
            monthData.externalTurnover += element.stake;
           totalExternalTurnover += element.stake;
        }
    });

    let slcPerMonthData = new SlcPerMonthData(totalStake, totalResult, totalProfitLoss, totalKickback, totalProfitLossAfterKickback, totalExternalTurnover, Object.values(slcReportDict));

    return slcPerMonthData;
}
export function getPerTimeUnitAndOperatorReportItemsFromData(data:MergedRoundAndResultsItem[], timeUnit: TimeUnits): TimeReport {
 
    let timeReport: TimeReport = new TimeReport();

    data.forEach(element => 
        {
            let [index, timeUnitName] = getTimeIndexAndName(timeUnit, element.deadline);

            if (element.operatorId != "EURO_OPERATOR" && element.operatorId != "MOCKOP") {
                timeReport.addBet(index, timeUnitName, element.operatorId, element.stake, element.result);
        }

    });

    return timeReport;
}

function getTimeIndexAndName(timeUnit: TimeUnits, date: Date): [number, string] {
    
    let index: number = timeUnit;
    let timeUnitName: string = "Undefined";
    if(timeUnit == TimeUnits.Month)
        {
            index = getMonthIndexSince2020(date);       
            timeUnitName = getShortMonthName(getMonthIndex(date));
        }
        else if(timeUnit == TimeUnits.Week) 
        {
            index = getWeekNumberSince2020(date)
            timeUnitName =  dateString(getFirstDayOfThisWeek(date));
        }
        else if(timeUnit == TimeUnits.Day)
        {
            index = getDayIndexSince2020(date);
            timeUnitName = dateString(date);
        }
        return [index, timeUnitName];
  }

export async function getUserReport(strFromDate: string, strToDate: string, timeUnit: TimeUnits):Promise<TimeUserReport>
{
    let betweenDates: BetweenDates = 
    {
        GreaterThanOrEqualTo: strFromDate + "T00:00:00+00:00",
        LessThanOrEqualTo: strToDate + "T00:00:00+00:00"
    };

    let dateInterval: DateIntervals = 
    {
        OpenToInterval: betweenDates
    };

    let done = false;
    let page = 0;
    let timeUserReport = new TimeUserReport();
    let i =0;

    while(!done)
    {
        let players = await getList('players',
        {
            pagination: { page: page, perPage: 100 },sort: { field: 'createdAt', order: 'DESC' }, 
        }) as PlayerResp;

        players.data.forEach(element =>
            {
                let createdAt = new Date(element.createdAt);
                if(createdAt < new Date(strFromDate))
                {
                    done = true;
                    return timeUserReport;
                }

                let [index, timeUnitName] = getTimeIndexAndName(timeUnit, createdAt);
                timeUserReport.addUser(index, timeUnitName, new UserReportItem(element.id, createdAt, element.operatorPlayerId, element.operatorId, element.createdOperatorChannel, element.notes));
                i++;
            });

        if(i >= players.total)
        {
            done = true;
        }  
        page++;
    }

        


    return timeUserReport;
}


export enum TimeUnits {
    Month,
    Week,
    Day,
  }

export class MergedRoundAndResultsItem 
{
    roundId: string;
    roundName: string;
    deadline: Date;
    operatorId: string;
    stake: number;
    result: number;

    constructor(roundId: string, roundName: string, deadline: Date, operatorId: string, stake: number, result: number) 
    {
        this.roundId = roundId;
        this.roundName = roundName;
        this.deadline = deadline;
        this.operatorId = operatorId;
        this.stake = stake;
        this.result = result;
    }
}
export class SlcPerMonthData {

    totalStake:number = 0;
    totalResult:number = 0;
    totalProfitLoss:number = 0;
    totalKickback:number = 0;
    totalProfitLossAfterKickback:number = 0;
    totalExternalTurnover:number = 0;
    slcPerMonthReportItems:SlcPerMonthReportItem[]  = [];

    constructor(totalStake:number, totalResult:number, totalProfitLoss:number, totalKickback:number, totalProfitLossAfterKickback:number, totalExternalTurnover:number, slcPerMonthReportItems:SlcPerMonthReportItem[]) 
    {   
        this.totalStake = totalStake;
        this.totalResult = totalResult;
        this.totalProfitLoss = totalProfitLoss;
        this.totalKickback = totalKickback;
        this.totalProfitLossAfterKickback = totalProfitLossAfterKickback;
        this.totalExternalTurnover = totalExternalTurnover;
        this.slcPerMonthReportItems = slcPerMonthReportItems;
    }
}
export class SlcPerMonthReportItem {

    monthIndex: number;
    stake: number;
    result: number;
    profitLoss: number;
    kickback: number;
    plAfterKickback: number;
    externalTurnover: number;

    constructor(monthIndex: number, stake: number, result: number, profitLoss: number, kickback: number, plAfterKickback: number, externalTurnover: number) {
        this.monthIndex = monthIndex;
        this.stake = stake;
        this.result = result;
        this.profitLoss = profitLoss;
        this.kickback = kickback;
        this.plAfterKickback = plAfterKickback;
        this.externalTurnover = externalTurnover;
    }
}




