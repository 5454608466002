import React, { useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Tooltip } from '@material-ui/core';
import { Draw, RoundOverviewFixture } from "./RoundOverviewModels";
import { min } from "moment";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "./RoundOverviewUtil";
import DrawRulesText from "./DrawRulesText";

interface DrawRulesDialogProps {
    openButtonLabel?: string;
    onClose?: () => void;
}


const DrawRulesDialog: React.FC<DrawRulesDialogProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    var openButtonLabel = props.openButtonLabel;
    if (!openButtonLabel || openButtonLabel.length <= 0){
        openButtonLabel = "Draw rules";
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    return (
        <div>
            <Tooltip title="Show Draw rules">
                <Button onClick={handleClickOpen} color="secondary" variant="contained" size="small">
                    {openButtonLabel}
                </Button>
            </Tooltip>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Draw rules</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                Extract from Mockop Help/FAQ page.
                </DialogContentText>
                 <DrawRulesText />
           </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default DrawRulesDialog;
