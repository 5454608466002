import React, { useState } from "react";
import { Button, TextField, Box, MenuItem } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { Team } from "../RoundOverview/RoundOverviewModels";
import ChooseTeamDialog from "./ChooseTeamDialog";

interface TeamInputProps {
    label : string;
    team? : Team;
    disabled?: boolean;
    onChange: (team: Team | undefined) => void;
}
interface TeamInputState {
    team?: Team;
}


const TeamInput: React.FC<TeamInputProps> = (props) => {
    const getDefaultFormState = () : TeamInputState => {
        return {
            team: props.team
        };
    }
    
    const [formState, setFormState] = useState<TeamInputState>(getDefaultFormState());
 
    const onCloseTeamDlg = (team?: Team) => {
        if (!team) {
            return;
        }
        var fs = {...formState, ["team"]: team};
        setFormState(fs);
        props.onChange(team);
    };

    var chooseTeamDlg = null;
    if (props.disabled === undefined || props.disabled === false) {
        chooseTeamDlg = (<ChooseTeamDialog open={false} openLabel="Change" onClose={onCloseTeamDlg} />);
    }
 
    return (
        <Box paddingTop={1} display="flex" alignItems="center">
            <TextField
                margin="dense"
                name="teamName"
                value={formState.team ? formState.team.name : ""}
                label={props.label && props.label.length > 0 ? props.label : "Team"}
                helperText={formState.team ? `id:${formState.team.id}; providerId:${formState.team.providerId}` : "Required"}
                error={!formState.team}
                InputProps={{ readOnly: true }}
                style={{minWidth:350}}
            />
            <span style={{ padding: 2 }}></span>
            {chooseTeamDlg}
        </Box>
    );
};

export default TeamInput;
