import React, { useEffect, useState } from "react";
import { Button, TextField, Box, MenuItem, FormLabel } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { Team } from "../RoundOverview/RoundOverviewModels";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import { Operator, OperatorFilter } from "../Admin/AdminModels";
import { QueryParams } from "../models/CouponShare";
import AdminUtil from "../Admin/AdminUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { ListResponse } from "../api/ApiNgModels";


interface ItemSelectItem<T> {
    id: string;
    name: string;
    item: T;
}
interface GetItemsResponse<T> {
    success: boolean;
    message: boolean;
    pageSize: number;
    lastId: string;
    items: ItemSelectItem<T>[];
}
interface ItemSelectProps<T, TKey> {
    value: TKey;
    label: string;
    getId: (item?: T) => TKey;
    getIdString: (item?: T) => string;
    getName: (item?: T) => string;
    getInfoText?: (item: T) => string;
    idFromString: (s: string) => TKey;
    getItems : (pageSize: number, lastId?: TKey) => Promise<GenericResponse<ListResponse<T>>>;
    disabled?: boolean;
    filter?: (item: T) => boolean;
    onChange: (item: T | undefined) => void;
    allItemEnabled: boolean;
    helperText?: string;
    error?: boolean;
}
interface ItemSelectState<T, TKey> {
    selectedId?: TKey;
    selectedItem?: T;
    error: string;
    pageSize: number;
}
interface ItemState<T, TKey> extends BaseItemState<T, TKey | undefined> {
}


const ItemSelect = <T, TKey>(props: ItemSelectProps<T, TKey>) : JSX.Element => {
    const ITEM_NOT_ENABLED = "Item not enabled";
    const ITEM_NOT_FOUND = "Item not found";
    
    const getDefaultFormState = () : ItemSelectState<T, TKey> => {
        var defaultDate = props.value;
        var state : ItemSelectState<T, TKey> = {
            selectedId: props.value,
            error: "",
            pageSize: 50
        };
        return state;
    }
    
    const [formState, setFormState] = useState<ItemSelectState<T, TKey>>(getDefaultFormState());
    const [itemState, setItemState] = useState<ItemState<T, TKey>>({
        items: [],
        lastId: undefined,
        more: false,
        refreshing: false,
    });
    const [actionError, setActionError] = useState<string>("");
 
    useEffect(() => {
        refreshItems("InitItems");
    }, []);

    const getItemNotEnabledString = () : string => {
        return `${props.label} not enabled`;
    }
    const getItemNotFoundString = () : string => {
        return `${props.label} not found`;
    }
    const refreshItems = (caller: string) : void => {
        var fs : ItemState<T, TKey> = {
            items: [],
            lastId: undefined,
            more: false,
            refreshing: false,
        };
        var lastId = caller === "onMoreItems" ? itemState.lastId : undefined;
        if (lastId) {
            fs.items = itemState.items;
        }
        setActionError("");
        itemState.refreshing = true;
        var success = false;
        props.getItems(formState.pageSize, lastId).then(resp => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                var items = listResponse.items;
                if (items) {
                    if (lastId) {
                        for (const item of items) {
                            fs.items.push(item);
                        }
                    } else {
                        fs.items = items;
                    }
                    if (items.length > 0) {
                        fs.lastId = props.getId(items[items.length - 1]);
                        fs.items = fs.items.sort((x, y) => props.getName(x).localeCompare(props.getName(y)))
                        if (listResponse.pageSize <= listResponse.items.length) {
                            fs.more = true;
                        }
                    }
                }
            }
            success = true;
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            itemState.refreshing = false;
            if (success || !lastId) {
                setItemState(fs);
            }
        });
    };

    const handleChange = (id: string) =>{
        var itemId : TKey = props.idFromString(id);
        var fs = {...formState, "selectedId": itemId};
        var item = itemState.items.find(it => props.getId(it) === itemId);
        fs.selectedItem = item;
        if (!item) {
            fs.error = getItemNotFoundString();
        } else {
            fs.error = "";
        }
        setFormState(fs);
        if (props.onChange) {
            props.onChange(item);
        }
    }
    const onMoreItem = () : void => {
        if (!itemState.lastId) {
            setActionError(`${props.label} more failure: LastId was not set`);
            return;
        }
        refreshItems("onMoreItem");
    }
    const checkSelectedItem = () : void => {
        if (!formState.selectedId) {
            if (formState.selectedItem) {
                var fs : ItemSelectState<T, TKey> = {...formState, "selectedId": undefined};
                fs.selectedItem = undefined;
                fs.error = "Required";
                setFormState(fs);
            }
            return;
        } 
        if (formState.selectedItem && props.getId(formState.selectedItem) === formState.selectedId) {
            return;
        }
        var item = itemState.items.find(it => props.getId(it) === formState.selectedId);
        if (item) {
            if (props.filter && !props.filter(item)) {
                if (formState.error === ITEM_NOT_ENABLED) {
                    return;
                }
                var fs : ItemSelectState<T, TKey> = {...formState, "error": ITEM_NOT_ENABLED};
                setFormState(fs);
                return;    
            }
            var fs : ItemSelectState<T, TKey> = {...formState, "selectedItem": item};
            fs.error = "";
            setFormState(fs);
            return;
        }

        if (itemState.refreshing) {
            return;
        }
        if (itemState.more) {
            refreshItems("onMoreItem");
            return;
        }
        if (formState.error === ITEM_NOT_FOUND) {
            return;
        }
        var fs : ItemSelectState<T, TKey>  = {...formState, "error": ITEM_NOT_FOUND};
        setFormState(fs);
        return;
    }
 
    var enabledItems : T[] = itemState.items;
    if (enabledItems.length > 0 && props.filter) {
        enabledItems = enabledItems.filter(props.filter).sort((x,y) => props.getName(x).localeCompare(props.getName(y)));
    }
    if (props.value && props.value !== formState.selectedId) {
        formState.selectedId = props.value;
        formState.selectedItem = enabledItems.find(it => props.getId(it) === props.value);
    }
    var itemStateInfoText = `${props.label} state - Refreshing:  ${itemState.refreshing} | Count: ${itemState.items.length} | PageSize: ${formState.pageSize}`;
    if (props.filter) {
        itemStateInfoText += ` | EnabledCount: ${enabledItems.length}`;
    }
    var itemStateInfoBox = (<Box fontSize={10}>{itemStateInfoText}</Box>);
    var selectedItemInfoBox = null;
    var selectedItem = formState.selectedItem;
    if (selectedItem && props.getInfoText) {
        var selectedItemInfoText = props.getInfoText(selectedItem);
        selectedItemInfoBox = (<Box fontSize={10}>{selectedItemInfoText}</Box>);
    }
    var moreBtn = null;
    if (itemState.more && !itemState.refreshing) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={itemState.refreshing}
            onClick={e => onMoreItem()}>
            More
        </Button>);
    }

    
    return (
        <div>
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label={props.label}
                select
                name="itemId"
                value={formState.selectedId ? formState.selectedId.toString() : ""}
                onChange={e => handleChange(e.target.value)}
                style={{width:300}}
                helperText={props.helperText}
                error={props.error}
            >
                {props.allItemEnabled ? (
                <MenuItem key={"op_wc"} value={""}>
                    {"(All)"}
                </MenuItem>) : null}
                {enabledItems.map(it => (
                <MenuItem key={`item_${props.getId(it)}`} value={`${props.getId(it)}`}>
                    {props.getName(it)}
                </MenuItem>
                ))}
            </TextField>
            &nbsp;
            {moreBtn}
        </Box>
        {itemStateInfoBox}
        {selectedItemInfoBox}
        </div>
    );
};

export default ItemSelect;
