import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { OperatorTickerOverviewItem } from './Ticker2Models';
import { dateStringTicker } from '../common/helpers/DateHelpers';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { formatToCurrency } from '../common/helpers/CurrencyHelpers';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import Ticker2Util from './Ticker2Util';


interface OperatorOverviewTableProps {
    items: OperatorTickerOverviewItem[];
    onSelect? : (item : OperatorTickerOverviewItem) => void;
    onDeselect?: () => void;
}

const OperatorOverviewTable: React.FC<OperatorOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<OperatorTickerOverviewItem>[]>([]);
    const currency = RoundOverviewUtil.CURRENCY_EUR;

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<OperatorTickerOverviewItem>[] = [
        { id: 'operatorId', numeric: false, disablePadding: true, label: "Operator" }
        // { id: 'betCount', numeric: true, disablePadding: false, label: '#Bets' },
        // { id: 'turnover', numeric: true, disablePadding: false, label: 'Turnover', renderValue: (item: OperatorTickerOverviewItem) => { return formatToCurrency(item.turnover, currency); } },
        // { id: 'result', numeric: true, disablePadding: false, label: 'Result', renderValue: (item: OperatorTickerOverviewItem) => { return formatToCurrency(item.result, currency); } },
        // { id: 'refund', numeric: true, disablePadding: false, label: 'Refund', renderValue: (item: OperatorTickerOverviewItem) => { return formatToCurrency(item.refund, currency); } },
        // { id: 'pl', numeric: true, disablePadding: false, label: 'PL', renderValue: (item: OperatorTickerOverviewItem) => { return formatToCurrency(item.pl, currency); } },
        // { id: 'fee', numeric: true, disablePadding: false, label: 'Fee', renderValue: (item: OperatorTickerOverviewItem) => { return formatToCurrency(item.fee, currency); } }
      ];
      Ticker2Util.appendTickerOverviewColumns<OperatorTickerOverviewItem>(cols);
      setColumns(cols);
    }
    const onSelect = (item : OperatorTickerOverviewItem) => {
      setSelectedId(item ? item.operatorId : "");
      if (props.onSelect){
        props.onSelect(item);
      }
    };
    const onDeselect = () => {
      setSelectedId("");
      if (props.onDeselect){
        props.onDeselect();
      }
    };

  return (
    <DataTable columns={columns} items={props.items} getId={it => it.operatorId} orderBy='turnover' order='desc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect} />
    );
};
  
export default OperatorOverviewTable;
