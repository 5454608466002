import React, {useState} from 'react';
import {
    Create,
    Edit,
    SimpleForm,
    Datagrid,
    TextField,
    DateField,
    List,
    NumberField,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    RadioButtonGroupInput,
    SelectField, Filter, TextInput, SelectInput, ReferenceManyField, FormDataConsumer, useRefresh, useNotify
} from 'react-admin';
import {TeamReferenceField, TeamReferenceInput} from "../teams";
import {SeasonReferenceField} from "../seasons";
import {GameRoundReferenceField, GameRoundReferenceInput} from "../gameRound";
import {LeagueReferenceField, LeagueReferenceInput} from "../leagues";
import VerboseSelectInput from "../../components/VerboseSelectInput";
import {DateInput, DateTimeInput} from "../../date-inputs";
import {FormSpy} from "react-final-form";
import HorizontalForm from "../../components/HorizontalForm";
import moment from "moment";
import pick from "lodash/pick";
import LocalFilter from "../../components/LocalFilter";
import {addDays} from "date-fns";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';
import {DrawGrid} from "../draws";
import fetchApi from "../../fetchApi";
import {FixtureInformationValueGrid, FixtureInformationValueReferenceField} from "../fixtureInformationValues";

const statusChoices = [
  { id: 'PENDING', name: 'Pending'},
  { id: 'PLAYING', name: 'Playing'},
  { id: 'FINISHED', name: 'Finished'},
  { id: 'ABORTED', name: 'Aborted'},
];

// eslint-disable-next-line no-unused-vars
const MultipleFixtureStatusInput = props =>
  <RadioButtonGroupInput {...props} choices={statusChoices} />;

export const FixtureStatusInput = props =>
  <SelectInput {...props} choices={statusChoices} />;

const FixtureStatusField = props =>
  <SelectField {...props} choices={statusChoices} />;


const updateModeChoices = [
  { id: 'AUTOMATIC', name: 'Automatic', desc: 'Status and score will be automatically updated from provider'},
  { id: 'MANUAL', name: 'Manual', desc: 'Status and scores are manually updated only'},
];

const FixtureUpdateModeInput = props =>
   <VerboseSelectInput {...props} choices={updateModeChoices}/>;

const SmartGameRoundReferenceInput = props =>
  <FormSpy subscription={{values: true}}>
    {({values}) =>
      values.leagueId ?
        <GameRoundReferenceInput filter={pick(values, 'leagueId', 'fromAt', 'toAt')} allowEmpty  {...props}/>:
        null
    }
  </FormSpy>


const FixtureFilterForm = ({filterComponent: FilterComponent, includeSearch, ...props}) => {
  return <FilterComponent {...props}>
    {includeSearch && <TextInput label="Search" source="q" alwaysOn />}
    <DateInput source={"fromAt"} alwaysOn/>
    <DateInput source={"toAt"} alwaysOn/>
    <LeagueReferenceInput label={'League'} source={"leagueId"} allowEmpty alwaysOn/>
    <SmartGameRoundReferenceInput label={'Game Round'} source={"gameRoundId"} alwaysOn/>
    <FixtureStatusInput label={'Status'} source={"status"} allowEmpty alwaysOn/>
  </FilterComponent>;
}

export const LocalFixtureFilter = props =>
  <FixtureFilterForm filterComponent={LocalFilter} {...props}/>


const FixtureFilter = (props) => (
  <FixtureFilterForm filterComponent={Filter} includeSearch {...props}/>
);


const CreateRelatedDrawButton = ({ record }) => (
  <Button
    variant={'contained'}
    component={Link}
    to={{
      pathname: '/draws/create',
      state: { record: { fixtureId: record.id } },
    }}
  >
    Create Draw for this Fixture
  </Button>
);
export const FixtureCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <DateTimeInput source={"startingAt"}/>
            <TeamReferenceInput source={"homeTeam.teamId"} label={'Home Team'}/>
            <TeamReferenceInput source={"awayTeam.teamId"} label={'Away Team'}/>
        </SimpleForm>
    </Create>
);

export const FixtureEdit = props => {
    const [forceSyncFixtureButtonVisible, setForceSyncFixtureButtonVisible] = useState(true);
    const refresh = useRefresh();
    const notify = useNotify();

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField source="id" />
                <DateTimeInput source={"startingAt"}/>
                <DateField source="createdAt" showTime/>
                <TeamReferenceField source={'homeTeam.teamId'} label={'Home Team'} addLabel={true}/>
                <TeamReferenceField source={'awayTeam.teamId'} label={'Away Team'} addLabel={true}/>
                <FixtureUpdateModeInput source={"updateMode"} addLabel={true}/>
                <HorizontalForm>
                    <FixtureStatusInput source={"status"}/>
                    <NumberInput source={'homeTeam.score'} label={'Home Team Score'}/>
                    <NumberInput source={'awayTeam.score'} label={'Away Team Score'}/>
                </HorizontalForm>
                <TextField source={"providerStatus"}/>
                <SeasonReferenceField source={"seasonId"} allowEmpty addLabel={true}/>
                <GameRoundReferenceField source={"gameRoundId"} allowEmpty addLabel={true}/>
                <LeagueReferenceField source={"leagueId"} allowEmpty addLabel={true}/>

                <ReferenceManyField reference={'fixtureInformationValues'} target={'fixtureId'} label={'Fixture InformationValues'}>
                    <FixtureInformationValueGrid/>
                </ReferenceManyField>

                <ReferenceManyField reference={'draws'} target={'fixtureId'} label={'Draws'}>
                    <DrawGrid/>
                </ReferenceManyField>
                <CreateRelatedDrawButton/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.id && forceSyncFixtureButtonVisible) {
                            return <Button type={'button'} style={{marginTop: '16px'}} onClick={() => forceSyncFixture(formData.id, refresh, notify, setForceSyncFixtureButtonVisible)} variant={'outlined'}>Force Sync Fixture With Provider</Button>
                        } else {
                            return <div/>
                        }}
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}

export const FixtureGrid = props =>
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <FixtureStatusField source={"status"}/>
    <TextField source={"providerStatus"}/>
    <DateField source="startingAt" showTime/>
    <DateField source="createdAt" showTime/>
    <TeamReferenceField source={'homeTeam.teamId'} label={'Home Team'} addLabel={true}/>
    <TeamReferenceField source={'awayTeam.teamId'} label={'Away Team'} addLabel={true}/>
    <NumberField source={'homeTeam.score'} label={'Home Team Score'}/>
    <NumberField source={'awayTeam.score'} label={'Away Team Score'}/>
    <SeasonReferenceField source={"seasonId"} allowEmpty addLabel={true}/>
    <GameRoundReferenceField source={"gameRoundId"} allowEmpty addLabel={true}/>
    <LeagueReferenceField source={"leagueId"} allowEmpty addLabel={true}/>
  </Datagrid>;

export const FixtureList = props => (
    <List filters={<FixtureFilter/>} filterDefaultValues={{status: "PENDING", fromAt: new Date(), toAt: addDays(new Date(), 14)}}  {...props}>
      <FixtureGrid/>
    </List>
);


const referenceNameOfFixture = f =>
  `${f?.id} ${f?.homeTeam?.teamName} - ${f?.awayTeam?.teamName} (${moment(f?.startingAt).fromNow()})`

export const FixtureReferenceInput = props =>
    <ReferenceInput label={'Fixture'}  reference="fixtures" {...props} >
        <AutocompleteInput optionText={referenceNameOfFixture} emptyValue={""}/>
    </ReferenceInput>;

const FixtureReferenceName = props => {
  const r = props.record;
  if (!r) return "";

  return <span>{referenceNameOfFixture(r)}</span>;
}


const forceSyncFixture = async (fixtureId, refresh, notify, setForceSyncFixtureButtonVisible) => {
    setForceSyncFixtureButtonVisible(false);
    const res = await fetchApi(`/extended/fixtures/sync/${fixtureId}`, {
        method: 'POST',
    }).then( async (json) => ({
        data: json,
    })).catch(err => {
        notify(`got error ${err}`);
        setForceSyncFixtureButtonVisible(true);
    })

    notify(`Synced fixture ${fixtureId} with Sportmonks`);
    refresh();
}

export const FixtureReferenceField = props =>
    <ReferenceField reference="fixtures" {...props} >
        <FixtureReferenceName/>
    </ReferenceField>;
