import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import RoundAmount from "./RoundAmount";

const RoundAmountField = ({ source, record = {} }) => <RoundAmount amount={get(record, source)}/>;

RoundAmountField.propTypes = {
    addLabel: PropTypes.bool,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

RoundAmountField.defaultProps = {
    addLabel: true
};

export default RoundAmountField;