import dayjs from "dayjs";


export const dateStringTicker = (date: Date) => {
    let str = dayjs(date);
    return str.format('DD MMM HH:mm');
};

export const getFirstDayLastWeek = () => {
    var currentDate = new Date;
    var currentMonthDay = currentDate.getDate()
    var currentWeekDay = currentDate.getDay();

    var firstday = new Date(currentDate.setDate(currentMonthDay - currentWeekDay - 6));
    return firstday;
};

export function getFirstDayOfThisWeek(date: Date): Date 
{
    var currentMonthDay = date.getDate()
    var currentWeekDay = date.getDay();

    var firstday = new Date(date.setDate(currentMonthDay - currentWeekDay + 1));
    return firstday;
};

export const getFirstDayCurrentMonth = () => {
    var currentDate = new Date;

    var firstday = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    return firstday;
};

export const getFirstDayCurrentMonthAsString = (format: string)  => {
    var firstday = getFirstDayCurrentMonth();
    let strFirstday = dayjs(firstday);
    return strFirstday.format(format);
};

export const getFirstDayThisWeek = () => {
    var currentDate = new Date;
    var currentMonthDay = currentDate.getDate()
    var currentWeekDay = currentDate.getDay();

    var firstday = new Date(currentDate.setDate(currentMonthDay - currentWeekDay + 1));
    return firstday;
};

export const getFirstDayLastWeekAsString = (format: string) => {
    var firstday = getFirstDayLastWeek();
    let strFirstday = dayjs(firstday);
    return strFirstday.format(format);
};

export const getFirstDayThisWeekAsString = (format: string) => {
    var firstday = getFirstDayThisWeek();
    let strFirstday = dayjs(firstday);
    return strFirstday.format(format);
};

export const getTodayAsString = (format: string) => {
    let strToday = dayjs(Date.now());
    return strToday.format(format);
};

export const getLastDayLastWeek = () => {
    var currentDate = new Date;
    var currentMonthDay = currentDate.getDate()
    var currentWeekDay = currentDate.getDay();

    var lastday = new Date(currentDate.setDate(currentMonthDay - currentWeekDay ));
    return lastday;
};

export const getLastDayLastWeekAsString = (format: string) => {
    var lastday = getLastDayLastWeek();
    let strLastday = dayjs(lastday);
    return strLastday.format(format);
};

export const getFirstDayofYearOrStart = () => {
    var currentDate = new Date;
    var firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    if(currentDate.getFullYear() === 2023){
        firstDayOfYear = new Date(2023, 0, 22);// Månader är nollbaserade, så 0 motsvarar januari
    }

    return firstDayOfYear;
};

export const getFirstDayofYearOrStartAsString = (format: string) => {
    var firstday = getFirstDayofYearOrStart();
    let strFirstday = dayjs(firstday);
    return strFirstday.format(format);
};

export const dateTimeString = (date: Date) => {
    let str = dayjs(date);
    return str.format('YYYY-MM-DD HH:mm');
};

export const dateString = (date: Date) => {
    let str = dayjs(date);
    return str.format('YYYY-MM-DD');
};

export const dateTimeStringUK = (date: Date) => {
    let str = dayjs(date, { locale: 'en-gb' });
    return str.format('MM/DD/YYYY HH:mm');
};

export const getMonthIndex= (date: Date): number => {
    let monthIndex = date.getMonth();
    return monthIndex;
  };


  export function getMonthIndexSince2020 (date: Date): number
  {
    const startDate = new Date(2020, 0, 1); // January is month 0
    const endDate = date;
  
    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthDiff = endDate.getMonth() - startDate.getMonth();
  
    return yearDiff * 12 + monthDiff;
  };

export function getDayIndexSince2020 (date: Date): number 
{
    const startDate = new Date(2020, 0, 1); // January is month 0
    const timeDifference = date.getTime() - startDate.getTime();
    const daysPassed = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    return daysPassed;
}

export function getWeekIndexSince2020(inputDate: Date): number {
    const startDate = new Date(2020, 0, 1); // January is month 0
    const endDate = inputDate;
  
    const timeDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);
  
    const startDay = (startDate.getDay() + 6) % 7; // Convert Sunday (0) to 6
    const endDay = (endDate.getDay() + 6) % 7;
  
    const fullWeeks = Math.floor(daysDiff / 7);
    let additionalDays = 0;
  
    if (startDay <= endDay) {
      additionalDays = endDay - startDay;
    } else {
      additionalDays = 7 - startDay + endDay;
    }
  
    return fullWeeks + (additionalDays > 0 ? 1 : 0);
}

export function getWeekNumberSince2020(d: Date): number {
    let year = d.getFullYear();
    let totWeeks = (year - 2020) *52;
    let week = getWeekNumber(d);
    return totWeeks + week;
}

export function getWeekNumber(d: Date): number {
    // Copy date so we don't modify the original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  
    // Set to the nearest Thursday (current date or before)
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  
    // Get the first day of the year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  
    // Calculate the full weeks that have passed since the first day of the year
    const weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
  
    return weekNo;
  }


  export const getShortMonthName = (monthIndex: number): string => {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return monthNames[monthIndex];
  };