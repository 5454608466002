import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, TableRow, TableCell, IconButton, makeStyles, Table, Icon, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import RofResult from '../RoundOverview/RofResult';
import { SettleFixtureInput, SettleFixtureItem } from './SettleRoundModels';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';
import RofInfoDialog from '../RoundOverview/RofInfoDialog';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import RofTitle from '../RoundOverview/RofTitle';

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1,
  style: { width: '2em', height: '1.5em', margin: '2px' },
  borderColor: 'text.primary',
  //fontSize:24
};
const signProps = {
  bgcolor: 'lightgray'
};
const validProps = {
  bgcolor: '#B5E61D'
};
const invalidProps = {
  bgcolor: '#F09B59'
};

const useStyles = makeStyles({
  hidden: {
    background: 'lightgray'
  },
  sameInput: {
    background: '#B5E61D'
  },
  differentInput: {
    background: '#F09B59'
  }
});



interface SettleRoundFixtureRowState {
  roundId: string;
  fixtureId: string;
  homeScore: string;
  awayScore: string;
  confirmed: boolean;
  valid: boolean;
  homeScoreError: string;
  awayScoreError: string;
  refreshed: boolean;
  sign: string;
}

interface SettleRoundFixtureRowProps {
  item: SettleFixtureItem;
  check: boolean;
  onChange?: (item: SettleFixtureInput) => void;
}

const SettleRoundFixtureRow: React.FC<SettleRoundFixtureRowProps> = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState<SettleRoundFixtureRowState>({
    roundId: props.item.fixture.roundId,
    fixtureId: props.item.fixtureId,
    homeScore: props.item.input ? props.item.input.homeScoreText : "",
    awayScore: props.item.input ? props.item.input.awayScoreText : "",
    confirmed: props.item.input ? props.item.input.confirmed : false,
    valid: props.item.input ? props.item.input.valid : false,
    homeScoreError: "",
    awayScoreError: "",
    refreshed: false,
    sign: props.item.input ? props.item.input.sign : "",
  });

  const refreshFormData = (fd: SettleRoundFixtureRowState) => {
    var fixture = props.item.fixture;
    if (fixture.drawSign && fixture.drawSign.length > 0) {
      fd.refreshed = true;
      return;
    }
    var homeScore = parseInt(fd.homeScore);
    var awayScore = parseInt(fd.awayScore);

    var valid = true;
    var homeScoreError = "";
    var awayScoreError = "";

    if (fd.homeScore.length === 0) {
      valid = false;
      homeScoreError = "Required";
    } else if (RoundOverviewUtil.isInvalidScore(homeScore, fd.homeScore)) {
      valid = false;
      homeScoreError = "Invalid score";
    }
    if (fd.awayScore.length === 0) {
      valid = false;
      awayScoreError = "Required";
    } else if (RoundOverviewUtil.isInvalidScore(awayScore, fd.awayScore)) {
      valid = false;
      awayScoreError = "Invalid score";
    }
    fd.refreshed = true;
    fd.valid = valid;
    fd.homeScoreError = homeScoreError;
    fd.awayScoreError = awayScoreError;
    if (fd.valid) {
      fd.sign = RoundOverviewUtil.getScoreSign(homeScore, awayScore);
    } else {
      fd.sign = "";
    }

  };

  if (formData.fixtureId !== props.item.fixtureId || formData.roundId !== props.item.fixture.roundId){
    var itemX = props.item;
    var inputX = itemX.input;
    console.log("SettleRoundFixtureRow.main issue fixed: formData.fixtureId !== props.fixtureId (%s !== /%s) or formData.roundId !== props.roundId (%s !== /%s)",
      formData.fixtureId, itemX.fixtureId, formData.roundId, itemX.fixture.roundId);
    formData.roundId = itemX.fixture.roundId;
    formData.fixtureId = itemX.fixtureId;
    formData.homeScore =  inputX ? inputX.homeScoreText : "";
    formData.awayScore = inputX ? inputX.awayScoreText : "";
    formData.confirmed = inputX ? inputX.confirmed : false;
    formData.valid = inputX ? inputX.valid : false;
    formData.homeScoreError = "";
    formData.awayScoreError = "";
    formData.refreshed = false;
    formData.sign = inputX ? inputX.sign : "";
  }
  if (!formData.refreshed){
    refreshFormData(formData)
  }


  if (!formData.refreshed) {
    refreshFormData(formData)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    handleChange2(name, value);
  };
  const handleChange2 = (name: string, value: any) => {
    var fd = { ...formData, [name]: value };

    if (!props.check) {
      fd.confirmed = false;
    }
    refreshFormData(fd);
    setFormData(fd);
    var homeScore = fd.valid ? parseInt(fd.homeScore) : null;
    var awayScore = fd.valid ? parseInt(fd.awayScore) : null;
    var fixture = props.item.fixture;
    var finished = (fixture.status === RoundOverviewUtil.FINISHED_STATUS);
    var valid2 = (fd.valid && fd.confirmed);
    if (fixture.drawSign && fixture.drawSign.length > 0) {
      finished = (fixture.draw?.status === RoundOverviewUtil.FINISHED_STATUS);
      if (!valid2 && fd.valid && finished) {
        valid2 = true;
      }
    } else {
      if (!valid2 && fd.valid && finished) {
        if (homeScore === fixture.homeScore && awayScore === fixture.awayScore) {
          valid2 = true;
        }
      }
    }

    if (props.onChange) {
      var changeArgs: SettleFixtureInput = {
        fixtureId: props.item.fixtureId,
        homeScoreText: fd.homeScore,
        awayScoreText: fd.awayScore,
        confirmed: fd.confirmed,
        valid: fd.valid,
        homeScore: homeScore,
        awayScore: awayScore,
        sign: fd.sign,
        valid2: valid2
      };
      props.onChange(changeArgs);
    }
  };

  const validColor = RoundOverviewUtil.VALID_COLOR;
  const invalidColor = RoundOverviewUtil.INVALID_COLOR;
  var row = props.item;
  var fixture = row.fixture;
  var providerValid = true;
  var providerValidIcon = null;
  var drawDone = false;
  var friendlySign = RoundOverviewUtil.getFriendlySign(formData.sign);
  if (fixture.drawSign && fixture.drawSign.length > 0) {
    drawDone = true;
    var drawInfo = `Draw ${fixture.drawSign} (${fixture.draw?.status})`;
    if (fixture.draw?.status === RoundOverviewUtil.FINISHED_STATUS) {
      providerValidIcon = (<Tooltip title={drawInfo}><DoneIcon style={{ color: validColor }} /></Tooltip>);
    } else {
      providerValid = false;
      providerValidIcon = (<Tooltip title={drawInfo}><CancelIcon style={{ color: invalidColor }} /></Tooltip>);
    }
  } else if (fixture.status === RoundOverviewUtil.FINISHED_STATUS) {
    providerValidIcon = (<Tooltip title={fixture.status + " (" + fixture.providerStatus + ")"}><DoneIcon style={{ color: validColor }} /></Tooltip>);
  } else {
    providerValid = false;
    providerValidIcon = (<Tooltip title={fixture.status + " (" + fixture.providerStatus + ")"}><CancelIcon style={{ color: invalidColor }} /></Tooltip>);
  }
  var resultText = null;
  var resultColor: any = validColor;
  var fixtureResult = fixture.result;
  if (fixtureResult) {
    resultText = `Round.fixtureResult: ${fixtureResult.sign} (${fixtureResult.status})`;
    if (drawDone) {
      if (fixtureResult.sign !== fixture.drawSign || fixtureResult.status !== RoundOverviewUtil.FINISHED_STATUS) {
        resultColor = invalidColor;
      }
    }
    else {
      if (fixtureResult.sign !== fixture.sign || fixtureResult.status !== RoundOverviewUtil.FINISHED_STATUS) {
        resultColor = invalidColor;
      }
    }
  } else {
      resultText = `Round.fixtureResult: not set`;
      resultColor = invalidColor;
  }
  
  var finishValidIcon = null;
  var resultInfo = null;
  if (props.check) {
    var finishValidMessage = row.finishValid ? "Valid" : "Invalid: " + row.finishMessage;
    //finishValidInfo = (<Box fontSize={11} color={row.finishValid ? validColor : invalidColor}>{finishValidMessage}</Box>);
    if (row.finishValid) {
      finishValidIcon = (<Tooltip title={finishValidMessage}><DoneIcon style={{ color: validColor }} /></Tooltip>);
    } else {
      finishValidIcon = (<Tooltip title={finishValidMessage}><CancelIcon style={{ color: invalidColor }} /></Tooltip>);
    }
    resultInfo = (<Box fontSize={11} color={resultColor}>{resultText}</Box>);
  }
  if (drawDone) {
    var confirmCheckBox = null;
    var fixtureInfoDlg = null;
    if (props.check) {
      fixtureInfoDlg = (<RofInfoDialog fixture={row.fixture} openButtonLabel="Info" allObject={row} allObjectType={"SettleFixtureItem"}  />);
      if (!providerValid) {
        confirmCheckBox = (<FormControlLabel
          control={
            <Checkbox checked={formData.confirmed} onChange={e => handleChange2(e.target.name, e.target.checked)} name="confirmed" />
          }
          label="Confirm (finish draw)"
        />);
      }
    }
    return (
      <TableRow key={row.fixtureId} >
        <TableCell component="th" scope="row">
          {row.fixture.ordNo}
        </TableCell>
        <TableCell>
          <RofTitle fixture={fixture} />
        </TableCell>
        <TableCell>
          <Box display="flex" padding={0} margin="0" >
            <TextField
              variant="outlined"
              name="homeProviderScore"
              size="small"
              inputProps={{ step: 1, className: classes.hidden, readOnly: true, tabIndex: -1 }}
              value={"Drawn To " + friendlySign}
              style={{ width: '10em' }}
              tabIndex={-1}
            />&nbsp;{providerValidIcon}
          </Box>
          {resultInfo}
        </TableCell>
        <TableCell>
          <Box display="flex" padding={0} margin="0" >
            <TextField
              variant="outlined"
              name="sign"
              size="small"
              inputProps={{ className: classes.hidden, readOnly: true, tabIndex: -1 }}
              value={friendlySign}
              style={{ width: '3em' }}
            />&nbsp;
            {confirmCheckBox}
          </Box>
        </TableCell>
        <TableCell>
          {finishValidIcon}
        </TableCell>
        <TableCell>
          {fixtureInfoDlg}
        </TableCell>
      </TableRow>
    );

  } else {

    if (!props.check) {
      return (
        <TableRow key={row.fixtureId} >
          <TableCell component="th" scope="row">
            {row.fixture.ordNo}
          </TableCell>
          <TableCell>
            <RofTitle fixture={fixture} />
          </TableCell>
          <TableCell>
            <form>
              <Box display="flex" padding={0} margin="0" >
                <TextField
                  variant="outlined"
                  name="homeProviderScore"
                  size="small"
                  inputProps={{ step: 1, className: classes.hidden, readOnly: true, tabIndex: -1 }}
                  value=""
                  style={{ width: '5em' }}
                  tabIndex={-1}
                />&nbsp;-&nbsp;
                <TextField
                  variant="outlined"
                  name="awayProviderScore"
                  size="small"
                  inputProps={{ step: 1, className: classes.hidden, readOnly: true, tabIndex: -1 }}
                  value=""
                  style={{ width: '5em' }}
                />&nbsp;{providerValidIcon}&nbsp;
                <TextField
                  variant="outlined"
                  name="homeScore"
                  size="small"
                  type='number'
                  inputProps={{ step: 1 }}
                  defaultValue={formData.homeScore}
                  error={formData.homeScoreError && formData.homeScoreError.length > 0 ? true : false}
                  helperText={formData.homeScoreError && formData.homeScoreError.length > 0 ? formData.homeScoreError : ""}
                  onChange={handleChange}
                  style={{ width: '5em' }}
                />&nbsp;-&nbsp;
                <TextField
                  variant="outlined"
                  name="awayScore"
                  size="small"
                  type='number'
                  inputProps={{ step: 1 }}
                  defaultValue={formData.awayScore}
                  error={formData.awayScoreError && formData.awayScoreError.length ? true : false}
                  helperText={formData.awayScoreError && formData.awayScoreError.length > 0 ? formData.awayScoreError : ""}
                  onChange={handleChange}
                  style={{ width: '5em' }}
                />&nbsp;
              </Box>
            </form>
          </TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>
            <Box display="flex" padding={0} margin="0" >
              <TextField
                variant="outlined"
                name="sign"
                size="small"
                inputProps={{ className: classes.hidden, readOnly: true, tabIndex: -1 }}
                value={friendlySign}
                style={{ width: '3em' }}
              />
            </Box>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      );

    } else {
      var finished = (fixture.status === RoundOverviewUtil.FINISHED_STATUS);
      var fixtureHomeScore = finished ? fixture.homeScore : "";
      var fixtureAwayScore = finished ? fixture.awayScore : "";
      var sameInput = finished;
      if (sameInput) {
        if (fixture.homeScore !== parseInt(formData.homeScore)) {
          sameInput = false;
        } else if (fixture.awayScore !== parseInt(formData.awayScore)) {
          sameInput = false;
        }
      }
      var scoreClass = sameInput ? classes.sameInput : classes.differentInput;
      var confirmCheckBox = null;
      if (!sameInput) {
        confirmCheckBox = (<FormControlLabel
          control={
            <Checkbox checked={formData.confirmed} onChange={e => handleChange2(e.target.name, e.target.checked)} name="confirmed" />
          }
          label="Confirm"
        />);
      }
      return (
        <TableRow key={row.fixtureId} >
          <TableCell component="th" scope="row">
            {row.fixture.ordNo}
          </TableCell>
          <TableCell>
            <RofTitle fixture={fixture} />
          </TableCell>
          <TableCell>
            <form>
              <Box display="flex" padding={0} margin="0" >
                <TextField
                  variant="outlined"
                  name="homeProviderScore"
                  size="small"
                  inputProps={{ step: 1, className: scoreClass, readOnly: true, tabIndex: -1 }}
                  value={fixtureHomeScore}
                  style={{ width: '5em' }}
                  tabIndex={-1}
                />&nbsp;-&nbsp;
                <TextField
                  variant="outlined"
                  name="awayProviderScore"
                  size="small"
                  inputProps={{ step: 1, className: scoreClass, readOnly: true, tabIndex: -1 }}
                  value={fixtureAwayScore}
                  style={{ width: '5em' }}
                />&nbsp;{providerValidIcon}&nbsp;
                <TextField
                  variant="outlined"
                  name="homeScore"
                  size="small"
                  inputProps={{ step: 1, className: scoreClass, readOnly: true, tabIndex: -1 }}
                  value={formData.homeScore}
                  style={{ width: '5em' }}
                />&nbsp;-&nbsp;
                <TextField
                  variant="outlined"
                  name="awayScore"
                  size="small"
                  inputProps={{ step: 1, className: scoreClass, readOnly: true, tabIndex: -1 }}
                  value={formData.awayScore}
                  style={{ width: '5em' }}
                />&nbsp;
              </Box>
              {resultInfo}
            </form>
          </TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>
          <Box display="flex" padding={0} margin="0" >
            <TextField
              variant="outlined"
              name="sign"
              size="small"
              inputProps={{ className: classes.hidden, readOnly: true, tabIndex: -1 }}
              value={friendlySign}
              style={{ width: '3em' }}
            />&nbsp;
            {confirmCheckBox}
            </Box>
          </TableCell>
          <TableCell>
            {finishValidIcon}
          </TableCell>
          <TableCell>
            <RofInfoDialog fixture={row.fixture} openButtonLabel="Info" allObject={row} allObjectType={"SettleFixtureItem"} />
          </TableCell>
        </TableRow>
      );
    }
  }
};

export default SettleRoundFixtureRow;
