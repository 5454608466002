import React, { useState,useEffect } from 'react';
import { Container,TextField,Button, MenuItem, Box, Switch, FormControlLabel } from "@material-ui/core";
import { RoundOverviewItem } from '../RoundOverview/RoundOverviewModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { ListResponse } from '../api/ApiNgModels';
import { Bet } from '../models/CouponDetail';
import CouponEdit, { CouponEditInfo } from '../Components/CouponEdit';

interface CreateCouponStepProps {
    round: RoundOverviewItem;
    bet: Bet;
    onChange : (legIndex: number, info: CouponEditInfo) => void;
}

const CreateCouponStep: React.FC<CreateCouponStepProps> = (props: CreateCouponStepProps) => {
    
    const onCouponChange = (legIndex: number, info: CouponEditInfo) => {
        if (props.onChange) {
            props.onChange(legIndex, info);
        }
    };

    return (
        <div>
            <CouponEdit bet={props.bet} round={props.round} roundId={props.round.id} onChange={onCouponChange} />
      </div>
  );
};

export default CreateCouponStep;

