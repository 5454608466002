import React from 'react';
import {
  Edit,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  List,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  FormTab,
  ReferenceManyField,
  Pagination,
  TabbedForm,
  RadioButtonGroupInput, SelectField, Filter, SelectInput
} from 'react-admin';
import {SeasonReferenceField} from "../seasons";
import {LeagueReferenceField} from "../leagues";
import {FixtureGrid} from "../fixtures";
import moment from "moment";

const statusChoices = [
  { id: 'INACTIVE', name: 'Inactive'},
  { id: 'CURRENT', name: 'Current'},
];

export const GameRoundStatusInput = props =>
  <SelectInput {...props} choices={statusChoices} />;

const MultiGameRoundStatusInput = props =>
  <RadioButtonGroupInput {...props} choices={statusChoices} />;

const GameRoundStatusField = props =>
  <SelectField {...props} choices={statusChoices} />;

const GameRoundFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <MultiGameRoundStatusInput label={'Status'} source={"status"} alwaysOn/>
  </Filter>
);

export const GameRoundGrid = props =>
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <GameRoundStatusField source="status" />
    <DateField source={"startsAt"} showTime/>
    <DateField source={"endsAt"} showTime/>
    <TextField source="providerId" />
    <SeasonReferenceField source={"seasonId"} allowEmpty addLabel={true}/>
    <LeagueReferenceField source={"leagueId"} allowEmpty addLabel={true}/>
  </Datagrid>


export const GameRoundList = props => (
    <List filters={<GameRoundFilter/>} filterDefaultValues={{status: 'CURRENT'}}  {...props}>
      <GameRoundGrid/>
    </List>
);

export const GameRoundEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label={'General'}>
        <TextField source="id" />
        <DateField source="createdAt" showTime/>
        <TextInput source="name" />
        <GameRoundStatusField source="status" />
        <SeasonReferenceField source={"seasonId"} allowEmpty addLabel={true}/>
        <LeagueReferenceField source={"leagueId"} allowEmpty addLabel={true}/>
      </FormTab>
      <FormTab label={'Fixtures'}>
        <ReferenceManyField label={"Fixtures"} reference="fixtures" target={"gameRoundId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
          <FixtureGrid/>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const referenceNameOfGameRound = gameRound => {
  if (!gameRound || gameRound.id === "") {
    return ""
  }

  return `${(gameRound.season || {}).name} ${gameRound.name} (${moment(gameRound.startsAt).fromNow()})`
}

export const GameRoundReferenceInput = props =>
  <ReferenceInput label={'GameRound'}  reference="gameRounds" {...props} >
    <AutocompleteInput optionText={r => referenceNameOfGameRound(r)} emptyValue={""}/>
  </ReferenceInput>;

const GameRoundReferenceName = props => {
  return <span>{referenceNameOfGameRound(props.record)}</span>
}

export const GameRoundReferenceField = props =>
    <ReferenceField reference="gameRounds" {...props} >
      <GameRoundReferenceName/>
    </ReferenceField>;
