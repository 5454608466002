import { DataTableColumn } from "../Components/DataTable";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { TickerItem } from "../RoundOverview/RoundOverviewModels";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { ListResponse } from "../api/ApiNgModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { formatToCurrency } from "../common/helpers/CurrencyHelpers";
import { AgentTickerOverviewItem, BaseItemState, BaseTickerOverviewItem, OperatorTickerOverviewItem, OverviewCriteria, PlayerTickerOverviewItem, RoundTickerOverviewItem } from "./Ticker2Models";
import queryString from "query-string";

class Ticker2Util {
    static getUrlParams(pageSize: number, from: Date | undefined, to: Date | undefined, lastId: any, turnoverOrder?: boolean): string {
        //var urlParams = `limit=${pageSize}&from=${from.toISOString()}&to=${to.toISOString()}&lastId=${lastId}`;
        // if (turnoverOrder !== undefined && turnoverOrder === true) {
        //     var filter = { turnoverOrder: true };
        //     const query = Object.assign({
        //         limit: pageSize,
        //         from: from,
        //         to: to,
        //         filter: JSON.stringify(filter)
        //     });
        //     urlParams = queryString.stringify(query);
        //     return urlParams;
        // }

        var urlParams = `limit=${pageSize}&lastId=${lastId}`;
        if (from) {
            urlParams += `&from=${from.toISOString()}`
        }
        if (to) {
            urlParams += `&to=${to.toISOString()}`
        }
        if (turnoverOrder !== undefined && turnoverOrder === true) {
            var filter = { turnoverOrder: true };
            const query = Object.assign({
                filter: JSON.stringify(filter)
            });
            urlParams += `&${queryString.stringify(query)}`;
        }
        return urlParams;
    }
    static async getRoundTickerOverviews(pageSize: number, from: Date, to: Date, lastId: number) : Promise<GenericResponse<ListResponse<RoundTickerOverviewItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId);
        var url = "ticker/roundOverviews?" + urlParams;
        var listResponse = await ApiNgUtil.getListByUrl<RoundTickerOverviewItem>(url, "roundOverviews");
        return listResponse;
    }
    static async getOperatorTickerOverviews(pageSize: number, from: Date, to: Date, lastId: string, turnoverOrder?: boolean) : Promise<GenericResponse<ListResponse<OperatorTickerOverviewItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId, turnoverOrder);
        var url = "ticker/operatorOverviews?" + urlParams;
        var listResponse = await ApiNgUtil.getListByUrl<OperatorTickerOverviewItem>(url, "operatorOverviews");
        return listResponse;
    }
    static async getPlayerTickerOverviews(pageSize: number, from: Date, to: Date, lastId: number, turnoverOrder?: boolean) : Promise<GenericResponse<ListResponse<PlayerTickerOverviewItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId, turnoverOrder);
        
        var url = "ticker/playerOverviews?" + urlParams;
        var listResponse = await ApiNgUtil.getListByUrl<PlayerTickerOverviewItem>(url, "playerOverviews");
        return listResponse;
    }
    static async getAgentTickerOverviews(pageSize: number, from: Date, to: Date, lastId: number, turnoverOrder?: boolean) : Promise<GenericResponse<ListResponse<AgentTickerOverviewItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId, turnoverOrder);
        var url = "ticker/agentOverviews?" + urlParams;
        var listResponse = await ApiNgUtil.getListByUrl<AgentTickerOverviewItem>(url, "agentOverviews");
        return listResponse;
    }
    static async getTickerItems(pageSize: number, from: Date, to: Date, lastId: number) : Promise<GenericResponse<ListResponse<TickerItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId);
        var url = `ticker?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<TickerItem>(url, "tickerItems");
        return listResponse;
    }
    static async getRoundTickerItems(roundId: number, pageSize: number, from: Date | undefined, to: Date | undefined, lastId: number) : Promise<GenericResponse<ListResponse<TickerItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId);
        var url = `ticker/rounds/${roundId}?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<TickerItem>(url, "tickerItems");
        return listResponse;
    }
    static async getOperatorTickerItems(operatorId: string, pageSize: number, from: Date, to: Date, lastId: number) : Promise<GenericResponse<ListResponse<TickerItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId);
        var url = `ticker/operators/${operatorId}?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<TickerItem>(url, "tickerItems");
        return listResponse;
    }
    static async getPlayerTickerItems(playerId: number, pageSize: number, from: Date, to: Date, lastId: number) : Promise<GenericResponse<ListResponse<TickerItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId);
        var url = `ticker/players/${playerId}?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<TickerItem>(url, "tickerItems");
        return listResponse;
    }
    static async getAgentTickerItems(agentId: number, pageSize: number, from: Date, to: Date, lastId: number) : Promise<GenericResponse<ListResponse<TickerItem>>> {
        var urlParams = Ticker2Util.getUrlParams(pageSize, from, to, lastId);
        var url = `ticker/agents/${agentId}?${urlParams}`;
        var listResponse = await ApiNgUtil.getListByUrl<TickerItem>(url, "tickerItems");
        return listResponse;
    }
    static sumTickerOverviews(items: BaseTickerOverviewItem[]) : BaseTickerOverviewItem {
        var betCount = 0;
        var turnover = 0;
        var result = 0;
        var refund = 0;
        var pl = 0;
        var fee = 0;
        var agentFee = 0;
        for (const item of items) {
            betCount += item.betCount;
            if (item.turnover) {
                turnover += item.turnover;
            }
            if (item.result) {
                result += item.result;
            }
            if (item.refund) {
                refund += item.refund;
            }
            if (item.pl) {
                pl += item.pl;
            }
            if (item.fee) {
                fee += item.fee;
            }
            if (item.agentFee) {
                agentFee += item.agentFee;
            }
        }
        return {
            betCount: betCount,
            turnover: turnover,
            result: result,
            refund: refund,
            pl: pl,
            fee: fee,
            agentFee: agentFee
        };
    }

    static toTickerOverviewItemString(item: BaseTickerOverviewItem, currency: string) {
        var s = `#Bets: ${item.betCount} | Turnover: ${formatToCurrency(item.turnover, currency)} | Result: ${formatToCurrency(item.result, currency)}`;
        s += ` | Refund: ${formatToCurrency(item.refund, currency)} | PL: ${formatToCurrency(item.pl, currency)}| Fee: ${formatToCurrency(item.fee, currency)}`;
        return s;
    }
    static sumTickerOverviewsToString(items: BaseTickerOverviewItem[], currency: string) : string {
        var sum = this.sumTickerOverviews(items);
        return "Summary - " + this.toTickerOverviewItemString(sum, currency);
    }

    static appendTickerOverviewColumns<T extends BaseTickerOverviewItem>(cols: DataTableColumn<T>[]) {
        var cols2: DataTableColumn<T>[] = [
            { id: 'betCount', numeric: true, disablePadding: false, label: '#Bets' },
            { id: 'turnover', numeric: true, disablePadding: false, label: 'Turnover', renderValue: (item: T) => { return RoundOverviewUtil.formatEurAumount(item.turnover); } },
            { id: 'result', numeric: true, disablePadding: false, label: 'Result', renderValue: (item: T) => { return RoundOverviewUtil.formatEurAumount(item.result); } },
            { id: 'refund', numeric: true, disablePadding: false, label: 'Refund', renderValue: (item: T) => { return RoundOverviewUtil.formatEurAumount(item.refund); } },
            { id: 'pl', numeric: true, disablePadding: false, label: 'PL', renderValue: (item: T) => { return RoundOverviewUtil.formatEurAumount(item.pl); } },
            { id: 'fee', numeric: true, disablePadding: false, label: 'Fee', renderValue: (item: T) => { return RoundOverviewUtil.formatEurAumount(item.fee); } },
            { id: 'agentFee', numeric: true, disablePadding: false, label: 'Agent Fee', renderValue: (item: T) => { return RoundOverviewUtil.formatEurAumount(item.agentFee); } }
        ];
        for (const col of cols2) {
            cols.push(col);
        }   
    }
    static criteriaChanged = (x: OverviewCriteria, y: OverviewCriteria) : boolean => {
        if (x === y) {
            return false;
        }
        if (x.pageSize !== y.pageSize) {
            return true;
        }
        if (x.from !== y.from) {
            return true;
        }
        if (x.to !== y.to) {
            return true;
        }
        if (x.createNo !== y.createNo) {
            return true;
        }
        return false;
    };
    static toItemStateString<TItem, TId, TState extends BaseItemState<TItem,TId>>(itemState: TState, criteria?: OverviewCriteria) {
        var s = `Refreshing: ${itemState.refreshing} | Count: ${itemState.items.length} | LastId: ${itemState.lastId} | More: ${itemState.more}`;
        if (criteria) {
            s += ` | PageSize: ${criteria.pageSize} | From: ${criteria.from.toISOString()} | To: ${criteria.to.toISOString()}`;
        }
        return s;
    }
   
}
export default Ticker2Util;