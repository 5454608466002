import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

interface TabPanelProps {
    children: React.ReactNode;
    value: number;
    index: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
