import React from 'react';
import Box from '@material-ui/core/Box';

const defaultProps = {
  bgcolor: '#d5e4da'

};



const DrawRulesText: React.FC = () => {
    return (
      <Box {...defaultProps}>
        <p>1.13</p><p>Notwithstanding anything else in these Terms of Play, once a pool has been settled for 72 hours, its settlement will be considered full and final.</p><p>1.14</p><p>If a match is not completed - i.e. the full period of play (so, for example, 90 minutes in the case of soccer), according to the match officials, plus any stoppage time, within 4 hours of the latest scheduled start date for any game on the coupon, it will be subject to a draw to decide the outcome of the match.</p><p>1.15</p><p>The draw will be arranged with probabilities for each outcome in the game. These probabilities can be found under the game details on the PoolX site. Probabilities are defined as: HomeProbability, DrawProbability, AwayProbability and are published when the round is created. After this they are never changed.</p><p>1.16</p><p>The random number used in the draw will be taken from the first game in order on the round not subject to a draw and not used in any other draw in the round. The last number of the minute for any of the following events will be used:</p><p>Goal
Yellow Card
Red Card</p><p>The minute used will be the minute stated by the organizing body of the tournament the game is part of. If the time of the event is given as minutes and seconds, events between 0:00 and 0:59 will be considered to be minute 1 and so on. Events in first half added time will be considered minute 45. Events in second half added time will be considered minute 90. The number will be between 0 and 9. This number is divided by 10 to give a number between 0 and 1. This number is defined as Random.</p><p>The outcome of the game in the draw is picked by</p><p>If Random is less than HomeProbability
	Outcome of game is Home
If Random is less than HomeProbability + DrawProbability
	Outcome of game is Draw
Else
	Outcome of game is Away</p>
    
    <p>1.17</p><p>The draws for the round will be conducted in the same order the games appear in the round.(If both game 3 and 6 needs to be drawn, game 3 will be drawn first using the spectator numbers from game 1. Game 6 will later be drawn with spectator numbers from game 2)</p><p>1.18</p><p>If more than half of the number of games in a round needs to be drawn, the round will be cancelled and stakes refunded.</p><p>1.19</p><p>If the scheduled venue for a Leg is changed after a pool has been loaded by PoolX, the Leg will be drawn only if the new venue is a home ground of the original away team. For matches played at a neutral venue Bets will stand regardless of which team is listed as the home team</p><p>1.20</p><p>References above within this clause 1 to a particular number of 'days' shall mean the end of the day in local time (where the relevant fixture, event or tournament is taking place) after the expiry of the specified number of days. For example, if a match is scheduled to be completed on 15th November when a pool is loaded on the Site, then the rule that allows the match to be completed within three days after the scheduled completion date would mean that the deadline for completion of that match would be 23.59:59 local time on 16th November.</p><p>1.21</p><p>PoolX accepts no responsibility for typing, transmission and/or evaluation errors. PoolX also accepts no liability for incorrectness or incompleteness or inaccuracy of the information provided via the Site, including (without limitation) any live scores and results.</p>
    <p>1.22</p><p>If there is any inconsistency in these Terms of Play between a provision of general application and a provision relating to a specific sport or a specific bet type, the latter will prevail.</p>

    <p>Date of last amendment 23 March 2023</p>
    </Box>
);
};
export default DrawRulesText;
