export class ReportRound {
    round_id: string;
    type: string;
    deadline: Date;

    constructor(round_id: string, type: string, deadline: Date) {
        this.round_id = round_id;
        this.type = type;
        this.deadline = deadline;
    }
}