import React from 'react';
import {
  Datagrid, TextField, DateField, List,
  ReferenceField, Edit, SimpleForm, ReferenceManyField, Pagination, FunctionField
} from 'react-admin';
import {RoundReferenceField} from "../rounds";
import {CouponDetails} from '../coupons';
import AmountField from '../../fields/AmountField';
import { SyndicateShareGrid } from '../syndicateShares';

export const SyndicateGrid = props => (
  <Datagrid rowClick={'edit'} {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="operatorId" />
    <TextField source="pendingCoupon.operatorChannel" label="Operator channel"/>
    <RoundReferenceField source="roundId"/>
    <TextField source="status" />
    <TextField source="agentFeePercentage"/>
    <FunctionField render={(record) => `${record.maxNShares - record.nShares}`} label="Shares available"/>
  </Datagrid>
)

export const SyndicateList = props => (
    <List sort={{ field: 'createdAt', order: 'desc'}} {...props}>
      <SyndicateGrid/>
    </List>
);

const ConditionalCouponDetails = ({record}) => {
  
  if (record.pendingCoupon) {
    return <div></div>
  }

  return (
    <ReferenceField source="pendingCoupon.id" reference="couponDetails" link={false} label={"Pending Coupon"}>
      <CouponDetails></CouponDetails>
    </ReferenceField>
  );
}

const TotalStake = ({record}) => {
  const result = parseInt(record.nShares) * parseFloat(record.stakePerShare.amount);
  
  record.totalStake = {
    currency: record.stakePerShare.currency, 
    amount: result
  };

  return <AmountField record={record} source="totalStake"/>;
}

export const SyndicateEdit = props => {
  return (<Edit {...props}> 
    <SimpleForm>
      <TextField source="id" />
      <DateField source="createdAt" showTime/>
      <TextField source="operatorId" />
      <TextField source="creatorAgentId" />
      <TextField source="targetNShares" label="Target number of shares" />
      <TextField source="maxNShares" label="Max number of shares"/>
      <TextField source="nShares" label="Current number of shares"/>
      <AmountField source="agentFeePerShare"/>
      <TextField source="agentFeePercentage"/>
      <TextField source="status" />
      <RoundReferenceField source="roundId" addLabel={true}/>
      <TotalStake addLabel={true} label="Total stake" />
      <ConditionalCouponDetails />
      
      <ReferenceField source="couponId" reference="couponDetails" link={false} label={"Coupon"}>
        <CouponDetails />
      </ReferenceField>

      <ReferenceManyField label={"Shares"} reference="syndicateShares" target={"syndicateId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
        <SyndicateShareGrid/>
      </ReferenceManyField>
      
    </SimpleForm>
  </Edit>);
  }


export const SyndicateReferenceField = props =>
    <ReferenceField reference="syndicates" {...props} >
        <TextField source="id" />
    </ReferenceField>;
