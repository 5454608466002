import React from 'react';
import {
    Datagrid, TextField, DateField,
} from 'react-admin';

export const TransactionalRequestExecutionsGrid = props => (
  <Datagrid {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="error" />
  </Datagrid>
);