import React, { useState } from "react";
import { Button, TextField, Box, MenuItem } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { Team } from "../RoundOverview/RoundOverviewModels";

interface TeamChooserProps {
    onChange: (team: Team | undefined) => void;
}
interface TeamChooserState {
    searchName: string;
    pageSize: string;
    teamId: string;
    team: Team | undefined;
}


const TeamChooser: React.FC<TeamChooserProps> = (props) => {
    const getDefaultFormState = () : TeamChooserState => {
        return {
            searchName: "",
            pageSize: "100",
            teamId: "",
            team: undefined
        };
    }
    
    const [formState, setFormState] = useState<TeamChooserState>(getDefaultFormState());
    const [teams, setTeams] = useState<Team[]>([]);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [page, setPage] = useState<number>(-1);
    const [more, setMore] = useState<boolean>(false);
 

    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        if (name === "teamId") {
            if (!value || value.length <= 0) {
                //fs.leagueName = "";
                fs.team = undefined;
            } else {
                var team = teams.find(it => it.id === value);
                fs.team = team;
            }
            props.onChange(fs.team);
        }
        setFormState(fs);
    }
    const refreshTeams = (more: boolean) => {
        var pageSize = parseInt(formState.pageSize);
        setSaveStatus(0);
        if (isNaN(pageSize) || pageSize < 1) {
            setSaveError("Page size is not a number or less than 1");
            return;
        }
        var pageX = page;
        if (more) {
            pageX++;
        } else {
            pageX = 0;
        }
        setSaveError("");
        setSaveStatus(1);
        CreateRoundUtil.getTeams(pageSize, pageX, formState.searchName).then(resp => {
            if (resp.success) {
                setPage(pageX);
                if (resp.item) {
                    var teamsX = resp.item;
                    if (more) {
                        CreateRoundUtil.addTeams(teams, teamsX, true);
                    } else {
                        CreateRoundUtil.sortTeams(teamsX);
                        setTeams(teamsX);
                    }
                    if (teamsX.length < pageSize){
                        setMore(false);
                    } else {
                        setMore(true);
                    }
        
                    if (formState.teamId !== ""){
                        var league = teamsX.find(it => it.id === formState.teamId);
                        if (league){
                            formState.team = league;
                        } else {
                            formState.team = undefined;
                        }
                    }
                    return;
                } else {
                    setTeams([]);
                    setMore(false);
                    formState.team = undefined;
                }
            
            } else {
                setSaveError(`Search error: ${resp.message}`);
            }
        }).catch(error => {
            setSaveError(`Search error: ${error}`);
        }).finally(() => {
            setSaveStatus(0);
        });
    }
    const onSearchClick = (): void => {
        refreshTeams(false);
    }
    const onMore = () => {
        refreshTeams(true);
     };

    var searchValid = true;
    var valid = true;
    var pageSizeErr = "";
    if (formState.pageSize.length === 0){
        searchValid = false;
        pageSizeErr = "Required";
    } else {
        var pageSize = parseInt(formState.pageSize);
        if (isNaN(pageSize) || pageSize < 1){
            pageSizeErr = "Invalid int (1-n)"
        }
    }

    var teamIdErr = "";
    if (formState.teamId === ""){
        valid = false;
        teamIdErr = "Required";
    } 

    var searchEnabled = (searchValid && saveStatus === 0);
    var saveEnabled = (valid && saveStatus === 0);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
   
    var teamHelperText = formState.team ? `id:${formState.team.id}; providerId:${formState.team.providerId}` : "";
    if (teamHelperText.length > 0){
        teamHelperText += "; ";
    }
    teamHelperText += "Count:"+ teams.length + "; Page:" + page + "; PageSize:" + formState.pageSize;
 
    return (
        <div>
                <Box paddingTop={1} display="flex" alignItems="center">
                    {/* <FormLabel style={{ paddingRight: 2 }}>Page size:</FormLabel> */}
                    <TextField
                        name="pageSize" label="Page size" type="number" value={formState.pageSize} style={{ width: 100 }}
                        error={pageSizeErr && pageSizeErr.length ? true : false}
                        helperText={pageSizeErr}
                        onChange={e => handleChange("pageSize", e.target.value)}
                    />
                    <span style={{ padding: 2 }}></span>
                    <TextField
                        name="searchName"
                        label="Search name"
                        value={formState.searchName}
                        onChange={e => handleChange("searchName", e.target.value)}
                    />
                    <span style={{ padding: 2 }}></span>
                    <Button variant="contained" color="secondary" type="button" size="small" onClick={onSearchClick} disabled={!searchEnabled}>
                        Search
                    </Button>
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">

                    <TextField
                        name="teamId"
                        label="Team"
                        select
                        value={formState.teamId}
                        onChange={e => handleChange("teamId", e.target.value)}
                        style={{ width: 500 }}
                        error={(teamIdErr && teamIdErr.length > 0) ? true : false}
                        helperText={(teamIdErr && teamIdErr.length > 0) ? teamIdErr : teamHelperText}
                    >
                        <MenuItem key="" value="" />
                        {teams.map(team => (
                            <MenuItem key={team.id} value={team.id}>
                                {`${team.name} (${team.id}; ${team.providerId})`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <span style={{ padding: 2 }}></span>
                    <Button variant="contained" color="secondary" size="small" disabled={!more} onClick={e => onMore()}>
                        More
                    </Button>
                </Box>
                {saveErrorBox} 
        </div>
    );
};

export default TeamChooser;
