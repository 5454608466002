import React, { useEffect } from 'react';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import { TickerItem } from './RoundOverviewModels';
import { dateStringTicker } from '../common/helpers/DateHelpers';
import { formatToCurrency } from '../common/helpers/CurrencyHelpers';
import DataTable, { DataTableColumn } from '../Components/DataTable';


interface TickerTableProps {
    items: TickerItem[];
    roundColumnsVisible?: boolean;
    onSelect? : (item : TickerItem) => void;
}

const TickerTable: React.FC<TickerTableProps> = (props) => {
  const [selectedItemId, setSelectedItemId] = React.useState<number>(0);
  const [columns, setColumns] = React.useState<DataTableColumn<TickerItem>[]>([]);

  useEffect(() => {
    refreshColumns();
  }, []);
  
  const refreshColumns = () => {
    var roundColsVisible : boolean = (props.roundColumnsVisible === undefined || props.roundColumnsVisible);
    var cols: DataTableColumn<TickerItem>[] = [
        { id: 'couponShareId', numeric: false, disablePadding: true, label: "Id", filterEnabled: true, renderValue: (row: TickerItem) => { return (<Link component={RouterLink} to={`couponView/${row.roundId}/${row.couponId}/${row.couponShareId}`}>{row.couponShareId}</Link>); } },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator', filterEnabled: true },
        { id: 'agentName', numeric: false, disablePadding: false, label: 'Agent', filterEnabled: true, renderValue: (item: TickerItem) => { return item.agentName; } },
        { id: 'playerId', numeric: false, disablePadding: false, label: 'Player', filterEnabled: true },
        { id: 'couponId', numeric: false, disablePadding: false, label: 'Coupon', filterEnabled: true, renderValue: (row: TickerItem) => { return (<Link component={RouterLink} to={`coupons/${row.couponId}`}>{row.couponId}</Link>); } },
        { id: 'placedAt', numeric: false, disablePadding: false, label: 'Placed', renderValue: (item: TickerItem) => { return dateStringTicker(item.placedAt); }},
        { id: 'stake', numeric: true, disablePadding: false, label: 'Stake', renderValue: (item: TickerItem) => { return formatToCurrency(item.stake, item.currency); } },
        { id: 'result', numeric: true, disablePadding: false, label: 'Result', renderValue: (item: TickerItem) => { return formatToCurrency(item.result, item.currency); } },
        { id: 'fee', numeric: true, disablePadding: false, label: 'Fee', renderValue: (item: TickerItem) => { return formatToCurrency(item.fee, item.currency); } },
        { id: 'agentFee', numeric: true, disablePadding: false, label: 'Agent Fee', renderValue: (item: TickerItem) => { return formatToCurrency(item.agentFee, item.currency); } },
        { id: 'unitStake', numeric: true, disablePadding: false, label: 'Unit Stake', renderValue: (item: TickerItem) => { return formatToCurrency(item.unitStake, item.unitCurrency); } },
        { id: 'unitResult', numeric: true, disablePadding: false, label: 'Unit Result', renderValue: (item: TickerItem) => { return formatToCurrency(item.unitResult, item.unitCurrency); }},
        { id: 'unitFee', numeric: true, disablePadding: false, label: 'Unit Fee', renderValue: (item: TickerItem) => { return formatToCurrency(item.unitFee, item.unitCurrency); } },
        { id: 'unitAgentFee', numeric: true, disablePadding: false, label: 'Unit Agent Fee', renderValue: (item: TickerItem) => { return formatToCurrency(item.unitAgentFee, item.unitCurrency); } },
        { id: 'currency', numeric: false, disablePadding: false, label: 'Currency', filterEnabled: true, visible: false },
        { id: 'unitCurrency', numeric: false, disablePadding: false, label: 'UnitCurrency', filterEnabled: true, visible: false },
    ];
    setColumns(cols);
  }
    
    const onSelect = (item : TickerItem) => {
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };

    return (
      <DataTable columns={columns} items={props.items} rowsPerPage={25} getId={it => it.couponShareId} orderBy='couponShareId' order='desc'resultFilterEnabled={true} size='xsmall' onSelect={onSelect}  />
    );
  };
  
  export default TickerTable;
 