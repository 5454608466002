import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PopupDialog({open, onClose, onOk = () => undefined, title, children, cancelLabel = "Cancel", okLabel = "Ok"}) {
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={e => { e.stopPropagation(); onClose(); }} color="primary">
            {cancelLabel}
          </Button>
          <Button onClick={e => { e.stopPropagation(); onClose(); onOk(); }} color="primary">
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}