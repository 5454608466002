import React, { useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Link } from '@material-ui/core';
import { Draw, Fixture, RoundOverviewFixture } from "./RoundOverviewModels";
import { min } from "moment";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "./RoundOverviewUtil";
import DrawRulesDialog from "./DrawRulesDialog";

interface FixtureDialogProps {
    open: boolean;
    fixture: RoundOverviewFixture;
    onClose: (fixture: Fixture | undefined) => void;
    nameLinkEnabled?: boolean;
}
interface FixtureDialogState {
    fixtureId: string;
    status: string;
    homeScore: string;
    awayScore: string;
    updateMode: string;
}


const FixtureDialog: React.FC<FixtureDialogProps> = (props) => {
    var propsFixture = props.fixture;
    const getDefaultFormState = () : FixtureDialogState => {
        return {
            fixtureId: propsFixture.id,
            status: propsFixture.fixture ? propsFixture.fixture.status : propsFixture.status,
            homeScore: propsFixture.fixture ? propsFixture.fixture.homeTeam.score.toString() : propsFixture.homeScore.toString(),
            awayScore: propsFixture.fixture ? propsFixture.fixture.awayTeam.score.toString() : propsFixture.awayScore.toString(),
            updateMode: propsFixture.fixture ? propsFixture.fixture.updateMode : ""
        };
    }
    
    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<FixtureDialogState>(getDefaultFormState());
    const [fixture, setFixture] = useState<Fixture | null | undefined>(props.fixture.fixture);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
 
    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
        var fixtureIds : number[] = [parseInt(props.fixture.id)];
        RoundOverviewUtil.getFixturesById(fixtureIds).then((resp) => {
            if (resp && resp.length === 1) {
                var f = resp[0];
                setFixture(f);
                var fs = {... formState, ["status"]: f.status};
                fs.homeScore = f.homeTeam.score.toString();
                fs.awayScore = f.awayTeam.score.toString();
                fs.updateMode = f.updateMode
                setFormState(fs);
            }
      });
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        setFormState(fs);
    }

    const onSave = () => {
        const methodName = "SaveDraw";
        var status = undefined;
        var homeTeam = undefined;
        var awayTeam = undefined;
        var updateMode = undefined;
        var changeCount = 0;
        setSaveError("");
        setSaveStatus(0);
        if (!fixture) {
            setSaveError("Fixture is null");
            return;
        }
        if (formState.status !== fixture.status) {
            status = formState.status;
            changeCount++;
        }
        if (formState.updateMode !== fixture.updateMode) {
            updateMode = formState.updateMode;
            changeCount++;
        }
        var homeScore = parseInt(formState.homeScore);
        if (isNaN(homeScore) || homeScore < 0) {
            setSaveError("Invalid Home score, must be 0-n");
            return;
        }
        if (homeScore !== fixture.homeTeam.score) {
            homeTeam = {...fixture.homeTeam};
            homeTeam.score = homeScore;
            changeCount++;
        }
        var awayScore = parseInt(formState.awayScore);
        if (isNaN(awayScore) || awayScore < 0) {
            setSaveError("Invalid Away score, must be 0-n");
            return;
        }
        if (awayScore !== fixture.awayTeam.score) {
            awayTeam = {...fixture.awayTeam};
            awayTeam.score = awayScore;
            changeCount++;
        }
        if (changeCount <= 0) {
            setSaveError("No changes");
            return;

        }
        setSaveStatus(1);
        var updateJson = JSON.stringify({status: status, homeTeam: homeTeam, awayTeam: awayTeam, updateMode: updateMode});
        CreateRoundUtil.updateFixture(fixture.id, updateJson).then(resp => {
            setSaveStatus(0);
          if (resp.success){
            if (resp.item) {
              props.fixture.fixture = resp.item;
              setFixture(resp.item);
              setOpen(false);
              if (props.onClose) {
                  props.onClose(resp.item);
              }
              return;
            } 
            setSaveError("SaveFixture failure: fixture was not returned");

          } else {
            setSaveError(resp.message);
          }
        }).catch(error => {
            setSaveError(`SaveFixture error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var valid = true;
    var updateModeErr = "";
    if (formState.updateMode.length === 0){
        valid = false;
        updateModeErr = "Required";
    }

    var statusErr = "";
    if (formState.status.length === 0){
        valid = false;
        statusErr = "Required";
    }
    
    var homeScoreErr = "";
    var homeScore = parseInt(formState.homeScore);
    if (formState.homeScore.length > 0) {
        if (isNaN(homeScore)) {
            valid = false;
            homeScoreErr = "Invalid int";
        } else if (homeScore < 0) {
            valid = false;
            homeScoreErr = "Not in range 0-n";
        }
    } else {
        valid = false;
        homeScoreErr = "Required";
    }

    var awayScoreErr = "";
    var awayScore = parseInt(formState.awayScore);
    if (formState.awayScore.length > 0) {
        if (isNaN(awayScore)) {
            valid = false;
            awayScoreErr = "Invalid int";
        } else if (awayScore < 0) {
            valid = false;
            awayScoreErr = "Not in range 0-n";
        }
    } else {
        valid = false;
        awayScoreErr = "Required";
    }
    var changed = false;
    if (fixture && valid) {
        if (formState.updateMode !== fixture.updateMode) {
            changed = true;
        } else if (formState.status !== fixture.status) {
            changed = true;
        } else if (homeScore !== fixture.homeTeam.score) {
            changed = true;
        } else if (awayScore !== fixture.awayTeam.score) {
            changed = true;
        }
    }
    var saveEnabled = (valid && saveStatus === 0 && changed);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    var openButton = null;
    if (props.nameLinkEnabled) {
        openButton = (<Link underline="hover" onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
                {props.fixture.name}
            </Link>);
    } else {
        openButton = (<Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    Fixture
            </Button>);

    }
    return (
        <div>
            {openButton}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Fixture</DialogTitle>
            <DialogContent dividers>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Fixture"
                        name="fixtureName"
                        value={props.fixture.name}
                        helperText={props.fixture.id + " | " + CreateRoundUtil.toGameDateString(props.fixture.startingAt) + " | " + props.fixture.leagueName}
                        InputProps={{
                        readOnly: true,
                        }}
                        style={{width:400}}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        label="Draw"
                        name="drawId"
                        value={props.fixture.drawId}
                        helperText={`${props.fixture.draw?.adminPickedOutcome} (${props.fixture.draw?.status})`}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                <Box marginTop={1} width="250px">
                    <TextField
                        label="Update mode"
                        select
                        name="updateMode"
                        value={formState.updateMode}
                        onChange={e => handleChange("updateMode", e.target.value)}
                        fullWidth
                        helperText={updateModeErr}
                        error={updateModeErr.length > 0}
                        autoFocus
                    >
                        {RoundOverviewUtil.updateModes.map(s => (
                        <MenuItem key={s} value={s}>
                            {s}
                        </MenuItem>
                        ))}
                    </TextField>
                </Box> 
                <Box marginTop={1} width="250px">
                    <TextField
                        label="Status"
                        select
                        name="status"
                        value={formState.status}
                        onChange={e => handleChange("status", e.target.value)}
                        fullWidth
                        helperText={statusErr}
                        error={statusErr.length > 0}
                    >
                        {RoundOverviewUtil.fixtureStatuses.map(s => (
                        <MenuItem key={s} value={s}>
                            {s}
                        </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box marginTop={1} display="flex" alignItems="center">
                <TextField
                    autoFocus
                    margin="dense"
                    name="homeScore"
                    value={formState.homeScore}
                    label="Home"
                    type="number"
                    onChange={e => handleChange("homeScore", e.target.value)}
                    helperText={homeScoreErr}
                    error={homeScoreErr.length > 0}
                    />&nbsp;&nbsp;
                <TextField
                    autoFocus
                    margin="dense"
                    name="awayScore"
                    value={formState.awayScore}
                    label="Away"
                    type="number"
                    onChange={e => handleChange("awayScore", e.target.value)}
                    helperText={awayScoreErr}
                    error={awayScoreErr.length > 0}
                    />

                </Box>
                {saveErrorBox} 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="primary" disabled={!saveEnabled}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default FixtureDialog;
