import React from 'react';
import {
    Datagrid, TextField, NumberField, DateField, List,
    ReferenceField, Edit, SimpleForm, TextInput, ArrayInput, SimpleFormIterator
} from 'react-admin';
import {RoundReferenceField} from "../rounds";
import {UserReferenceField} from "../users";
import ReactDiffViewer from 'react-diff-viewer';

export const AuditLogGrid = props => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="entityType" />
    <RoundReferenceField source="roundId" />
    <UserReferenceField source="userId" allowEmpty/>
  </Datagrid>
);

const JsonCompareField = ({record}) => {
  if (!record) {
    return null;
  }

  return <ReactDiffViewer oldValue={JSON.stringify(record.beforeEntity, null, 1)} newValue={JSON.stringify(record.afterEntity, null, 1)}/>
}

export const AuditLogEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <TextField source="id" />
          <DateField source="createdAt" showTime/>
          <TextField source="entityType" />
          <RoundReferenceField source="roundId" addLabel={true}/>
          <UserReferenceField source="userId" allowEmpty addLabel={true}/>
          <JsonCompareField addLabel={true} label={'Difference'}/>
      </SimpleForm>
  </Edit>
);

export const AuditLogList = props => (
    <List sort={{ field: 'id', order: 'desc'}} {...props}>
      <AuditLogGrid/>
    </List>
);

export const AuditLogReferenceField = props =>
    <ReferenceField reference="AuditLogs" {...props} >
        <TextField source="id" />
    </ReferenceField>;
