import ApiNgUtil from "../api/ApiNgUtil";
import { ListResponse } from "../api/ApiNgModels";
import { FreebetOverviewFilter, FreebetOverviewItem, FreebetType, FreebetCampaign, Freebet, Player } from "./FreebetModels";
import queryString from "query-string";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import AgentUtil from "../AgentOverview/AgentUtil";
import AdminUtil from "../Admin/AdminUtil";
import { Operator } from "../Admin/AdminModels";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";

class FreebetUtil {
    static async getFreebetOverviews(pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: FreebetOverviewFilter) : Promise<GenericResponse<ListResponse<FreebetOverviewItem>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = "freebets/overviews?" + urlParams;
        var response = await ApiNgUtil.getListByUrl<FreebetOverviewItem>(url, "freebetOverviews");
        if (response.item?.items) {
            for (var item of response.item?.items) {
                FreebetUtil.transformFreebetOverviewItem(item);
            }            
        }
        return response;
    }
    static async getFreebetOverview(freebetCampaignId: number) : Promise<GenericResponse<FreebetOverviewItem>> {
        var url = `freebets/overviews/${freebetCampaignId}`;
        var response = await ApiNgUtil.getObject<FreebetOverviewItem>(url);
        if (response.item) {
            FreebetUtil.transformFreebetOverviewItem(response.item);
        }
        return response;
    }
    static async getFreebetTypes(pageSize: number, lastId: number) : Promise<GenericResponse<ListResponse<FreebetType>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, undefined, undefined, lastId);
        var url = "freebets/types?" + urlParams;
        var response = await ApiNgUtil.getListByUrl<FreebetType>(url, "freebetTypes");
        if (response.item?.items) {
            for (var item of response.item?.items) {
                FreebetUtil.transformFreebetType(item);
            }            
        }
        return response;
    }
    static async getFreebetType(id: number) : Promise<GenericResponse<FreebetType>> {
        var url = `freebets/types/${id}`;
        var response = await ApiNgUtil.getObject<FreebetType>(url);
        if (response.item) {
            FreebetUtil.transformFreebetType(response.item);
        }
        return response;
    }
    static async saveFreebetType(id: number, saveJson: string) : Promise<GenericResponse<FreebetType>> {
        var url = "freebets/types";
        var method = InvokeApiMethods.POST();
        if (id > 0) {
            url += `/${id}`;
            method = InvokeApiMethods.PATCH();
        }
        var apiResponse = await ApiNgUtil.invokeApi2(url, method, saveJson);
        if (!apiResponse.success) {
            return CreateRoundUtil.createGenericResponse<FreebetType>(false, apiResponse.message, null);
        }
        var item : FreebetType | null = null; 
        if (apiResponse.json) {
            item = apiResponse.json;
            if (item) {
                FreebetUtil.transformFreebetType(item);
            }
        }
        return CreateRoundUtil.createGenericResponse<FreebetType>(true, apiResponse.message, item);
    }
    // static async getFreebetCampaign(id: number) : Promise<GenericResponse<FreebetCampaign>> {
    //     var url = `freebets/campaigns/${id}`;
    //     var response = await ApiNgUtil.getObject<FreebetCampaign>(url);
    //     return response;
    // }
    static async saveFreebetCampaign(id: number, saveJson: string) : Promise<GenericResponse<FreebetOverviewItem>> {
        var url = "freebets/campaigns";
        var method = InvokeApiMethods.POST();
        if (id > 0) {
            url += `/${id}`;
            method = InvokeApiMethods.PATCH();
        }
        var apiResponse = await ApiNgUtil.invokeApi2(url, method, saveJson);
        if (!apiResponse.success) {
            return CreateRoundUtil.createGenericResponse<FreebetOverviewItem>(false, apiResponse.message, null);
        }
        var item : FreebetOverviewItem | null = null; 
        if (apiResponse.json) {
            item = apiResponse.json;
            if (item) {
                FreebetUtil.transformFreebetOverviewItem(item);
            }
        }
        return CreateRoundUtil.createGenericResponse<FreebetOverviewItem>(true, apiResponse.message, item);
    }
    static async getFreebets(freebetCampaignId: number | undefined, pageSize: number, lastId?: number) : Promise<GenericResponse<ListResponse<Freebet>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, undefined, undefined, lastId);
        var filterCount = 0;
        var filter : object | undefined = undefined;
        if (freebetCampaignId && freebetCampaignId > 0) {
            filter = { freebetCampaignId: freebetCampaignId };
        }
        const query = Object.assign({
            pageSize: pageSize,
            page: 0,
            lastId: lastId,
            filter: JSON.stringify(filter)
        });
        var url = `freebets?${queryString.stringify(query)}`;

        var response = await ApiNgUtil.getListByUrl<Freebet>(url, "freebets");
        if (response.item?.items) {
            for (var item of response.item?.items) {
                FreebetUtil.transformFreebet(item);
            }            
        }
        return response;
    }
    static async getFreebet(id: number) : Promise<GenericResponse<Freebet>> {
        var url = `freebets/${id}`;
        var response = await ApiNgUtil.getObject<Freebet>(url);
        if (response.item) {
            FreebetUtil.transformFreebet(response.item);
        }
        return response;
    }
    static async saveFreebet(id: number, saveJson: string) : Promise<GenericResponse<Freebet>> {
        var url = "freebets";
        var method = InvokeApiMethods.POST();
        if (id > 0) {
            url += `/${id}`;
            method = InvokeApiMethods.PATCH();
        }
        var apiResponse = await ApiNgUtil.invokeApi2(url, method, saveJson);
        if (!apiResponse.success) {
            return CreateRoundUtil.createGenericResponse<Freebet>(false, apiResponse.message, null);
        }
        var item : Freebet | null = null; 
        if (apiResponse.json) {
            item = apiResponse.json;
            if (item) {
                FreebetUtil.transformFreebet(item);
            }
        }
        return CreateRoundUtil.createGenericResponse<Freebet>(true, apiResponse.message, item);
    }
    static async getPlayer(id: number) : Promise<GenericResponse<Player>> {
        var url = `players/${id}`;
        var response = await ApiNgUtil.getObject<Player>(url);
        if (response.item) {
            FreebetUtil.transformPlayer(response.item);
        }
        return response;
    }
    private static transformFreebetType(item: FreebetType) : void {
        if (item.createdAt) {
            item.createdAt = new Date(item.createdAt);
        }
    }
    private static transformFreebetOverviewItem(item: FreebetOverviewItem) : void {
        if (item.createdAt) {
            var d : Date = item.createdAt;
            item.createdAt = new Date(item.createdAt);
        }
        if (item.validFrom) {
            item.validFrom = new Date(item.validFrom);
        }
        if (item.validTo) {
            item.validTo = new Date(item.validTo);
        }
    }
    private static transformFreebet(item: Freebet) : void {
        if (item.createdAt) {
            item.createdAt = new Date(item.createdAt);
        }
        if (item.validFrom) {
            item.validFrom = new Date(item.validFrom);
        }
        if (item.validTo) {
            item.validTo = new Date(item.validTo);
        }
    }
    private static transformPlayer(item: Player) : void {
        if (item.createdAt) {
            item.createdAt = new Date(item.createdAt);
        }
    }
}
export default FreebetUtil;