import React, { useEffect, useState } from 'react';
import { IconButton, Link, } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink } from "react-router-dom";
import DataTable, { DataTableColumn } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { SyndicateInstance } from './CreateSyndicateModels';
import { formatNumber, formatToCurrency } from '../common/helpers/CurrencyHelpers';
import { formatAmount } from '../../components/Amount';

interface SyndicateInstanceTableProps {
    items: SyndicateInstance[];
    onSelect? : (item : SyndicateInstance) => void;
    onDelete? : (item : SyndicateInstance) => void;
}

const SyndicateInstanceTable: React.FC<SyndicateInstanceTableProps> = (props) => {
    const [selectedId, setSelectedId] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<SyndicateInstance>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    const renderActionValue = (item: SyndicateInstance) => {
      return (<IconButton aria-label="delete" color="secondary" size="small" onClick={(e) => onDelete(item)} >
          <DeleteIcon />
        </IconButton>);      
    }
    const refreshColumns = () => {
      var cols: DataTableColumn<SyndicateInstance>[] = [
        { id: undefined, numeric: false, disablePadding: false, label: 'Action', renderValue: renderActionValue },
        { id: 'agentName', numeric: false, disablePadding: false, label: 'Agent', filterEnabled: true },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator', filterEnabled: true },
        { id: 'playerId', numeric: false, disablePadding: false, label: 'Player', filterEnabled: true },
        { id: 'syndicateName', numeric: false, disablePadding: false, label: 'Syndicate name', filterEnabled: true },
        { id: 'agentFeePercentage', numeric: true, disablePadding: false, label: 'Agent fee ratio', filterEnabled: true, renderValue : (item: SyndicateInstance) => formatNumber(item.agentFeePercentage, 4)},
        { id: 'targetShares', numeric: true, disablePadding: false, label: 'Target shares', filterEnabled: true },
        { id: 'rowsPerShare', numeric: true, disablePadding: false, label: 'Rows', filterEnabled: true },
        // { id: 'stakePerShare', numeric: true, disablePadding: false, label: 'Stake per share', filterEnabled: true, renderValue: (item: SyndicateInstance) => formatToCurrency(item.stakePerShare, undefined) },
        { id: 'currency', numeric: false, disablePadding: false, label: 'Currency', filterEnabled: true },
      ];
      setColumns(cols);
    }
    const getKey = (it: SyndicateInstance) => {
      return `${it.agentId};${it.playerId}`;
    }
    const onDelete = (item : SyndicateInstance) => {
      if (props.onDelete){
        props.onDelete(item);
        return;
      }
    };
    const onSelect = (item : SyndicateInstance) => {
        setSelectedId(getKey(item));
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    return (
          <DataTable columns={columns} items={props.items} getId={getKey} orderBy='agentName' order='asc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect}  />
    );
  };
  
  export default SyndicateInstanceTable;
 