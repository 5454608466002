import { CouponDetail } from "../../models/CouponDetail";
import { Fixture, Round, SlimFixture } from "../../models/Round";

export const friendlyName = (name: string | undefined) => {

    if (name === undefined) {
        return "Unknown";
    }

    if (name.indexOf("Max") > -1) {
        return "Max";
    }
    if (name.indexOf("Mix") > -1) {
        return "Mix";
    }
    if (name.indexOf("Flex") > -1) {
        return "Flex";
    }

    return name;

};

function getCorrectSign(index: number, round: Round) {
    if (!round.preliminaryCorrectRow) {
        return 'X';
    }
    return round.preliminaryCorrectRow.signs[index];
}

interface CorrectedRow {
    numCorrect: number;
    numPotential: number;
}

export const isFixtureAfterGameStart = (fixture: Fixture) => {
    return fixture.startingAt < new Date() || fixture.status === "PLAYING" || fixture.status === "FINISHED";
}

export const numCorrect = (coupon: CouponDetail, round: Round, fixtures: Fixture[]) => {

    let correctedRows: CorrectedRow[] = []

    if(coupon?.roundCoupon === undefined){
        return {
            numCorrect: 0,
            potential: 0,
        };
    }

    coupon.roundCoupon.bets[0].rows.forEach(row => {

        let numCorrect = 0;        
        let numWrongCompleted = 0;

        for (let index = 0; index < row.signs.length; index++) {
            const sign = row.signs[index];
            const correctSign = getCorrectSign(index, round);
            if (round.fixtures[index] === undefined) {
                return {
                    numCorrect: 0,
                    potential: 0,
                };
            }
            let fixture = fixtures.find(f => f.id === round.fixtures[index].fixtureId);
            if (fixture === undefined) {
                return {
                    numCorrect: 0,
                    potential: 0,
                };
            }
            
            if (sign === correctSign) {
                numCorrect++;                
            }
            else {
                if (fixture.status === "FINISHED") {
                    numWrongCompleted++;
                }
            }
        }

        correctedRows.push({
            numCorrect: numCorrect,
            numPotential: round.fixtures.length - numWrongCompleted
        });

    });

    if(correctedRows.length===0){
        return {
            numCorrect: 0,
            potential: 0,
        };
    }

    let bestCorrect = correctedRows.sort((a, b) => b.numCorrect - a.numCorrect)[0].numCorrect;
    let bestPotential = correctedRows.sort((a, b) => b.numPotential - a.numPotential)[0].numPotential;

    return {
        numCorrect: bestCorrect,
        potential: bestPotential,
    };
};