import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, TableRow, TableCell, IconButton, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { OddsInfo, RoundFixtureInput, RoundFixtureItem } from "./CreateRoundModels";
import DeleteIcon from '@material-ui/icons/Delete';
import DownIcon from '@material-ui/icons/ArrowDownward';
import UpIcon from '@material-ui/icons/ArrowUpward';
import { green } from '@material-ui/core/colors';
import CreateRoundUtil from './CreateRoundUtil';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';

const useStyles = makeStyles({
  readonly: {
    background: 'lightgray'
  }
});

interface RoundFixtureRowState {
  fixtureId: string;
  oneOdds: string;
  xOdds: string;
  twoOdds: string;
  valid: boolean;
  oneOddsError: string ;
  xOddsError: string;
  twoOddsError: string;
  info: OddsInfo | null;
  refreshed: boolean;
  keepDraw: boolean;
  version: number;
}

interface RoundFixtureRowProps {
  fixture: RoundFixtureItem;
  onChange?: (item: RoundFixtureInput) => void;
  onDelete?: (item: RoundFixtureItem) => void;
  onMoveUp?: (item: RoundFixtureItem) => void;
  onMoveDown?: (item: RoundFixtureItem) => void;
}
const RoundFixtureRow: React.FC<RoundFixtureRowProps> = (props) => {
  const classes = useStyles(); 
  const propsInput = props.fixture.input;
  const [formData, setFormData] = useState<RoundFixtureRowState>({
    fixtureId: props.fixture.id,
    oneOdds: propsInput ? propsInput.oneOdds : "",
    xOdds: propsInput ? propsInput.xOdds : "",
    twoOdds: propsInput ? propsInput.twoOdds : "",
    valid: propsInput ? propsInput.valid : false,
    oneOddsError: "",
    xOddsError: "",
    twoOddsError: "",
    info: null,
    refreshed: false,
    keepDraw: propsInput ? propsInput.keepDraw : true,
    version: props.fixture.version
  });
  const refreshFormData = (fd: RoundFixtureRowState) => {
    var oneOdds = parseFloat(fd.oneOdds);
    var xOdds = parseFloat(fd.xOdds);
    var twoOdds = parseFloat(fd.twoOdds);
  
    var valid = true;
    var oneOddsError = "";
    var xOddsError = "";
    var twoOddsError = "";
  
    if (fd.oneOdds.length === 0){
      valid = false;
      oneOddsError = "Required";
    } else if (CreateRoundUtil.isInvalidOdds(oneOdds, fd.oneOdds)) {
      valid = false;
      oneOddsError = "Invalid odds";
    }
    if (fd.xOdds.length === 0){
      valid = false;
      xOddsError = "Required";
    } else if (CreateRoundUtil.isInvalidOdds(xOdds, fd.xOdds)) {
      valid = false;
      xOddsError = "Invalid odds";
    }
    if (fd.twoOdds.length === 0){
      valid = false;
      twoOddsError = "Required";
    } else if (CreateRoundUtil.isInvalidOdds(twoOdds, fd.twoOdds)) {
      valid = false;
      twoOddsError = "Invalid odds";
    }
  
    var oddsInfo: OddsInfo | null = null;
    if (valid) {
      oddsInfo = CreateRoundUtil.getOddsInfo(fd.oneOdds, fd.xOdds, fd.twoOdds);
      if (oddsInfo === null) {
        valid = false;
      } 
    }
     fd.info = oddsInfo;
     fd.refreshed = true;
     fd.valid = valid;
    fd.oneOddsError = oneOddsError;
    fd.xOddsError = xOddsError;
    fd.twoOddsError = twoOddsError;
  
  };

  if (formData.fixtureId !== props.fixture.id || formData.version !== props.fixture.version){
    console.log("RoundFixtureRow.main issue fixed: formData.fixtureId !== props.fixture.id (%s !== /%s", formData.fixtureId, props.fixture.id);
    formData.fixtureId = props.fixture.id;
    formData.version = props.fixture.version;
    formData.oneOdds = propsInput ? propsInput.oneOdds : "";
    formData.xOdds = propsInput ? propsInput.xOdds : "";
    formData.twoOdds = propsInput ? propsInput.twoOdds : "";
    formData.keepDraw = propsInput ? propsInput.keepDraw : false;
    formData.refreshed = false;
  }
  if (!formData.refreshed){
    refreshFormData(formData)
  }
  
  const handleChange2 = (name: string, value: any) => {
    var fd = { ...formData, [name]: value };

    refreshFormData(fd);
    setFormData(fd);
    if (props.onChange) {
      var changeArgs : RoundFixtureInput = {
        id: props.fixture.id,
        oneOdds: fd.oneOdds,
        xOdds: fd.xOdds,
        twoOdds: fd.twoOdds,
        oneP: fd.info ? fd.info.oneP : null,
        xP: fd.info ? fd.info.xP : null,
        twoP: fd.info ? fd.info.twoP : null,
        oneN: fd.info ? fd.info.oneN : null,
        xN: fd.info ? fd.info.xN : null,
        twoN: fd.info ? fd.info.twoN : null,
        valid: fd.valid,
        keepDraw: fd.keepDraw
      };
      props.onChange(changeArgs);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    handleChange2(name, value);
  };
  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.fixture);
      return;
    }
    alert("RoundFixtureRow.onDelete property not set.");
  };
  const onMoveUp = () => {
    if (props.onMoveUp) {
      props.onMoveUp(props.fixture);
      return;
    }
    alert("RoundFixtureRow.onMoveUp property not set.");
  };
  const onMoveDown = () => {
    if (props.onMoveDown) {
      props.onMoveDown(props.fixture);
      return;
    }
    alert("RoundFixtureRow.onMoveDown property not set.");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formData);
  };

  var oneOddsError = formData.oneOddsError;
  var xOddsError = formData.xOddsError;
  var twoOddsError = formData.twoOddsError;


  var oddsPercentX = "";
  var onePercent = "";
  var xPercent = "";
  var twoPercent = "";
  if (formData.valid) {
    var oddsInfo: OddsInfo | null = formData.info;
    if (oddsInfo !== null) {
      oddsPercentX = oddsInfo.oddsPercent.toFixed(2) + "%";
      onePercent = (oddsInfo.oneP * 100).toFixed(2) + "% (" + oddsInfo.oneN + ")";
      xPercent = (oddsInfo.xP * 100).toFixed(2) + "% (" + oddsInfo.xN + ")";
      twoPercent = (oddsInfo.twoP * 100).toFixed(2) + "% (" + oddsInfo.twoN + ")";
    }
  }

  var row = props.fixture;
  var draw = row.draw;
  //var keepDraw = draw && formData.keepDraw;
  var keepDraw = draw ? true : false;
  var keepDrawClass = keepDraw ? classes.readonly : undefined;
  var drawInfo = null;
  //var keepDrawCheckBox = null;
  if (draw) {
    var drawText = `Draw | ${draw.adminPickedOutcome} (${draw.status})`;
    draw.outcomes.forEach(o => {
      drawText += ` | ${o.sign} (${o.n})`;
    });
    drawInfo = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{drawText}</Box>);
    // keepDrawCheckBox = (<FormControlLabel
    //   control={
    //     <Checkbox checked={formData.keepDraw} onChange={e => handleChange2(e.target.name, e.target.checked)} name="keepDraw" />
    //   }
    //   label="Keep Draw"
    // />);
  }
  return (
    <TableRow key={row.id} >
      <TableCell component="th" scope="row">
        {row.ordNo}
      </TableCell>
      <TableCell>
        <IconButton aria-label="delete" color="secondary" size="small" onClick={(e: any) => onDelete()} >
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="up" color="primary" size="small" onClick={(e: any) => onMoveUp()} >
          <UpIcon />
        </IconButton>
        <IconButton aria-label="up" style={{ color: green[500] }} size="small" onClick={(e: any) => onMoveDown()} >
          <DownIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        {row.listItem.title}
        <Box fontSize={10}>{row.listItem.leagueName}</Box>
      </TableCell>
      <TableCell>
        <Box>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            name="oneOdds"
            size="small"
            type='number'
            inputProps={{ step: 0.01, readOnly: keepDraw, className: keepDrawClass }}
            defaultValue={formData.oneOdds}
            error={oneOddsError && oneOddsError.length > 0 ? true : false}
            helperText={oneOddsError && oneOddsError.length > 0 ? oneOddsError : onePercent}
            onChange={handleChange}
            style={{ width: 100 }}
          />
          <TextField
            variant="outlined"
            name="xOdds"
            size="small"
            type='number'
            inputProps={{ step: 0.01, readOnly: keepDraw, className: keepDrawClass }}
            defaultValue={formData.xOdds}
            error={xOddsError && xOddsError.length ? true : false}
            helperText={xOddsError && xOddsError.length > 0 ? xOddsError : xPercent}
            onChange={handleChange}
            style={{ width: 100 }}
          />
          <TextField
            variant="outlined"
            name="twoOdds"
            size="small"
            type='number'
            inputProps={{ step: 0.01, readOnly: keepDraw, className: keepDrawClass }}
            defaultValue={formData.twoOdds}
            error={twoOddsError && twoOddsError.length ? true : false}
            helperText={twoOddsError && twoOddsError.length > 0 ? twoOddsError : twoPercent}
            onChange={handleChange}
            style={{ width: 100 }}
          />
          <span style={{color:"gray", marginRight:"4"}}>{oddsPercentX}</span>&nbsp;&nbsp;
          {/*keepDrawCheckBox*/}
          {/* <Box>
            FormData: {JSON.stringify(formData)}
          </Box>
          <Box>
            props: {JSON.stringify(props)}
          </Box> */}
          {/* <Button onClick={e => onApply()} variant="contained" color="primary">Apply</Button> */}
        </form>
        </Box>
        {drawInfo}
      </TableCell>
    </TableRow>
);
};

export default RoundFixtureRow;
