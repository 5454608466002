import React, { useEffect, useState } from 'react';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { FreebetType } from './FreebetModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import EditFreebetTypeDialog from './EditFreebetTypeDialog';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";

interface FreebetTypeTableProps {
    items: FreebetType[];
    onSelect? : (item : FreebetType) => void;
}

const FreebetTypeTable: React.FC<FreebetTypeTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<FreebetType>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<FreebetType>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Id", renderValue: (item: FreebetType) => (<Link component={RouterLink} to={"editFreebetType/" + item.id}>{item.id}</Link>) },
        { id: 'name', numeric: false, disablePadding: false, label: 'Freebet type' },
        { id: 'numFull', numeric: true, disablePadding: false, label: '#Full' },
        { id: 'numHalf', numeric: true, disablePadding: false, label: '#Half' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: FreebetType) => CreateRoundUtil.toGameDateString(item.createdAt) },
      ];
      setColumns(cols);
    }
    const onSelect = (item : FreebetType) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
  
  return (
    <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect}  />
    );
};
  
export default FreebetTypeTable;
