import React from 'react';
import {
    Datagrid, TextField, NumberField, DateField, List,
    ReferenceField, Edit, SimpleForm
} from 'react-admin';
import {RoundReferenceField} from "../rounds";
import AmountField from "../../fields/AmountField";
import {CouponReferenceField} from "../coupons";
import {CouponShareReferenceField} from "../couponShares";
import {PlayerReferenceField} from "../players";
import {SettlementReferenceField} from "../settlements";

export const AccountTransactionGrid = props => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="transactionType" />
    <RoundReferenceField source="roundId" />
    <TextField source="balance" />
    <NumberField source="balanceUnits"/>
    <NumberField source="balanceBeforeUnits" label={"Before"}/>
    <NumberField source="balanceAfterUnits" label={"After"}/>
    <AmountField source="amount"/>
    <AmountField source="operatorAmount"/>
    <AmountField source="agentFeeAmount"/>
    <AmountField source="operatorAgentFeeAmount"/>
    <SettlementReferenceField source={"settlementId"}/>
    <TextField source={"rollbackAccountTransactionId"}/>
    <CouponReferenceField source="couponId"/>
    <CouponShareReferenceField source="couponShareId" allowEmpty/>
    <PlayerReferenceField source={"playerId"}/>
  </Datagrid>
)

export const AccountTransactionEdit = props => {
  return (<Edit {...props} > 
    <SimpleForm>
      <TextField source="id" />
      <DateField source="createdAt" showTime/>
      <TextField source="transactionType" />
      <RoundReferenceField source="roundId" />
      <TextField source="balance" />
      <NumberField source="balanceUnits"/>
      <NumberField source="balanceBeforeUnits" label={"Before"}/>
      <NumberField source="balanceAfterUnits" label={"After"}/>
      <AmountField source="amount"/>
      <AmountField source="operatorAmount"/>
      <AmountField source="agentFeeAmount"/>
      <AmountField source="operatorAgentFeeAmount"/>
      <SettlementReferenceField source={"settlementId"}/>
      <TextField source={"rollbackAccountTransactionId"}/>
      <CouponReferenceField source="couponId"/>
      <CouponShareReferenceField source="couponShareId" allowEmpty/>
      <PlayerReferenceField source={"playerId"}/>
    </SimpleForm>
  </Edit>);
  }

export const AccountTransactionList = props => (
    <List sort={{ field: 'id', order: 'desc'}} {...props}>
      <AccountTransactionGrid/>
    </List>
);

export const AccountTransactionReferenceField = props =>
    <ReferenceField reference="accountTransactions" {...props} >
        <TextField source="id" />
    </ReferenceField>;
