import React from 'react';
import Amount from "../../components/Amount";
import {AutocompleteInput, ReferenceInput} from "react-admin";
import moment from "moment";

const RoundAmount = ({amount}) => {
  if (!amount) {
    return null;
  } else {
    return <span>{amount.units} (<Amount amount={amount.money}/>)</span>;
  }
};
export default RoundAmount;

const referenceNameOfRound = round =>
    `${round.id} ${round.name}`

export const RoundReferenceInput = props =>
    <ReferenceInput label={'Round'}  reference="rounds" {...props} >
      <AutocompleteInput optionText={referenceNameOfRound} emptyValue={""}/>
    </ReferenceInput>;

