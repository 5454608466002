
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, TextField, Button, MenuItem, Box } from "@material-ui/core";
import { Title } from 'react-admin';
import { Round, RoundOverviewFixture, FixtureTeam } from '../RoundOverview/RoundOverviewModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import { getObject } from '../api/dataProvider';
import { RoundType } from '../models/RoundType';
import { GenericResponse, LeagueListItem, LogEntry } from '../CreateRound/CreateRoundModels';
import RoundInfo from './RoundInfo';
import { SettleFixtureInput, SettleFixtureItem } from './SettleRoundModels';
import SettleRoundFixtureTable from './SettleRoundFixtureTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';

interface SettleRoundState {
  id: string | null;
  fixtures: SettleFixtureItem[];
}
// interface SettleRoundResult {
//   updateFixtureCount: number;
//   updateDrawCount: number;
//   fixtureErrorCount: number;
//   fixtureAlreadyOkCount: number;
//   finishRoundCount: number;
//   finishRoundErrorCount: boolean;
//   finishRoundMessage: boolean;
// }  
// class CreateRoundContext{
//   roundTypeId : number;
//   roundType : any;

// }
//   export interface RoundTypeX {
//     id: string;
//     name: string;
// }

const SettleRound: React.FC = () => {
  const location = useLocation();

  var roundId: string | null = null;
  var pos = location.pathname.lastIndexOf("/");
  if (pos > 0) {
    roundId = location.pathname.substring(pos + 1);
  }
  const initialized = useRef(false);
  const [round, setRound] = useState<Round | null>(null);
  const [roundType, setRoundType] = useState<any>(null)
  const [formState, setFormState] = useState<SettleRoundState>({
    id: roundId ? roundId : "",
    fixtures: []
  });
  const [leagues, setLeagues] = useState<LeagueListItem[]>([]);
  const [step, setStep] = useState<number>(1);
  const [initErr, setInitErr] = useState<string>("");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [validInput, setValidInput] = useState<boolean>(false);
  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
  const [saveError, setSaveError] = useState<string>("");
  const [saveStatus, setSaveStatus] = useState<number>(0);

  const checkFinishValidItem = (item: SettleFixtureItem): boolean => {
    item.finishValid = false;
    item.finishMessage = "Not checked yet";
    var input = item.input;
    if (!input || !input.valid) {
      item.finishMessage = "Not valid";
      return false;
    }
    if (!input.valid2) {
      item.finishMessage = "Not valid2";
      return false;
    }
    var rof = item.fixture;
    var fixtureResult = rof.result;
    if (!fixtureResult) {
      item.finishMessage = "Property 'result' is missing";
      return false;
    }
    if (fixtureResult.status !== RoundOverviewUtil.FINISHED_STATUS) {
      item.finishMessage = `Result status '${fixtureResult.status}' is not '${RoundOverviewUtil.FINISHED_STATUS}'`;
      return false;
    }
    if (fixtureResult.sign !== input.sign){
      item.finishMessage = `Result sign '${fixtureResult.sign}' does not match input sign '${input.sign}'`;
      return false;
    }
    if (rof.drawSign && rof.drawSign.length > 0){
      if (fixtureResult.sign !== rof.drawSign){
        item.finishMessage = `Result sign '${fixtureResult.sign}' does not match draw sign '${rof.drawSign}'`;
        return false;
      }
      var draw = rof.draw;
      if (!draw) {
        item.finishMessage = "Property 'draw' is missing";
        return false;
      }
      if (rof.drawSign !== draw.adminPickedOutcome) {
        item.finishMessage = `Fixture drawSign '${rof.drawSign}' does not match draw adminPickedOutcome '${draw.adminPickedOutcome}'`;
        return false;
      }
      if (draw.status !== RoundOverviewUtil.FINISHED_STATUS) {
        item.finishMessage = `Draw status '${draw.status}' is not '${RoundOverviewUtil.FINISHED_STATUS}'`;
        return false;
      }
         
    } else {
      if (fixtureResult.sign !== rof.sign){
        item.finishMessage = `Result sign '${fixtureResult.sign}' does not match fixture sign '${rof.sign}'`;
        return false;
      }
      if (rof.status !== RoundOverviewUtil.FINISHED_STATUS) {
        item.finishMessage = `Fixture status '${rof.status}' is not '${RoundOverviewUtil.FINISHED_STATUS}'`;
        return false;
      }
    }
    item.finishValid = true;
    item.finishMessage = "";
    return true;
};
const checkFinishValid = (): GenericResponse<number> => {
  var invalidCount = 0;
  var invalidIndex = -1;
  var invalidMessage = "";
  for (var iFixture = 0; iFixture < formState.fixtures.length; iFixture++) {
    var fixture = formState.fixtures[iFixture];
    if (!checkFinishValidItem(fixture)){
      invalidCount++;
    }
  }
  if (!round) {
    return CreateRoundUtil.createGenericResponse<number>(false, "Round not found", invalidCount);
  }
  if (!RoundOverviewUtil.isSettleEnabled(round.status, round.openTo)) {
    return CreateRoundUtil.createGenericResponse<number>(false, "Round not enabled for settlement.", invalidCount);
  }
  if (invalidCount > 0) {
    var invalidItem = formState.fixtures.find(f => !f.finishValid);
    var invalidFixture = invalidItem?.fixture;
    if (invalidFixture) {
      return CreateRoundUtil.createGenericResponse<number>(false, `There are invalid fixtures [Count:${invalidCount} of ${formState.fixtures.length}], first is '${invalidFixture.name}' [OrdNo:${invalidFixture.ordNo}] [Message:${invalidItem?.finishMessage}]`, invalidCount);
    }
    return CreateRoundUtil.createGenericResponse<number>(false, `There are invalid fixtures [Count:${invalidCount} of ${formState.fixtures.length}]`, invalidCount);
  }
  return CreateRoundUtil.createGenericResponse<number>(true, "", invalidCount);
};
const refreshRound = (init : boolean, roundOnly: boolean, roundX? : Round) => {
    if (!roundId || roundId.length <= 0) {
      setInitErr("RoundId not specified.");
      return;
    }
    if (init) {
      setInitErr("Initializing...");
    } else {
      setInitErr("Refreshing...");
    }
    setRefreshing(true);
    refreshRound2(init, roundOnly, roundX).then((resp) => {
      if (resp.success) {
        setInitErr("");
      } else {
        setInitErr(resp.message);
      }
      setValidInput(isValid(step));
      setRefreshing(false);
    }).catch(error => {
      console.error("RefreshRound error.", error);
      setInitErr(`RefreshRound error: ${error}`);
      setRefreshing(false);
    });
  }
  const refreshRound2 = async (init: boolean, roundOnly: boolean, roundY? : Round): Promise<GenericResponse<number>> => {
    const METHOD_NAME = "RefreshRound";
    var refreshCount = 0;
    var roundId = formState.id ? formState.id : "";
    if (!roundId || roundId.length <= 0) {
        return CreateRoundUtil.createGenericResponse<number>(false, "RoundId not specified.", refreshCount);
    }
    try {
      var roundX : Round | null | undefined = roundY;
      if (!roundX) {
        var roundResp = await RoundOverviewUtil.getRound(roundId);
        if (!roundResp.success) {
          return CreateRoundUtil.createGenericResponse(false, `GetRound error: ${roundResp.message}`, refreshCount);
        }
        roundX = roundResp.item;
        if (!roundX) {
          return CreateRoundUtil.createGenericResponse(false, "GetRound did not return round.", refreshCount);
        }
  
      }
      setRound(roundX);
      refreshCount++;
      // addSaveInfo("GetRound done [Id:" + roundId + "]");

      if (init || !roundType && !roundType.id) {
        var roundTypeResp = await getObject("roundTypes?id=" + roundX.roundTypeId);
        var rt = null;
        var rts = roundTypeResp.data?._embedded?.roundTypes;
        if (rts !== null && rts.length > 0) {
          rt = rts[0];
        }
        setRoundType(rt);
        refreshCount++;
      // addSaveInfo("GetRoundType done [Id:" + roundX.roundTypeId + "]");
      }


      if (!init && roundOnly && roundX.fixtureResults && roundX.fixtureResults.length > 0 && roundX.fixtureResults.length === formState.fixtures.length) {
        var items : SettleFixtureItem[] = [];
        for (var iItem = 0; iItem < formState.fixtures.length; iItem++) {
          var item = formState.fixtures[iItem];
          var fixtureResult = roundX.fixtureResults[iItem];
          if (!fixtureResult.drawId || fixtureResult.drawId.length == 0) {
            //Something wrong
            break;
          }
          if (fixtureResult.drawId !== item.fixture.drawId) {
            var item2 = formState.fixtures.find(it => it.fixture.drawId === fixtureResult.drawId);
            if (!item2) {
              //Something wrong
              break;
            }
            item = item2;
          }
          item.fixture.result = fixtureResult;
          checkFinishValidItem(item);
          items.push(item);
        }

        if (items.length === formState.fixtures.length) {
          var fState1 : SettleRoundState = {
            id: formState.id,
            fixtures: items
          };
          setFormState(fState1);
          return CreateRoundUtil.createGenericResponse<number>(true, "", refreshCount);
        }
      }

      var fState: SettleRoundState = {
        id: formState.id,
        fixtures: []
      }

      var roFixtures = await RoundOverviewUtil.getRoundOverviewFixturesByRound(roundX, leagues);
      // addSaveInfo("GetFixtures done [Count:" + roFixtures.length + "]");
      if (!roFixtures) {
        roFixtures = [];
      }
      for (var iFixture = 0; iFixture < roFixtures.length; iFixture++) {
        var rof = roFixtures[iFixture];
        var exFixture : SettleFixtureItem | undefined = undefined;
        if (!init && formState.fixtures.length > 0) {
          exFixture = iFixture < formState.fixtures.length ? formState.fixtures[iFixture] : undefined;
          if (!exFixture || exFixture.fixtureId !== rof.id) {
            exFixture = formState.fixtures.find(f => f.fixtureId === rof.id);
          }
        }
        if (exFixture) {
          var input1 = { ...exFixture.input };
          var item1: SettleFixtureItem = {
            fixtureId: rof.id,
            fixture: rof,
            input: input1,
            finishValid: false,
            finishMessage: "Not checked yet"
          };
          var exDrawSign = exFixture.fixture.drawSign;
          if (rof.drawSign && rof.drawSign.length > 0) {
            input1.sign = rof.drawSign;
            input1.valid = true;
            if (rof.draw?.status === RoundOverviewUtil.FINISHED_STATUS){
              input1.valid2 = true;
            } else if (rof.drawSign !== exDrawSign) {
              input1.valid2 = false;
              input1.confirmed = false
            }
            input1.homeScoreText = "";
            input1.awayScoreText = "";
            input1.homeScore = null;
            input1.awayScore = null;
          } else if (exDrawSign && exDrawSign.length > 0) {
            input1.sign = "";
            input1.valid = false;
            input1.valid2 = false;
            input1.confirmed = false;
            input1.homeScoreText = "";
            input1.awayScoreText = "";
            input1.homeScore = null;
            input1.awayScore = null;
          } else {
            if (rof.homeScore !== exFixture.fixture.homeScore || rof.awayScore !== exFixture.fixture.awayScore) {
              if (rof.homeScore !== input1.homeScore || rof.awayScore !== input1.awayScore) {
                input1.valid = false;
                input1.valid2 = false;
                input1.confirmed = false;
              }
            }
          }
          checkFinishValidItem(item1);
          fState.fixtures.push(item1);
          continue;
        }
        var sign = "";
        var confirmed = false;
        var valid = false;
        var valid2 = false;
        if (rof.drawSign && rof.drawSign.length > 0) {
          sign = rof.drawSign;
          if (sign.length > 0) {
            valid = true;
            if (rof.draw?.status === RoundOverviewUtil.FINISHED_STATUS) {
              valid2 = true;
            }
          }
        }
        var item: SettleFixtureItem = {
          fixtureId: rof.id,
          fixture: rof,
          input: {
            fixtureId: rof.id,
            homeScoreText: "",
            awayScoreText: "",
            homeScore: null,
            awayScore: null,
            sign: sign,
            confirmed: false,
            valid: valid,
            valid2: valid2
          },
          finishValid: false,
          finishMessage: "Not checked yet"
        };
        checkFinishValidItem(item);
        fState.fixtures.push(item);
      }
      setFormState(fState);
      refreshCount += fState.fixtures.length;
      return CreateRoundUtil.createGenericResponse<number>(true, "", refreshCount);
    } catch (error) {
      console.error(`${METHOD_NAME} error.`, error);
      return CreateRoundUtil.createGenericResponse<number>(false, `${METHOD_NAME} error: ${error}.`, refreshCount);
    }
  }

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true
    refreshRound(true, false);
  }, []);

  const onChange = (input: SettleFixtureInput) => {
    var fixture = formState.fixtures.find(it => it.fixtureId === input.fixtureId);
    if (!fixture) {
      alert(`Fixture changed failure: fixture not found [FixtureId:${input.fixtureId}].`);
      return;
    }
    fixture.input = input;
    checkFinishValidItem(fixture);
    setValidInput(isValid(step));
  };
  const isValid = (step: number): boolean => {
    if (!round) {
      return false;
    }
    if (!RoundOverviewUtil.isSettleEnabled(round.status, round.openTo)) {
      return false;
    }
    var invalid2Count = 0;
    for (var iFixture = 0; iFixture < formState.fixtures.length; iFixture++) {
      var fixture = formState.fixtures[iFixture];
      var input = fixture.input;
      if (!input || !input.valid) {
        return false;
      }
      if (!input.valid2) {
        invalid2Count++;
        continue;
      }
    }
    if (step === 1) {
      return true;
    }
    if (invalid2Count > 0) {
      return false;
    }
    return true;
  };
  const isFinishValid = (): GenericResponse<string[]> => {
    if (!round) {
      return CreateRoundUtil.createGenericResponse<string[]>(false, "State 'round' is not set.", null);
    }
    if (!RoundOverviewUtil.isSettleEnabled(round.status, round.openTo)) {
      return CreateRoundUtil.createGenericResponse<string[]>(false, `Round is not settleable [Status:${round.status}] [OpenTo:${CreateRoundUtil.toGameDateString(round.openTo)}].`, null);;
    }
    var errors : string[] = []
    for (var iFixture = 0; iFixture < formState.fixtures.length; iFixture++) {
      var fixture = formState.fixtures[iFixture];
      if (!fixture.finishValid) {
        errors.push(`(${fixture.fixture.ordNo}) ${fixture.fixture.name}: ${fixture.finishMessage}`);
      }
    }
    if (errors.length <= 0) {
      return CreateRoundUtil.createGenericResponse<string[]>(true, "", null);
    }
    return CreateRoundUtil.createGenericResponse<string[]>(false, `${errors.length} fixtures are invalid for finish.`, errors);
  };
  const onSaveError = (error: string) => {
    setSaveError(error);
    logEntries.push(CreateRoundUtil.createLogEntry(error, true));
    setLogEntries([...logEntries])
  };

  const addSaveInfo = (info: string) => {
    logEntries.push(CreateRoundUtil.createLogEntry(info, false));
    setLogEntries([...logEntries])
  };

  const onCheckClick = () => {
    var newStep = step + 1;
    setStep(newStep);
    setValidInput(isValid(newStep));
  };
  const onBackClick = () => {
    var newStep = step - 1;
    setStep(newStep);
    setValidInput(isValid(newStep));
  };
  const onContinueClick = () => {
    CreateRoundUtil.clearArray(logEntries);
    setLogEntries([...logEntries]);
    setSaveError("");

    var newStep = step + 1;
    setStep(newStep);
    setValidInput(isValid(newStep));
  };
  const settleRound = async (fixturesOnly: boolean): Promise<GenericResponse<number>> => {
    var updateFixtureCount = 0;
    var updateDrawCount = 0;
    var updateCount = 0;
    var updateDrawJson = JSON.stringify({ status: RoundOverviewUtil.FINISHED_STATUS });

    //Save fixtures, score and set FINISHED status if needed
    for (var iFixture = 0; iFixture < formState.fixtures.length; iFixture++) {
      var fixtureItem = formState.fixtures[iFixture];
      var fixture = fixtureItem.fixture;
      var input = fixtureItem.input;
      var draw = fixture.draw;
      if (fixture.drawSign && fixture.drawSign.length > 0) {
        var draw = fixture.draw;
        if (!draw) {
          return CreateRoundUtil.createGenericResponse(false, "UpdateFixture.Draw '" + fixture.name + "' failure: draw not set [Index:" + iFixture + "].", updateCount);
        }
        var drawStatus = draw.status;
        if (!drawStatus || drawStatus.length <= 0) {
          return CreateRoundUtil.createGenericResponse(false, "UpdateFixture.Draw '" + fixture.name + "' failure: draw status not set [Index:" + iFixture + "].", updateCount);
        }
        if (drawStatus === RoundOverviewUtil.FINISHED_STATUS) {
          continue;
        }
        if (drawStatus !== RoundOverviewUtil.PUBLISHED_STATUS) {
          return CreateRoundUtil.createGenericResponse(false, `UpdateFixture.Draw '${fixture.name}' failure: invalid draw status '${drawStatus}'  [Index:${iFixture}].`, updateCount);
        }
        var drawResp = await CreateRoundUtil.updateDraw(draw.id, updateDrawJson);
        if (!drawResp.success) {
          return CreateRoundUtil.createGenericResponse(false, "UpdateFixture.Draw '" + fixture.name + "' error: " + drawResp.message + " [Index:" + iFixture + "].", updateCount);
        }
        if (drawResp.item) {
          fixture.draw = drawResp.item;
        } else {
          return CreateRoundUtil.createGenericResponse(false, "UpdateFixture.Draw '" + fixture.name + "' failure: draw was not returned [Index:" + iFixture + "].", updateCount);
        }
        updateDrawCount++;
        updateCount++;
        continue;
      }
      if (input.homeScore === null || isNaN(input.homeScore) || input.awayScore === null || isNaN(input.awayScore)) {
        continue;
      }
      //var propsDict : Record<string, any> = {};
      var status: string | undefined = undefined;
      var homeTeam: FixtureTeam | undefined = undefined;
      var awayTeam: FixtureTeam | undefined | null = undefined;
      var changeCount = 0;
      if (fixture.status !== RoundOverviewUtil.FINISHED_STATUS) {
        status = RoundOverviewUtil.FINISHED_STATUS;
        changeCount++;
      }
      if (fixture.homeScore !== input.homeScore) {
        var homeTeamX = fixture.fixture?.homeTeam;
        if (homeTeamX) {
          homeTeam = { ...homeTeamX };
          homeTeam.score = input.homeScore;
          changeCount++;
        }
      }
      if (fixture.awayScore !== input.awayScore) {
        var awayTeamX = fixture.fixture?.awayTeam;
        if (awayTeamX) {
          awayTeam = { ...awayTeamX };
          awayTeam.score = input.awayScore;
          changeCount++;
        }
      }
      if (changeCount <= 0) {
        continue;
      }
      var fixtureJson = JSON.stringify({ status: status, homeTeam: homeTeam, awayTeam: awayTeam });
      var fixtureResponse = await CreateRoundUtil.updateFixture(fixture.id, fixtureJson);
      if (!fixtureResponse.success) {
        return CreateRoundUtil.createGenericResponse(false, "UpdateFixture '" + fixture.name + "' error: " + fixtureResponse.message + " [Index:" + iFixture + "].", updateFixtureCount);
      }
      var respFixture = fixtureResponse.item;
      if (respFixture) {
        fixture.fixture = respFixture;
        fixture.awayScore = respFixture.awayTeam.score;
        fixture.homeScore = respFixture.homeTeam.score;
        fixture.providerStatus = respFixture.providerStatus;
        fixture.status = respFixture.status;
        fixture.startingAt = respFixture.startingAt;
        fixture.sign = RoundOverviewUtil.getScoreSign(fixture.homeScore, fixture.awayScore);
      }else {
        return CreateRoundUtil.createGenericResponse(false, "UpdateFixture '" + fixture.name + "' failure: fixture was not returned [Index:" + iFixture + "].", updateCount);
      }
      updateFixtureCount++;
      updateCount++;
    }
    addSaveInfo("Fixtures updated [Count:" + updateCount + " of " + formState.fixtures.length + "]  [FixtureUpdates:" + updateFixtureCount + "] [DrawUpdates:" + updateDrawCount + "]");

    if (updateCount > 0) {
      //Sleep/delay 2 seconds to have fixture results properly updated
      addSaveInfo("Wait 2 seconds for fixture results to be updated...");
      await CreateRoundUtil.sleep(2000);
      addSaveInfo("Wait 2 seconds for fixture results to be updated - done");
    }

    if (fixturesOnly) {
      addSaveInfo("Fixtures only - don't finish round.");
      return CreateRoundUtil.createGenericResponse(true, "", updateCount);
        
    }

    //Finish round  
    var finishRoundJson = JSON.stringify({ status: RoundOverviewUtil.FINISHED_STATUS });
    var rId = round ? round.id : "0";
    var finishRoundResp = await CreateRoundUtil.updateRound(rId, finishRoundJson);
    if (!finishRoundResp.success) {
      return CreateRoundUtil.createGenericResponse(false, "FinishRound error: " + finishRoundResp.message, updateCount);
    }
    var roundX = finishRoundResp.item;
    if (roundX === null) {
      return CreateRoundUtil.createGenericResponse(false, "FinishRound did not return round.", updateCount);
    }
    setRound(roundX);
    updateCount++;
    addSaveInfo("Round finished [Id:" + rId + "]");

    return CreateRoundUtil.createGenericResponse(true, "", updateCount);
  }
  

  const onSettleFixturesClick = () => {
    CreateRoundUtil.clearArray(logEntries);
    setLogEntries([...logEntries]);
    setSaveError("");

    //Saving
    setSaveStatus(1);
    addSaveInfo("Input is OK, start saving");
    settleRound(true).then((settleRoundResp) => {
      var updateCount = settleRoundResp.item;
      addSaveInfo("Settle Fixtures done [Success:" + settleRoundResp.success + "] [Message:" + settleRoundResp.message + "] [UpdateCount:" + updateCount + "]");
      if (settleRoundResp.success) {
        //Saved something
        setSaveStatus(2);
      } else {
        //Not saved
        setSaveStatus(0);
      }
      refreshRound(false, true);

    }).catch(error => {
      console.error('Settle Fixtures error!', error);
      onSaveError("Settle Fixtures error (onSettleFixturesClick): " + error);
      setSaveStatus(0);
    });

  };
  const onFinishRoundClick = () => {
    CreateRoundUtil.clearArray(logEntries);
    setLogEntries([...logEntries]);
    setSaveError("");

    //Saving
    setSaveStatus(1);
    addSaveInfo("Input is OK, start saving");
    var updateJson = JSON.stringify({status: RoundOverviewUtil.FINISHED_STATUS});
    var rId = roundId ? roundId : "";
    CreateRoundUtil.updateRound(rId, updateJson).then((finishRoundResp) => {
      var roundX = finishRoundResp.item;
      var updateCount = roundX ? 1 : 0;
      if (finishRoundResp.success) {
        addSaveInfo("FinishRound done [Success:" + finishRoundResp.success + "] [Message:" + finishRoundResp.message + "] [UpdateCount:" + updateCount + "]");
        if (roundX){
          setRound(roundX);
        }
        //Saved something
        setSaveStatus(3);
      } else {
        onSaveError("FinishRound error: " + finishRoundResp.message);
        //Not saved
        setSaveStatus(2);
      }
      //refreshRound(false, false);

    }).catch(error => {
      console.error('FinishRound error!', error);
      onSaveError("FinishRound error (onFinishRoundClick): " + error);
      setSaveStatus(2);
    });

  };
  const onRefreshClick = () => {
    refreshRound(false, false);
  };

  var roundInfo = null;
  if (round) {
    roundInfo = (<RoundInfo round={round} roundType={roundType} />);
  }

  var initInfo = null;
  var isInitErr = (initErr && initErr.length > 0);
  if (isInitErr) {
    initInfo = (<Box><h2>{initErr}</h2></Box>);
  } else if (!round) {
    isInitErr = true;
    initInfo = (<Box><h2>{"Round not found."}</h2></Box>);
  } else if (round.status === RoundOverviewUtil.FINISHED_STATUS) {
    isInitErr = true;
    initInfo = (<Box><h2>{"Round is finished."}</h2></Box>);
  } else if (!RoundOverviewUtil.isSettleEnabled(round.status, round.openTo)) {
    isInitErr = true;
    initInfo = (<Box><h2>{"Round not enabled for settlement."}</h2></Box>);
  } else {
    var x = 1;
  }

  var items1 = formState.fixtures;
  var actionButton = null;
  var finishButton = null;
  var finishInvalidInfo = null;
  var title = "Settle Round";
  if (step === 1) {
    actionButton = (<Button disabled={isInitErr || !validInput || saveStatus > 0} onClick={e => onCheckClick()} variant="contained" color="primary">Check</Button>);
    title += " - Result input (1 of 3)";
  } else if (step === 2) {
    if (saveStatus === 2) {
      actionButton = (<Button disabled={isInitErr || !validInput} onClick={e => onContinueClick()} variant="contained" color="primary">Continue</Button>);
    } else {
      actionButton = (<Button disabled={isInitErr || !validInput || saveStatus > 0} onClick={e => onSettleFixturesClick()} variant="contained" color="primary">Settle Fixtures</Button>);
    }
    title += " - Check/Settle fixtures (2 of 3)";
  } else if (step === 3) {
      var finishValid : boolean = (saveStatus === 2);
      if (finishValid) {
        var finishValidResp = isFinishValid();
        finishValid = finishValidResp.success;
        if (!finishValidResp.success) {
          var errorColor = CreateRoundUtil.getLogEntryColor(true);
          var subErrors : string[] = finishValidResp.item ? finishValidResp.item : [];
          finishInvalidInfo = (
          <Box paddingTop={2}>
            <h4>Round is not valid for finish</h4>
            <p style={{ color: errorColor }}>{finishValidResp.message}</p>
            {subErrors.map((subError) => (
              <p style={{ color: errorColor }}>{subError}</p>
            ))}
          </Box>
          );        
        }    
      }
      var finishEnabled = validInput && finishValid;
      actionButton = (<Button disabled={isInitErr || !finishEnabled} onClick={e => onFinishRoundClick()} variant="contained" color="primary">Finish Round</Button>);
      title += " - Finish Round (3 of 3)";
  }

  var actionInfoSpan: any = "";
  if (saveStatus === 2 && step === 2) {
    if (saveError.length > 0) {
      actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{`Fixtures were settled with problem... ${saveError}`}</span>);
    } else {
      actionInfoSpan = (<span style={{ color: "blue", paddingLeft: "50" }}>&nbsp;{`Fixtures were successfully settled.`}</span>);
    }
  } else if (saveStatus === 3 && step === 3) {
    if (saveError.length > 0) {
      actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{`Round was finished with problem... ${saveError}`}</span>);
    } else {
      actionInfoSpan = (<span style={{ color: "blue", paddingLeft: "50" }}>&nbsp;{`Round was successfully finished.`}</span>);
    }
  } else if (saveError.length > 0) {
    actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{saveError}</span>);
  }

  return (
    <Container maxWidth="xl">
      <Title title={title} />
      {roundInfo}
      {initInfo}
      <SettleRoundFixtureTable items={items1} check={(step > 1)} onChange={onChange} />
      <Box marginTop={1}>
        <Button disabled={(refreshing || saveStatus === 1)} onClick={e => onRefreshClick()} variant="contained" color="primary">Refresh</Button>&nbsp;&nbsp;&nbsp;&nbsp;
        <Button disabled={(step === 1 || saveStatus > 0)} onClick={e => onBackClick()} variant="contained" color="primary">Back</Button>&nbsp;
        {actionButton}{actionInfoSpan}
      </Box>
      <Box paddingTop={2}>
        {logEntries.map((info) => (
          <p style={{ color: CreateRoundUtil.getLogEntryColor(info.isError) }}>{info.message}</p>
        ))}
      </Box>
      {finishInvalidInfo}
    </Container>
  );
};

export default SettleRound;

