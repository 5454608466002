import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Tab, Tabs, Box, Typography, Button, TextField } from "@material-ui/core";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { QueryParams } from "../models/CouponShare";
import { TabPanel } from "../Components/TabPanel";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import { BaseItemState, OverviewCriteria } from "../Ticker2/Ticker2Models";
import FreebetTypesView from "./FreebetTypesView";

const FreebetTypesPage: React.FC = () => {
    return (
        <Container maxWidth="xl">
            <Title title="Freebet Types" />
            <FreebetTypesView />        

        </Container>
    );
};

export default FreebetTypesPage;
