import React, {useState} from 'react';
import SignField from "../sign/SignField";
import {getAccessToken} from "../apiToken";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const apiUrl = process.env["REACT_APP_API_URL"]

const getComparison = (row, roundId, setLoading, setObjectUrl) => {
    setLoading(true);
    const handleErr = (err) => {
        alert('Failed, see console');
        console.log(err);
        setLoading(false);
    }
    getAccessToken().then(token => {
        fetch(`${apiUrl}/extended/rounds/settlementComparison`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({row: row, roundId: roundId})
        }).then(res => {
            if (res.status !== 200) {

                handleErr('got status ' + res.status);
                return;
            }
            res.json().then(jsonResult => {
                console.log(jsonResult);
                const jsonString = JSON.stringify(jsonResult, null, 2);
                const blob = new Blob([jsonString], {type: "application/json"});
                const objUrl = window.URL.createObjectURL(blob);
                setObjectUrl(objUrl);
                setLoading(false);
            });
        }, (err) => handleErr(err));
    })
}

const SettlementComparison = (props) => {
 const nFixtures = parseInt(props.match.params.nFixtures);
 const roundId = props.match.params.roundId;
 const defaultRow = Array(nFixtures).fill("X");
 const [row, setRow] = useState(defaultRow)
 const [objectUrl, setObjectUrl] = useState(null);
 const [loading, setLoading] = useState(false);


 const updateRow = (index, val) => {
   const newRow = row;
   newRow[index] = val;
   setRow([...newRow]);
   console.log(row);
 }

 return (
     <div style={{paddingTop: '18px'}}>
      <p style={{marginBottom: '16px', fontSize: '18px'}}>Choose an alternative row settlement for round: {roundId}</p>
      {row.map((val, i) => {
       let value = val;
       if (!value) {
        value = "X";
       }
       return (
           <div style={{marginTop: '4px'}}>
                <SignField input={{onChange: (newVal) => updateRow(i, newVal), value: value}}/>
           </div>
       );
      })}

         <Button
             style={{marginTop: '16px'}}
             variant={'outlined'}
             type={'button'}
             disabled={loading}
             onClick={() => getComparison(row, roundId, setLoading, setObjectUrl)}>
                Get Settlement Comparison
         </Button>
         <div style={{marginTop: '16px'}}>
            {objectUrl ? <a href={objectUrl} target={'_blank'}>Download Result</a> : <div />}
            {loading ? <CircularProgress /> : <div />}
         </div>

     </div>
 );
};

export default SettlementComparison;