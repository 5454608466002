import React from 'react';
import {
  Create,
  SimpleForm,
  Datagrid,
  TextField,
  DateField,
  List,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  TextInput,
} from 'react-admin';
import {FixtureReferenceField, FixtureReferenceInput} from "../fixtures";
import {RoundReferenceField} from "../rounds";
import {useLocation} from "react-router-dom";

import {RoundReferenceInput} from "../rounds/RoundAmount";

export const FixtureInformationValueGrid = props =>
    <Datagrid rowClick="edit" {...props}>
        <TextField source="id" />
        <FixtureReferenceField source={'fixtureId'}/>
        <RoundReferenceField source={'roundId'}/>
        <DateField source="createdAt" showTime/>
        <TextField source={"key"}/>
        <TextField source={"value"}/>
    </Datagrid>;

export const FixtureInformationValuesCreate = props => {
    const history = useLocation();
    const {fixtureId, roundId} = history.state;

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={"key"}/>
                <TextInput source={"value"}/>
                <FixtureReferenceInput source={"fixture_id"} defaultValue={fixtureId}/>
                <RoundReferenceInput source={"round_id"} defaultValue={roundId}/>
            </SimpleForm>
        </Create>
    );
};

export const FixtureInformationValueList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <FixtureReferenceField source={'fixtureId'}/>
            <RoundReferenceField source={'roundId'}/>
            <DateField source="createdAt" showTime/>
            <TextField source={"key"}/>
            <TextField source={"value"}/>
        </Datagrid>
    </List>
);

export const RoundTypeReferenceInput = props =>
    <ReferenceInput label={'Round Type'}  reference="roundTypes" {...props} >
        <AutocompleteInput optionText="parameters.name" emptyValue={""}/>
    </ReferenceInput>;

export const FixtureInformationValueReferenceField = props =>
    <ReferenceField reference="roundTypes" {...props} >
        <TextField source="parameters.name" />
    </ReferenceField>;
