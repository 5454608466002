import { fetchUtils } from "react-admin";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { InvokeApiOptions, InvokeApiResponse } from "./ExternalApiModels";

class ExternalApiUtil {
    static async invokeApi(url : string, options : InvokeApiOptions | undefined) : Promise<InvokeApiResponse>{
        const METHOD_NAME = "ExternalApiUtil.invokeApi";
        try {
            var fetchOptions : fetchUtils.Options | undefined = undefined;
            if (options) {
                fetchOptions = {
                    method: options.method,
                    body: options.body,
                    headers: options.headers
                };
            }
            
            var fetchResponse =  await fetchUtils.fetchJson(url, fetchOptions);
            var success = true;
            var message = "";
            if (fetchResponse.status !== 200 && fetchResponse.status !== 204) {
                success = false;
                message = `Status != 200,204 (OK, No content) [Status:${fetchResponse.status}]`;
            }
            return {
                success: success,
                message: message,
                body:fetchResponse.body,
                headers: fetchResponse.headers,
                json: fetchResponse.json,
                status: fetchResponse.status
            };

        } catch (error) {
            var errorJson = JSON.stringify(error);
            console.error(METHOD_NAME + " error.", error);
            return {
                success: false,
                message: CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error")
            };            
        }
    }
}

export default ExternalApiUtil;