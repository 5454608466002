import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { PlayerTickerOverviewItem } from './Ticker2Models';
import { dateStringTicker } from '../common/helpers/DateHelpers';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { formatToCurrency } from '../common/helpers/CurrencyHelpers';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import Ticker2Util from './Ticker2Util';


interface PlayerOverviewTableProps {
    items: PlayerTickerOverviewItem[];
    onSelect? : (item : PlayerTickerOverviewItem) => void;
    onDeselect?: () => void;
}

const PlayerOverviewTable: React.FC<PlayerOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<PlayerTickerOverviewItem>[]>([]);
    const currency = RoundOverviewUtil.CURRENCY_EUR;

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<PlayerTickerOverviewItem>[] = [
        { id: 'playerId', numeric: false, disablePadding: true, label: "Player" },
        { id: 'operatorId', numeric: false, disablePadding: true, label: "Operator" }
      ];
      Ticker2Util.appendTickerOverviewColumns(cols);
      setColumns(cols);
    }
    const onSelect = (item : PlayerTickerOverviewItem) => {
      setSelectedId(item ? item.playerId : 0);
      if (props.onSelect){
        props.onSelect(item);
      }
    };
    const onDeselect = () => {
      setSelectedId(0);
      if (props.onDeselect){
        props.onDeselect();
      }
    };

  return (
    <DataTable columns={columns} items={props.items} getId={it => it.playerId} orderBy='turnover' order='desc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect} />
    );
};
  
export default PlayerOverviewTable;
