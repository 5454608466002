import React, { useEffect, useState } from "react";
import { Draw, RoundOverviewFixture, RoundOverviewItem } from "./RoundOverviewModels";
import RoundOverviewUtil from "./RoundOverviewUtil";
import { LeagueListItem } from "../CreateRound/CreateRoundModels";
import RoundOverviewFixtureTable from "./RoundOverviewFixtureTable";
import { Box, Button } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import MakeDrawDialog from "./DrawDialog";

interface RoundOverviewGeneralState {
    roundId: number;
    fixtures: RoundOverviewFixture[];
}
interface RoundOverviewGeneralProps {
    round: RoundOverviewItem;
    leagues: LeagueListItem[];
}
const RoundOverviewGeneral: React.FC<RoundOverviewGeneralProps> = (props) => {
    // const [roundId, setRoundId] = useState<number>(0);
    // const [fixtures, setFixtures] = useState<RoundOverviewFixture[]>([]);
    const [roundState, setRoundState] = useState<RoundOverviewGeneralState>({
        roundId: 0,
        fixtures: []
    });
    const [leagues, setLeagues] = useState<LeagueListItem[]>(props.leagues ? props.leagues : []);
    const history = useHistory();   

    useEffect(() => {
        refreshRound();
        // setRoundId(props.round.id);
        // if (props.round.fixtures || props.round.id === 0) {
        //     return;
        // }
        // RoundOverviewUtil.getRoundOverviewFixtures(props.round.id.toString(), leagues).then( roFixtures => {
        //     if (!roFixtures){
        //         roFixtures = [];
        //     }
        //     setFixtures(roFixtures);
        // })

    }, []);

    const refreshRound = () => {
        var rs : RoundOverviewGeneralState = {
            roundId:props.round.id,
            fixtures:[]
        }
        if (props.round.fixtures) {
            rs.fixtures = props.round.fixtures;
            setRoundState(rs);
            return;
        }
        if (props.round.id === 0) {
            setRoundState(rs);
            return;
        }
        RoundOverviewUtil.getRoundOverviewFixtures(props.round.id.toString(), leagues).then( roFixtures => {
            if (!roFixtures){
                roFixtures = [];
            }
            rs.fixtures = roFixtures;
            setRoundState(rs);
        });
    };
    const onRefreshRound = () => {
        refreshRound();
    };
    const onEditRound = () => {
        history.push("rounds/" + props.round.id);
    };    
    var fixtures1 = roundState.fixtures;
    if (roundState.roundId !== props.round.id){
        roundState.roundId = props.round.id;
        roundState.fixtures = [];
        fixtures1 = [];
        refreshRound();
    }
    var editRoundButton = null;
    if (props.round?.id){
        //editRoundButton = (<Button variant="contained" color="secondary" size="small" onClick={e => onEditRound()}>Edit</Button>);
        editRoundButton = (<Button variant="contained" color="secondary" size="small" component={RouterLink} to={"rounds/" + props.round.id}>Edit</Button>);

    }
    var readOnly = !RoundOverviewUtil.isEnabledStatus(props.round.status);
    return (
        <div>
            <RoundOverviewFixtureTable fixtures={fixtures1} readOnly={readOnly} onRefreshRound={onRefreshRound} />
            <Box marginTop={1}>
                {editRoundButton}
            </Box>
            
        </div>
    );
};

export default RoundOverviewGeneral;

