// src/MyForm.tsx
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { TextField, Button, Box, FormLabel, Container, IconButton, Tooltip, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { getList, getListByPost, getListWithId } from '../api/dataProvider';
import { FixtureListItem, LogEntry, RoundFixtureInput, RoundFixtureItem, SaveOutcomeParams, SaveRoundFixture, SaveRoundParams } from './CreateRoundModels';
import RoundFixtureTable from './RoundFixtureTable';
//import CreateRound2 from './CreateRound2_old';
import FixtureChooser from './FixtureChooser';
//import {useForm} from 'react-hook-form'
import CreateRoundUtil from './CreateRoundUtil';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import fetchApi from '../../fetchApi';
import { Title } from 'react-admin';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';

interface UpdateRoundResponse {
    drawIds: number[],
    roundFixtures: RoundFixtureItem[]
}
interface CreateRound2State {
    openToDate: string;
    openToTime: string;
    jackpot: string;
}

interface CreateRound2Props {
    roundTypeId: string;
    roundType: any
}


const CreateRound2: React.FC<CreateRound2Props> = (props) => {
    //   const timeFormat : string = "^([01]\\d|2[0-3]):[0-5]\\d$";
    //   const timeInfoText : string = "HH:mm";
    var nFixtures: number = 0;
    var roundType = props.roundType;
    var roundTypeName = "";
    if (roundType !== null && roundType !== undefined) {
        nFixtures = roundType.parameters?.nFixtures;
        roundTypeName = roundType.parameters?.name ?? "";
    }

    const history = useHistory();

    const [formData, setFormData] = useState<CreateRound2State>({
        openToDate: "",
        openToTime: "",
        jackpot: CreateRoundUtil.getDefaultJackpotString(roundTypeName)
    });

    const [selectFixturesExpanded, setSelectFixturesExpanded] = useState<boolean>(true);
    const [openToDateError, setOpenToDateError] = useState<string>("");
    const [openToTimeError, setOpenToTimeError] = useState<string>("");
    const [jackpotError, setJackpotError] = useState<string>("");
    const [roundFixtures, setRoundFixtures] = useState<RoundFixtureItem[]>([]);
    const [roundId, setRoundId] = useState<string>("");
    const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [jackpotId, setJackpotId] = useState<string>("");
    const [updateRoundResp, setUpdateRoundResp] = useState<UpdateRoundResponse>({
        drawIds:[],
        roundFixtures:[]
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const onSaveError = (error: string) => {
        setSaveError(error);
        logEntries.push(CreateRoundUtil.createLogEntry(error, true));
        setLogEntries([...logEntries])
    };
  
    const addSaveInfo = (info: string) => {
          logEntries.push(CreateRoundUtil.createLogEntry(info, false));
          setLogEntries([...logEntries])
    };
    const addSaveError = (info: string) => {
        logEntries.push(CreateRoundUtil.createLogEntry(info, true));
        setLogEntries([...logEntries])
    };
    const updateDrawsTest = () => {
        if (updateRoundResp.drawIds.length <= 0){
            alert("updateDrawsTest failure - no draws");
            return;
        }
        updateDraws(updateRoundResp.drawIds, updateRoundResp.roundFixtures)
        .then((count) => {
            addSaveInfo("updateDrawsTest done [count:" + count + "]");
        });
    }
    const updateDraws = async (drawIds: number[], roundFixtures2: RoundFixtureItem[]): Promise<number> => {
        var updateCount: number = 0;
        try {

            var resp = await getListWithId("draws", drawIds);
            var draws: any = resp.data;
            if (draws === null || draws.length <= 0) {
                setSaveError("No draws could be fetched");
                return 0;
            }
            var updateOutcomes: SaveOutcomeParams[] = [];
            for (var iDraw = 0; iDraw < draws.length; iDraw++) {
                var draw = draws[iDraw];
                var drawId: number = parseInt(draw.id);
                if (!draw.outcomes) {
                    continue;
                }
                var drawIndex = drawIds.indexOf(drawId);
                if (drawIndex < 0) {
                    continue;
                }
                var roundFixture: RoundFixtureItem = roundFixtures2[drawIndex];
                // if (roundFixture.draw && roundFixture.input && roundFixture.input.keepDraw) {
                //     continue;
                // }
                if (roundFixture.draw) {
                    continue;
                }
                updateOutcomes = [];
                var sumN : number = 0;
                for (var iOutcome = 0; iOutcome < draw.outcomes.length; iOutcome++) {
                    var outcome = draw.outcomes[iOutcome];
                    var p: number = -1;
                    var sign: string = outcome.sign;
                    var n: number = -1;
                    switch (sign) {
                        case "ONE":
                            p = roundFixture.input?.oneP ?? -1;
                            n = roundFixture.input?.oneN ?? -1;
                            break;
                        case "X":
                        case "x":
                            p = roundFixture.input?.xP ?? -1;
                            n = roundFixture.input?.xN ?? -1;
                            break;
                        case "TWO":
                            p = roundFixture.input?.twoP ?? -1;
                            n = roundFixture.input?.twoN ?? -1;
                            break;
                    }
                    if (!p || isNaN(p) || p < 0) {
                        continue;
                    }
                    //var n = Math.round(p * 100);
                    if (!n || isNaN(n) || n < 0) {
                        continue;
                    }
                    sumN += n;
                    updateOutcomes.push({
                        id: outcome.id,
                        n: n,
                        sign: sign,
                        result: false,
                        probability: outcome.probability
                    });
                }
                if (updateOutcomes.length !== 3) {
                    onSaveError("Update Outcomes failure: outcomes <> 3 [DrawId:" + drawId + "] [outcomes:" + updateOutcomes.length + "].");
                    continue;
                }
                if (sumN != CreateRoundUtil.ticketSum){
                    onSaveError("Update Outcomes failure: ticket sum <> " + CreateRoundUtil.ticketSum + " [DrawId:" + drawId + "] [outcomes:" + updateOutcomes.length + "] [ticketSum:" + sumN + "].");
                    continue;
                }
                var updateOutcomesJson = JSON.stringify({ outcomes: updateOutcomes });
                try {
                    
                    var updateOutcomeResp = await fetchApi("draws/" + drawId, {
                        method: 'PATCH',
                        body: updateOutcomesJson
                    });
                    if (!updateOutcomeResp) {
                        addSaveError(`Update Draw Outcomes failure: no response [Fixture:${roundFixture.listItem.name}] [DrawId:${drawId}]`);
                    }
                    updateCount++;
                } catch (error) {
                    addSaveError(`Update Draw Outcomes error: ${error} [Fixture:${roundFixture.listItem.name}] [DrawId:${drawId}]`);
                }
            }
            addSaveInfo("Update Draws [count:" + updateCount + "]")
            return updateCount;
        } catch (error) {
            console.error("Update Draws error", error);
            onSaveError("Update Draws error: " + error);
            if (updateCount <= 0) {
                return -1;
            }
            return updateCount;
        }

    };

    const updateRound = async (roundId: string, saveRoundJson: string): Promise<UpdateRoundResponse | null> => {
        try {

            var respX = await fetchApi("rounds/" + roundId, {
                method: 'PATCH',
                body: saveRoundJson
            });
            var resp = respX.json;
            if (!resp) {
                onSaveError("Update Round failure: no response.");
                return null;
            }
            var rId: string = resp.id;
            if (!rId) {
                onSaveError("Update Round failure: id property is missing in response.");
                return null;
            }
            rId = rId.trim();
            if (rId.length <= 0) {
                onSaveError("Update Round failure: id property is an empty string.");
                return null;
            }
            addSaveInfo("Update Round (datebase) done");

            //3. Update outcomes for each draw/fixture
            var dummyRoundFixture: RoundFixtureItem = {
                id: "",
                input: CreateRoundUtil.createRoundFixtureInput(""),
                listItem: roundFixtures[0].listItem,
                ordNo: -1,
                version: -1
            };

            var drawIds: number[] = [];
            var roundFixtures2: RoundFixtureItem[] = [];
            resp.draws.forEach((draw: any) => {
                var fixtureId: string = draw.fixtureId + "";
                // eslint-disable-next-line no-loop-func
                var roundFixture = roundFixtures.find(rf => rf.id === fixtureId) ?? dummyRoundFixture;
                if (roundFixture.id === "") {
                    //dummy
                    return;
                }
                var valid = roundFixture.input ? roundFixture.input.valid : false;
                if (!valid) {
                    return;
                }
                var drawId = parseInt(draw.id);
                if (isNaN(drawId)) {
                    return;
                }
                drawIds.push(drawId);
                roundFixtures2.push(roundFixture);
            });
            addSaveInfo("Update Round (compile drawIds per RoundFixture) done [Draws:" + drawIds.length + "]");
            var updateRoundResponse: UpdateRoundResponse = {
                drawIds: drawIds,
                roundFixtures: roundFixtures2
            };
            setUpdateRoundResp(updateRoundResponse)
            return updateRoundResponse;
        } catch (error) {
            console.error("Update Round error!", error);
            onSaveError("Update Round error: " + error);
            return null;
        }
    }
    const createRound = async (): Promise<string> => {
        try {
            var resp = await getListByPost("rounds", { roundTypeId: props.roundTypeId });
            if (!resp || !resp.data) {
                onSaveError("Create Round failure: no response.");
                return "";
            }
            var rId: string = resp.data.id;
            if (!rId) {
                onSaveError("Create Round failure: id property is missing in response.");
                return "";
            }
            rId = rId.trim();
            if (rId.length <= 0) {
                onSaveError("Create Round failure: id property is an empty string.");
                return "";
            }
            addSaveInfo("Create Round done [id:" + rId + "]");
            setRoundId(rId);
            return rId;
        } catch (error) {
            console.error("Create Round error", error);
            onSaveError("Create Round error: " + error);
            return "";
        }
    }
    const saveRoundPromotionValue = async (id : string, roundId: string, key: string, value: string): Promise<string> => {
        try {
            var url = "roundPromotionValues"
            var method = "POST";
            var body : any = null;
            if (id && id.length > 0){
                //Update
                var deleteRespX = await fetchApi(url + "/" + id, {
                    method: "DELETE"
                });
            }
            body = {
                roundId: roundId,
                key: key,
                value: value 
            };
            var bodyJson = JSON.stringify(body);
            var respX = await fetchApi(url, {
                method: method,
                body: bodyJson
            });
            var resp = respX.json;
            if (!resp) {
                onSaveError("Update RoundPromotionValue failure: no response.");
                return "";
            }
            var rpvId: string = resp.id;
            if (!rpvId) {
                onSaveError("Update RoundPromotionValue failure: id property is missing in response.");
                return "";
            }
            rpvId = rpvId.trim();
            if (rpvId.length <= 0) {
                onSaveError("Update RoundPromotionValue failure: id property is an empty string.");
                return "";
            }
            addSaveInfo("Save RoundPromotionValue done [id:" + rpvId + "]");
            return rpvId;
        } catch (error) {
            console.error("Update RoundPromotionValue error!", error);
            onSaveError("Update RoundPromotionValue error: " + error);
            return "";
        }
    }
    const saveRound = async (saveRoundJson: string): Promise<number> => {
        var updateCount = 0;
        var rId = roundId;
        if (!rId || rId.length === 0) {
            rId = await createRound();
            if (!rId || rId.length === 0) {
                return updateCount;
            }
            updateCount++;
            //Sleep/delay x seconds for created round to be handled
            if (CreateRoundUtil.createRoundDelaySeconds > 0) {
                addSaveInfo(`Wait ${CreateRoundUtil.createRoundDelaySeconds} seconds for created round to be handled...`);
                await CreateRoundUtil.sleep(CreateRoundUtil.createRoundDelaySeconds * 1000);
                addSaveInfo(`Wait ${CreateRoundUtil.createRoundDelaySeconds} seconds for created round to be handled - done`); 
            }     
            // onSaveError("***Test error after create***");
            // return updateCount;
        } else {
            addSaveInfo(`Round is already created [id:${rId}]`);
        }

        var updateRoundResp = await updateRound(rId, saveRoundJson);
        if (!updateRoundResp) {
            return updateCount;
        }
        updateCount++;

        var updateDrawCount = await updateDraws(updateRoundResp.drawIds, updateRoundResp.roundFixtures);
        if (updateDrawCount > 0) {
            updateCount += updateDrawCount;
        }

        if (formData.jackpot.length > 0 && jackpotId === "") {
            var jId = await saveRoundPromotionValue(jackpotId, rId, "jackpotEUR", formData.jackpot);
            if (jId.length > 0 && jId !== jackpotId){
                setJackpotId(jId);
            }
        }
        return updateCount;
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Add form submission logic here
        //console.log(formData);
        var openToDate = CreateRoundUtil.parseDateX(formData.openToDate);
        var openTo = openToDate;
        var jackpot = parseInt(formData.jackpot);

        var valid = true;
        var openToDateErr = "";
        var openToTimeErr = "";
        var jackpotErr = "";
        var saveErr = "";
        if (roundFixtures.length === 0) {
            valid = false;
            saveErr = "No fixtures added.";
        } else if (roundFixtures.length > nFixtures) {
            valid = false;
            saveErr = "More than " + nFixtures + " fixtures added.";
        } else if (roundFixtures.length < nFixtures) {
            valid = false;
            saveErr = "Less than " + nFixtures + " fixtures added.";
        } else {
            var invalidFixtureCount = 0;
            roundFixtures.forEach(rf => {
                if (!rf.input?.valid) {
                    invalidFixtureCount++;
                }
            });
            if (invalidFixtureCount > 0) {
                valid = false;
                saveErr = "There are " + invalidFixtureCount + " invalid fixtures.";
            }
        }
        if (formData.openToDate.length > 0) {
            var dateNumber = openToDate.getDate();
            if (isNaN(dateNumber)) {
                valid = false;
                openToTimeErr = "Invalid date";
            }
        }
        else {
            valid = false;
            openToDateErr = "Required";
        }
        if (formData.openToTime.length > 0) {
            if (CreateRoundUtil.isValidTime(formData.openToTime)) {
                openTo = CreateRoundUtil.addTime(openTo, formData.openToTime);
            }
            else {
                valid = false;
                openToTimeErr = "Invalid time";
            }
        }
        else {
            valid = false;
            openToTimeErr = "Required";
        }
        if (formData.jackpot.length > 0) {
            if (isNaN(jackpot)) {
                valid = false;
                jackpotErr = "Invalid int";
            }
        }
        else {
            valid = false;
            jackpotErr = "Required (int)";
        }

        setOpenToDateError(openToDateErr);
        setOpenToTimeError(openToTimeErr);
        setJackpotError(jackpotErr);
        CreateRoundUtil.clearArray(logEntries);
        setLogEntries([...logEntries]);
        setSaveError(saveErr);

        if (!valid) {
            return;
        }

        var saveFixtures: SaveRoundFixture[] = [];
        roundFixtures.forEach(f => saveFixtures.push({ fixtureId: f.id }));
        var saveRoundParams: SaveRoundParams = {
            fixtures: saveFixtures,
            openTo: openTo,
            jackpot: undefined
        };
        let saveRoundJson = JSON.stringify(saveRoundParams);
        //Saving
        setSaveStatus(1);
        addSaveInfo("Input is OK, start saving");
        saveRound(saveRoundJson).then((updateCount) => {
            addSaveInfo("Save done [UpdateCount:" + updateCount + "]");
            if (updateCount > 0) {
                //Saved something
                setSaveStatus(2);
            } else {
                //Not saved
                setSaveStatus(0);
            }
        }).catch(error => {
            console.error('Save Round error!', error);
            onSaveError("Save Round error (handleSubmit): " + error);
            setSaveStatus(0);
        });
    };
    const onAddFixture = (fixture: FixtureListItem) => {
        if (!fixture) {
            alert("CreateRound2.onAddFixture, fixture not set");
            return;
        }
        if (roundFixtures.length >= nFixtures) {
            alert("AddFixture, maximum number of fixtures already added [max:" + nFixtures + "].");
            return;
        }
        var roundFixture1 = roundFixtures.find(f => f.id === fixture.id);
        if (roundFixture1) {
            alert("AddFixture, round fixture already added [fixtureId:" + fixture.id + "] [title:" + roundFixture1?.listItem.title + "]");
            return;
        }
        var lastOrdNo = 0;
        if (roundFixtures && roundFixtures.length > 0) {
            roundFixtures.forEach(rf => {
                if (lastOrdNo < rf.ordNo) {
                    lastOrdNo = rf.ordNo;
                }
            });
        }
        var input = CreateRoundUtil.createRoundFixtureInput(fixture.id);
        var roundFixture: RoundFixtureItem = {
            id: fixture.id,
            listItem: fixture,
            ordNo: lastOrdNo + 1,
            input: input,
            version: 1
        };
        roundFixtures.push(roundFixture);

        CreateRoundUtil.getDrawsByFixture(fixture.id).then(drawResp => {
            if (!drawResp.success) {
                return;
            }
            if (!drawResp.item || drawResp.item.length <=  0) {
                return;
            }
            var draw = drawResp.item[0];
            if (!draw.outcomes || draw.outcomes.length <= 0) {
                return;
            }
            roundFixture.draw = draw;
            for (var iOutcome = 0; iOutcome < draw.outcomes.length; iOutcome++){
                var o = draw.outcomes[iOutcome];
                var p = parseFloat(o.probability);
                var odds = Math.round(100 / p) / 100;
                var sOdds = odds.toString();
                switch (o.sign) {
                    case RoundOverviewUtil.ONE_OUTCOME_SIGN:
                        input.oneN = o.n;
                        input.oneP = p;
                        input.oneOdds = sOdds;
                        break;
                    case RoundOverviewUtil.X_OUTCOME_SIGN:
                        input.xN = o.n;
                        input.xP = p;
                        input.xOdds = sOdds;
                        break;
                    case RoundOverviewUtil.TWO_OUTCOME_SIGN:
                        input.twoN = o.n;
                        input.twoP = p;
                        input.twoOdds = sOdds;
                        break;
                }
            };
            input.keepDraw = true;
            roundFixture.version += roundFixture.version;
        }).catch(error => {
            console.error("CreateRound2.AddFixture.draw error.", error);
        }).finally(() => {
            var rfs = [...roundFixtures];
            setRoundFixtures(rfs);
    
        });


    };
    const renumberRoundFixtures = (rfs: RoundFixtureItem[]) => {
        var ordNo = 0;
        rfs.forEach(rf => {
            ordNo++;
            rf.ordNo = ordNo;
        })

    }
    const onChangeFixture = (input: RoundFixtureInput) => {
        var idx = roundFixtures.findIndex(rf => rf.id === input.id);
        if (idx < 0) {
            alert("onChangeFixture, fixture not found [fixtureId:" + input.id + "]");
            setRoundFixtures([...roundFixtures]);
            return;
        }
        var fixture = roundFixtures[idx];
        fixture.input = input;
    };

    const onDeleteFixture = (item: RoundFixtureItem) => {
        var idx = roundFixtures.findIndex(rf => rf.id === item.id);
        if (idx < 0) {
            alert("DeleteFixture, fixture not found [fixtureId:" + item.id + "]");
            setRoundFixtures([...roundFixtures]);
            return;

        }
        roundFixtures.splice(idx, 1);

        //Renumber
        if (roundFixtures.length > 1 && idx < roundFixtures.length) {
            roundFixtures.sort(function (a: RoundFixtureItem, b: RoundFixtureItem) {
                return a.ordNo - b.ordNo
            });
        }
        renumberRoundFixtures(roundFixtures);
        setRoundFixtures([...roundFixtures]);
    };
    const onMoveUpFixture = (item: RoundFixtureItem) => {
        var rfs = [...roundFixtures];
        var idx = rfs.findIndex(rf => rf.id === item.id);
        if (idx < 0) {
            alert("onMoveUpFixture, fixture not found [fixtureId:" + item.id + "]");
            setRoundFixtures(rfs);
            return;
        }
        if (idx === 0) {
            return;
        }
        var fixture = rfs[idx];
        var fixture2 = rfs[idx - 1];
        rfs[idx - 1] = fixture;
        rfs[idx] = fixture2;

        renumberRoundFixtures(rfs);
        setRoundFixtures(rfs);
    };

    const onMoveDownFixture = (item: RoundFixtureItem) => {
        var idx = roundFixtures.findIndex(rf => rf.id === item.id);
        if (idx < 0) {
            alert("onMoveUpFixture, fixture not found [fixtureId:" + item.id + "]");
            setRoundFixtures([...roundFixtures]);
            return;
        }
        if (idx >= (roundFixtures.length - 1)) {
            return;
        }
        var fixture = roundFixtures[idx];
        var fixture2 = roundFixtures[idx + 1];
        roundFixtures[idx + 1] = fixture;
        roundFixtures[idx] = fixture2;

        renumberRoundFixtures(roundFixtures);
        setRoundFixtures([...roundFixtures]);
    };

    const onRefreshOpenTo = () => {
        var minStartTime: Date | null = null;
        roundFixtures.forEach(rf => {
            if (minStartTime === null) {
                minStartTime = rf.listItem.startingAt;
            } else if (minStartTime > rf.listItem.startingAt) {
                minStartTime = rf.listItem.startingAt;
            }
        });
        if (minStartTime === null) {
            return;
        }

        var openToDateString = CreateRoundUtil.getDateWithoutTimeString(minStartTime);
        var openToTimeString = CreateRoundUtil.getTimeString(minStartTime);
        var fd = { ...formData };
        fd.openToDate = openToDateString;
        fd.openToTime = openToTimeString;
        setFormData(fd);
    };
    const onClickSelectFixtures = () => {
        setSelectFixturesExpanded(!selectFixturesExpanded);
    };
    const onGotoRounds = () => {
        history.push('rounds');

    };
    const onEditRound = () => {
        history.push("rounds/" + roundId);

    };

    var actionInfoSpan : any = "";
    if (roundId.length > 0 && saveStatus === 2) {
        if (saveError.length > 0) {
            actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{`Round was created with problem [Id:${roundId}]... ${saveError}`}</span>);
        } else {
            actionInfoSpan = (<span style={{ color: "blue", paddingLeft: "50" }}>&nbsp;{`Round was successfully created [Id:${roundId}].`}</span>);
        }
    } else if (saveError.length > 0) {
      actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{saveError}</span>);
    }
    
    var proceedSave = (roundId.length > 0 && updateRoundResp.drawIds.length === 0);
    var roundUpdated = (roundId.length > 0 && updateRoundResp.drawIds.length > 0)
    var saveEnabled = (saveStatus !== 1 && (roundId.length === 0 || updateRoundResp.drawIds.length === 0));
    var saveButtonText = proceedSave ? "Save (Proceed)" : "Save";
    return (
        <Container maxWidth="lg">
            <Title title="Create Round - Fixtures and key info (2 of 2)" />
            <Box>
                <TextField
                    label="Round type"
                    name="roundTypeId"
                    value={roundType?.parameters.name}
                    helperText={"id:" + props.roundTypeId + "; " + roundType?.parameters.nFixtures.toString() + " fixtures"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
            <div>
                <Accordion expanded={selectFixturesExpanded} onChange={(e) => onClickSelectFixtures()}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Select Fixtures</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FixtureChooser maxFixtures={nFixtures} onAdd={onAddFixture} />
                    </AccordionDetails>
                </Accordion>
            </div>
            <RoundFixtureTable fixtures={roundFixtures} onChange={onChangeFixture} onDelete={onDeleteFixture} onMoveUp={onMoveUpFixture} onMoveDown={onMoveDownFixture} />
            <form onSubmit={handleSubmit}>
                <Box paddingTop={2} display="flex" alignItems="center">
                    <TextField
                        type="date"
                        name="openToDate"
                        label="Open to"
                        value={formData.openToDate}
                        InputLabelProps={{shrink: true}}
                        error={openToDateError && openToDateError.length > 0 ? true : false}
                        helperText={openToDateError} onChange={handleChange}
                    />
                    <span style={{ padding: 2 }}></span>
                    <TextField
                        name="openToTime"
                        label={CreateRoundUtil.timeInfoText}
                        value={formData.openToTime}
                        style={{ width: 40 }}
                        InputLabelProps={{shrink: true}}
                        error={openToTimeError && openToTimeError.length ? true : false}
                        helperText={openToTimeError}
                        onChange={handleChange}
                    />
                    &nbsp;
                    <IconButton aria-label="refreshOpenTo" color="secondary" size="small" onClick={(e: any) => onRefreshOpenTo()} >
                        <Tooltip title="Refresh from Fixtures">
                            <RefreshIcon />
                        </Tooltip>
                    </IconButton>
                </Box>
                <Box>
                    <TextField
                        name="jackpot"
                        label="Jackpot"
                        type="number"
                        value={formData.jackpot}
                        style={{ width: 100 }}
                        error={jackpotError && jackpotError.length ? true : false}
                        helperText={jackpotError}
                        onChange={handleChange} />
                </Box>
                <Box paddingTop={2}>
                    <Button variant="contained" color="primary" type="submit" disabled={!saveEnabled}>{saveButtonText}</Button>
                    {actionInfoSpan}
                </Box>
                    {roundUpdated && saveStatus === 2
                    ? <Box paddingTop={2}>
                        <Button style={{textTransform:"none"}} color="secondary" onClick={(e) => onGotoRounds()}>Go to Rounds</Button>
                        <Button style={{textTransform:"none"}} color="secondary" onClick={(e) => onEditRound()}>Edit Round</Button>
                    </Box>
                    : ""
                    }
            </form>
            <Box paddingTop={2}>
                {logEntries.map((info) => (
                    <p style={{ color: CreateRoundUtil.getLogEntryColor(info.isError) }}>{info.message}</p>
                ))}
            </Box>
        </Container>
    );
};

export default CreateRound2;

