import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, TextField, Box, MenuItem, Container, FormControlLabel, Switch } from '@material-ui/core';
import { FreebetCampaign, FreebetCampaignUpdate, FreebetOverviewItem, FreebetType } from "./FreebetModels";
import FreebetUtil from "./FreebetUtil";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import AdminUtil from "../Admin/AdminUtil";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import { Operator, OperatorSetting } from "../Admin/AdminModels";
import OperatorSelect from "../Components/OperatorSelect";
import DateTimePickerX, { DateTimePickerXValue, getDateTimePickerXValue } from "../CreateFixture/DateTimePickerX";
import FreebetTypeSelect from "./FreebetTypeSelect";

interface EditFreebetCampaignState {
    item?: FreebetOverviewItem;
    id: number;
    name: string;
    freebetTypeId: number;
    operatorId: string;
    maxNumberOfFreebets: string;
    validFrom: DateTimePickerXValue;
    validTo: DateTimePickerXValue;
    enabled: boolean;
    valid: boolean;
    nameError: string;
    freebetTypeIdError: string;
    operatorIdError: string;
    maxNumberOfFreebetsError: string;
    validFromError: string;
    validToError: string;
    value?: FreebetCampaign;
    update?: FreebetCampaignUpdate;
}

function checkState(state : EditFreebetCampaignState) {
    var valid = true;
    var nameErr = "";
    var freebetTypeId = state.freebetTypeId;
    //operatorId: "",
    var maxNumberOfFreebets = parseInt(state.maxNumberOfFreebets);
    // var validFromNumber = Date.parse(state.validFrom);
    // var validToNumber = Date.parse(state.validTo);
    // var validFrom = new Date(state.validFrom);
    // var validTo = new Date(state.validTo);
    //var validFromNumber = state.validFrom ? state.validFrom.getDate() : 0;
    //var validToNumber = state.validTo ? state.validTo.getDate() : 0;
    var validFrom = state.validFrom.dateTime ? state.validFrom.dateTime : new Date();
    var validTo = state.validTo.dateTime ? state.validTo.dateTime : new Date();
    
    var freebetTypeErr = "";
    var operatorErr = "";
    var maxNumberOfFreebetsErr = "";
    var validFromErr = "";
    var validToErr = "";
    if (state.name.length === 0) {
        valid = false;
        nameErr = "Required";
    } else if (state.name.trim().length === 0) {
        valid = false;
        nameErr = "Cannot be blank";
    } else if (state.name.trim().length !== state.name.length) {
        valid = false;
        nameErr = "Starts/ends with blanks";
    }
    if (state.freebetTypeId <= 0) {
        valid = false;
        freebetTypeErr = "Required";
    }
    if (state.operatorId.length === 0) {
        valid = false;
        operatorErr = "Required";
    } else if (state.operatorId.trim().length === 0) {
        valid = false;
        operatorErr = "Cannot be blank";
    } else if (state.operatorId.trim().length !== state.operatorId.length) {
        valid = false;
        operatorErr = "Starts/ends with blanks";
    }

    if (state.maxNumberOfFreebets.length > 0) {
        if (isNaN(maxNumberOfFreebets)) {
            valid = false;
            maxNumberOfFreebetsErr = "Invalid int";
        } else if (maxNumberOfFreebets <= 0 || maxNumberOfFreebets > 1000) {
            valid = false;
            maxNumberOfFreebetsErr = "Not in range 1-1000";
        }
    } else {
        valid = false;
        maxNumberOfFreebetsErr = "Required";
    }

    if (!state.validFrom.dateTime) {
        valid = false;
        validFromErr = "Required";
    }

    if (!state.validTo.dateTime) {
        valid = false;
        validToErr = "Required";
    }
    if (validFromErr.length <=0 && validToErr.length <= 0) {
        if (validTo < validFrom) {
            valid = false;
            validToErr = "Less than ValidTo";
        }
    }

    state.valid = valid;
    state.nameError = nameErr;
    state.freebetTypeIdError = freebetTypeErr;
    state.operatorIdError = operatorErr;
    state.maxNumberOfFreebetsError = maxNumberOfFreebetsErr;
    state.validFromError = validFromErr;
    state.validToError = validToErr;

    if (valid) {
        var item = state.item;
        var campaign : FreebetCampaign = {
            createdAt: item ? item.createdAt : new Date(),
            enabled: state.enabled,
            freebetTypeId: freebetTypeId,
            id: item ? item.freebetCampaignId : 0,
            maxNumberOfFreebets: maxNumberOfFreebets,
            name: state.name,
            operatorId: state.operatorId,
            validFrom: validFrom,
            validTo: validTo
        };
        state.value = campaign;

        var campaignUpdate : FreebetCampaignUpdate | undefined = { };
        var newCampaign = campaign;
        var changeCount = 0;
        if (item) {
            if (newCampaign.name !== item.freebetCampaignName) {
                campaignUpdate.name = newCampaign.name;
                changeCount++;
            }
            if (newCampaign.freebetTypeId !== item.freebetTypeId) {
                campaignUpdate.freebetTypeId = newCampaign.freebetTypeId;
                changeCount++;
            }
            if (newCampaign.operatorId !== item.operatorId) {
                campaignUpdate.operatorId = newCampaign.operatorId;
                changeCount++;
            }
            if (newCampaign.maxNumberOfFreebets !== item.maxNumberOfFreebets) {
                campaignUpdate.maxNumberOfFreebets = newCampaign.maxNumberOfFreebets;
                changeCount++;
            }
            if (newCampaign.validFrom !== item.validFrom) {
                campaignUpdate.validFrom = newCampaign.validFrom;
                changeCount++;
            }
            if (newCampaign.validTo !== item.validTo) {
                campaignUpdate.validTo = newCampaign.validTo;
                changeCount++;
            }
            if (newCampaign.enabled !== item.enabled) {
                campaignUpdate.enabled = newCampaign.enabled;
                changeCount++;
            }
             if (changeCount <= 0) {
                campaignUpdate = undefined;
            }
        } else {
            campaignUpdate.name = newCampaign.name;
            campaignUpdate.freebetTypeId = newCampaign.freebetTypeId;
            campaignUpdate.operatorId = newCampaign.operatorId;
            campaignUpdate.maxNumberOfFreebets = newCampaign.maxNumberOfFreebets;
            campaignUpdate.validFrom = newCampaign.validFrom;
            campaignUpdate.validTo = newCampaign.validTo;
            campaignUpdate.enabled = newCampaign.enabled;
        }
        state.update = campaignUpdate;
    } else {
        state.value = undefined;
        state.update = undefined;
    }
}
function updateState(state: EditFreebetCampaignState, item: FreebetOverviewItem) {
    state.item = item;
    state.id = item.freebetCampaignId;
    state.name = item.freebetCampaignName;
    state.freebetTypeId = item.freebetTypeId;
    state.operatorId = item.operatorId;
    state.maxNumberOfFreebets = item.maxNumberOfFreebets.toString();
    state.validFrom = getDateTimePickerXValue(item.validFrom);
    state.validTo = getDateTimePickerXValue(item.validTo);
    state.enabled = item.enabled;
    checkState(state);

}
function getDefaultValidFrom() : Date {
    var validFrom = CreateRoundUtil.addHours(new Date(), 1);
    var roundedValidFrom = CreateRoundUtil.getDateWithoutTime(validFrom);
    var minutes = validFrom.getMinutes();
    if (minutes === 0){
      roundedValidFrom = CreateRoundUtil.addHours(roundedValidFrom, validFrom.getHours());
    } else {
      roundedValidFrom.setHours(validFrom.getHours() + 1, 0);
    }
    return roundedValidFrom;
}
function getDefaultValidTo(validFrom: Date) : Date {
    return CreateRoundUtil.addDays(validFrom, 7);
}
function getInitialState() : EditFreebetCampaignState {
    var validFrom = getDefaultValidFrom();
    var validTo = getDefaultValidTo(validFrom);
    var state : EditFreebetCampaignState = {
        id: 0,
        name: "",
        freebetTypeId: 0,
        operatorId: "",
        maxNumberOfFreebets: "10",
        validFrom: getDateTimePickerXValue(validFrom),
        validTo: getDateTimePickerXValue(validTo),
        enabled: true,
        valid: false,
        nameError: "",
        freebetTypeIdError: "",
        operatorIdError: "",
        maxNumberOfFreebetsError: "",
        validFromError: "",
        validToError: "" 
    };
    checkState(state);
    return state;
}

interface FreebetTypeItemState extends BaseItemState<FreebetType, number> {
    displayItems: FreebetType[],
    success: boolean;
    message: string;
}

const EditFreebetCampaign: React.FC = (props) => {
    const location = useLocation();
    const history = useHistory();    
   
    const [formState, setFormState] = useState<EditFreebetCampaignState>(getInitialState());
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [initErr, setInitErr] = useState<string>("");
    const [refreshing, setRefreshing] = useState<boolean>(false);
    // const [freebetTypeItemState, setFreebetTypeItemState] = useState<FreebetTypeItemState>({
    //     items: [],
    //     lastId: 0,
    //     more: false,
    //     refreshing: false,
    //     displayItems: [],
    //     success: true,
    //     message: "Not refreshed"  
    // });
    // const [freebetTypes, setFreebetTypes] = useState<FreebetType[]>([]);
    const [freebetEnabledOperators, setFreebetEnabledOperators] = useState<Map<string, boolean>>(new Map<string, boolean>());
    

    useEffect(() => {
        initData().then(result => {
        });
    }, []);
    const compareOperatorSetting = (x: OperatorSetting, y: OperatorSetting) : number => {
        var comp = x.operatorId.localeCompare(y.operatorId);
        if (comp !== 0) {
            return comp;
        }
        comp = x.settingName.localeCompare(y.settingName);
        if (comp !== 0) {
            return comp;
        }
        comp = x.validFrom.getDate() - y.validFrom.getDate();
        if (comp !== 0) {
            return comp;
        }
        return x.id - y.id;
    }
    const initData = async () : Promise<number> => {
        var initCount = 0;
        setInitErr("Initializing...");
        setRefreshing(true);
        try {   
            const freebetEnabledSetting = "FreebetEnabled";
            var opSettingsResponse = await AdminUtil.getOperatorSettingsByName(freebetEnabledSetting, 100);
            if (opSettingsResponse.success) {
                if (opSettingsResponse.item && opSettingsResponse.item.items) {
                    var sortedOpSettings = opSettingsResponse.item.items.filter(it => it.settingName === freebetEnabledSetting);
                    sortedOpSettings = sortedOpSettings.sort(compareOperatorSetting);
                    var opFreebetEnableds : Map<string, boolean> = new Map<string, boolean>();
                    for (var opSetting of sortedOpSettings) {
                        if (opSetting.settingName !== freebetEnabledSetting) {
                            continue;
                        }
                        var enabled = CreateRoundUtil.toBoolean(opSetting.settingValue, false);
                        opFreebetEnableds.set(opSetting.operatorId, enabled);
                    }
                    setFreebetEnabledOperators(opFreebetEnableds);
                }
                initCount++;
            } else {
                setInitErr(`Init FreebetEnabledOperators error: ${opSettingsResponse.message}'.`);
                return initCount;
            }

            var pos = location.pathname.lastIndexOf("/");
            if (pos <= 0) {
                setInitErr("");
                return initCount;
            }
            var idString = location.pathname.substring(pos + 1);
            var id = parseInt(idString);
            if (isNaN(id) || id <= 0) {
                setInitErr(`FreebetCampaignId param is invalid '${idString}'.`);
                return initCount;
            }
            var fs = {...formState, id: id};
            var resp = await FreebetUtil.getFreebetOverview(id);
            if (resp.success) {
                setInitErr("");
                initCount++;
            } else {
                setInitErr(`Init error: ${resp.message}`);
                return initCount;
            }
            var freebetOverview = resp.item;
            if (freebetOverview) {
                updateState(fs, freebetOverview); 
            } else {
                setInitErr("Freebet campaign not found.")
            }
            setFormState(fs);
        } catch (error) {
            console.error("Init error.", error);
            setInitErr(`Init error: ${error}`);
        } finally {
            setRefreshing(false);
        }

        return initCount;
    }
    const handleChange = (name: string, value:any) => {
        var fs = {...formState, [name]: value};
        checkState(fs);
        setFormState(fs);
    }
    const onOperatorChange = (item?: Operator) : void => {
        var fs = {...formState, ["operatorId"]: item ? item.id : ""};
        checkState(fs);
        setFormState(fs);
    }
    const onFreebetTypeChange = (item?: FreebetType) : void => {
        var fs = {...formState, ["freebetTypeId"]: item ? item.id : 0};
        checkState(fs);
        setFormState(fs);
    }
    const onValidFromChange = (value: DateTimePickerXValue) : void => {
        var fs = {...formState, ["validFrom"]: value};
        checkState(fs);
        setFormState(fs);
    }
    const onValidToChange = (value: DateTimePickerXValue) : void => {
        var fs = {...formState, ["validTo"]: value};
        checkState(fs);
        setFormState(fs);
    }
    const isFreebetEnabled = (item: Operator) : boolean => {
        var enabled = freebetEnabledOperators.get(item.id);
        if (enabled) {
            return true;
        }
        return false;
    }
    const onSave = () => {
        const METHOD_NAME = "SaveFreebetCampaign";
        
        setSaveError("");
        setSaveStatus(0);
        if (!formState.valid) {
            setSaveError("Input is not valid");
            return;
        }
        if (!formState.update) {
            setSaveError("No changes");
            return;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify(formState.update);
        FreebetUtil.saveFreebetCampaign(formState.id, saveJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var item = resp.item;
                if (item) {
                    if (formState.id === 0) {
                        history.push(`editFreebetCampaign/${item.freebetCampaignId}`);
                        return;
                    }
                    var fs = {...formState, id: item.freebetCampaignId};
                    updateState(fs, item);
                    setFormState(fs);
                    return;
                } 
                setSaveError(`${METHOD_NAME} failure: FreebetCampaign was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var saveEnabled = false;
    var freebetOverview = formState.item;
    if (formState.valid && formState.update && !refreshing && saveStatus === 0) {
        saveEnabled = true;
    }
    var initErrorBox = null;
    if (initErr && initErr.length) {
        initErrorBox = (<Box marginTop={3} color="red">{initErr}</Box>);
        saveEnabled = false;
    }

    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    // var defaultValidFrom = formState.validFrom ? formState.validFrom : getDefaultValidFrom();
    // var defaultValidTo = formState.validTo ? formState.validTo : getDefaultValidTo(defaultValidFrom);
    var title = freebetOverview ? `Edit Freebet Campaign #${freebetOverview.freebetCampaignId}` : "Create Freebet Campaign";
    return (
        <Container maxWidth="xl">
            <Title title={title} />
            <Box>
                <TextField
                    label="Id"
                    name="id"
                    value={formState.id}
                    helperText={formState.item ? `CreatedAt: ${CreateRoundUtil.toGameDateString(formState.item.createdAt)}` : ""}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    autoFocus
                    label="Name"
                    name="name"
                    value={formState.name}
                    onChange={e => handleChange("name", e.target.value)}
                    helperText={formState.nameError}
                    error={formState.nameError.length > 0}
                    style={{width:400}}
                />
            </Box>
            <FreebetTypeSelect value={formState.freebetTypeId}
                onChange={onFreebetTypeChange}
                allItemEnabled={false}
                helperText={formState.freebetTypeIdError}
                error={formState.freebetTypeIdError.length > 0}
            />
            <OperatorSelect value={formState.operatorId}
                onChange={onOperatorChange}
                filter={it => isFreebetEnabled(it)}
                allItemEnabled={false}
                helperText={formState.operatorIdError}
                error={formState.operatorIdError.length > 0}
            />
            <Box marginTop={1}>
                <TextField
                    margin="dense"
                    name="maxNumberOfFreebets"
                    label="Max number of Freebets"
                    value={formState.maxNumberOfFreebets}
                    type="number"
                    onChange={e => handleChange("maxNumberOfFreebets", e.target.value)}
                    helperText={formState.maxNumberOfFreebetsError}
                    error={formState.maxNumberOfFreebetsError.length > 0}
                />
            </Box>
            <Box marginTop={1} display="flex" alignItems="center">
                <DateTimePickerX 
                    label="Valid from"
                    value={formState.validFrom}
                    onChange={onValidFromChange}
                /><span style={{width: 20}} />
                <DateTimePickerX 
                    label="Valid to"
                    value={formState.validTo}
                    onChange={onValidToChange}
                    errorText={formState.validToError}
                />
            </Box>
            <Box>
            <FormControlLabel control={<Switch
                    checked={formState.enabled}
                    onChange={(e:any) => handleChange("enabled", !formState.enabled)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />}
                label="Enabled"
            />
            </Box>
            {initErrorBox} 
            <Box marginTop={2}>
                <Button onClick={e => onSave()} color="primary" variant="contained" disabled={!saveEnabled}>
                    Save
                </Button>
            </Box>
            {saveErrorBox} 
            <Box>
            <Button
                    component={RouterLink}
                    color="secondary"
                    size="small"
                    style={{textTransform:"none"}}
                    to={"/freebetOverview"}
                    >
                    Goto Freebet Overview
                </Button>&nbsp;

            </Box>
        </Container>
    );
};

export default EditFreebetCampaign;
