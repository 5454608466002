import React, {useState} from "react";
import {FieldTitle, InputHelperText, ResettableTextField} from 'react-admin';
import {InputAdornment} from '@material-ui/core';
import {Field} from 'react-final-form';
import Decimal from 'decimal.js';
import isEmpty from 'lodash/isEmpty';

const safeParseDecimal = v => {
  if (isEmpty(v)) {
    return new Decimal("0")
  }
  try {
    return new Decimal(v);
  } catch (e) {
    console.log(e);
    return null;
  }
}

const PercentageField = ({input: {name, onChange, value}, meta: {error, touched}, label, resource, isRequired, helperText}) => {
  let safeValue = safeParseDecimal(value);
  if (safeValue !== null) {
    safeValue = safeValue.mul(new Decimal("100"));
  }

  const [fieldValue, setFieldValue] = useState(safeValue || value);

  const textFieldOnChange = (e) => {
    const newValue = e.target.value;
    setFieldValue(newValue);

    const decimalValue = safeParseDecimal(newValue);
    if (decimalValue !== null) {
      onChange(decimalValue.div(new Decimal("100")).toString());
    }
  }

  if (safeParseDecimal(fieldValue) === null) {
    error = "invalid value"
    touched = true
  }

  return <ResettableTextField variant={'filled'}
                              label={
                                label !== '' &&
                                label !== false && (
                                  <FieldTitle
                                    label={label}
                                    source={name}
                                    resource={resource}
                                    isRequired={isRequired}
                                  />
                                )
                              }
                              error={!!(touched && error)}
                              helperText={
                                <InputHelperText
                                  touched={touched}
                                  error={error}
                                  helperText={helperText}
                                />
                              }
                              InputProps={{
                                onChange: textFieldOnChange,
                                value: fieldValue,
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}/>
}

//    }} label={label} source={source} format={v => new Decimal(isEmpty(v) || "0").mul(new Decimal("100"))} parse={v => new Decimal(v).div(new Decimal("100")).toString()}/>

const PercentageInput = ({source, label}) =>
  <Field name={source} component={PercentageField} label={label}/>

export default PercentageInput;