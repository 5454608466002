import React, { useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Link } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import TeamChooser from "./TeamChooser";
import { Team } from "../RoundOverview/RoundOverviewModels";

interface ChooseTeamDialogProps {
    open: boolean;
    openLabel?: string;
    onClose: (team: Team | undefined) => void;
}
interface ChooseTeamDialogState {
    teamId: string;
    team: Team | undefined;
}


const ChooseTeamDialog: React.FC<ChooseTeamDialogProps> = (props) => {
    const getDefaultFormState = () : ChooseTeamDialogState => {
        return {
            teamId: "",
            team: undefined
        };
    }
    
    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<ChooseTeamDialogState>(getDefaultFormState());
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
 
    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        setFormState(fs);
    }
    const onTeamChange = (team: Team | undefined) =>{
        var teamId = team ? team.id : "";
        setFormState({ 
            teamId: teamId,
            team: team
        });
    }

    const onOk = () => {
        const methodName = "OnOK";
        setSaveError("");
        if (!formState.teamId || formState.teamId.length <= 0) {
            setSaveError("Leauge is not specified.");
            return;
        }
        if (!formState.team) {
            setSaveError("Leauge is not set.");
            return;
        }
        setOpen(false);
        if (props.onClose) {
            props.onClose(formState.team);
        }           
    };

    var valid = true;
    var teamIdErr = "";
    if (formState.teamId === ""){
        valid = false;
        teamIdErr = "Required";
    } else if (!formState.team) {
        valid = false;
        teamIdErr = "Team is not set";
    }

    var saveEnabled = (valid && saveStatus === 0);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    var openLabel = props.openLabel && props.openLabel.length > 0 ? props.openLabel : "Choose Team";
    var openButton = (<Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    {openLabel}
            </Button>);

    return (
        <div>
            {openButton}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Choose Team</DialogTitle>
            <DialogContent dividers>
                <TeamChooser onChange={onTeamChange} />
                {saveErrorBox} 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onOk} color="primary" disabled={!saveEnabled}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default ChooseTeamDialog;
