import {TextInput} from "react-admin";
import React from "react";
import {
    InputAdornment
} from '@material-ui/core';

const UnitsInput = ({source, label, ...props}) =>
    <TextInput options={{
        InputProps:{
            endAdornment: <InputAdornment position="end">units</InputAdornment>,
        }
    }} label={label} source={source} {...props}/>

export default UnitsInput;