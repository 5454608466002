import firebase from './firebase'
import {getCurrentUser} from "./apiToken";

const authProvider =  {
    login: async ({user }) => {
        return Promise.resolve();
    },
    logout: () => {
        return firebase.then(f => {
            f.auth().signOut();

            return Promise.resolve();
        })
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return authProvider.logout().then(() => Promise.reject())
        }

        return Promise.resolve();
    },
    checkAuth: () => {
        return firebase.then(f => getCurrentUser(f.auth())).then(user =>
          user != null ? Promise.resolve() : Promise.reject()
        )
    },

    getPermissions: () => {
        return Promise.resolve([]);
    },

    getIdentity: () => {
        return firebase.then(f => {
            if (!f) {
                return {}
            }

            return getCurrentUser(f.auth()).then(user => {
                if (user) {
                    return ({ uid: user.uid, fullName: user.displayName, avatar: user.photoURL })
                } else {
                    return {}
                }
              });
        })
    }
};


export default authProvider;
