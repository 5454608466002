import * as React from 'react';
import PropTypes from 'prop-types';
import CompositeForm from "./CompositeForm";

const HorizontalForm = props =>
  <CompositeForm style={{display: 'flex', flexDirection: 'row', flex: '1 0 auto', justifyContent: 'flex-start'}} {...props}/>;

HorizontalForm.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string,
    addLabel: PropTypes.bool
};

HorizontalForm.defaultProps = {
    addLabel: false
}
HorizontalForm.displayName = 'HorizontalForm';

export default HorizontalForm;