export class TimeReport 
    {
        timeDict:{ [key: number]: TimeReportItem } = {};
        bookmakerLookup: { [key: string]: string } = {};
        numberOfBookmakers: number =0;
        stake: number =0;
        result: number =0;
        count: number =0;

        addBet(timeIndex:number, timeUnitName:string, bookmaker: string, stake: number, result: number) {
            this.stake += stake;
            this.result += result;
            this.count += 1;

            let x = this.bookmakerLookup[bookmaker];
            if (x == undefined) {
                this.numberOfBookmakers += 1;
                this.bookmakerLookup[bookmaker] = bookmaker;
            }

            let timeItem = this.timeDict[timeIndex]
            if (timeItem == undefined) {
                timeItem = new TimeReportItem(timeIndex, timeUnitName);
                this.timeDict[timeIndex] = timeItem;
            }
            timeItem.addBet(bookmaker, stake, result);
        }
    }

export class TimeReportItem 
    {
        dateIndex: number;
        stake: number =0;
        result: number =0;
        count: number =0;
        bookmakerDict: { [key: string]: BookmakerReportItem } = {};
        timeUnitName: string;

        constructor(dateIndex: number, timeUnitName:string) {
            this.dateIndex = dateIndex;
            this.timeUnitName = timeUnitName;
        }

        addBet(bookmaker: string, stake: number, result: number) {
            this.stake += stake;
            this.result += result;
            this.count += 1;

            let x = this.bookmakerDict[bookmaker];
            if (x == undefined) {
                x = new BookmakerReportItem(bookmaker, stake, result);
                this.bookmakerDict[bookmaker] = x;
            }
            else {
                x.stake += stake;
                x.result += result;
                x.count += 1;
            }
        }

    }

export class BookmakerReportItem
    {
        name: string;
        stake: number;
        result: number;
        count: number;

        constructor(name: string, stake: number, result: number) {
            this.name = name;
            this.stake = stake;
            this.result = result;
            this.count = 1;
        }
    }