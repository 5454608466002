import React, { useEffect, useState } from 'react';
import { Link, } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import { FixtureListItem } from '../CreateRound/CreateRoundModels';
import DataTable, { DataTableColumn } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';

interface FixtureTableProps {
    fixtures: FixtureListItem[];
    onSelect? : (item : FixtureListItem) => void;
}

const FixtureTable: React.FC<FixtureTableProps> = (props) => {
    const [selectedFixtureId, setSelectedFixtureId] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<FixtureListItem>[]>([]);
 
    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<FixtureListItem>[] = [
        { id: 'id', numeric: true, disablePadding: true, label: "Fixture Id", filterEnabled: true, renderValue: (item: FixtureListItem) => { return (<Link component={RouterLink} to={"fixtures/" + item.id}>{item.id}</Link>); } },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name', filterEnabled: true },
        { id: 'startingAt', numeric: false, disablePadding: false, label: 'Starting at', renderValue: (item: FixtureListItem) => { return CreateRoundUtil.toGameDateString(item.startingAt); } },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status', filterEnabled: true },
        { id: 'providerStatus', numeric: false, disablePadding: false, label: 'Provider status', filterEnabled: true },
        { id: 'score', numeric: false, disablePadding: false, label: 'Score', filterEnabled: true },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: FixtureListItem) => { return CreateRoundUtil.toGameDateString(item.createdAt); } },
      ];
      setColumns(cols);
    }
    const onSelect = (item : FixtureListItem) => {
        setSelectedFixtureId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    return (
      <DataTable columns={columns} items={props.fixtures} getId={it => it.id} orderBy='startingAt' order='asc' resultFilterEnabled={true} size='xsmall' onSelect={onSelect}  />
    );
  };
  
  export default FixtureTable;
 