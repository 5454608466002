import React from 'react';
import {
  Datagrid, TextField, DateField, List,
  ReferenceField, Edit, SimpleForm, ReferenceManyField, Pagination
} from 'react-admin';
import {RoundReferenceField} from "../rounds";
import RoundAmountField from "../rounds/RoundAmountField";
import RoundCoupon from "./RoundCoupon";
import {CouponShareGrid} from "../couponShares";
import {PlayerReferenceField} from "../players";

export const CouponGrid = props => (
  <Datagrid rowClick={'edit'} {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="operatorId" />
    <TextField source="operatorChannel" />
    <PlayerReferenceField source={"playerId"}/>
    <RoundReferenceField source="roundId" />
    <RoundAmountField source="totalStake" />
  </Datagrid>
)

export const CouponList = props => (
    <List sort={{ field: 'createdAt', order: 'desc'}} {...props}>
      <CouponGrid/>
    </List>
);

export const CouponDetails = ({record}) => {
  return record && <RoundCoupon coupon={record.roundCoupon}/>
}

export const CouponEdit = props =>
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <DateField source="createdAt" showTime/>
      <TextField source="operatorId" />
      <TextField source="operatorChannel" />
      <PlayerReferenceField source={"playerId"} addLabel={true}/>
      <RoundReferenceField source="roundId" addLabel={true}/>
      <RoundAmountField source="totalStake" />
      <ReferenceField source="id" reference="couponDetails" link={false} label={"Details"}>
        <CouponDetails/>
      </ReferenceField>
      <ReferenceManyField label={"Shares"} reference="couponShares" target={"couponId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
        <CouponShareGrid/>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>


export const CouponReferenceField = props =>
    <ReferenceField reference="coupons" {...props} >
        <TextField source="id" />
    </ReferenceField>;
