import {usePaginationState} from "ra-core/lib/controller";
import get from 'lodash/get';

const useReferenceManyFieldController = (
  props
) => {
  const {
    page: initialPage,
    perPage: initialPerPage,
    record,
    source,
  } = props;

  const records = get(record, source);
  // pagination
  //logic
  const { page, setPage, perPage, setPerPage } = usePaginationState({
    page: initialPage,
    perPage: initialPerPage,
  });

  const startOffset = Math.min(records.length, (page-1) * perPage);
  const endOffset = Math.min(records.length, page * perPage);

  const data = records.slice(startOffset, endOffset)
  const total = records.length;

  return {
    data,
    page,
    perPage,
    setPage,
    setPerPage,
    total,
  };
};

export default useReferenceManyFieldController;