import React from 'react';
import PropTypes from 'prop-types';
import {ListContextProvider} from 'ra-core';
import useArrayFieldController from "./useArrayFieldController";

export const PaginatedArrayField = props => {
  const {
    basePath,
    children,
    filter,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      '<PaginatedArrayField> only accepts a single child (like <Datagrid>)'
    );
  }

  const controllerProps = useArrayFieldController({
    basePath,
    filter,
    page,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  });

  return (
    <ListContextProvider value={controllerProps}>
      <ArrayFieldView {...props} {...controllerProps} />
    </ListContextProvider>
  );
};

PaginatedArrayField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  perPage: PropTypes.number,
  record: PropTypes.any,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
};

PaginatedArrayField.defaultProps = {
  perPage: 25,
  addLabel: true,
};

export const ArrayFieldView = props => {
  const { basePath, children, pagination, reference, ...rest } = props;
  return (
    <>
      {React.cloneElement(React.Children.only(children), {
        ...rest,
        basePath,
        resource: reference,
      })}
      {pagination &&
      props.total !== undefined &&
      React.cloneElement(pagination)}
    </>
  );
};

ArrayFieldView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  currentSort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.any,
  ids: PropTypes.array,
  loaded: PropTypes.bool,
  pagination: PropTypes.element,
  reference: PropTypes.string,
  setSort: PropTypes.func,
};

export default PaginatedArrayField;