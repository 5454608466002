import React from 'react';
import {
    Datagrid, TextField, List,
    ReferenceField, Show, SimpleShowLayout
} from 'react-admin';

import {SyndicateReferenceField} from "../syndicates";
import {CouponShareReferenceField} from "../couponShares";
import AmountField from '../../fields/AmountField';

export const SyndicateShareGrid = props => (
  <Datagrid rowClick={'show'} {...props}>
      <TextField source="id" />
      <SyndicateReferenceField source="syndicateId" addLabel={true}/>
      <TextField source="status" />
      <CouponShareReferenceField source="couponShareId" addLabel={true}/>
  </Datagrid>
)

export const SyndicateShareList = props => (
    <List sort={{ field: 'createdAt', order: 'desc'}} {...props}>
      <SyndicateShareGrid/>
    </List>
);

export const SyndicateShareShow = props =>
  <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <SyndicateReferenceField source="syndicateId" addLabel={true}/>
        <TextField source="status" />
        <CouponShareReferenceField source="couponShareId" addLabel={true}/>
      </SimpleShowLayout>
  </Show>

export const SyndicateShareReferenceField = props =>
    <ReferenceField reference="couponShares" {...props} >
        <TextField source="id" />
    </ReferenceField>;
