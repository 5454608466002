import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { InvokeApiOptions } from "./ExternalApiModels";
import ExternalApiUtil from "./ExternalApiUtil";
import { SignInMockopResult } from "./MockopApiModels";
import { GetPlayerInfoResult, PlaceBetsInput, PlaceBetsResult, PlayerRound, PlayerRoundResult, SignInPlayerResult } from "./PlayerApiModels";

class PlayerApiUtil {
    static async signInPlayerApi(mockopSignIn: SignInMockopResult): Promise<GenericResponse<SignInPlayerResult>> {
        const METHOD_NAME = "PlayerApiUtil.signInPlayerApi";
        try {
            var baseUrl = process.env["REACT_APP_PLAYER_API_URL"];
            var url = baseUrl + `/oauth/token?operatorId=MOCKOP&operatorChannel=online&operatorPlayerId=${mockopSignIn.playerId}&operatorGameId=poolx`;
            var formCode = "9SIuMMl2Z5UGkzsi";
            var playerAuthData = `------WebKitFormBoundary${formCode}\nContent-Disposition: form-data; name="grant_type"\n\nurn:ietf:params:oauth:grant-type:token-exchange\n------WebKitFormBoundary${formCode}\nContent-Disposition: form-data; name="subject_token"\n\n${mockopSignIn.token}\n------WebKitFormBoundary${formCode}--`;
            var invokeOptions: InvokeApiOptions = {
                method: "POST",
                body: playerAuthData
            };
            invokeOptions.headers = new Headers();
            invokeOptions.headers.set("Content-Type", `multipart/form-data; boundary=----WebKitFormBoundary${formCode}`);

            var invokeResp = await ExternalApiUtil.invokeApi(url, invokeOptions);
            if (!invokeResp.success) {
                return CreateRoundUtil.createGenericResponse<SignInPlayerResult>(false, invokeResp.message, null);
            }
            return CreateRoundUtil.createGenericResponse<SignInPlayerResult>(true, "", invokeResp.json);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<SignInPlayerResult>(false, METHOD_NAME + " error: " + error, null);
        }

    };
    static async getPlayerInfo(playerToken: string): Promise<GenericResponse<GetPlayerInfoResult>> {
        const METHOD_NAME = "PlayerApiUtil.getPlayerInfo";
        try {
            var baseUrl = process.env["REACT_APP_PLAYER_API_URL"];
            var url = baseUrl;
            if (!url) {
                return CreateRoundUtil.createGenericResponse<GetPlayerInfoResult>(false, METHOD_NAME + " failure: base url not specified.", null);
            }
            var invokeOptions: InvokeApiOptions = {
                method: "GET"
            };
            invokeOptions.headers = new Headers();
            invokeOptions.headers.set("Authorization", `Bearer ${playerToken}`);

            var invokeResp = await ExternalApiUtil.invokeApi(url, invokeOptions);
            if (!invokeResp.success) {
                return CreateRoundUtil.createGenericResponse<GetPlayerInfoResult>(false, invokeResp.message, null);
            }
            return CreateRoundUtil.createGenericResponse<GetPlayerInfoResult>(true, "", invokeResp.json);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<GetPlayerInfoResult>(false, METHOD_NAME + " error: " + error, null);
        }

    };

    static async getPlayerRound(roundId: string, playerToken: string): Promise<GenericResponse<PlayerRoundResult>> {
        const METHOD_NAME = "PlayerApiUtil.getPlayerRound";
        try {
            var baseUrl = process.env["REACT_APP_PLAYER_API_URL"];
            var url = baseUrl + "/rounds/" + roundId;
            if (!url) {
                return CreateRoundUtil.createGenericResponse<PlayerRoundResult>(false, METHOD_NAME + " failure: base url not specified.", null);
            }
            var invokeOptions: InvokeApiOptions = {
                method: "GET"
            };
            invokeOptions.headers = new Headers();
            invokeOptions.headers.set("Authorization", `Bearer ${playerToken}`);

            var invokeResp = await ExternalApiUtil.invokeApi(url, invokeOptions);
            if (!invokeResp.success) {
                return CreateRoundUtil.createGenericResponse<PlayerRoundResult>(false, invokeResp.message, null);
            }
            return CreateRoundUtil.createGenericResponse<PlayerRoundResult>(true, "", invokeResp.json);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<PlayerRoundResult>(false, METHOD_NAME + " error: " + error, null);
        }

    };

    //playerId: poolx internal playerid, not operator playerid
    //seed: set to true to disable throttling (prevent frequent bets)
    static async placeBets(input: PlaceBetsInput, playerId: string, playerToken: string, seed: boolean): Promise<GenericResponse<PlaceBetsResult>> {
        const METHOD_NAME = "PlayerApiUtil.placeBets";
        try {
            var baseUrl = process.env["REACT_APP_PLAYER_API_URL"];
            var url = baseUrl + `/players/${playerId}/coupons`;
            if (seed){
                url = url + "?seed=true";
            }
            var body = JSON.stringify(input);

            var invokeOptions: InvokeApiOptions = {
                method: "POST",
                body: body
            };
            invokeOptions.headers = new Headers();
            invokeOptions.headers.set("Authorization", `Bearer ${playerToken}`);

            var invokeResp = await ExternalApiUtil.invokeApi(url, invokeOptions);
            if (!invokeResp.success) {
                return CreateRoundUtil.createGenericResponse<PlaceBetsResult>(false, invokeResp.message, null);
            }
            return CreateRoundUtil.createGenericResponse<PlaceBetsResult>(true, "", invokeResp.json);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<PlaceBetsResult>(false, METHOD_NAME + " error: " + error, null);
        }

    };

}

export default PlayerApiUtil;