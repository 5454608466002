import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import SettleRoundFixtureRow from './SettleRoundFixtureRow';
import { SettleFixtureInput, SettleFixtureItem } from './SettleRoundModels';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

interface SettleRoundFixtureTableProps {
    items: SettleFixtureItem[];
    check: boolean;
    onChange?: (item: SettleFixtureInput) => void; 
}

const SettleRoundFixtureTable: React.FC<SettleRoundFixtureTableProps> = (props) => {
    const classes = useStyles();
  
    const onChange = (input : SettleFixtureInput) => {
        if (props.onChange){
            props.onChange(input);
            return;
        }
        alert("SettleRoundFixtureTable.onChange property not set.");
    };
    
    return (
        <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size='small' >
          <TableHead>
            <TableRow>
              <TableCell>Game no</TableCell>
              <TableCell>Game</TableCell>
              <TableCell width={100}>Result</TableCell>
              <TableCell>Sign</TableCell>
              <TableCell>Valid</TableCell>
              <TableCell>...</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((row) => (
                <SettleRoundFixtureRow item={row} onChange={onChange} check={props.check} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    );
  };
  
  export default SettleRoundFixtureTable;
  


  