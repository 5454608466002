import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: '1.5em',
    border: '1px solid',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  selected: {
    background: theme.palette.primary.main,
    color: 'white',
  }
}))

const SignBox = ({className, sign, value, onChange}) => {
  const classes = useStyles();

  return <div onClick={e => {
    e.stopPropagation()
    onChange(!value)
  }} className={clsx(className, classes.root, {
    [classes.selected]: value,
  })}>
    {sign}
  </div>
}

export default SignBox;
