import React, {useCallback, useState} from 'react';
import {
    Create, Edit, SimpleForm, Datagrid, TextField, DateField, List, SelectField, TabbedForm, FormTab,
    NumberField, SelectInput, ArrayInput, SimpleFormIterator, ReferenceField, ReferenceManyField, Pagination,
    Filter, TextInput, CheckboxGroupInput, Link, FormDataConsumer, useRefresh, useNotify, BooleanInput,
} from 'react-admin';
import { addDays } from 'date-fns';
import {RoundTypeParametersInput, RoundTypeReferenceField, RoundTypeReferenceInput} from "../roundTypes";
import HorizontalForm from "../../components/HorizontalForm";
import {FixtureReferenceField, FixtureReferenceInput, LocalFixtureFilter} from "../fixtures";
import {AccountTransactionGrid} from "../accountTransactions";
import CompositeForm from "../../components/CompositeForm";
import { makeStyles } from '@material-ui/core/styles';
import SignField from "../../sign/SignField";
import {Field} from "react-final-form";
import {useHistory} from "react-router-dom";
import Outcome from "../../sign/Outcome";
import AdjustBalanceButton from "./AdjustBalanceButton";
import TransferBalanceButton from "./TransferBalanceButton";
import VerboseSelectInput from "../../components/VerboseSelectInput";
import {DateTimeInput} from "../../date-inputs";
import PeriodInput from "../../components/PeriodInput";
import Amount from "../../components/Amount";
import get from 'lodash/get';
import {CouponGrid} from "../coupons";
import {CouponShareGrid} from "../couponShares";
import {SettlementGrid} from "../settlements";
import ScheduleSettlementButton from "./ScheduleSettlementButton";
import {DrawReferenceField} from "../draws";
import {AuditLogGrid} from "../auditLogs";
import {RoundPromotionValuesGrid,} from "../roundPromotionValues";
import Button from "@material-ui/core/Button";
import fetchApi from "../../fetchApi";
import { useCreatePath } from 'react-admin';



const statusChoices = [
        { id: 'PENDING', name: 'Pending', desc: 'Fully editable and in progress, not visible anywhere'},
        { id: 'ACTIVE', name: 'Active', desc: 'Activated and purchases may be made if open, no settings may be changed'},
        { id: 'VOIDED', name: 'Voided', desc: 'No more transactions except cancellations of coupon shares will be allowed, visible if forced'},
        { id: 'PAUSED', name: 'Paused', desc: 'Activated but transactions are temporarily disabled, visible if forced'},
        { id: 'FINISHED', name: 'Finished', desc: 'Finished and will be settled as soon as possible'},
        { id: 'CANCELED', name: 'Canceled', desc: 'Discarded and not shown'},
];

const RoundStatusInput = props =>
    <VerboseSelectInput {...props} choices={statusChoices} />;

const MultipleRoundStatusInput = props =>
  <CheckboxGroupInput {...props} choices={statusChoices} />;



const RoundStatusField = props =>
    <SelectField {...props} choices={statusChoices} />;

const updateModeChoices = [
  { id: 'AUTOMATIC', name: 'Automatic', desc: 'Results will be automatically updated when fixtures are updated'},
  { id: 'MANUAL', name: 'Manual', desc: 'Results are updated manually only'},
];

const RoundUpdateModeInput = props =>
  <VerboseSelectInput {...props} choices={updateModeChoices}/>;

const fixtureResultStatusChoices = [
  { id: 'PENDING', name: 'Pending'},
  { id: 'FINISHED', name: 'Finished'},
];

const FixtureResultStatusInput = props =>
  <SelectInput {...props} choices={fixtureResultStatusChoices} />;

const FixtureResultStatusField = props =>
  <SelectField {...props} choices={fixtureResultStatusChoices} />;

const RoundFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <MultipleRoundStatusInput label={'Status'} source={"status"} alwaysOn/>
  </Filter>
);
export const RoundCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <RoundTypeReferenceInput source={"roundTypeId"}/>
        </SimpleForm>
    </Create>
);

const useStyles = makeStyles({
  fixture: {
    display: 'block',
    minWidth: '32em'
  },
  fixtureResult: {
    display: 'block',
    minWidth: '20em'
  },
  fixtureDraw: {
    display: 'block',
    marginLeft: '4em',
  },
  balance: {
    width: '12em'
  }
});

const FixtureResultField = ({className, input: {name, value}}) =>
  <div className={className} style={{display: 'flex'}}>
    <Field component={Outcome} name={`${name}.sign`}/>
    <FixtureResultStatusField source={"status"} record={value}/>
  </div>;

const FixtureResultInput = ({className, input: {name}}) =>
  <div className={className} style={{display: 'flex'}}>
    <Field component={SignField} name={`${name}.sign`}/>
    <div style={{width: '16px'}}/>
    <Field component={FixtureResultStatusInput} name={`${name}.status`} options={{margin: 'none', variant: 'outlined', size: 'small'}} label={false}/>
  </div>;

const FixtureListForm = ({className, resultComponent, ...props}) => {
  const classes = useStyles();

  const record = props.record;
  if (!record) return null;


  return <div>
    <div style={{display: 'flex'}}>
      <b className={classes.fixture}>Fixture</b>
      <b className={classes.fixtureResult}>Result</b>
      <b className={classes.fixtureDraw}>Draw</b>
    </div>
    {record.fixtures.map((fixture, index) =>
      <CompositeForm style={{display: 'flex'}} key={fixture.fixtureId} {...props}>
        <FixtureReferenceField className={classes.fixture} source={`fixtures[${index}].fixtureId`}/>
        <Field className={classes.fixtureResult} component={resultComponent} name={`fixtureResults[${index}]`}/>
        <DrawReferenceField className={classes.fixtureDraw} source={`fixtureResults[${index}].drawId`} allowEmpty/>
      </CompositeForm>
    )}
  </div>

}

const useRedirectToPageWithState = () => {
    const history = useHistory();
    return useCallback((pathname, state) => {
        history.push({
            pathname,
            state
        })
    }, [history]);
}

const StatusBasedFields = props => {
  const {fixtureFilterInitial} = props;
  const [fixtureFilter, setFixtureFilter] = useState(fixtureFilterInitial);
  const history = useHistory();
  const redirect = useRedirectToPageWithState();
  const record = props.record;
  if (!record) return null;
  const status = record.status;


    switch (status) {
      case 'PENDING':
        return (
         <div>
            <>
                <CompositeForm {...props} className={null}>
                    <LocalFixtureFilter setFilters={setFixtureFilter} label={'Fixture filter'} addLabel={true} fullWidth initialValues={fixtureFilterInitial}/>
                    <ArrayInput source={`fixtures`} label={'Fixtures'}>
                        <SimpleFormIterator>
                            <FixtureReferenceInput source="fixtureId" label={'Fixture'} filter={fixtureFilter}/>
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    if (!scopedFormData || !formData) {
                                        return <></>;
                                    }

                                    const fixtureId = scopedFormData.fixtureId;
                                    const draws = formData.draws;
                                    for (let i = 0; i < draws.length; i ++) {
                                        const ddr = draws[i];
                                        if (!ddr) {
                                            return <></>;
                                        }

                                        if (ddr.fixtureId === fixtureId) {
                                            return (
                                             <div>
                                                 <Button type={'button'} onClick={() => history.push(`/draws/${ddr.id}`)} style={{margin: '-8px 8px 8px 0'}} variant={'outlined'}>Show Draw</Button>
                                                 <Button type={'button'} onClick={() => redirect('/fixtureInformationValues/create', {fixtureId: fixtureId, roundId: formData.id})} style={{margin: '-8px 0 8px 0'}} variant={'outlined'}>Show Fixture Information Values</Button>
                                             </div>
                                            );
                                        }
                                    }

                                    return <></>;
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </CompositeForm>
            </>
         </div>
         );
      case 'PAUSED':
      case 'ACTIVE':
        return <FixtureListForm resultComponent={FixtureResultInput} {...props}/>;
      default:
        return <FixtureListForm resultComponent={FixtureResultField} {...props}/>;
    }
}

const AccountingAmount = ({amount, label}) => {
  return <span>{label && `${label}: `}{amount.units} (<Amount amount={amount.money}/>) </span>;
}

const PrizePlanField = ({record, source}) => {
  const prizePlan = get(record, source)
  if (!prizePlan) {
    return null;
  }

  return <CompositeForm>
    <table>
      {prizePlan.groups.map((g, index) => <tr key={index}>
        <td style={{width: '4em'}}>{record.parameters.nFixtures - index}</td>
        <td style={{whiteSpace: 'nowrap'}}><AccountingAmount amount={g.payout}/></td>
        <td style={{whiteSpace: 'nowrap'}}>({g.rowsUnits} rows)</td>
      </tr>)}
    </table>
    <AccountingAmount amount={prizePlan.nextExtra} label={'To next round'}/>
    <AccountingAmount amount={prizePlan.nextExtraTop} label={'To next round top winner'}/>
  </CompositeForm>
}

const CreateRoundPromotionValueButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/roundPromotionValues/create',
            state: { record: { roundId: record.id } },
        }}
    >
        Add Round Promotion Value
    </Button>
);


export const RoundEdit = props => {
    const classes = useStyles();
    const now = new Date();
    const refresh = useRefresh();
    const notify = useNotify();
    const [cancelAllButtonVisible, setCancelAllButtonVisible] = useState(true);
    const history = useHistory();

    // Needs to be outside form, otherwise generates infinite render loop
    const fixtureFilterInitial = {status: "PENDING", fromAt: now, toAt: addDays(now, 7)};

    return <Edit undoable={false} {...props}>
        <TabbedForm>
            <FormTab label={"General"}>
                <TextField source="id" />
                <DateField source="createdAt" showTime/>
                <DateField source="finishedAt" showTime/>
                <RoundStatusInput source="status"/>
                <PeriodInput from={"openFrom"} to={"openTo"} label={'Open for purchase'}/>
                <PeriodInput from={"publishedFrom"} to={"publishedTo"} label={'Published on microsite'}/>
                <BooleanInput label={'Defines if round is visible to players'} source={'visibleToPlayers'}/>
                <DateTimeInput  label={"Schedule to finish at"} source={"scheduledFinishedAt"} options={{ clearable: true }}/>
                <RoundUpdateModeInput source={"resultUpdateMode"} addLabel={true}/>
                <StatusBasedFields fixtureFilterInitial={fixtureFilterInitial}/>
                <ReferenceManyField label="Round Promotion Values" reference="roundPromotionValues" target="roundId" sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>}>
                    <RoundPromotionValuesGrid/>
                </ReferenceManyField>
                <CreateRoundPromotionValueButton />
            </FormTab>
            <RoundTypeParametersInput label={"Parameters"} source={"parameters"} inEditView={true}/>
            <FormTab label={'Accounting'}>
              <TextField label={'Status'} source={'accountingStatus'}/>
              <PrizePlanField addLabel={true} label={'Prize Plan'} source={'prizePlan'}/>
              <NumberField className={classes.balance} source={"balances.POOL"} label={'Pool'}/>
              <CompositeForm style={{display: 'flex'}}>
                <NumberField className={classes.balance} source={"balances.FUND"} label={'Fund'}/>
                <AdjustBalanceButton balance={'FUND'}/>
                <TransferBalanceButton balance={'FUND'}/>
              </CompositeForm>
              <HorizontalForm>
                <NumberField className={classes.balance} source={"balances.EXTRA"} label={'Extra'}/>
                <AdjustBalanceButton balance={'EXTRA'}/>
                <TransferBalanceButton balance={'EXTRA'}/>
              </HorizontalForm>
              <HorizontalForm>
                <NumberField className={classes.balance} source={"balances.EXTRA_TOP"} label={'Extra to all correct'}/>
                <AdjustBalanceButton balance={'EXTRA_TOP'}/>
                <TransferBalanceButton balance={'EXTRA_TOP'}/>
              </HorizontalForm>
              <HorizontalForm label={'Balances'}>
              </HorizontalForm>
              <ReferenceManyField label="Transactions" reference="accountTransactions" target="roundId" sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>}>
                <AccountTransactionGrid/>
              </ReferenceManyField>
              <ReferenceManyField label="Settlements" reference="settlements" target="roundId" sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>}>
                <SettlementGrid/>
              </ReferenceManyField>
              <HorizontalForm>
                <ScheduleSettlementButton/>
              </HorizontalForm>

                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.accountingStatus !== 'SETTLED' && cancelAllButtonVisible) {
                            return <Button type={'button'} style={{marginTop: '16px'}} onClick={() => cancelAllCouponShares(formData.id, refresh, notify, setCancelAllButtonVisible)} variant={'outlined'}>Cancel All Coupon Shares</Button>
                        } else {
                            return <div/>
                        }}
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.accountingStatus === 'SETTLED') {
                            return <Button type={'button'} onClick={() => history.push(`/settlementComparison/${formData.fixtures.length}/${formData.id}`)} style={{marginTop: '16px'}} variant={'outlined'}>Compare Settlement</Button>
                        } else {
                            return <div/>
                        }}
                    }
                </FormDataConsumer>

            </FormTab>
          <FormTab label={'Coupons'}>
            <ReferenceManyField label={"Coupons"} reference="coupons" target={"roundId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
              <CouponGrid/>
            </ReferenceManyField>
          </FormTab>
          <FormTab label={'Coupon Shares'}>
            <ReferenceManyField label={"Coupon Shares"} reference="couponShares" target={"roundId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
              <CouponShareGrid/>
            </ReferenceManyField>
          </FormTab>
          <FormTab label={'Audit Log'}>
            <ReferenceManyField label={"Changes"} reference="auditLogs" target={"roundId"} sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>}>
              <AuditLogGrid/>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
    </Edit>
}

const OpenForPurchase = ({record}) => {
    record.openForPurchase = 'N/A';
    const result = <TextField record={record} source="openForPurchase"/>;
    if (!record.openTo || !record.openFrom) {
        return result;
    }

    const now = new Date();
    const openTo = new Date(record.openTo);
    const openFrom = new Date(record.openFrom);
    const withinTimespan = openFrom <= now && now < openTo;
    const open = record.status === 'ACTIVE' && withinTimespan;
    record.openForPurchase = open ? `True` : 'False';

    return result;
}


const cancelAllCouponShares = async (roundId, refresh, notify, setCancelAllButtonVisible) => {
    setCancelAllButtonVisible(false);
    const res = await fetchApi(`/extended/couponShares/cancelAllInRound/${roundId}`, {
        method: 'POST',
    }).then( async (json) => {
        // wait for propagation of events (eventual consistency)
        await new Promise(r => setTimeout(r, 1000));
        return ({
            data: json,
        })
    }).catch(err => {
        notify(`got error ${err}`)
        setCancelAllButtonVisible(true);
    })

    notify('Cancelled all shares in round, round status should be updated manually');
    refresh();
}


const PoolSize = ({record}) => {  
  const result = <TextField record={record} source="poolSize"/>;
  const nf = Intl.NumberFormat('sv-SV',{style:'currency', currency:'SEK', maximumFractionDigits: 0 });
  if(record.balances!=null){
    record.poolSize = nf.format(record.balances.POOL);
  }
  else{
      record.poolSize=0;
    }
  return result;
}

export const RoundList = props => (
    <List sort={{ field: 'id', order: 'desc'}} filters={<RoundFilter/>} filterDefaultValues={{status: ['PENDING', 'ACTIVE', 'FINISHED']}} {...props}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="parameters.name" label={'Name'}/>
          <DateField source="openTo" showTime label={'Deadline'} options={{year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' }}/>          
          <RoundTypeReferenceField source={"roundTypeId"}/>
          <RoundStatusField source={"status"}/>
          <OpenForPurchase addLabel={true} label="Open For Purchase" />
          <PoolSize addLabel={true} label={'Pool'}/>          
          <DateField source="createdAt" showTime/>
        </Datagrid>
    </List>
);

const RoundTextField = ({record}) =>
  <span>{record.parameters.name} ({record.id})</span>

export const RoundReferenceField = props =>
  <ReferenceField reference="rounds" {...props} >
    <RoundTextField/>
  </ReferenceField>;
