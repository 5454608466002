import React from 'react';

export const formatToEuro = (number: number): string => {

  return formatToCurrency(number, "EUR");
};

export const formatToCurrency = (number: number | undefined, currency: string | undefined): string => {

  // if (Number.isNaN(number) || currency === undefined || number === undefined) {
  //   return "";
  // }
  if (number === undefined || number === null || Number.isNaN(number) ) {
    return "";
  }

  if (!currency) {
    let formattedNumberNoCurrency = new Intl.NumberFormat('us-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

    return formattedNumberNoCurrency;
  }
  if (currency === "USDT") {
    let formattedNumberNoCurrency = new Intl.NumberFormat('us-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

    return "USDT " + formattedNumberNoCurrency;
  }

  const formattedNumber = new Intl.NumberFormat('us-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber;
};

export const formatToPercent = (number: number): string => {
  const formattedPercent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(number);

  return formattedPercent;
};
export const formatNumber = (number: number | undefined, fractionDigits?: number): string => {
  if (number === undefined || number === null || Number.isNaN(number) ) {
    return "";
  }
  var fd = 0;
  if (fractionDigits === undefined || fractionDigits === null || Number.isNaN(fractionDigits) ) {
    fd = 2;
  } else {
    fd = fractionDigits;
  }

  let formattedNumber = new Intl.NumberFormat('us-US', {
    minimumFractionDigits: fd,
    maximumFractionDigits: fd,
  }).format(number);

  return formattedNumber;
};

