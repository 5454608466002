import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import HorizontalForm from "../../components/HorizontalForm";
import UnitsInput from "../../fields/UnitsInput";
import PropTypes from "prop-types";


const useStyles = makeStyles({
  field: {
    marginRight: '2em',
  },
});

const GuaranteeInput = ({record, source, ...props}) => {
  const classes = useStyles();

  return <HorizontalForm {...props}>
    <UnitsInput className={classes.field} source={`${source}.minStakeUnits`} label={"Minimum stake"}/>
    <UnitsInput className={classes.field} source={`${source}.minWinUnits`} label={"Minimum win"}/>
  </HorizontalForm>;
}

GuaranteeInput.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  addLabel: PropTypes.bool
};

GuaranteeInput.defaultProps = {
  addLabel: true
}

export default GuaranteeInput;