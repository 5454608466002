import React from 'react';
import {
    Datagrid, TextField, List,
    ReferenceField, SimpleForm, Edit, ArrayField, SingleFieldList, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import Chip from '@material-ui/core/Chip';

const RoleField = ({record, source}) => <Chip label={record}/>;

export const UserGrid = props => (
  <Datagrid rowClick={'edit'} {...props}>
    <TextField source="id" />
    <TextField source="externalId"/>
    <TextField source="email"/>
    <ArrayField source="roles">
      <SingleFieldList>
        <RoleField/>
      </SingleFieldList>
    </ArrayField>
  </Datagrid>
)

export const UserList = props => (
    <List sort={{ field: 'id', order: 'desc'}} {...props}>
      <UserGrid/>
    </List>
);

export const UserEdit = props =>
  <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <TextField source="externalId"/>
        <TextField source="email"/>
        <ReferenceArrayInput source="roles" reference="roles" label={"Roles"}>
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
  </Edit>

export const UserReferenceField = props =>
    <ReferenceField reference="users" {...props} >
        <TextField source="email" />
    </ReferenceField>;
