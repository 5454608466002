import React, { useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem } from '@material-ui/core';
import { Draw, RoundOverviewFixture } from "./RoundOverviewModels";
import { min } from "moment";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "./RoundOverviewUtil";
import DrawRulesDialog from "./DrawRulesDialog";

interface DrawDialogProps {
    open: boolean;
    fixture: RoundOverviewFixture;
    onClose: (draw: Draw | undefined) => void;
}
interface DrawDialogState {
    drawId: string;
    minute: string;
    outcome: string;
    status: string;
}


const DrawDialog: React.FC<DrawDialogProps> = (props) => {
    const getDefaultFormState = () : DrawDialogState => {
        return {
            drawId: props.fixture.drawId,
            minute: "",
            outcome: props.fixture.drawSign ? props.fixture.drawSign : "",
            status: props.fixture.draw ? props.fixture.draw.status : ""
        };
    }
    
    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<DrawDialogState>(getDefaultFormState());
    const [draw, setDraw] = useState<Draw | null | undefined>(props.fixture.draw);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
 
    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
        var drawIds : number[] = [parseInt(props.fixture.drawId)];
        CreateRoundUtil.getDraws(drawIds).then((resp) => {
            if (resp.success && resp.item && resp.item.length === 1) {
                var d = resp.item[0];
                setDraw(d);
                var fs = {... formState, ["status"]: d.status};
                fs.outcome = d.adminPickedOutcome;
                // if (d.result && d.result.outcome.result) {
                //     fs.outcome = d.result.outcome.sign;
                // }
                setFormState(fs);
            }
      });
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        setFormState(fs);
    }

    const setOutcomeFromMinute = () => {
        if (!draw) {
            return;
        }
        var outcome = RoundOverviewUtil.getDrawOutcomeForMinute(draw, parseInt(formState.minute));
        if (!outcome) {
            return;
        }
        var fs = {...formState, ["outcome"]: outcome.sign};
        setFormState(fs);
    };
    const onSave = () => {
        const methodName = "SaveDraw";
        var status = undefined;
        var adminPickedOutcome = undefined;
        var changeCount = 0;
        setSaveError("");
        setSaveStatus(0);
        if (!draw) {
            setSaveError("Draw is null");
            return;
        }
        if (formState.status !== draw.status) {
            status = formState.status;
            changeCount++;
        }
        if (formState.outcome !== draw.adminPickedOutcome) {
            adminPickedOutcome = formState.outcome;
            changeCount++;
        }
        if (changeCount <= 0) {
            setSaveError("No changes");
            return;

        }
        setSaveStatus(1);
        var updateJson = JSON.stringify({status: status, adminPickedOutcome: adminPickedOutcome});
        CreateRoundUtil.updateDraw(props.fixture.drawId, updateJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                if (resp.item) {
                    props.fixture.draw = resp.item;
                    setDraw(resp.item);
                    setOpen(false);
                    if (props.onClose) {
                        props.onClose(resp.item);
                    }
                    return;
                } 
                setSaveError("SaveDraw failure: draw was not returned");

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`SaveDraw error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var validMinute = true;
    var minuteErr = "";
    if (formState.minute.length > 0) {
        var minute = parseInt(formState.minute);
        if (isNaN(minute)) {
            validMinute = false;
            minuteErr = "Invalid int";
        } else if (minute < 1 || minute > 90) {
            validMinute = false;
            minuteErr = "Not in range 1-90";
        }
    } else {
        validMinute = false;
    }
    var outcomeErr = "";
    var statusErr = "";
    var errCount = 0;
    var saveEnabled = false;
    if (formState.outcome.length === 0){
        outcomeErr = "Required";
        errCount++;
    } 
    if (formState.status.length === 0){
        statusErr = "Required";
        errCount++;
    } else if (formState.status === RoundOverviewUtil.FINISHED_STATUS && formState.outcome === RoundOverviewUtil.UNKNOWN_OUTCOME_SIGN) {
        statusErr = "FINISHED not valid with selected outcome";
        errCount++;
    }
    if (errCount <= 0 && draw) {
        if (formState.outcome !== draw.adminPickedOutcome){
            saveEnabled = true;
        } else if (formState.status !== draw.status) {
            saveEnabled = true;
        }
    }
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    return (
        <div>
                <Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    Draw
                </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Draw</DialogTitle>
            <DialogContent dividers>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Fixture"
                        name="fixtureName"
                        value={props.fixture.name}
                        helperText={CreateRoundUtil.toGameDateString(props.fixture.startingAt) + " | " + props.fixture.leagueName}
                        InputProps={{
                        readOnly: true,
                        }}
                        style={{width:400}}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        label="Draw"
                        name="drawId"
                        value={props.fixture.drawId}
                        helperText={`${props.fixture.draw?.adminPickedOutcome} (${props.fixture.draw?.status})`}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                <Box marginTop={1} display="flex" alignItems="center">
                <TextField
                    autoFocus
                    margin="dense"
                    name="minute"
                    value={formState.minute}
                    label="Action minute"
                    type="number"
                    onChange={e => handleChange("minute", e.target.value)}
                    helperText={minuteErr}
                    error={minuteErr.length > 0}
                    /><span style={{ padding: 2 }}></span>
                    <Button onClick={e => setOutcomeFromMinute()} color="secondary" variant="contained" size="small" disabled={!validMinute}>
                        Set Outcome
                    </Button>

                </Box>
                <Box marginTop={1} width="250px">
                    <TextField
                        label="Outcome"
                        select
                        name="outcome"
                        value={formState.outcome}
                        onChange={e => handleChange("outcome", e.target.value)}
                        fullWidth
                        helperText={outcomeErr}
                        error={outcomeErr.length > 0}
                    >
                        <MenuItem key={RoundOverviewUtil.UNKNOWN_OUTCOME_SIGN} value={RoundOverviewUtil.UNKNOWN_OUTCOME_SIGN}>
                            {RoundOverviewUtil.UNKNOWN_OUTCOME_SIGN}
                        </MenuItem>
                        {draw?.outcomes.map(o => (
                        <MenuItem key={o.sign} value={o.sign}>
                            {o.sign + " (" + o.n + ")"}
                        </MenuItem>
                        ))}
                    </TextField>
                </Box> 
                <Box marginTop={1} width="250px">
                    <TextField
                        label="Status"
                        select
                        name="status"
                        value={formState.status}
                        onChange={e => handleChange("status", e.target.value)}
                        fullWidth
                        helperText={statusErr}
                        error={statusErr.length > 0}
                    >
                        {RoundOverviewUtil.enabledDrawStatuses.map(s => (
                        <MenuItem key={s} value={s}>
                            {s}
                        </MenuItem>
                        ))}
                        <MenuItem key={RoundOverviewUtil.FINISHED_STATUS} value={RoundOverviewUtil.FINISHED_STATUS}>
                            {RoundOverviewUtil.FINISHED_STATUS}
                        </MenuItem>
                    </TextField>
                </Box>
                {saveErrorBox} 
            </DialogContent>
            <DialogActions>
                <DrawRulesDialog openButtonLabel="Rules" /><div style={{flex: '1 0 0'}} />
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="primary" disabled={!saveEnabled}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default DrawDialog;
