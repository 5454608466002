
// import React from "react";
import React, { useState,useEffect } from 'react';
import { Container,TextField,Button, MenuItem, Box, Switch, FormControlLabel } from "@material-ui/core";
import { RoundType } from '../models/RoundType';
import { getList, getObject } from '../api/dataProvider';
import CreateRound2 from './CreateRound2';
import CreateRoundUtil from './CreateRoundUtil';
import { Title } from 'react-admin';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
// import MyForm from './MyForm';
// import FixturesChooser from './FixturesChooser';
//import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// interface CreateRoundFixture {
//     // Define your form fields and their types here
//     id: number
//     oneProbability: number;
//     xProbability: number;
//     twoProbability: number;
//   }
interface CreateRoundState {
    // Define your form fields and their types here
    id: number;
    roundTypeId: string;
    openTo: string;
    // jackpot: number;
    //fixturesFilterFrom: string;
    fixturesFilterTo: string;
    // fixtures: CreateRoundFixture[]
    roundType : RoundType | null;
    step : number;
}
interface RoundTypeFiltering  {
  enabled: boolean;
  filter?: (roundTypes: RoundType[]) => RoundType[];
}

const CreateRound: React.FC = () => {
    const [roundTypes, setRoundTypes] = useState<RoundType[]>([]);
    const [allRoundTypes, setAllRoundTypes] = useState<RoundType[]>([]);
    const [roundTypeId, setRoundTypeId] = useState<string>('');
    const [roundType, setRoundType] = useState<any>(null)
    const [step, setStep] = useState<number>(1);
    const [filtering, setFiltering] = useState<RoundTypeFiltering>({ enabled: false});
    const [filtered, setFiltered] = useState<boolean>(false);

    useEffect(() => {
        var env = RoundOverviewUtil.getEnv();
        var filteringX : RoundTypeFiltering | null = null;
        var filteredX = false;
        switch (env) {
            case RoundOverviewUtil.PROD_ENVIRONMENT:
            case RoundOverviewUtil.DEMO_ENVIRONMENT:
              filteringX = { enabled: true, filter: CreateRoundUtil.filterProdRoundTypes};
              filteredX = true;
              break;
            case RoundOverviewUtil.DEV_ENVIRONMENT:
              filteringX = { enabled: true, filter: CreateRoundUtil.filterTestRoundTypes};
              filteredX = true;
              break;
        }
        if (filteringX) {
          setFiltering(filteringX);
        }
        CreateRoundUtil.getRoundTypes().then((rts : RoundType[]) => {
          setAllRoundTypes(rts);
          var rtsX = rts;
          if (filteredX && filteringX && filteringX.filter){
            rtsX = filteringX.filter(rts);
            setFiltered(true);
          }
          // if (RoundOverviewUtil.isProdEnv()) {
          //   rtsX = CreateRoundUtil.filterProdRoundTypes(rts);
          // } /* else if (RoundOverviewUtil.isTestEnv()) {
          //   rtsX = CreateRoundUtil.filterTestRoundTypes(rts);
          // } */
          setRoundTypes(rtsX);
      });

    }, []); 

    const handleRoundTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRoundTypeId(event.target.value);
      refreshRoundType(event.target.value); 
    };
  
    const onFilterChanged = () => {
      var filteredX = !filtered;
      var roundTypesX = allRoundTypes;
      if (filteredX && filtering.enabled && filtering.filter){
          roundTypesX = filtering.filter(allRoundTypes);
          if (roundTypeId && roundTypeId.length > 0) {
            var roundTypeX = roundTypesX.find(it => it.id === roundTypeId);
            if (!roundTypeX) {
                setRoundTypeId("");
                setRoundType(null);
            }
          }
      }
      setRoundTypes(roundTypesX);
      setFiltered(filteredX);
    };
    const onContinueClick = () => {
      setStep(2);
    };
    const refreshRoundType = (rtId : string | null) => {
      if (rtId != null && rtId.length > 0)
      {
        var refresh : boolean = false;
        if (roundType === null || roundType === undefined) {
          refresh = true;
        } else if (roundType.id !== rtId) {
          refresh = true;
        }
        if (!refresh){
          return;
        }
        getObject("roundTypes?id=" + rtId
        ).then((resp) => {
          var rt  = null;
          var rts = resp.data?._embedded?.roundTypes;
          if (rts !== null && rts.length > 0){
            rt = rts[0];
          }
          setRoundType(rt);
        });
      } else if (roundType !== null) {
        setRoundType(null);
      }

    };

    var roundTypeDefJson : string = ''
    var nFixtures : number = 0;
    if (roundType !== null && roundType !== undefined)
    {
      roundTypeDefJson = JSON.stringify(roundType);
      nFixtures = roundType.parameters?.nFixtures;
      // var rts = roundType._embedded?.roundTypes;
      // if (rts !== null && rts.length > 0){
      //   var rt = rts[0];
      //   nFixtures = rt.parameters?.nFixtures;
      // }
    }
    if (step === 2){
      return (
      <CreateRound2 roundTypeId={roundTypeId} roundType={roundType} />
      // <Container maxWidth="lg">
      //   Step 2
      //   </Container>
      );
    }
    else {
      var filter = null;
      if (filtering.enabled && filtering.filter && allRoundTypes.length > 0) {
        filter = (<FormControlLabel control={<Switch
          checked={filtered}
          onChange={(e: any) => onFilterChanged()}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={(allRoundTypes.length <= 0)}
        />}
        label="Filter"
        />);
      }
return (
    <Container maxWidth="lg">
      <Title title="Create Round - Select Round type (1 of 2)" />
      <Box marginTop={1} display="flex" alignItems="center">
        <TextField
            label="Round type"
            select
            name="roundTypeId"
            value={roundTypeId}
            onChange={handleRoundTypeChange}
            style={{width:250}}
            helperText={roundType ? "Id:" + roundTypeId + "; " + nFixtures + " fixtures" : `${roundTypes.length} of ${allRoundTypes.length} RoundTypes`}
          >
            {roundTypes.map(roundType => (
              <MenuItem key={roundType.id} value={roundType.id}>
                {roundType.parameters?.name}
              </MenuItem>
            ))}
          </TextField>
          <span style={{ padding: 2 }}></span>{filter}
      </Box> 
      <Box paddingTop={2}>
        <Button onClick={e => onContinueClick()} variant="contained" color="primary" disabled={(!roundTypeId || roundTypeId.length === 0) ? true : false}>Continue</Button>
      </Box>
      {/* <Box width="250px">
        Round type definition:
        {roundTypeDefJson}
        <Box>
        nFixtures:
        {nFixtures}
        </Box>
      </Box> */}
    </Container>
  );
  }
};

export default CreateRound;

