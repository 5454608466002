import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    List,
    ReferenceField,
    Edit,
    FormTab,
    ReferenceManyField,
    Pagination,
    TabbedForm,
    ReferenceInput,
    AutocompleteInput,
    TextInput, Toolbar, SaveButton, Filter
} from 'react-admin';
import {CouponGrid} from "../coupons";
import {CouponShareGrid} from "../couponShares";

export const PlayerGrid = props => (
  <Datagrid  rowClick={'edit'} {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="operatorId"/>
    <TextField source="operatorPlayerId"/>
    <TextField source="createdOperatorChannel" label={'Created in channel'}/>
    <TextField source="notes"/>
  </Datagrid>
)

export const PlayerList = props => (

    <List sort={{ field: 'createdAt', order: 'desc'}} filters={<PlayerFilter/>} filterDefaultValues={{}} {...props}>
      <PlayerGrid/>
    </List>
);

const PlayerEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const PlayerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="id" alwaysOn />
    </Filter>
);

export const PlayerEdit = props =>
  <Edit {...props}>
      <TabbedForm toolbar={<PlayerEditToolbar/>}>
        <FormTab label={'General'}>
          <TextField source="id" />
          <DateField source="createdAt" showTime/>
          <TextField source="operatorId"/>
          <TextField source="operatorPlayerId"/>
          <TextField source="createdOperatorChannel" label={'Created in channel'}/>
          <TextInput multiline source="notes" fullWidth/>
        </FormTab>
        <FormTab label={'Coupons'}>
          <ReferenceManyField label={"Coupons"} reference="coupons" target={"playerId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
            <CouponGrid/>
          </ReferenceManyField>
        </FormTab>
        <FormTab label={'Coupon Shares'}>
          <ReferenceManyField label={"Coupon Shares"} reference="couponShares" target={"playerId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
            <CouponShareGrid/>
          </ReferenceManyField>
        </FormTab>

      </TabbedForm>
  </Edit>

export const PlayerReferenceField = props =>
    <ReferenceField reference="players" {...props} >
        <TextField source="id" />
    </ReferenceField>;

export const PlayerReferenceInput = props =>
  <ReferenceInput label={'Player'}  reference="players" {...props} >
    <AutocompleteInput optionText={"id"} emptyValue={""}/>
  </ReferenceInput>;
