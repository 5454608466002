import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import DataTable, { DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RoundTickerOverviewItem } from './Ticker2Models';
import { dateStringTicker } from '../common/helpers/DateHelpers';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { formatToCurrency } from '../common/helpers/CurrencyHelpers';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import Ticker2Util from './Ticker2Util';


interface RoundOverviewTableProps {
    items: RoundTickerOverviewItem[];
    onSelect? : (item : RoundTickerOverviewItem) => void;
    onDeselect?: () => void;
}

const RoundOverviewTable: React.FC<RoundOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<RoundTickerOverviewItem>[]>([]);
    const currency = RoundOverviewUtil.CURRENCY_EUR;

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<RoundTickerOverviewItem>[] = [
        { id: 'roundId', numeric: false, disablePadding: true, label: "Round Id" },
        { id: 'roundTypeName', numeric: false, disablePadding: false, label: 'Round Type' },
        { id: 'deadline', numeric: false, disablePadding: false, label: 'Deadline', renderValue: (item: RoundTickerOverviewItem) => { return CreateRoundUtil.toGameDateString(item.deadline); }  },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        // { id: 'betCount', numeric: true, disablePadding: false, label: '#Bets' },
        // { id: 'turnover', numeric: true, disablePadding: false, label: 'Turnover', renderValue: (item: RoundTickerOverviewItem) => { return formatToCurrency(item.turnover, currency); } },
        // { id: 'result', numeric: true, disablePadding: false, label: 'Result', renderValue: (item: RoundTickerOverviewItem) => { return formatToCurrency(item.result, currency); } },
        // { id: 'refund', numeric: true, disablePadding: false, label: 'Refund', renderValue: (item: RoundTickerOverviewItem) => { return formatToCurrency(item.refund, currency); } },
        // { id: 'pl', numeric: true, disablePadding: false, label: 'PL', renderValue: (item: RoundTickerOverviewItem) => { return formatToCurrency(item.pl, currency); } },
        // { id: 'fee', numeric: true, disablePadding: false, label: 'Fee', renderValue: (item: RoundTickerOverviewItem) => { return formatToCurrency(item.fee, currency); } }
      ];
      Ticker2Util.appendTickerOverviewColumns(cols);
      setColumns(cols);
    }
    const onSelect = (item : RoundTickerOverviewItem) => {
      setSelectedId(item ? item.roundId : 0);
      if (props.onSelect){
        props.onSelect(item);
      }
    };
    const onDeselect = () => {
      setSelectedId(0);
      if (props.onDeselect){
        props.onDeselect();
      }
    };

  return (
    <DataTable columns={columns} items={props.items} getId={it => it.roundId} orderBy='deadline' order='desc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect} />
    );
};
  
export default RoundOverviewTable;
