import React, { useState,useEffect } from 'react';
import { Title } from 'react-admin';
import { Container,TextField,Button, MenuItem, Box } from "@material-ui/core";
import { RoundType } from '../models/RoundType';
import { getList, getObject } from '../api/dataProvider';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { GenericResponse, LeagueListItem, LogEntry, SaveOutcomeParams, SaveRoundFixture, SaveRoundParams, TeamItem } from '../CreateRound/CreateRoundModels';
import { Fixture, Round } from '../RoundOverview/RoundOverviewModels';
import fetchApi from '../../fetchApi';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import { useHistory } from 'react-router-dom';
import AddFakeBets from './PlaceFakeBets';
import { SignInMockopResult } from '../ExternalApi/MockopApiModels';
import MockopApiUtil from '../ExternalApi/MockopApiUtil';
import { Bet, Leg, PlaceBetsInput } from '../ExternalApi/PlayerApiModels';
import PlayerApiUtil from '../ExternalApi/PlayerApiUtil';


  
const CreateFakeRound: React.FC = () => {
    const [roundTypes, setRoundTypes] = useState<RoundType[]>([]);
    const [roundTypeId, setRoundTypeId] = useState<string>('');
    const [roundType, setRoundType] = useState<RoundType | null | undefined>(null);
    const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [jackpotId, setJackpotId] = useState<string>("");
    const [roundId, setRoundId] = useState<string>("");
    const [step, setStep] = useState<number>(1);
    const [round, setRound] = useState<Round | null>(null);
    const [isTestEnv, setIsTestEnv] = useState<boolean>(RoundOverviewUtil.isTestEnv(RoundOverviewUtil.getEnv()));
    
    const history = useHistory();

    useEffect(() => {
        CreateRoundUtil.getRoundTypes().then((rts : RoundType[]) => {
            setRoundTypes(rts);
        });
    }, []); 

    const handleRoundTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRoundTypeId(event.target.value);
      var rt = roundTypes.find(rt => rt.id === event.target.value);
      setRoundType(rt);
    };

    var roundTypeDefJson : string = ''
    var nFixtures : number = 0;
    var roundTypeName : string | null = null;
    if (roundType)
    {
      roundTypeDefJson = JSON.stringify(roundType);
      nFixtures = roundType?.parameters.nFixtures;
      roundTypeName = roundType?.parameters?.name;
    }
   
    const onSaveError = (error: string) => {
      setSaveError(error);
      logEntries.push(CreateRoundUtil.createLogEntry(error, true));
      setLogEntries([...logEntries])
    };

    const addSaveInfo = (info: string) => {
        logEntries.push(CreateRoundUtil.createLogEntry(info, false));
        setLogEntries([...logEntries])
    };
    const updateDraws = async (round: Round): Promise<GenericResponse<number>> => {
      const METHOD_NAME = "updateDraws";
      var updateCount: number = 0;
      var errorCount: number = 0;
      try {

          if (round.draws === null || round.draws.length <= 0) {
              return CreateRoundUtil.createGenericResponse<number>(false, "Round has no draws", 0);
          }
          var drawIds : number[] = [];
          round.draws.forEach(d => drawIds.push(parseInt(d.id)));
          var drawsResp = await CreateRoundUtil.getDraws(drawIds);
          if (!drawsResp.success){
            return CreateRoundUtil.createGenericResponse<number>(false, "GetDraws error: " + drawsResp.message, 0);
          }
          var draws = drawsResp.item;
          if (draws === null || draws.length <= 0) {
            return CreateRoundUtil.createGenericResponse<number>(false, "GetDraws returned nothing", 0);
          }
    
          var updateOutcomes: SaveOutcomeParams[] = [];
          for (var iDraw = 0; iDraw < draws.length; iDraw++) {
              var draw = draws[iDraw];
              var drawId: number = parseInt(draw.id);
              if (!draw.outcomes) {
                  continue;
              }
              updateOutcomes = [];
              var sumN : number = 0;
              for (var iOutcome = 0; iOutcome < draw.outcomes.length; iOutcome++) {
                  var outcome = draw.outcomes[iOutcome];
                  var p: number = -1;
                  var sign: string = outcome.sign;
                  var n: number = -1;
                  switch (sign) {
                      case "ONE":
                          n = 50;
                          break;
                      case "X":
                      case "x":
                          n = 30;
                        break;
                      case "TWO":
                          n = 20;
                          break;
                  }
                  p = n / 100;
                  if (!p || isNaN(p) || p < 0) {
                      continue;
                  }
                  if (!n || isNaN(n) || n < 0) {
                      continue;
                  }
                  sumN += n;
                  updateOutcomes.push({
                      id: outcome.id,
                      n: n,
                      sign: sign,
                      result: false,
                      probability: outcome.probability
                  });
              }
              if (updateOutcomes.length !== 3) {
                errorCount++;
                onSaveError("Update Outcomes failure: outcomes <> 3 [DrawId:" + drawId + "] [outcomes:" + updateOutcomes.length + "].");
                continue;
              }
              if (sumN != CreateRoundUtil.ticketSum){
                errorCount++;
                onSaveError("Update Outcomes failure: ticket sum <> " + CreateRoundUtil.ticketSum + " [DrawId:" + drawId + "] [outcomes:" + updateOutcomes.length + "] [ticketSum:" + sumN + "].");
                continue;
              }
              var updateOutcomesJson = JSON.stringify({ outcomes: updateOutcomes })
              var updateOutcomeResp = await fetchApi("draws/" + drawId, {
                  method: 'PATCH',
                  body: updateOutcomesJson
              });
              if (!updateOutcomeResp) {
                  return CreateRoundUtil.createGenericResponse<number>(false, "Update Outcomes failure: no response [DrawId:" + drawId + "].", updateCount);
              }
              updateCount++;
          }
          return CreateRoundUtil.createGenericResponse<number>(true, "", updateCount);
        } catch (error) {
          console.error("Update Draws error", error);
          return CreateRoundUtil.createGenericResponse<number>(false,"Update Draws error: " + error, updateCount);
      }
  };
  const saveRound = async (openTo: Date, jackpot : string, teamNames : string[]): Promise<GenericResponse<number>> => {
      var fakeLeagueResponse = await CreateRoundUtil.getLeagueByName(CreateRoundUtil.fakeLeagueName);
      if (!fakeLeagueResponse.success){
        return CreateRoundUtil.createGenericResponse(false, "GetLeagueByName('" + CreateRoundUtil.fakeLeagueName + ") error: " + fakeLeagueResponse.message + ".", 0);
      }
      var fakeLeague = fakeLeagueResponse.item;
      if (!fakeLeague) {
        return CreateRoundUtil.createGenericResponse(false, "Fake League is null.", 0);
      }
      var updateCount = 0;
      
      //Get teams
      var teams : TeamItem[] = [];
      for (var iTeam = 0; iTeam < (nFixtures * 2); iTeam++) {
        var teamName = teamNames[iTeam];
        var teamResponse = await CreateRoundUtil.getTeamByName(teamNames[iTeam]);
        if (!teamResponse.success){
          return CreateRoundUtil.createGenericResponse(false, "GetTeamByName('" + teamName + "): " + teamResponse.message + " [Index:" + iTeam + "].", 0);
        }
        var team = teamResponse.item;
        if (team === null) {
          return CreateRoundUtil.createGenericResponse(false, "Team '" + teamName + "' not found  (null) [Index:" + iTeam + "].", 0);
        }
        if (team.name !== teamName) {
          return CreateRoundUtil.createGenericResponse(false, "Team '" + teamName + "' name diff [Index:" + iTeam + "] [team.id:" + team.id + "] [team.name:" + team.name + "].", 0);
        }
        teams.push(team);
      }
      addSaveInfo("Get teams done [Count:" + teams.length + "]");

      //Create fixtures
      var fixtures : Fixture[] = [];
      for (var iFixture = 0; iFixture < nFixtures; iFixture++){
        var homeTeam = teams[iFixture * 2];
        var awayTeam = teams[iFixture * 2 + 1];
        var fixtureResponse = await CreateRoundUtil.createFixture(fakeLeague.id, homeTeam.id, awayTeam.id, openTo);
        if (!fixtureResponse.success){
          return CreateRoundUtil.createGenericResponse(false, "CreateFixture('" + homeTeam.name + " v " + awayTeam.name + "') error: " + fixtureResponse + " [Index:" + iFixture + "].", 0);
        }
        var fixture = fixtureResponse.item;
        if (fixture === null) {
          return CreateRoundUtil.createGenericResponse(false, "Fixture '" + homeTeam.name + " v " + awayTeam.name + "' was not returned.", updateCount);
        }
        fixtures.push(fixture);
        updateCount++;
      }
      addSaveInfo("Fixtures created [Count:" + fixtures.length + "]");

      //Create round
      var rId = roundId;
      if (rId.length === 0) {
        var createRoundResp = await CreateRoundUtil.createRound(roundTypeId);
        if (!createRoundResp.success) {
          return CreateRoundUtil.createGenericResponse(false, "CreateRound error: " + createRoundResp.message, updateCount);
        }
        var rIdX = createRoundResp.item;
        if (!rIdX || rIdX.length === 0) {
          return CreateRoundUtil.createGenericResponse(false, "CreateRound failure: no round id", updateCount);
        }
        rId = rIdX;
        updateCount++;
        addSaveInfo("Round created [Id:" + rId + "]");
        setRoundId(rId);


        //Sleep/delay x seconds for created round to be handled
        if (CreateRoundUtil.createRoundDelaySeconds > 0) {
          addSaveInfo(`Wait ${CreateRoundUtil.createRoundDelaySeconds} seconds for created round to be handled...`);
          await CreateRoundUtil.sleep(CreateRoundUtil.createRoundDelaySeconds * 1000);
          addSaveInfo(`Wait ${CreateRoundUtil.createRoundDelaySeconds} seconds for created round to be handled - done`); 
        }     
        // onSaveError("***Test error after create***");
        // return CreateRoundUtil.createGenericResponse(false, "UpdateRound test error after create", updateCount);
      } else {
        addSaveInfo(`Round is already created [id:${rId}]`);
      }

      var saveFixtures: SaveRoundFixture[] = [];
      fixtures.forEach(f => saveFixtures.push({ fixtureId: f.id }));
      var saveRoundParams: SaveRoundParams = {
        fixtures: saveFixtures,
        openTo: openTo,
        jackpot: undefined
      };
      
      var saveRoundJson = JSON.stringify(saveRoundParams)
      var updateRoundResp = await CreateRoundUtil.updateRound(rId, saveRoundJson);
      if (!updateRoundResp.success) {
        return CreateRoundUtil.createGenericResponse(false, "UpdateRound error: " + updateRoundResp.message, updateCount);
      }
      var round = updateRoundResp.item;
      setRound(round);
      if (round === null) {
        return CreateRoundUtil.createGenericResponse(false, "UpdateRound did not return round.", updateCount);
      }
      updateCount++;
      addSaveInfo("Round updated [Id:" + rId + "]");

      var updateDrawsResp = await updateDraws(round);
      if (!updateDrawsResp.success) {
        return CreateRoundUtil.createGenericResponse(false, "UpdateDraws error: " + updateDrawsResp.message + " [UpdateDrawCount:" + updateDrawsResp.item + "].", updateCount);
      }
      var updateDrawCount = updateDrawsResp.item;
      if (updateDrawCount && updateDrawCount > 0) {
          updateCount += updateDrawCount;
      }
      addSaveInfo("Draws updated [Count:" + updateDrawCount + "]");

      if (jackpot.length > 0 && jackpotId === "") {
          var jackpotResp = await CreateRoundUtil.saveRoundPromotionValue(jackpotId, rId, "jackpotEUR", jackpot);
          if (!jackpotResp.success) {
            addSaveInfo("SaveJackpot error: " + jackpotResp.message);
          } else {
            var jId = jackpotResp.item;
            if (jId && jId.length > 0 && jId !== jackpotId){
                setJackpotId(jId);
            }
            updateCount++;
          }
      }

      var activateRoundJson = JSON.stringify({ status: RoundOverviewUtil.ACTIVE_STATUS, visibleToPlayers: true });
      var activateRoundResp = await CreateRoundUtil.updateRound(rId, activateRoundJson);
      if (!activateRoundResp.success) {
        return CreateRoundUtil.createGenericResponse(false, "ActivateRound error: " + updateRoundResp.message, updateCount);
      }
      round = activateRoundResp.item;
      if (round === null) {
        return CreateRoundUtil.createGenericResponse(false, "ActivateRound did not return round.", updateCount);
      }
      setRound(round);
      updateCount++;
      addSaveInfo("Round activated [Id:" + rId + "]");

      return CreateRoundUtil.createGenericResponse(true, "", updateCount);
    }

    const onCreateClick = () => {
      if (!roundTypeId || roundTypeId === ""){
        alert("Round type is not selected");
        return;
      }
      //Set openTo (deadline) to 2 hours forward in time, to nearest quarter of hour
      setRoundId("");
      setRound(null);
      CreateRoundUtil.clearArray(logEntries);
      setLogEntries([...logEntries]);
      setSaveError("");
      
      var openTo = CreateRoundUtil.addHours(new Date(), 2);
      var roundedOpenTo = CreateRoundUtil.getDateWithoutTime(openTo);
      var minutes = openTo.getMinutes();
      if (minutes === 0){
        roundedOpenTo = CreateRoundUtil.addHours(roundedOpenTo, openTo.getHours());
      } else if (minutes <= 15) {
        roundedOpenTo.setHours(openTo.getHours(), 15);
      } else if (minutes <= 30) {
        roundedOpenTo.setHours(openTo.getHours(), 30);
      } else if (minutes <= 45) {
        roundedOpenTo.setHours(openTo.getHours(), 45);
      } else {
        roundedOpenTo.setHours(openTo.getHours() + 1, 0);
      }
      addSaveInfo("openTo:" + CreateRoundUtil.toGameDateString(roundedOpenTo));

      var jackpot = CreateRoundUtil.getDefaultJackpotString(roundTypeName);
      addSaveInfo("jackpot: '" + jackpot + "'");
      var teamNames : string[] = [];
      var aCharCode = "A".charCodeAt(0);
      for (var iTeam = 0; iTeam < 26; iTeam++) {
        var charCode = aCharCode + iTeam;
        var teamName = String.fromCharCode(charCode).repeat(5);
        teamNames.push(teamName);
      }

      //Saving
      setSaveStatus(1);
      addSaveInfo("Input is OK, start saving");
      saveRound(roundedOpenTo, jackpot, teamNames).then((saveRoundResp) => {
        var updateCount = saveRoundResp.item;
        addSaveInfo("Save done [Success:" + saveRoundResp.success + "] [Message:" + saveRoundResp.message + "] [UpdateCount:" + updateCount + "]");
          if (updateCount && updateCount > 0) {
              //Saved something
              setSaveStatus(2);
          } else {
              //Not saved
              setSaveStatus(0);
          }
      }).catch(error => {
          console.error('Save Round error!', error);
          onSaveError("Save Round error (onCreateClick): " + error);
          setSaveStatus(0);
      });

    };
    const onPlaceBetsClick = () => {
      // if (!round) {
      //   RoundOverviewUtil.getRound("402").then(roundResp => {
      //     if (!roundResp.success) {
      //       setSaveError(`PlaceBets.Test error: ${roundResp.message}`);
      //       return;
      //     }
      //     setRound(roundResp.item);
      //     if (roundResp.item) {
      //       setStep(2);
      //     }
      //   });
      //   return;
      // }
      setStep(2);
    };
    const onGotoRounds = () => {
      history.push('rounds');
    };
    const onEditRound = () => {
        history.push("rounds/" + roundId);

    };
    const onGotoRoundOverview = () => {
      history.push('roundOverview');
    };
    
    if (!isTestEnv) {
      return (
      <Container maxWidth="lg">
        <Title title="Create Fake Round" />
        <h2>Create Fake Round disabled: current environment is not for test</h2>
        </Container>
      );
    } else if (step === 2){
      var roundTypeX = roundType;
      if (roundTypeX?.id !== round?.roundTypeId) {
        roundTypeX =  roundTypes.find(rt => rt.id == round?.roundTypeId);
      }
      return (
      <AddFakeBets round={round} roundType={roundTypeX} />
      );
    }
    else {
      var actionInfoSpan : any = "";

      var placeBetsButton : any = "";
      var proceedCreate = (roundId.length > 0 && !round);
      var roundUpdated = (roundId.length > 0 && round)
      var createEnabled = (roundTypeId && roundTypeId.length > 0 && saveStatus !== 1 && (roundId.length === 0 || !round));
      var createButtonText = proceedCreate ? "Create (Proceed)" : "Create";
      var createButtonDisabled = !createEnabled;
      if (roundId.length > 0 && saveStatus === 2) {
        if (saveError.length > 0) {
          actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{`Round was created with problem [Id:${roundId}]... ${saveError}`}</span>);
        } else {
          actionInfoSpan = (<span style={{ color: "blue", paddingLeft: "50" }}>&nbsp;{`Round was successfully created [Id:${roundId}].`}</span>);
        }
        if (roundUpdated) {
          placeBetsButton = (<Box paddingTop={2}>
            <Button onClick={e => onPlaceBetsClick()} variant="contained" color="primary">Place Bets &gt;</Button>                    
          </Box>);
        }
      } else if (saveError.length > 0) {
        actionInfoSpan = (<span style={{ color: "red", paddingLeft: "50" }}>&nbsp;{saveError}</span>);
      }
      var roundTypeInput = null;
      if (roundId.length > 0) {
        roundTypeInput = (
          <TextField
            label="Round type"
            name="roundTypeId"
            value={roundType?.parameters.name}
            helperText={"id:" + roundTypeId + "; " + roundType?.parameters.nFixtures.toString() + " fixtures"}
            InputProps={{
                readOnly: true,
            }}
          />
        );
      } else {
        roundTypeInput = (
          <TextField
            label="Round type"
            select
            name="roundTypeId"
            value={roundTypeId}
            onChange={handleRoundTypeChange}
            fullWidth
            helperText={roundType ? "Id:" + roundTypeId + "; " + nFixtures + " fixtures" : ""}
          >
            {roundTypes.map(roundType => (
              <MenuItem key={roundType.id} value={roundType.id}>
                {roundType.parameters?.name}
              </MenuItem>
            ))}
          </TextField>
        );

      }
  return (
    <Container maxWidth="lg">
      <Title title="Create Fake Round" />
      <Box width="250px">
        {roundTypeInput}
      </Box> 
      <Box paddingTop={2}>
        <Button onClick={e => onCreateClick()} variant="contained" color="primary" disabled={createButtonDisabled}>{createButtonText}</Button>
        {actionInfoSpan}
      </Box>
      {placeBetsButton}
      <Box paddingTop={2}>
                {logEntries.map((info) => (
                    <p style={{ color: CreateRoundUtil.getLogEntryColor(info.isError) }}>{info.message}</p>
                ))}
      </Box>
    </Container>
  );
  }
};

export default CreateFakeRound;

