export interface InvokeApiOptions {
    method?: string | undefined;
    headers?: Headers | undefined;
    body?: string | undefined;
}
export interface InvokeApiResponse {
    success: boolean;
    message: string;
    status?: number;
    headers?: Headers;
    body?: string;
    json?: any;
}
class InvokeApiMethods {
  static GET() { return "GET"; }
  static POST() { return "POST"; }
  static PATCH() { return "PATCH"; }
}
export default InvokeApiMethods;
  