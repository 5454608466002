export class TimeUserReport 
    {
        timeDict:{ [key: number]: TimeUserReportItem } = {};
        bookmakerLookup: { [key: string]: number } = {};
        numberOfBookmakers: number =0;
        count: number =0;

        addUser(timeIndex:number, timeUnitName:string, user: UserReportItem) {
            this.count += 1;

            let x = this.bookmakerLookup[user.operatorId];
            if (x == undefined) {
                this.numberOfBookmakers += 1;
                this.bookmakerLookup[user.operatorId] = 0;
            }

            this.bookmakerLookup[user.operatorId] += 1;
            let timeItem = this.timeDict[timeIndex]
            if (timeItem == undefined) {
                timeItem = new TimeUserReportItem(timeIndex, timeUnitName);
                this.timeDict[timeIndex] = timeItem;
            }
            timeItem.addUser(user);
        }

        GetTotalNumberOfNewBookmakerUsers(bookmaker : string):number
        {
            let x = this.bookmakerLookup[bookmaker];
            if(x == undefined)
            {
                return 0;
            }
            else
            {
                return x;
            }
        }

        GetMaxNumberOfUser(): number
        {
            return this.count;
        }

        GetMaxNumberOfUserPerTimeUnti(): number
        {
            let max = 0;
            let timeObjList = Object.values(this.timeDict);

            timeObjList.map((timeUnit, index) => {
            {
                if(timeUnit.count > max)
                {
                    max = timeUnit.count;
                }
            }});
        
            return max;
        }

        GetNumUserPerTimeUnti(): any[]
        {
            let data: any[] = [];
            let timeObjList = Object.values(this.timeDict);

            timeObjList.map((timeUnit, index) => {
            {
                let datapoint = {
                    x: timeUnit.timeUnitName,
                    y: timeUnit.count
                };
                data.push(datapoint);
            }});
        
            return data;
        }

        GetTotalNumUserPerOperator(): any[]
        {
            let data: any[] = [];
            let operators = Object.keys(this.bookmakerLookup);

            operators.map((operatorId, index) => {
            {
                let datapoint = {
                    x: operatorId,
                    y: this.GetTotalNumberOfNewBookmakerUsers(operatorId)
                };
                data.push(datapoint);
            }});
        
            return data;
        }

        GetAccumulatedNumUserPerTimeUnti(): any[]
        {
            let data: any[] = [];
            let timeObjList = Object.values(this.timeDict);
            let accumulated = 0;

            timeObjList.map((timeUnit, index) => {
            {
                accumulated += timeUnit.count;
                let datapoint = {
                    x: timeUnit.timeUnitName,
                    y: accumulated
                };
                data.push(datapoint);
            }});
        
            return data;
        }
        
        GetUsersForOperatorPerTimeUnitData(operatorId:string): any[]
        {
            let data: any[] = [];
            let timeObjList = Object.values(this.timeDict);

            timeObjList.map((timeUnit, index) => {
            {
                let datapoint = timeUnit.GetNumberOfUsersForAnOperatorData(operatorId);
                data.push(datapoint);
            }});
        
            return data;
        }


    }

export class TimeUserReportItem 
    {
        dateIndex: number;
        count: number =0;
        bookmakerDict: { [key: string]: TimeOperatorUserReportItem } = {};
        timeUnitName: string;

        constructor(dateIndex: number, timeUnitName:string) {
            this.dateIndex = dateIndex;
            this.timeUnitName = timeUnitName;
        }

        addUser(user: UserReportItem) 
        {
            this.count += 1;
            let x = this.bookmakerDict[user.operatorId];
            if (x == undefined) {
                this.bookmakerDict[user.operatorId] = new TimeOperatorUserReportItem(user);
            }
            else
            {
                x.addUser(user);
            }
        }
        

        GetNumberOfUsersForAnOperatorData(operatorId:string):any
        {
            let x = this.bookmakerDict[operatorId];
            if(x == undefined)
            {   
                return {x: this.timeUnitName, y: 0};
            }
            else
            {
                return {x: this.timeUnitName, y: x.GetNumberOfUsers()};
            }        
        }
    }

    export class TimeOperatorUserReportItem 
    {
        count: number =0;
        operator: string;

        constructor(user: UserReportItem) {
            this.operator = user.operatorId;
            this.count += 1;
        }

        addUser(user: UserReportItem) {
            this.count += 1;
        }

        GetNumberOfUsers():number
        {
            return this.count;
        }
    }

export class UserReportItem 
{
    id: string;
    createdAt:Date;
    operatorPlayerId: string;
    operatorId:string;
    createdOperatorChannel: string;
    notes: string;

    constructor(id: string, createdAt: Date, operatorPlayerId: string, operatorId:string, createdOperatorChannel: string, notes: string) {
        this.id = id;
        this.createdAt = createdAt;
        this.operatorPlayerId = operatorPlayerId;
        this.createdOperatorChannel = createdOperatorChannel;
        this.notes = notes;
        this.operatorId = operatorId;   
    }
}