import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    List, SimpleForm, Edit, SelectInput, TextInput,
    FormDataConsumer,
} from 'react-admin';

import AmountField from "../../fields/AmountField";
import {RoundTypeReferenceField} from "../roundTypes";
import AmountInput from "../../fields/AmountInput";

const targetBalanceChoices = [
    { id: 'EXTRA', name: 'Extra' },
    { id: 'EXTRA_TOP', name: 'Extra Top' },
];

export const RoundTypeRolloverFundsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <RoundTypeReferenceField source={'roundTypeId'}/>
            <AmountField source={'fundRolloverBalance'} label={'Fund Rollover Balance'}/>
        </Datagrid>
    </List>
);

export const TargetBalanceInput = props =>
    <SelectInput {...props} choices={targetBalanceChoices} />;


export const RoundTypeRolloverFundsEdit = props => {
    const {source} = props;
    return (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <RoundTypeReferenceField source={'roundTypeId'}/>
            <AmountField source={'fundRolloverBalance'} label={'Fund Rollover Balance'}/>
            <TextInput source={'targetRoundId'}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <AmountInput source={'amount'} fixedCurrency={formData.fundRolloverBalance.currency}/>
                }
            </FormDataConsumer>

            <SelectInput source={'targetBalance'} choices={targetBalanceChoices}/>
        </SimpleForm>
    </Edit>
)};
