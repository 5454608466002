import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showNotification as showNotificationAction, refreshView as refreshViewAction } from 'react-admin';
import fetchApi from "../../fetchApi";
import PopupDialog from "../../components/PopupDialog";
import UnitsInput from "../../fields/UnitsInput";
import { Form } from 'react-final-form'

class AdjustBalanceButton extends Component {
    constructor(props) {
        super(props);

        this.state = {submitting: false, open: false}
    }
    handleConfirm = (values) => {
        const { balance, record, showNotification, refreshView } = this.props;

        this.setState({submitting: true})
        fetchApi(record._links.adjustBalance.href, {
            method: 'POST',
            body: JSON.stringify({
                balance,
                ...values
            })
        }).then(() => {
            showNotification('Balance adjusted', 'info');

            refreshView();
            this.setState({submitting: false})
        }).catch((e) => {
            showNotification('Error adjusting balance', 'error');
            this.setState({submitting: false})
        });
    };

    handleClose = () => {
        this.setState({open: false})
    }

    handleOpen = () => {
        this.setState({open: true})
    }

    render() {
        const {balance} = this.props;
        const {submitting, open} = this.state;

        return <div>
                <Form initialValues={{units: "0"}} onSubmit={this.handleConfirm} render={({ handleSubmit}) =>
                  <form onSubmit={handleSubmit}>
                      <PopupDialog open={open} onOk={handleSubmit} onClose={this.handleClose} title={"Adjust Balance " + balance}>
                          <UnitsInput type={'numeric'} source={'units'}/>
                      </PopupDialog>
                  </form>
                }/>
                <Button variant={'contained'} color={'primary'} disabled={submitting} onClick={this.handleOpen}>Adjust</Button>
                <span style={{visibility: !submitting ? 'hidden' : 'visible'}}><CircularProgress/></span>
            </div>;
    }
}

AdjustBalanceButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    refreshView: PropTypes.func,
    balance: PropTypes.string
};

export default connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
})(AdjustBalanceButton)