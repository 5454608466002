import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  BooleanField,
  SelectField, Filter, TextInput, SelectInput
} from 'react-admin';
import VerboseSelectInput from "../../components/VerboseSelectInput";
import {Field} from "react-final-form";
import {addDays} from "date-fns";
import {FixtureReferenceField, FixtureReferenceInput} from "../fixtures";
import {
    AdminPickedOutcomeInput,
    OutcomeDecisionMethodInput,
    RoundTypeFormField,
    RoundTypeFormInput
} from "../roundTypes";
import CompositeForm from "../../components/CompositeForm";
import {makeStyles} from "@material-ui/core/styles";
import Outcome from "../../sign/Outcome";

const useStyles = makeStyles({
  outcome: {
    display: 'flex',
    alignItems : 'center',
  },
  outcomeValue: {
    width: '6em'
  },
  outcomeN: {
    marginLeft: '2em',
    minWidth: '4em',
    width: '4em',
  },
  outcomeChosen: {
    marginLeft: '2em'
  }
})

const statusChoices = [
  { id: 'PENDING', name: 'Pending', desc: 'Draw is work in progress and will not be shown to players'},
  { id: 'PUBLISHED', name: 'Published', desc: 'Draw is published to players, but not finished yet'},
  { id: 'FINISHED', name: 'Finished', desc: 'Draw is finished, update to this status to perform draw and update round result'},
];

export const DrawStatusInput = props =>
  <SelectInput {...props} choices={statusChoices} />;

export const VerboseDrawStatusInput = props =>
  <VerboseSelectInput {...props} choices={statusChoices} />;

const DrawStatusField = props =>
  <SelectField {...props} choices={statusChoices} />;


const DrawFilterForm = ({filterComponent: FilterComponent, ...props}) => {
  return <FilterComponent {...props}>
    <DrawStatusInput label={'Status'} source={"status"} allowEmpty alwaysOn/>
  </FilterComponent>;
}

const DrawFilter = (props) => (
  <DrawFilterForm filterComponent={Filter} includeSearch {...props}/>
);


export const DrawCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <FixtureReferenceInput source={'fixtureId'}/>
            <RoundTypeFormInput source={'form'}/>
            <OutcomeDecisionMethodInput source={'outcomeDecisionMethod'}/>
        </SimpleForm>
    </Create>
);

const DrawOutcomeListForm = (props) => {
  const classes = useStyles();

  const record = props.record;
  if (!record) return null;


  return <div>
    <div style={{display: 'flex'}}>
      <b className={classes.outcomeValue}>Outcome</b>
      <b className={classes.outcomeN}>Tickets</b>
      <b className={classes.outcomeChosen}>Chosen?</b>
    </div>
    {record.outcomes.map((outcome, index) =>
      <CompositeForm {...props} className={classes.outcome} key={outcome.id}>
        <Field className={classes.outcomeValue} name={`outcomes[${index}].sign`} component={Outcome} addLabel={false}/>
        {record.status === 'FINISHED' ?
          <div className={classes.outcomeN} fullWidth>
            <NumberField {...props} source={`outcomes[${index}].n`} addLabel={false} label={''}/>
          </div>
          :
          <NumberInput {...props} className={classes.outcomeN} source={`outcomes[${index}].n`} addLabel={false} label={''}
          />
        }
        <BooleanField className={classes.outcomeChosen} source={`outcomes[${index}].result`} addLabel={false}/>
      </CompositeForm>
    )}
  </div>

}

export const DrawEdit = props => (
    <Edit {...props}>
        <SimpleForm>
          <TextField source="id" />
          <FixtureReferenceField source={'fixtureId'} addLabel={true}/>
          <RoundTypeFormField source={'form'} addLabel={true}/>
          <OutcomeDecisionMethodInput source={'outcomeDecisionMethod'} addLabel={true}/>
          <AdminPickedOutcomeInput source={'adminPickedOutcome'} addLabel={true}/>
          <VerboseDrawStatusInput source={'status'} addLabel={true}/>
          <DateField source="createdAt" showTime/>
          <DateField source="result.createdAt" showTime label={'Outcome chosen at'}/>
          <DrawOutcomeListForm label={'Outcomes'} addLabel={true}/>
        </SimpleForm>
    </Edit>
);

export const DrawGrid = props =>
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" />
    <FixtureReferenceField source={'fixtureId'}/>
    <RoundTypeFormField source={'form'}/>
    <DateField source="createdAt" showTime/>
    <DrawStatusField source={"status"}/>
  </Datagrid>;

export const DrawList = props => (
    <List filters={<DrawFilter/>} filterDefaultValues={{status: "PENDING", fromAt: new Date(), toAt: addDays(new Date(), 14)}}  {...props}>
      <DrawGrid/>
    </List>
);


const referenceNameOfDraw = f =>
  `${f.id}-${f.status}`

export const DrawReferenceInput = props =>
    <ReferenceInput label={'Draw'}  reference="draws" {...props} >
        <AutocompleteInput optionText={referenceNameOfDraw} emptyValue={""}/>
    </ReferenceInput>;

const DrawReferenceName = props => {
  const r = props.record;
  if (!r) return "";

  return <span>{referenceNameOfDraw(r)}</span>;
}

export const DrawReferenceField = props =>
    <ReferenceField reference="draws" {...props} >
        <DrawReferenceName/>
    </ReferenceField>;
