import React from "react";
import { useEffect, useState } from 'react';
import { CouponShare } from "../models/CouponShare";
import dayjs from 'dayjs';
import { AppBar, Box, Button, Container, Grid, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import { getList, getListByPost } from "../api/dataProvider";
import { BetweenDates, DateIntervals, Roundbetsandresults, RoundbetsandresultsItem } from "../models/Roundbetsandresults";
import { dateTimeStringUK, getFirstDayLastWeek, getFirstDayLastWeekAsString, getFirstDayThisWeekAsString, getLastDayLastWeekAsString } from "../common/helpers/DateHelpers";
import { RoundResp, } from "../models/RoundsDto";
import {  formatToEuro, formatToPercent} from "../common/helpers/CurrencyHelpers";
import { ReportRound } from "./ReportInterfaces/ReportRound";


export class SlcReportItem {
    round_id: string;
    type: string;
    deadline: Date;
    stake: number;
    result: number;
    profitLoss: number;
    kickback: number;
    plAfterKickback: number;
    externalTurnover: number;

    constructor(round_id: string, type: string, deadline: Date, stake: number, result: number, profitLoss: number, kickback: number, plAfterKickback: number, externalTurnover: number) {
        this.round_id = round_id;
        this.type = type;
        this.deadline = deadline;
        this.stake = stake;
        this.result = result;
        this.profitLoss = profitLoss;
        this.kickback = kickback;
        this.plAfterKickback = plAfterKickback;
        this.externalTurnover = externalTurnover;
    }
}

// export class SlcRound {
//     round_id: string;
//     type: string;
//     deadline: Date;

//     constructor(round_id: string, type: string, deadline: Date) {
//         this.round_id = round_id;
//         this.type = type;
//         this.deadline = deadline;
//     }
// }
let slcRoundDict: { [key: string]: ReportRound } = {};
let slcReportDict: { [key: string]: SlcReportItem } = {};

let totalStake:number = 0;
let totalResult:number = 0;
let totalProfitLoss:number = 0;
let totalKickback:number = 0;
let totalProfitLossAfterKickback:number = 0;
let totalExternalTurnover:number = 0;

function SlcReport() {
    let [strFromDate, setStrFromDate] = useState(() => getFirstDayLastWeekAsString('YYYY-MM-DD'))
    let [strToDate, setStrToDate] = useState(() => getFirstDayThisWeekAsString('YYYY-MM-DD'))

    let empty2: SlcReportItem[] = [];
    const [slcReportData, setslcReportData] = useState(() => empty2)

    function loadData() {

        totalStake = 0;
        totalResult = 0;
        totalProfitLoss = 0;
        totalKickback = 0;
        totalProfitLossAfterKickback = 0;
        totalExternalTurnover = 0;

        let q: BetweenDates = {
            GreaterThanOrEqualTo: strFromDate + "T00:00:00+00:00",
            LessThanOrEqualTo: strToDate + "T00:00:00+00:00"
        };

        let q2: DateIntervals = {
            OpenToInterval: q
        };

        slcReportDict = {};

        getList('rounds',
            {
                pagination: { page: 0, perPage: 1000 },
                filter: { "column_operator_filters":[{"column":"open_to", "operator":"GREATER_THAN_OR_EQUAL", "comparison_value":q.GreaterThanOrEqualTo}, {"column":"open_to", "operator":"LESS_THAN_OR_EQUAL", "comparison_value":q.LessThanOrEqualTo}]},
            }).then((resp) => {
                console.log(resp);

                let b = resp as RoundResp;
                if (b.data != undefined) {
                    b.data.forEach(element => {
                        let x = new ReportRound(element.id, element.parameters.name, element.openTo);
                        slcRoundDict[element.id] = x;
                    });
                }



                getListByPost('extended/reports/roundbetsandresults', q2).then((resp) => {

                    let b = resp as Roundbetsandresults;

                    

                    if (b.data != undefined && b.data.items != undefined) {
                        b.data.items.forEach(element => {
                            let stake:number = parseFloat(element.unit_stake_currency_bet_sum.money.amount.toString());
                            let result:number = parseFloat(element.unit_stake_currency_result_sum.money.amount.toString());

                            let x = slcReportDict[element.round_id];
                            if (x == undefined) {
                                let type = "Undefined";
                                let deadline = new Date;
                                let round = slcRoundDict[element.round_id];
                                if (round != undefined) {
                                    type = round.type;
                                    deadline = new Date(round.deadline);
                                }   

                                x = new SlcReportItem(element.round_id, type, deadline, 0, 0, 0, 0, 0, 0);
                                slcReportDict[element.round_id] = x;
                            }
                            if (element.operator_id == "EURO_OPERATOR" || element.operator_id == "MOCKOP") {

                                x.stake += stake;
                                x.result += result;
                                x.profitLoss = x.result - x.stake ;
                                x.kickback = x.stake * 0.2;
                                x.plAfterKickback = x.profitLoss + x.kickback;

                                totalStake += stake;
                                totalResult += result;
                                totalProfitLoss += x.result - x.stake ;
                                totalKickback += x.stake * 0.2;
                                totalProfitLossAfterKickback += x.profitLoss + x.kickback;
                            }
                            else {
                               x.externalTurnover += stake;
                               totalExternalTurnover += stake;
                            }
                        });
                        setslcReportData(Object.values(slcReportDict).sort((a,b)=>a.deadline.getTime()-b.deadline.getTime()));
                    }
                });
            });
    }

    let slcReportItems: SlcReportItem[] = [];
    slcReportItems = slcReportData;

    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <TextField
                        id="fromDate"
                        label="From:"
                        type="date"
                        defaultValue={strFromDate}
                        onChange={(e) => { setStrFromDate(e.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="toDate"
                        label="To:"
                        type="date"
                        defaultValue={strToDate}
                        onChange={(e) => { { setStrToDate(e.target.value) } }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button variant="contained" onClick={() => { loadData() }}>Get</Button>
                    <SlcReportTable reportItems={slcReportItems} />
                </Grid>
            </Grid>
        </Container>
    );
}

export default SlcReport


function SlcReportTable({ reportItems }: { reportItems: SlcReportItem[] }) {
    

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Deadline</TableCell>
                    <TableCell>Stake</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>ProfitLoss</TableCell>
                    <TableCell>Kickback</TableCell>
                    <TableCell>P/L after Kickback</TableCell>
                    <TableCell>External Turnover</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    reportItems.map((round, index) => {
                        return (
                            <TableRow key={'row' + index}>
                                <TableCell key={'type' + index}>{round.type}</TableCell>
                                <TableCell key={'deadline' + index}>{dateTimeStringUK(round.deadline)}</TableCell>
                                <TableCell key={'stake' + index}>{formatToEuro(round.stake)}</TableCell>
                                <TableCell key={'result' + index}>{formatToEuro(round.result)}</TableCell>
                                <TableCell key={'profitLoss' + index}>{formatToEuro(round.profitLoss)}</TableCell>
                                <TableCell key={'kickback' + index}>{formatToEuro(round.kickback)}</TableCell>
                                <TableCell key={'plAfterKickback' + index}>{formatToEuro(round.plAfterKickback)}</TableCell>
                                <TableCell key={'externalTurnover' + index}>{formatToEuro(round.externalTurnover)}</TableCell>
                            </TableRow>);
                    })
                   
                }
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{formatToEuro(totalStake)}</TableCell>
                        <TableCell>{formatToEuro(totalResult)}</TableCell>
                        <TableCell>{formatToEuro(totalProfitLoss)}</TableCell>
                        <TableCell>{formatToEuro(totalKickback)}</TableCell>
                        <TableCell>{formatToEuro(totalProfitLossAfterKickback)}</TableCell>
                        <TableCell>{formatToEuro(totalExternalTurnover)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Margin vs External</TableCell>
                        <TableCell>{formatToPercent(totalProfitLossAfterKickback/totalExternalTurnover)}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
            </TableBody>

        </Table>
    );

}



