import React from 'react';
import {
  Edit,
  Datagrid,
  TextField,
  DateField,
  List,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Pagination,
  NumberField,
  SimpleForm
} from 'react-admin';
import {SeasonGrid, SeasonReferenceField} from "../seasons";
import {GameRoundGrid, GameRoundReferenceField} from "../gameRound";
import {FixtureGrid} from "../fixtures";

export const ReducedSystemList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <NumberField source="nRows"/>
        </Datagrid>
    </List>
);

export const ReducedSystemEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="nRows"/>
    </SimpleForm>
  </Edit>
);

export const ReducedSystemReferenceInput = props =>
    <ReferenceInput label={'ReducedSystem'}  reference="reducedSystems" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""}/>
    </ReferenceInput>;

export const ReducedSystemReferenceField = props =>
    <ReferenceField reference="reducedSystems" {...props} >
        <TextField source="name" />
    </ReferenceField>;
