import React, { useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Link } from '@material-ui/core';
import { Draw, Fixture, RoundOverviewFixture } from "./RoundOverviewModels";
import { min } from "moment";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "./RoundOverviewUtil";
import DrawRulesDialog from "./DrawRulesDialog";
import { LeagueListItem } from "../CreateRound/CreateRoundModels";

interface LeagueChooserProps {
    onChange: (league: LeagueListItem | undefined) => void;
}
interface LeagueChooserState {
    searchLeagueName: string;
    pageSize: string;
    leagueId: string;
    league: LeagueListItem | undefined;
}


const LeagueChooser: React.FC<LeagueChooserProps> = (props) => {
    const getDefaultFormState = () : LeagueChooserState => {
        return {
            searchLeagueName: "",
            pageSize: "100",
            leagueId: "",
            league: undefined
        };
    }
    
    const [open, setOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<LeagueChooserState>(getDefaultFormState());
    const [leagues, setLeagues] = useState<LeagueListItem[]>([]);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [leaguePage, setLeaguePage] = useState<number>(-1);
    const [leaguesMore, setLeaguesMore] = useState<boolean>(false);
 
    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
    };

    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        if (name === "leagueId") {
            if (!value || value.length <= 0) {
                //fs.leagueName = "";
                fs.league = undefined;
            } else {
                var league = leagues.find(it => it.id === value);
                fs.league = league;
            }
            props.onChange(fs.league);
        }
        setFormState(fs);
    }
    const refreshLeagues = (more: boolean) => {
        var pageSize = parseInt(formState.pageSize);
        setSaveStatus(0);
        if (isNaN(pageSize) || pageSize < 1) {
            setSaveError("Page size is not a number or less than 1");
            return;
        }
        var page = leaguePage;
        if (more) {
            page++;
        } else {
            page = 0;
        }
        setSaveError("");
        setSaveStatus(1);
        CreateRoundUtil.getLeagueListItems(pageSize, page, formState.searchLeagueName).then(resp => {
            if (resp.success) {
                setLeaguePage(page);
                if (resp.item) {
                    var leaguesX = resp.item;
                    if (more) {
                        CreateRoundUtil.addLeagues(leagues, leaguesX, true);
                    } else {
                        setLeagues(leaguesX);
                    }
                    if (leaguesX.length < pageSize){
                        setLeaguesMore(false);
                    } else {
                        setLeaguesMore(true);
                    }
                    setLeaguePage(page);
        
                    if (formState.leagueId !== ""){
                        var league = leaguesX.find(it => it.id === formState.leagueId);
                        if (league){
                            formState.league = league;
                        } else {
                            formState.league = undefined;
                        }
                    }
                    return;
                } else {
                    setLeagues([]);
                    setLeaguesMore(false);
                    formState.league = undefined;
                }
            
            } else {
                setSaveError(`Search error: ${resp.message}`);
            }
        }).catch(error => {
            setSaveError(`Search error: ${error}`);
        }).finally(() => {
            setSaveStatus(0);
        });
    }
    const onSearchClick = (): void => {
        refreshLeagues(false);
    }
    const onLeaguesMore = () => {
        refreshLeagues(true);
     };

    var searchValid = true;
    var valid = true;
    var pageSizeErr = "";
    if (formState.pageSize.length === 0){
        searchValid = false;
        pageSizeErr = "Required";
    } else {
        var pageSize = parseInt(formState.pageSize);
        if (isNaN(pageSize) || pageSize < 1){
            pageSizeErr = "Invalid int (1-n)"
        }
    }

    var leagueIdErr = "";
    if (formState.leagueId === ""){
        valid = false;
        leagueIdErr = "Required";
    } 

    var searchEnabled = (searchValid && saveStatus === 0);
    var saveEnabled = (valid && saveStatus === 0);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
   
    var leuageHelperText = formState.league ? formState.league.rawName : "";
    if (leuageHelperText.length > 0){
       leuageHelperText += "; ";
    }
    leuageHelperText += "Count:"+ leagues.length + "; Page:" + leaguePage + "; PageSize:" + formState.pageSize;

    return (
        <div>
            <Box paddingTop={1} display="flex" alignItems="center">
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">
                    {/* <FormLabel style={{ paddingRight: 2 }}>Page size:</FormLabel> */}
                    <TextField
                        name="pageSize" label="Page size" type="number" value={formState.pageSize} style={{ width: 100 }}
                        error={pageSizeErr && pageSizeErr.length ? true : false}
                        helperText={pageSizeErr}
                        onChange={e => handleChange("pageSize", e.target.value)}
                    />
                    <span style={{ padding: 2 }}></span>
                    <TextField
                        name="searchLeagueName"
                        label="Search name"
                        value={formState.searchLeagueName}
/*                         helperText={"Character sequence of League name"}
 */                        onChange={e => handleChange("searchLeagueName", e.target.value)}
                    />
                    <span style={{ padding: 2 }}></span>
                    <Button variant="contained" color="secondary" type="button" size="small" onClick={onSearchClick} disabled={!searchEnabled}>
                        Search
                    </Button>
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">

                    <TextField
                        name="leagueId"
                        label="League"
                        select
                        value={formState.leagueId}
                        onChange={e => handleChange("leagueId", e.target.value)}
                        style={{ width: 500 }}
                        error={(leagueIdErr && leagueIdErr.length > 0) ? true : false}
                        helperText={(leagueIdErr && leagueIdErr.length > 0) ? leagueIdErr : leuageHelperText}
                    >
                        <MenuItem key="" value="" />
                        {leagues.map(league => (
                            <MenuItem key={league.id} value={league.id}>
                                {league.title}
                            </MenuItem>
                        ))}
                    </TextField>
                    <span style={{ padding: 2 }}></span>
                    <Button variant="contained" color="secondary" size="small" disabled={!leaguesMore} onClick={e => onLeaguesMore()}>
                        More
                    </Button>
                </Box>
                {saveErrorBox} 
        </div>
    );
};

export default LeagueChooser;
