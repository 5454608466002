import React, { useEffect } from "react";
import { useState } from 'react';
import { Button, Container, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { getFirstDayCurrentMonthAsString, getFirstDayThisWeekAsString, getFirstDayofYearOrStartAsString, getShortMonthName, getTodayAsString } from "../common/helpers/DateHelpers";
import {  formatToEuro, formatToPercent} from "../common/helpers/CurrencyHelpers";
import { MergedRoundAndResultsItem, SlcPerMonthData, TimeUnits, getPerTimeUnitAndOperatorReportItemsFromData, getSlcPerMonthReportItems, getTransformAndMergeRoundsAndResult } from "../common/helpers/ApiHelper";
import { BookmakerReportItem, TimeReport } from "./ReportInterfaces/TimeReportItem";

function TurnoverReport() 
{
    let [strFromDate, setStrFromDate] = useState(() => getFirstDayCurrentMonthAsString('YYYY-MM-DD'))
    let [strToDate, setStrToDate] = useState(() => getTodayAsString('YYYY-MM-DD'))
    
    let [timeUnit, setTimeUnit] = useState(() => TimeUnits.Month)
    useEffect(() => { ReloadData() }, [timeUnit]);

    const [mergedRoundAndResultsItems, setMergedRoundAndResultsItems] = useState(() => new Array<MergedRoundAndResultsItem>())


    const [timeData, setTimeData] = useState(() => new TimeReport())
    let timeReportData: TimeReport = timeData;

    function loadData() 
    {
        getTransformAndMergeRoundsAndResult(strFromDate, strToDate).then((data: MergedRoundAndResultsItem[]) => 
        {
            setMergedRoundAndResultsItems(data);
            let timeData = getPerTimeUnitAndOperatorReportItemsFromData(data, timeUnit);
            setTimeData(timeData);
        }); 
    }

    function ReloadData() 
    {
        let timeData = getPerTimeUnitAndOperatorReportItemsFromData(mergedRoundAndResultsItems, timeUnit);
        setTimeData(timeData);
    }

    


    return (
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                    <Grid item xs={3}>
                        <TextField
                            id="fromDate"
                            label="From:"
                            type="date"
                            defaultValue={strFromDate}
                            onChange={(e) => { setStrFromDate(e.target.value) }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <br />
                        <TextField
                            id="toDate"
                            label="To:"
                            type="date"
                            defaultValue={strToDate}
                            onChange={(e) => { { setStrToDate(e.target.value) } }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={timeUnit}
                            onChange={(e) => {  
                                                //ReloadData(); 
                                                setTimeUnit(parseInt(e.target.value)); 
                                                
                                            }} 
                        >
                            <FormControlLabel value={TimeUnits.Month} control={<Radio />} label="Month" />
                            <FormControlLabel value={TimeUnits.Week} control={<Radio />} label="Week" />
                            <FormControlLabel value={TimeUnits.Day} control={<Radio />} label="Day" />
                        </RadioGroup>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => { loadData() }}>Get</Button>
                    </Grid>
                <Grid item xs={12}>
                    <ReportTable reportData={timeReportData} />
                </Grid>

            </Grid>
        </Container>
    );
}

function GetOperatorTurnover(tunoverDict:{ [key: string]: BookmakerReportItem }, operatorId:string) : number
{
    let turnover = 0;
    let operatorItem = tunoverDict[operatorId];
    if(operatorItem != undefined)
    {
        turnover = operatorItem.stake;
    }
    return turnover;
}

function GetOperatorResult(tunoverDict:{ [key: string]: BookmakerReportItem }, operatorId:string) : number
{
    let result = 0;
    let operatorItem = tunoverDict[operatorId];
    if(operatorItem != undefined)
    {
        result = operatorItem.result;
    }
    return result;
}


export default TurnoverReport


function ReportTable({ reportData }: { reportData: TimeReport }) 
{
    let operators = Object.keys(reportData.bookmakerLookup);
    let timeObjList = Object.values(reportData.timeDict)


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Stake</TableCell>
                    <TableCell>Result</TableCell>
                    {
                        operators.map((operatorId, index) => {
                            return (
                                <TableCell colSpan={2} key={'operator' + index}>{operatorId}</TableCell>
                            );
                        })
                    }
                </TableRow>
            </TableHead>
             <TableBody>
                {
                    timeObjList.map((timeUnit, index) => {
                        return (
                            <TableRow key={'row' + index}>
                                <TableCell key={'time' + index}>{timeUnit.timeUnitName}</TableCell>
                                <TableCell key={'stake' + index}>{formatToEuro(timeUnit.stake)}</TableCell>
                                <TableCell key={'result' + index}>{formatToEuro(timeUnit.result)}</TableCell>
                                {
                                    operators.map((operatorId, index) => {
                                        return (
                                            <><TableCell key={'operatorTurnover' + index}>{formatToEuro(GetOperatorTurnover(timeUnit.bookmakerDict, operatorId))}</TableCell>
                                            <TableCell key={'operator' + index}>{formatToEuro(GetOperatorResult(timeUnit.bookmakerDict, operatorId))}</TableCell></>
                                        );
                                    })
                                }
                            </TableRow>);
                    })
                   
                }

            </TableBody> 

        </Table>
    );
}