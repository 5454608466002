import React from 'react';
import {
  Edit, TextInput, Datagrid, TextField, DateField, List,
  ReferenceInput, AutocompleteInput, ReferenceField, TabbedForm, SelectField, Filter,
  RadioButtonGroupInput, ReferenceManyField, Pagination, FormTab
} from 'react-admin';
import {LeagueReferenceField} from "../leagues";
import {GameRoundGrid, GameRoundReferenceField} from "../gameRound";
import {FixtureGrid} from "../fixtures";

const statusChoices = [
  { id: 'INACTIVE', name: 'Inactive'},
  { id: 'CURRENT', name: 'Current'},
];

const SeasonStatusInput = props =>
  <RadioButtonGroupInput {...props} choices={statusChoices} />;

const SeasonStatusField = props =>
  <SelectField {...props} choices={statusChoices} />;

const SeasonFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SeasonStatusInput label={'Status'} source={"status"} alwaysOn/>
  </Filter>
);

export const SeasonGrid = props =>
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id"/>
    <TextField source="name"/>
    <DateField source="createdAt" showTime/>
    <SeasonStatusField source="status"/>
    <LeagueReferenceField source={"leagueId"} addLabel={true}/>
    <TextField source="providerId"/>
    <GameRoundReferenceField source={"currentGameRoundId"} allowEmpty addLabel={true}/>
  </Datagrid>;

export const SeasonList = props => (
    <List filters={<SeasonFilter/>} filterDefaultValues={{status: 'CURRENT'}} {...props}>
      <SeasonGrid/>
    </List>
);

export const SeasonEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label={'General'}>
        <TextField source="id" />
        <DateField source="createdAt" showTime/>
        <TextField source="name" />
        <TextField source="status" />
        <LeagueReferenceField source={"leagueId"} addLabel={true}/>
        <TextField source="providerId" />
        <GameRoundReferenceField source={"currentGameRoundId"} allowEmpty addLabel={true}/>
      </FormTab>
      <FormTab label={'Game Rounds'}>
        <ReferenceManyField label={"Game Rounds"} reference="gameRounds" target={"leagueId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>} addLabel={true}>
          <GameRoundGrid/>
        </ReferenceManyField>
      </FormTab>
      <FormTab label={'Fixtures'}>
        <ReferenceManyField label={"Fixtures"} reference="fixtures" target={"seasonId"} sort={{field: 'createdAt', order: 'DESC'}} pagination={<Pagination/>}>
          <FixtureGrid/>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);


export const SeasonReferenceInput = props =>
    <ReferenceInput label={'Season'}  reference="seasons" {...props} >
        <AutocompleteInput optionText="name" emptyValue={""}/>
    </ReferenceInput>;

export const SeasonReferenceField = props =>
    <ReferenceField reference="seasons" {...props} >
        <TextField source="name" />
    </ReferenceField>;
