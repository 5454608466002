import fetchApi from "../../fetchApi";
import { QueryParams } from "../models/CouponShare";
import queryString from "query-string";

export function getList(resource: string, params: QueryParams) {

    const query = Object.assign({
        orderBy: `${params.sort?.field} ${params.sort?.order}`,
        pageSize: params.pagination?.perPage,
        page: params.pagination?.page,
        filter: JSON.stringify(params.filter)
    });
    const url = `${resource}?${queryString.stringify(query)}`;

    return fetchApi(url).then(({ json }) => ({
        data: json._embedded[resource],
        total: json.totalSize
    }));
}

export function getListWithId(resource: string, ids: number[]) {

    const query = Object.assign({
        id: ids.join(', '),
        pageSize: 100,
    });
    const url = `${resource}?${queryString.stringify(query)}`;

    return fetchApi(url).then(({ json }) => ({
        data: json._embedded[resource],
        total: json.totalSize
    }));
}

export function getObject(resource: string) {
   
    const url = `${resource}`;

    return fetchApi(url).then(({ json }) => ({
       data: json
    }));
}



export function getListByPost(resource: string, requestParams: any) {

    let params = JSON.stringify(requestParams);

    return fetchApi(resource, {
        method: 'POST',
        body: params
    }).then(({ json }) => ({
        data: json
    })).catch(error => {
        console.error('There was an error!', error);
    });;
}