import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import { League } from './LeagueModels';
import LeagueRow from './LeagueRow';
import DataTable, { DataTableColumn, Order, filterDataTableItems, getDataTablePage, useDataTableStyles } from '../Components/DataTable';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';



interface LeagueTableProps {
    leagues: League[];
    onSelect? : (item : League) => void;
}

const LeagueTable: React.FC<LeagueTableProps> = (props) => {
    const [selectedLeagueId, setSelectedLeagueId] = useState<number>(0);
    const [columns, setColumns] = React.useState<DataTableColumn<League>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<League>[] = [
        { id: 'id', numeric: true, disablePadding: true, label: "League Id", filterEnabled: true },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name', filterEnabled: true },
        { id: 'title', numeric: false, disablePadding: false, label: 'Title', filterEnabled: true },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at' },
        { id: 'currentSeasonName', numeric: false, disablePadding: false, label: 'Current Season', filterEnabled: true },
        { id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled' },
        { id: 'nFixtures', numeric: true, disablePadding: false, label: '#Future fixtures' },
        { id: 'externalId', numeric: false, disablePadding: false, label: 'External id', filterEnabled: true },
      ];
      setColumns(cols);
    }
  
    const onSelect = (item : League) => {
        setSelectedLeagueId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const createRow = (item : League, classes: ClassNameMap) : JSX.Element =>  {
      return (<LeagueRow league={item} selected={selectedLeagueId === item.id} onSelect={onSelect} />);
    };
  
  return (
      <DataTable columns={columns} items={props.leagues} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={true} size='xsmall' createRow={createRow} onSelect={onSelect}  />
  );
};
  
export default LeagueTable;