import React from "react";



function EmptyReport()
{   
    return (
        <h1>Empty Report</h1>
        );

}

export default EmptyReport