import { CouponShare } from "../models/CouponShare";
import { useEffect, useState } from 'react';

import React from "react";
import { Box, Container, Grid, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import EmptyReport from "./EmptyReport";
import SlcReport from "./SlcReports";
import TurnoverReport from "./TurnoverReport";
import NewUsersReport from "./NewUsersReport";
import { Title } from "react-admin";


enum ReportType {
    None,
    SLC,
    Turnover,
    NewUsers
}
export interface Report {
    id: ReportType;
    name: string;
}

function Reports() {

    let allReports: Report[] = [
        { id: ReportType.None, name: 'None Selected' },
        { id: ReportType.SLC, name: 'SLC Report' },
        { id: ReportType.Turnover, name: 'Turnover' },
        { id: ReportType.NewUsers, name: 'New Users' }
    ];

    const [selectedReport, setSelectedReport] = React.useState(0);


    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedReport(event.target.value as number);
    };

    return (
        <Container maxWidth="lg">
            <Title title="Reports" />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <FormControl variant="filled">
                        <InputLabel id="select-filled-label">Selected Report</InputLabel>
                        <Select
                            labelId="select-label"
                            id="simple-select"
                            value={selectedReport}
                            onChange={handleChange}
                        >
                            {Object.keys(allReports).map((key, index) => (
                                <MenuItem key={index} value={index}>
                                    {allReports[index].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                {selectedReport === ReportType.None && 
                    <EmptyReport/>
                }
                {selectedReport === ReportType.Turnover &&  
                    <TurnoverReport/>
                }
                {selectedReport === ReportType.SLC &&  
                    <SlcReport/>
                }
                {selectedReport === ReportType.NewUsers &&  
                    <NewUsersReport/>
                }
                </Grid>
            </Grid>
        </Container>

    )

}

export default Reports;