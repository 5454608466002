import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    List, SimpleForm, Edit, SelectInput, TextInput, Create,
    FormDataConsumer, ReferenceInput, AutocompleteInput, ReferenceField, NumberField,
} from 'react-admin';

import {RoundReferenceField} from "../rounds";

export const RoundPromotionValuesList = props => (
    <List {...props}>
        <RoundPromotionValuesGrid />
    </List>
);

export const RoundPromotionValuesCreate = props => {
    return (
    <Create undoable={false} {...props}>
        <SimpleForm>
            <TextInput source={'roundId'}/>
            <TextInput source={'key'}/>
            <TextInput source={'value'}/>
        </SimpleForm>
    </Create>
)};

export const RoundPromotionValuesEdit = props => {
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm>
                <TextInput source={'roundId'}/>
                <TextInput source={'key'}/>
                <TextInput source={'value'}/>
            </SimpleForm>
        </Edit>
    )};

export const RoundPromotionValuesGrid = props => (
    <Datagrid rowClick="edit" {...props}>
        <TextField source="id" />
        <RoundReferenceField source={'roundId'}/>
        <TextField source={'key'}/>
        <TextField source={'value'}/>
    </Datagrid>
)