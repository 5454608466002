import React from "react";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import ItemSelect from '../Components/ItemSelect';
import { ListResponse } from "../api/ApiNgModels";
import { FreebetType } from "./FreebetModels";
import FreebetUtil from "./FreebetUtil";

interface FreebetTypeSelectProps {
    value: number;
    disabled?: boolean;
    onChange: (item: FreebetType | undefined) => void;
    allItemEnabled: boolean;
    helperText?: string;
    error?: boolean;
}
const FreebetTypeSelect: React.FC<FreebetTypeSelectProps> = (props) => {
  
    const getItems = async (pageSize: number, lastId?: number) : Promise<GenericResponse<ListResponse<FreebetType>>> => {
        return await FreebetUtil.getFreebetTypes(pageSize, lastId ? lastId : 0);
    };


    const getId = (item?: FreebetType) : number => {
        return item ? item.id : 0;
    };

    const onChange = (item?: FreebetType) : void => {
        if (props.onChange) {
            props.onChange(item);
        }
    };
  
    return (
        <ItemSelect<FreebetType, number>
            value={props.value}
            label="Freebet type"
            getId={getId}
            getIdString={it => it ? it.id.toString() : ""}
            getName={it => it ? it.name : ""}
            getItems={getItems}
            idFromString={s => (s && s.length > 0) ? parseInt(s) : 0}
            onChange={onChange}
            disabled={props.disabled}
            getInfoText={it => it ? `Id: ${it.id} | Name:  ${it.name}` : ""}
            allItemEnabled={props.allItemEnabled}
            helperText={props.helperText}
            error={props.error}
        />
    );
};

export default FreebetTypeSelect;
