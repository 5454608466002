import React, { useState,useEffect } from 'react';
import { Container,TextField,Button, MenuItem, Box, Switch, FormControlLabel } from "@material-ui/core";
import { RoundCurrency, RoundOverviewItem } from '../RoundOverview/RoundOverviewModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import SelectRoundStep from './SelectRoundStep';
import { Title } from 'react-admin';
import CreateCouponStep from './CreateCouponStep';
import { Bet } from '../models/CouponDetail';
import { CouponEditInfo } from '../Components/CouponEdit';
import { CreateSyndicatesRequest, SyndicateInstanceContext } from './CreateSyndicateModels';
import SelectSyndicateInstancesStep from './SelectSyndicateInstancesStep';
import CreateSyndicateUtil from './CreateSyndicateUtil';
import FinishStep from './FinishStep';
import { SyndicateOverviewItem } from '../AgentOverview/AgentModels';
import SyndicateTable from './SyndicateTable';

type FinishStatus = 'None' | 'Finishing' | 'Error' | "Finished";
interface CreateSyndicateState {
    round?: RoundOverviewItem;
    roundError: string;
    couponError: string;
    instancesError: string;
    step : number;
    finishStatus: FinishStatus;
    finishMessage: "";
}

interface FinishState {
    status: FinishStatus,
    message: string;
    syndicates: SyndicateOverviewItem[]
}
const CreateSyndicate: React.FC = () => {
    const stepCount = 4;
    const [formState, setFormState] = useState<CreateSyndicateState>({
        roundError: "",
        couponError: "Coupon is not created",
        instancesError: "No syndicate instances selected",
        step: 1,
        finishStatus: 'None',
        finishMessage: ""
    });
    const [finishState, setFinishState] = useState<FinishState>({
        status: 'None',
        message: "",
        syndicates: []
    });

    const [bet, setBet] = useState<Bet>({
        id: "",
        legs: [],
        rows: []
    });
    const [instancesContext, setInstancesContext] = useState<SyndicateInstanceContext>({
        rowCount: 0,
        instances: [],
        error: "No instances selected"
    });

    const onSelectRound = (item?: RoundOverviewItem) : void => {
        var fs = { ...formState, ["round"]: item };
        fs.roundError = "";
        if (item) {
            if (item.deadline) {
                var now = new Date();
                if (item.deadline < now) {
                    fs.roundError = "Deadline has passed";
                }
            } else {
                fs.roundError = "Deadline is not set";
            }

            if (fs.roundError.length <= 0)  {
                if (item.status !== RoundOverviewUtil.ACTIVE_STATUS) {
                    fs.roundError = "Round is not active";
                }
            }
            if (fs.roundError.length <= 0)  {
                if (!item.visible) {
                    fs.roundError = "Round is not visible";
                }
            }
        } else {
            fs.roundError = "Round is not selected";
        }
        setFormState(fs);
        instancesContext.round = item;
    };
    const onCouponChange = (legIndex: number, info: CouponEditInfo) => {
        if (formState.couponError === info.error) {
            return;
        }
        var fs = { ...formState, ["couponError"]: info.error};
        instancesContext.rowCount = info.rowCount;
        setFormState(fs);
    };
    const onInstancesChange = () => {
        if (formState.instancesError === instancesContext.error) {
            return;
        }
        var fs = { ...formState, ["instancesError"]: instancesContext.error};
        setFormState(fs);
    };
    const onContinueClick = () => {
        var fs = { ...formState, ["step"]: formState.step + 1 };
        setFormState(fs);
    };
    // const onFinishClick = () => {
    //     var METHOD_NAME = "Finish";
    //     //var finishStatus : FinishStatus = "Finishing";
    //     var finishStatus = 1;
    //     var finishMessage : string = "";
    //     var fs = { ...formState, ["finishStatus"]: 2 };
    //     fs.finishMessage = "";
    //     try {
    //         setFormState(fs);
    //         var request : CreateSyndicatesRequest = {
    //             roundId: fs.round ? fs.round.id : 0,
    //             legs: bet.legs,
    //             instances: instancesContext.instances
    //         };
    //         CreateSyndicateUtil.createSyndicates(request).then((resp) => {
    //             if (!resp.success) {
    //                 //finishStatus = "Error";
    //                 finishStatus = 2;
    //                 //finishMessage  = `${METHOD_NAME} error: ${resp.message}`;
    //                 finishMessage  = METHOD_NAME + " error: " + resp.message;
    //                 return;
    //             }
    //             //finishStatus = "Finished";
    //             finishStatus = 4;
    //         }).catch(error => {
    //             //finishStatus = "Error";
    //             finishStatus = 2;
    //             //finishMessage  = `${METHOD_NAME} error: ${error}`;
    //             finishMessage  = METHOD_NAME + " error: " + error;
    //         }).finally( () => {
    //             var fs2 = { ...formState, ["finishStatus"]: finishStatus };
    //             //var error : string = finishMessage;
    //             fs2.finishMessage = finishMessage;
    //             setFormState(fs2);
    //         });
    //     } catch (error) {
    //         finishStatus = "Error";
    //         finishMessage = `${METHOD_NAME} error: ${error}`;
    //         console.error(finishMessage);
    //     } finally {
    //         var fs2 = { ...formState, ["finishStatus"]: finishStatus };
    //         fs2.finishMessage = finishMessage;
    //         setFormState(fs);
            
    //     }
    //};
    const onFinishClick = () => {
        var METHOD_NAME = "Finish";
        var finishStatus : FinishStatus = "Finishing";
        var finishMessage : string = "";
        var syndicates : SyndicateOverviewItem[] = [];
        setFinishState({ status: finishStatus, message: finishMessage, syndicates: [] });
        var request : CreateSyndicatesRequest = {
            roundId: formState.round ? formState.round.id : 0,
            bet: bet,
            instances: instancesContext.instances
        };
        CreateSyndicateUtil.createSyndicates(request).then((resp) => {
            if (!resp.success) {
                finishStatus = "Error";
                //finishStatus = 2;
                //finishMessage  = `${METHOD_NAME} error: ${resp.message}`;
                finishMessage  = METHOD_NAME + " error: " + resp.message;
                return;
            }
            finishStatus = "Finished";
            syndicates = resp.item ? resp.item : [];
            if (resp.message && resp.message.length > 0) {
                finishMessage = `Syndicates were created: ${resp.message}`;
            } else {
                finishMessage = `Syndicates were created`;
            }
        }).catch(error => {
            finishStatus = "Error";
            //finishMessage  = `${METHOD_NAME} error: ${error}`;
            finishMessage  = METHOD_NAME + " error: " + error;
        }).finally( () => {
            setFinishState({ status: finishStatus, message: finishMessage, syndicates: syndicates });
        });
    };
        
    var roundId : number = formState.round ? formState.round.id : 0;
    var wizStep = null;
    var stepName = "";
    var continueEnabled = false;
    var stepError = "";
    if (formState.step === 1){

        stepName = "Select round";
        wizStep = (<SelectRoundStep selectedId={roundId} onSelect={onSelectRound} />);
        if (formState.roundError.length > 0) {
            stepError = formState.roundError;
        } else if (formState.round) {
            continueEnabled = true;
        }
    } else if (formState.step === 2) {
        stepName = "Create coupon";
        if (formState.round) {
            wizStep = (<CreateCouponStep round={formState.round} bet={bet} onChange={onCouponChange} />);
            if (formState.couponError.length > 0) {
                stepError = formState.couponError;
            } else {
                continueEnabled = true;
            }
        } else {
            stepError = `Round is not specified [Error: ${formState.roundError}]`;
        }
    } else if (formState.step === 3) {
        stepName = "Select instances";
        if (formState.round) {
            wizStep = (<SelectSyndicateInstancesStep context={instancesContext} onChange={onInstancesChange} />);
            if (formState.instancesError.length > 0) {
                stepError = formState.instancesError;
            } else {
                continueEnabled = true;
            }
        } else {
            stepError = `Round is not specified [Error: ${formState.roundError}]`;
        }
    } else if (formState.step === 4) {
        stepName = "Finish";
        if (formState.round) {
            wizStep = (<FinishStep round={formState.round} bet={bet} rowCount={instancesContext.rowCount} instances={instancesContext.instances} />);
        }
    } else {
        stepName = "Not implemented";
        stepError = "Not implemented";
    }
    var stepErrorBox = null;
    if (stepError.length > 0) {
        stepErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{stepError}</Box>);      
    }
    var roundInfoBox = null;
    if (formState.step > 1 && formState.round) {
        roundInfoBox  = (<Box><h5>{`Round: ${RoundOverviewUtil.getRoundTitle(formState.round)} | Rows: ${instancesContext.rowCount}`}</h5></Box>);
    }
    
    var continueButton = null;
    if (formState.step < 4) {
        continueButton = (<Button onClick={e => onContinueClick()} variant="contained" color="primary" disabled={!continueEnabled}>Continue</Button>);
    }
    var finishButton = null;
    var syndicateTable = null;
    if (formState.step === 4) {
        finishButton = (<Button onClick={e => onFinishClick()} variant="contained" color="primary" disabled={finishState.status === "Finishing" || finishState.status === "Finished"}>Finish</Button>);
        if (finishState.message.length > 0) {
            if (finishState.status === "Error") {
                stepErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{finishState.message}</Box>);      
            } else if (finishState.status === "Finished") {
                stepErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.VALID_COLOR}>{finishState.message}</Box>);      
            }
        }
        if (finishState.status === "Finished") {
            syndicateTable = (
                <Box paddingTop={2}>
                    Receipt:
                    <SyndicateTable items={finishState.syndicates} />
                </Box>
            );
        }
    }
    return (
    <Container maxWidth="lg">
      <Title title={`Create Syndicate - ${stepName} (${formState.step} of ${stepCount})`} />
      {roundInfoBox}
      {wizStep}
      {stepErrorBox}
      <Box paddingTop={2}>
        {continueButton}
        {finishButton}
      </Box>
      {syndicateTable}
    </Container>
  );
  
};

export default CreateSyndicate;

