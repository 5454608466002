import firebase from 'firebase/app';
import 'firebase/auth';

const configureFirebase = async () => {
    firebase.initializeApp( {
        apiKey: process.env["REACT_APP_FIREBASE_KEY"],
        authDomain: process.env["REACT_APP_FIREBASE_DOMAIN"],
    });

    return firebase;

};

export default configureFirebase();
