import { stringify } from 'query-string';
import fetchApi from "./fetchApi";
import {omitBy, isEqual} from 'lodash';

const calcPatch = (previous, next) => {
    return omitBy(next, function (v, k) { return isEqual(previous[k], v)});
};

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = Object.assign({
            orderBy: `${field} ${order}`,
            pageSize: perPage,
            page: page-1,
            q: params.filter.q,
            filter: JSON.stringify(params.filter)
        });
        const url = `${resource}?${stringify(query)}`;

        return fetchApi(url).then(({ json }) => ({
            data: json._embedded[resource],
            total: json.totalSize
        }));
    },

    getOne: (resource, params) =>
        fetchApi(`${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            id: params.ids.join(",")
        };
        const url = `${resource}?${stringify(query)}`;
        return fetchApi(url).then(({ json }) => ({
            data: json._embedded[resource],
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const targetFilter = params.target ?
            {
                [params.target]: params.id
            }:
            {};

        const query = Object.assign({
            orderBy: `${field} ${order}`,
            pageSize: perPage,
            page: page-1,
            filter: JSON.stringify({
                ...params.filter,
                ...targetFilter,
            })
        })

        const url = `${resource}?${stringify(query)}`;

        return fetchApi(url).then(({ headers, json }) => ({
            data: json._embedded[resource],
            total: json.totalSize
        }));
    },

    update: (resource, params) =>
        fetchApi(`${resource}/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(calcPatch(params.previousData, params.data)),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        return Promise.all(
            params.ids.map(id => fetchApi(`${resource}/${id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }))).then(() => ({data : params.ids}))
    },

    create: (resource, params) =>
        fetchApi(`${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(async ({ json }) => {
            // Deal with eventual consistency, the newly created resource will load in edit view at once
            await new Promise(r => setTimeout(r, 1000));
            return ({
                data: json,
            })
        }),

    delete: (resource, params) =>
        fetchApi(`${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(() => ({ data: ({id: params.id}) })),

    deleteMany: (resource, params) => {
        return Promise.all(
            params.ids.map(id => fetchApi(`${resource}/${id}`, {
                method: 'DELETE',
            }))).then(() => ({data : params.ids}))
    },
};