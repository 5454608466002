import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Container, Link } from '@material-ui/core';
import { FreebetType } from "./FreebetModels";
import FreebetUtil from "./FreebetUtil";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";

interface EditFreebetTypeState {
    item?: FreebetType;
    id: number;
    name: string;
    numFull: string;
    numHalf: string;
}


const EditFreebetType: React.FC = (props) => {
    const location = useLocation();
   
    const [formState, setFormState] = useState<EditFreebetTypeState>({
        id: 0,
        name: "",
        numFull: "1",
        numHalf: "1",
    });
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [initErr, setInitErr] = useState<string>("");
    const [refreshing, setRefreshing] = useState<boolean>(false);
  
    useEffect(() => {
        var pos = location.pathname.lastIndexOf("/");
        if (pos <= 0) {
            return;
        }
        var idString = location.pathname.substring(pos + 1);
        var id = parseInt(idString);
        if (isNaN(id) || id <= 0) {
            setInitErr(`FreebetTypeId param is invalid '${idString}'.`);
            return;
        }
        var fs = {...formState, id: id};
        setInitErr("Initializing...");
        setRefreshing(true);
        FreebetUtil.getFreebetType(id).then(resp => {
            if (resp.success) {
                setInitErr("");
            } else {
                setInitErr(`Init error: ${resp.message}`);
                return;
            }
            var freebetType = resp.item;
            if (freebetType) {
                fs.item = freebetType;
                fs.name = freebetType.name;
                fs.numFull = freebetType.numFull.toString();
                fs.numHalf = freebetType.numHalf.toString();
            } else {
                setInitErr("Freebet type not found.")
            }
        }).catch(error => {
            console.error("Init error.", error);
            setInitErr(`Init error: ${error}`);
      
        }).finally(() => {
            setRefreshing(false);
            setFormState(fs);
        });
    }, []);

    const handleChange = (name: string, value:string) =>{
        var fs = {...formState, [name]: value};
        setFormState(fs);
    }

    const onSave = () => {
        const METHOD_NAME = "SaveFreebetType";
        var name : string | undefined = undefined;
        var numFull : number | undefined = undefined;
        var numHalf : number | undefined = undefined;
        var changeCount = 0;
        setSaveError("");
        setSaveStatus(0);
        var freebetType = formState.item;
        var id : number = freebetType ? freebetType.id : 0;
        var numFullX = parseInt(formState.numFull);
        if (isNaN(numFullX) || numFullX < 0) {
            setSaveError("Invalid Number of Full");
            return;
        }
        var numHalfX = parseInt(formState.numHalf);
        if (isNaN(numHalfX) || numHalfX < 0) {
            setSaveError("Invalid Number of Half");
            return;
        }
        if (freebetType) {
            if (formState.name !== freebetType.name) {
                name = formState.name;
                changeCount++;
            }
            if (numFullX !== freebetType.numFull) {
                numFull = numFullX;
                changeCount++;
            }
            if (numHalfX !== freebetType.numHalf) {
                numHalf = numHalfX;
                changeCount++;
            }
            if (changeCount <= 0) {
                setSaveError("No changes");
                return;
            }
        } else {
            name = formState.name;
            numFull = numFullX;
            numHalf = numHalfX;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify({name: name, numFull: numFull, numHalf: numHalf});
        FreebetUtil.saveFreebetType(id, saveJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                if (resp.item) {
                    var fs = {...formState, id: formState.id};
                    fs.item = resp.item;
                    fs.id = resp.item.id;
                    fs.name = resp.item.name;
                    fs.numFull = resp.item.numFull.toString();
                    fs.numHalf = resp.item.numHalf.toString();
                    setFormState(fs);
                    return;
                } 
                setSaveError(`${METHOD_NAME} failure: FreebetType was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var valid = true;
    var nameErr = "";
    var numFullErr = "";
    var numHalfErr = "";
    var numFull = parseInt(formState.numFull);
    var numHalf = parseInt(formState.numHalf);
    if (formState.name.length === 0) {
        valid = false;
        nameErr = "Required";
    } else if (formState.name.trim().length === 0) {
        valid = false;
        nameErr = "Cannot be blank";
    }
    if (formState.numFull.length > 0) {
        if (isNaN(numFull)) {
            valid = false;
            numFullErr = "Invalid int";
        } else if (numFull < 0 || numFull > 8) {
            valid = false;
            numFullErr = "Not in range 0-8";
        }
    } else {
        valid = false;
        nameErr = "Required";
    }
    if (formState.numHalf.length > 0) {
        if (isNaN(numHalf)) {
            valid = false;
            numFullErr = "Invalid int";
        } else if (numHalf < 0 || numHalf > 8) {
            valid = false;
            numHalfErr = "Not in range 0-8";
        }
    } else {
        valid = false;
        nameErr = "Required";
    }
    if (valid) {
        if (numFull + numHalf > 8) {
            valid = false;
            numHalfErr = "Fulls+Halves is not in range 0-8";
        }
    }
    var saveEnabled = false;
    var freebetType = formState.item;
    if (valid) {
        if (freebetType) {
            if (formState.name !== freebetType.name || numFull !== freebetType.numFull || numHalf != freebetType.numHalf) {
                saveEnabled = true;
            }
        } else {
            saveEnabled = true;
        }
    }
    var initErrorBox = null;
    if (initErr && initErr.length) {
        initErrorBox = (<Box color="red">{initErr}</Box>);
        saveEnabled = false;
    }

    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    var title = freebetType ? `Edit Freebet Type #${freebetType.id}` : "Create Freebet Type";
    
    return (
        <Container maxWidth="xl">
            <Title title={title} />
            <Box>
                <TextField
                    label="Id"
                    name="id"
                    value={formState.id}
                    helperText={formState.item ? `CreatedAt: ${CreateRoundUtil.toGameDateString(formState.item.createdAt)}` : ""}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    autoFocus
                    label="Name"
                    name="name"
                    value={formState.name}
                    onChange={e => handleChange("name", e.target.value)}
                    helperText={nameErr}
                    error={nameErr.length > 0}
                    style={{width:400}}
                />
            </Box>
            <Box marginTop={1} display="flex" alignItems="center">
                <TextField
                    margin="dense"
                    name="numHalf"
                    label="Number of Full"
                    value={formState.numFull}
                    type="number"
                    onChange={e => handleChange("numFull", e.target.value)}
                    helperText={numFullErr}
                    error={numFullErr.length > 0}
                /><span style={{ padding: 2 }}></span>
                <TextField
                    margin="dense"
                    name="numHalf"
                    label="Number of Half"
                    value={formState.numHalf}
                    type="number"
                    onChange={e => handleChange("numHalf", e.target.value)}
                    helperText={numHalfErr}
                    error={numHalfErr.length > 0}
                />
            </Box>
            {initErrorBox} 
            <Box marginTop={2}>
                <Button onClick={e => onSave()} color="primary" variant="contained" disabled={!saveEnabled}>
                    Save
                </Button>
            </Box>
            {saveErrorBox} 
            <Box>
            <Button
                    component={RouterLink}
                    color="secondary"
                    size="small"
                    style={{textTransform:"none"}}
                    to={"/freebetTypes"}
                    >
                    Goto Freebet Types
                </Button>&nbsp;

            </Box>
        </Container>
    );
};

export default EditFreebetType;
