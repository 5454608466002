import { max } from "moment";
import { getList, getListByPost, getListWithId, getObject } from "../api/dataProvider";
import { FixtureListItem, GenericResponse, LeagueListItem, LogEntry, OddsInfo, RoundFixtureInput, TeamItem } from "./CreateRoundModels";
import { RoundType } from "../models/RoundType";
import fetchApi from "../../fetchApi";
import { Draw, Fixture, Round, Team } from "../RoundOverview/RoundOverviewModels";
import {fetchUtils} from 'react-admin';
import { QueryParams } from "../models/CouponShare";
import queryString from "query-string";

class CreateRoundUtil {
    // static timeFormat = "^([01]\\d|2[0-3]):[0-5]\\d$";
    static timeFormat = /^([01]\d|2[0-3]):[0-5]\d$/;
    static timeInfoText: string = "HH:mm";
    //static leagueFormatPattern = /(?<name>[\w\d\s]+)\s+\((?<location>[\w\d\s]+)\)$/;
    static leagueFormatPattern = /(?<name>.+)\s+\((?<location>.+)\)$/;
    static ticketSum = 100;
    static fakeLeagueName = "Fake League (Fake)";
    
    static Flextipset8EUR = "Flextipset8EUR";
    static MixtipsetEUR = "MixtipsetEUR";
    static MaxtipsetEUR = "MaxtipsetEUR";

    static Flextipset8 = "Flextipset8";
    static Mixtipset = "Mixtipset";
    static Maxtipset = "Maxtipset";

    static createRoundDelaySeconds = 3;
    
    static addDays(date: Date, days: number) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    static addHours(date: Date, hours: number) {
        var result = new Date(date);
        result.setHours(result.getHours() + hours);
        return result;
    }
    static addTime(date: Date, time: string) {
        var result = new Date(date);
        result.setHours(parseInt(time.substring(0, 2)), parseInt(time.substring(3)));
        return result;
    }
    static getDateWithoutTime(date: Date) {
        var result = new Date(date);
        result.setHours(0, 0, 0, 0);
        return result;
    }
    static getDateWithoutTimeString(date: Date) {
        var dateObj = new Date(date);
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        var day = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear();
        var shortDate = year + '-' + month + '-' + day;
        return shortDate;
    }
    static getTimeString(date: Date) {
        var d = new Date(date);
        var s = ('0' + d.getHours().toString()).slice(-2) + ":" + ('0' + d.getMinutes().toString()).slice(-2);
        return s;
    }
    static getLogTimeString() {
        var d = new Date();
        var s = ('0' + d.getHours().toString()).slice(-2) + ":" + ('0' + d.getMinutes().toString()).slice(-2)
            + ":" + ('0' + d.getSeconds().toString()).slice(-2) + "." + d.getMilliseconds().toString();
        return s;
    }
    static getLogMessage(s: string) {
        return CreateRoundUtil.getLogTimeString() + " " + s;
    }
    static createLogEntry(message: string, isError: boolean) : LogEntry {
        return {
            message: CreateRoundUtil.getLogTimeString() + " " + message,
            isError: isError
        };
    }
    static getLogEntryColor(isError : boolean) : string {
        if (isError) {
          return "red";
        }
        return "blue";
    };    
    static getClientLocale() {
        if (typeof Intl !== 'undefined') {
            try {
                return Intl.NumberFormat().resolvedOptions().locale;
            } catch (err) {
                console.error("Cannot get locale from Intl")
            }
            var language;
            if (window.navigator.languages) {
                language = window.navigator.languages[0];
            } else {
                language = window.navigator.userLanguage || window.navigator.language;
            }
            return language;
        }
    }
    static getISODateString(date: Date) {
        var s = date.toISOString();
        return s.substring(0, 10);
    }

    static parseDateX(input: string) {
        var date = new Date(parseInt(input.substring(0, 4)), parseInt(input.substring(5, 7)) - 1, parseInt(input.substring(8)));
        return date;
    }

    static toGameDateString(date: Date | null | undefined) : string {
        if (!date){
            return "";
        }
        var d = new Date(date);
        var s = CreateRoundUtil.getDateWithoutTimeString(d) + " " + ('0' + d.getHours().toString()).slice(-2) + ":" + ('0' + d.getMinutes().toString()).slice(-2);
        //var s = d.toISOString();
        //return s.substring(0, 16);
        return s;
    }
    static isValidTime(time: string) {
        if (time.match(CreateRoundUtil.timeFormat)) {
            return true;
        }
        return false;
    }
    static compareString(a: string, b: string) {
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        }
        return 0;
    }
    static createRoundFixtureInput(id: string) {
        var p = 1.0 / 3.0;
        var input: RoundFixtureInput = {
            id: id,
            oneOdds: "3",
            xOdds: "3",
            twoOdds: "3",
            oneP: p,
            xP: p,
            twoP: p,
            oneN:34,
            xN: 33,
            twoN: 33,
            valid: true,
            keepDraw: false
        };
        return input;
    }
    static sumNumbers(numbers : number[]) : number {
        var sum = 0;
        for (var i = 0; i < numbers.length; i++){
            sum += numbers[i];
        }
        return sum;
    }
    static getOddsInfo(oneOddsInput: string, xOddsInput: string, twoOddsInput: string) : OddsInfo | null {
        var oneOdds = parseFloat(oneOddsInput);
        if (isNaN(oneOdds) || oneOdds <= 0) {
            return null;
        }
        var xOdds = parseFloat(xOddsInput);
        if (isNaN(xOdds) || xOdds <= 0) {
            return null;
        }
        var twoOdds = parseFloat(twoOddsInput);
        if (isNaN(twoOdds) || twoOdds <= 0) {
            return null;
        }
        var oneP = 1 / oneOdds;
        var xP = 1 / xOdds;
        var twoP = 1 / twoOdds;
        var sumP = oneP + xP + twoP;
        var onePNorm = oneP / sumP;
        var xPNorm = xP / sumP;
        var twoPNorm = twoP / sumP;
        var sumPNorm = onePNorm + xPNorm + twoPNorm;
        
        var oneTicket = Math.round(onePNorm * CreateRoundUtil.ticketSum);
        var xTicket = Math.round(xPNorm * CreateRoundUtil.ticketSum);
        var twoTicket = Math.round(twoPNorm * CreateRoundUtil.ticketSum);
        var tickets : number[]=  [oneTicket, xTicket, twoTicket]
        //var sumTicket = oneTicket + xTicket + twoTicket;
        var sumTicket = CreateRoundUtil.sumNumbers(tickets);
        var ticketIters = 0;
        while (sumTicket !== CreateRoundUtil.ticketSum){
            ticketIters++;
            if (ticketIters > 2){
                break;
            }
            var diffs : number[]=  [tickets[0] - onePNorm * CreateRoundUtil.ticketSum, tickets[1] - xPNorm * CreateRoundUtil.ticketSum,  tickets[2] - twoPNorm * CreateRoundUtil.ticketSum];
            if (sumTicket > CreateRoundUtil.ticketSum){
                var maxDiff = diffs[0];
                var maxIndex = 0;
                for (var index  = 1; index < diffs.length; index++){
                    if (maxDiff < diffs[index]){
                        maxDiff = diffs[index];
                        maxIndex = index;
                    }
                }
                tickets[maxIndex] = tickets[maxIndex] - 1;
            } else {
                var minDiff = diffs[0];
                var minIndex = 0;
                for (var index  = 1; index < diffs.length; index++){
                    if (minDiff > diffs[index]){
                        minDiff = diffs[index];
                        minIndex = index;
                    }
                }
                tickets[minIndex] = tickets[minIndex] + 1;
            }
            sumTicket = CreateRoundUtil.sumNumbers(tickets);
        }
       
        var info: OddsInfo = {
            oddsPercent: sumP * 100,
            oneP: onePNorm,
            xP: xPNorm,
            twoP: twoPNorm,
            oneN: tickets[0],
            xN: tickets[1],
            twoN: tickets[2]
        };
        return info;
    }
    static isInvalidOdds(odds: number, oddsString: string) {
        if (isNaN(odds) || odds < 1 || oddsString !== odds.toString()) {
            return true;
        }
        return false;
    }
    static createGenericResponse<T>(success: boolean, message: string, item : T | null) : GenericResponse<T> {
        var resp : GenericResponse<T> = {
            success: success,
            message: message,
            item: item
        };
        return resp;
    }
    static getDefaultStartingAt() : Date {
        var startingAt1 = CreateRoundUtil.addHours(new Date(), 2);
        var startingAt = CreateRoundUtil.getDateWithoutTime(startingAt1);
        var minutes = startingAt1.getMinutes();
        if (minutes === 0){
          startingAt = CreateRoundUtil.addHours(startingAt, startingAt1.getHours());
        } else if (minutes <= 15) {
          startingAt.setHours(startingAt1.getHours(), 15);
        } else if (minutes <= 30) {
          startingAt.setHours(startingAt1.getHours(), 30);
        } else if (minutes <= 45) {
          startingAt.setHours(startingAt1.getHours(), 45);
        } else {
          startingAt.setHours(startingAt1.getHours() + 1, 0);
        }
        return startingAt;
    }
    static toLeagueListItems(leagues1: any) : LeagueListItem[] {
        var leagues2: LeagueListItem[] = [];
        leagues1.forEach((league1: any) => {
            var leagueName: string = league1.name;
            var leagueLocation = "";
            var leagueTitle = league1.name;
            var match = leagueName.match(CreateRoundUtil.leagueFormatPattern);
            if (match) {
                leagueName = match.groups?.name ?? league1.name;
                leagueLocation = match.groups?.location ?? "";
                if (leagueName !== league1.name && leagueLocation.length > 0) {
                    leagueTitle = leagueLocation + " - " + leagueName;
                }
            }
            var league: LeagueListItem = {
                id: league1.id,
                rawName: league1.name,
                name: leagueName,
                title: leagueTitle,
                location: leagueLocation
            };
            leagues2.push(league);
        });
        return leagues2;
    }
    static addLeagues(leagues : LeagueListItem[], otherLeagues : LeagueListItem[], sort: boolean){
        if (leagues.length === 0){
            otherLeagues.forEach(league => leagues.push(league));
        } else {
            otherLeagues.forEach(league => {
                var existing = leagues.find(it => it.id === league.id);
                if (!existing){
                    leagues.push(league);
                }
            });
        }

        if (sort){
            CreateRoundUtil.sortLeagues(leagues);
        }
    }
    static compareLeague(a: LeagueListItem, b: LeagueListItem){
        try {
            var comp = CreateRoundUtil.compareString(a.title, b.title);
            return comp;
        
        } catch (error) {
            return 0;        
        }
    }
    static sortLeagues(leagues : LeagueListItem[]) {
        if (leagues.length < 2){
            return;
        }
        leagues.sort(CreateRoundUtil.compareLeague);
    }
    static getQueryParams(perPage : number = 100, page : number = 0, q : string | undefined = undefined) : QueryParams {
        var params : QueryParams = {
            pagination: { page: page, perPage: perPage },
            filter: { },
            sort: { field: "id", order: "desc" }
        };
        return params;
    }
    static async getLeagueListItems(perPage : number = 100, page : number = 0, q : string | undefined = undefined) : Promise<GenericResponse<LeagueListItem[]>> {
        // var params : QueryParams = {
        //     pagination: { page: page, perPage: perPage },
        //     filter: { },
        //     sort: { field: "id", order: "desc" }
        // };
        var params = this.getQueryParams(perPage, page, q);
        var resp = await this.getApiItems<any>("leagues", params, q);
        if (!resp.success) {
            return CreateRoundUtil.createGenericResponse<LeagueListItem[]>(false, resp.message, null);
        }
        if (resp.item == null) {
            return CreateRoundUtil.createGenericResponse<LeagueListItem[]>(true, resp.message, null);
        }
        var items = CreateRoundUtil.toLeagueListItems(resp.item);
        this.sortLeagues(items);
        return CreateRoundUtil.createGenericResponse<LeagueListItem[]>(true, "", items);
    }
    static async getLeagues(perPage : number = 100) : Promise<LeagueListItem[]> {      
        var resp = await getList('leagues', {
            pagination: { page: 0, perPage: perPage },
            filter: {},
            sort: { field: "id", order: "desc" }
        });
        return CreateRoundUtil.toLeagueListItems(resp.data);
    }
    static async getLeagueByName(leagueName : string) : Promise<GenericResponse<LeagueListItem>> {
        const METHOD_NAME = "CreateRoundUtil.getLeagueByName";
        try {
            var resp = await getList('leagues', {
                pagination: { page: 0, perPage: 2 },
                filter: { "name": leagueName },
                sort: { field: "id", order: "desc" }
            });
            var items = CreateRoundUtil.toLeagueListItems(resp.data);
            if (!items || items.length < 1) {
                return CreateRoundUtil.createGenericResponse<LeagueListItem>(false, "League not found", null);
            }
            return CreateRoundUtil.createGenericResponse<LeagueListItem>(true, "", items[0]);
        } catch (error) {
            console.error(METHOD_NAME + " error!", error);
            return CreateRoundUtil.createGenericResponse<LeagueListItem>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async getLeaguesWithId(ids : number[]) : Promise<any> {
        var resp = await getListWithId('leagues', ids);
        return resp.data;
    }
    static getDefaultJackpotString(roundTypeName : string | null | undefined){
        var rtName = roundTypeName ?? "";
        if (!rtName || rtName.length === 0){
            return "";
        }
        switch (rtName) {
            case CreateRoundUtil.Flextipset8EUR:
                return "100000";
            case CreateRoundUtil.MaxtipsetEUR:
                return "1000000";
            case CreateRoundUtil.MixtipsetEUR:     
                return "250000";
            case CreateRoundUtil.Flextipset8:
                return "1000";
            case CreateRoundUtil.Maxtipset:
                return "10000";
            case CreateRoundUtil.Mixtipset:
                return "2500";
        }
        return "";
    }
    static isProdRoundType(roundTypeName: string) : boolean {
        switch (roundTypeName){
            case CreateRoundUtil.Flextipset8EUR:
            case CreateRoundUtil.MaxtipsetEUR:
            case CreateRoundUtil.MixtipsetEUR:     
                return true;
        }
        return false;
    }
    static filterProdRoundTypes(roundTypes: RoundType[]) : RoundType[] {
        var rts : RoundType[] = [];
        for (var i = 0; i < roundTypes.length; i++) {
            var rt = roundTypes[i];
            if (CreateRoundUtil.isProdRoundType(rt.parameters.name)) {
                rts.push(rt);
            }
        }
        return rts;
    }
    static isTestRoundType(roundTypeName: string) : boolean {
        switch (roundTypeName){
            case CreateRoundUtil.Flextipset8:
            case CreateRoundUtil.Maxtipset:
            case CreateRoundUtil.Mixtipset:     
                return true;
        }
        return false;
    }
    static filterTestRoundTypes(roundTypes: RoundType[]) : RoundType[] {
        var rts : RoundType[] = [];
        for (var i = 0; i < roundTypes.length; i++) {
            var rt = roundTypes[i];
            if (CreateRoundUtil.isTestRoundType(rt.parameters.name)) {
                rts.push(rt);
            }
        }
        return rts;
    }
    static clearArray<T>(array: T[]) {
        while (array.length > 0) {
          array.pop();
        }
    }
    static compareRoundType(a: RoundType, b: RoundType){
        try {
            var comp = CreateRoundUtil.compareString(a.parameters.name, b.parameters.name);
            return comp;
        
        } catch (error) {
            return 0;        
        }
    }
    static sortRoundTypes(roundTypes : RoundType[]) {
        if (!roundTypes || roundTypes.length < 2){
            return;
        }
        roundTypes.sort(CreateRoundUtil.compareRoundType);
    }
    static async getRoundTypes() : Promise<RoundType[]> {
        var resp = await getList('roundTypes', {
            pagination: { page: 0, perPage: 100 },
            filter: {},
            sort: { field: "id", order: "desc" }
        });
        var rts : RoundType[] = resp.data;
        CreateRoundUtil.sortRoundTypes(rts);
        return rts;
    }
    static toTeamItems(teams1: any) : TeamItem[] {
        var teams2: TeamItem[] = [];
        teams1.forEach((team1: any) => {
            var team : TeamItem = team1;
            teams2.push(team);
        });
        return teams2;
    }
    static async getTeamByName(teamName : string) : Promise<GenericResponse<TeamItem>> {
        const METHOD_NAME = "CreateRoundUtil.getTeamByName";
        try {
            var resp = await getList('teams', {
                pagination: { page: 0, perPage: 10 },
                filter: { "name": teamName },
                sort: { field: "id", order: "desc" }
            });
            var items = CreateRoundUtil.toTeamItems(resp.data);
            if (!items || items.length < 1) {
                return CreateRoundUtil.createGenericResponse<TeamItem>(false, "Team not found", null);
            }
            return CreateRoundUtil.createGenericResponse<TeamItem>(true, "", items[0]);
        } catch (error) {
            console.error(METHOD_NAME + " error!", error);
            return CreateRoundUtil.createGenericResponse<TeamItem>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async createFixture(leagueId: string, homeTeamId : string, awayTeamId : string, startingAt : Date) : Promise<GenericResponse<Fixture>> {
        const METHOD_NAME = "CreateRoundUtil.createFixture";
        try {
            var createData : any = {
                startingAt: startingAt,
                homeTeam: {
                    teamId: homeTeamId
                },
                awayTeam: {
                    teamId: awayTeamId
                },
                leagueId: leagueId
            };
            var createJson = JSON.stringify(createData);
            var respX = await fetchApi("fixtures", {
                method: 'POST',
                body: createJson
            });
            var fixture : Fixture = respX.json;
            if (!fixture) {
                return CreateRoundUtil.createGenericResponse<Fixture>(false, "No response", null);
            }
            if (!fixture.id) {
                return CreateRoundUtil.createGenericResponse<Fixture>(false, "Response problem: id property is missing in response", null);
            }
            fixture.id = fixture.id.trim();
            if (fixture.id.length <= 0) {
                return CreateRoundUtil.createGenericResponse<Fixture>(false, "Response problem: id property is an empty string", null);
            }
            return CreateRoundUtil.createGenericResponse<Fixture>(true, "", fixture);
        } catch (error) {
            console.error(METHOD_NAME + " error!", error);
            return CreateRoundUtil.createGenericResponse<Fixture>(false, METHOD_NAME + " error: " + error, null);
        }     
    }
    //Create round from roundTypeId, returns roundId.
    static async createRound(roundTypeId: string): Promise<GenericResponse<string>>  {
        try {
            var resp = await getListByPost("rounds", { roundTypeId: roundTypeId });
            if (!resp || !resp.data) {
                return CreateRoundUtil.createGenericResponse<string>(false, "No response", null);
            }
            var rId: string = resp.data.id;
            if (!rId) {
                return CreateRoundUtil.createGenericResponse<string>(false, "Response problem: id property is an empty string", null);
            }
            rId = rId.trim();
            if (rId.length <= 0) {
                return CreateRoundUtil.createGenericResponse<string>(false, "Response problem: id property is an empty string", null);
            }
            return CreateRoundUtil.createGenericResponse<string>(true, "", rId);
        } catch (error) {
            console.error("Create Round error", error);
            return CreateRoundUtil.createGenericResponse<string>(false, "Create Round error: " + error, null);
        }
    }
    static async updateRound(roundId: string, updateRoundJson: string): Promise<GenericResponse<Round>>  {
        const METHOD_NAME = "CreateRoundUtil.updateRound";
        try {

            var respX = await fetchApi("rounds/" + roundId, {
                method: 'PATCH',
                body: updateRoundJson
            });
            var resp : Round = respX.json;
            if (!resp) {
                return CreateRoundUtil.createGenericResponse<Round>(false, METHOD_NAME + " failure: no response.", null);
            }
            var rId: string = resp.id;
            if (!rId) {
                return CreateRoundUtil.createGenericResponse<Round>(false, METHOD_NAME + " failure: id property is missing in response.", null);
            }
            rId = rId.trim();
            if (rId.length <= 0) {
                return CreateRoundUtil.createGenericResponse<Round>(false, METHOD_NAME + " failure: id property is an empty string.", null);
            }
            return CreateRoundUtil.createGenericResponse<Round>(true, "", resp);
        } catch (error) {
            console.error(METHOD_NAME + " error", error);
            return CreateRoundUtil.createGenericResponse<Round>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    //Saves RoundPromotionValue, specify id to update, otherwise insert. Returns roundPromotionValueId if success.
    static async saveRoundPromotionValue(id : string, roundId: string, key: string, value: string): Promise<GenericResponse<string>> {
        const METHOD_NAME = "CreateRoundUtil.saveRoundPromotionValue";
        try {
            var url = "roundPromotionValues"
            if (id && id.length > 0){
                var deleteRespX = await fetchApi(url + "/" + id, {
                    method: "DELETE"
                });
            }
            var body = {
                roundId: roundId,
                key: key,
                value: value 
            };
            var bodyJson = JSON.stringify(body);
            var respX = await fetchApi(url, {
                method: "POST",
                body: bodyJson
            });
            var resp = respX.json;
            if (!resp) {
                return CreateRoundUtil.createGenericResponse<string>(false, METHOD_NAME + " failure: no response.", null);
            }
            var rpvId: string = resp.id;
            if (!rpvId) {
                return CreateRoundUtil.createGenericResponse<string>(false, METHOD_NAME + " failure: id property is missing in response.", null);
            }
            rpvId = rpvId.trim();
            if (rpvId.length <= 0) {
                return CreateRoundUtil.createGenericResponse<string>(false, METHOD_NAME + " failure: id property is an empty string.", null);
            }
            return CreateRoundUtil.createGenericResponse<string>(true, METHOD_NAME + " failure: id property is an empty string.", rpvId);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<string>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async getDraws(drawIds : number[]) : Promise<GenericResponse<Draw[]>> {
        const METHOD_NAME = "CreateRoundUtil.getDraws";
        try {
            var resp = await getListWithId("draws", drawIds);
            var draws: Draw[] = resp.data;
            return CreateRoundUtil.createGenericResponse<Draw[]>(true, "", draws);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Draw[]>(false, METHOD_NAME + " error: " + error, null);
        }
    }  
    static async getApiItems<T>(resource : string, params: QueryParams, q? : string) : Promise<GenericResponse<T[]>> {
        const METHOD_NAME = "CreateRoundUtil.getApiItems." + resource;
        try {
            const query = Object.assign({
                orderBy: `${params.sort?.field} ${params.sort?.order}`,
                pageSize: params.pagination?.perPage,
                page: params.pagination?.page,
                filter: JSON.stringify(params.filter),
                q: q
            });
            const url = `${resource}?${queryString.stringify(query)}`;
            var resp = await getObject(url);
            var items: T[] = resp.data._embedded[resource];
            return CreateRoundUtil.createGenericResponse<T[]>(true, "", items);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<T[]>(false, METHOD_NAME + " error: " + error, null);
        }
    }  
    static async getDrawsByFixture(fixtureId : string) : Promise<GenericResponse<Draw[]>> {
        const METHOD_NAME = "CreateRoundUtil.getDrawsByFixture";
        try {
            var params: QueryParams = {
                pagination: { page: 0, perPage: 25 },
                filter: { fixtureId },
                sort: { field: "id", order: "desc" }
            };
            var resp = await this.getApiItems<Draw>("draws", params);
            return resp;
            // const query = Object.assign({
            //     orderBy: `${params.sort?.field} ${params.sort?.order}`,
            //     pageSize: params.pagination?.perPage,
            //     page: params.pagination?.page,
            //     filter: JSON.stringify(params.filter)
            // });
            // const resource = "draws";
            // const url = `${resource}?${queryString.stringify(query)}`;
            // var resp = await getObject(url);
            // var draws: Draw[] = resp.data._embedded[resource];
            // return CreateRoundUtil.createGenericResponse<Draw[]>(true, "", draws);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Draw[]>(false, METHOD_NAME + " error: " + error, null);
        }
    }  
    
    static getExceptionMessage(exception : any, includeStackTrace? : boolean, userMessage?: string) : string {
        if (!exception) {
            if (userMessage) {
                return userMessage;
            }
            return "";
        }
        var items : string[] = [];
        if (userMessage && userMessage.length > 0) {
            items.push(userMessage + ": " + exception);
        } else {
            items.push("Error: " + exception);
        }
        var val : any = exception.message;
        if (val) {
            items.push("[message:" + val + "]");
        }
        val = exception.status;
        if (val) {
            items.push("[status:" + val + "]");
        }
        val = exception.name;
        if (val) {
            items.push("[name:" + val + "]");
        }
        val = exception.body;
        if (val) {
            try {
                var json = JSON.stringify(val);
                items.push("[body:" + json + "]");
            } catch (error) {
                try {
                    items.push("[body:" + val + "]");
                } catch (error2) {
                    items.push("[body:unknown]");
                }
            }
        }
        var message = items.join(" ");
        if (includeStackTrace && includeStackTrace === true && exception.stack && exception.stack.length) {
            message += "\r\nStack trace:\r\n" + exception.stack;
        }
        return message;
    }
    static async updateFixture(fixtureId: string, updateJson: string): Promise<GenericResponse<Fixture>>  {
        const METHOD_NAME = "CreateRoundUtil.updateFixture";
        try {

            var respX = await fetchApi("fixtures/" + fixtureId, {
                method: 'PATCH',
                body: updateJson
            });
            var resp : Fixture = respX.json;
            if (!resp) {
                return CreateRoundUtil.createGenericResponse<Fixture>(false, METHOD_NAME + " failure: no response.", null);
            }
            var fId: string = resp.id;
            if (!fId) {
                return CreateRoundUtil.createGenericResponse<Fixture>(false, METHOD_NAME + " failure: id property is missing in response.", null);
            }
            fId = fId.trim();
            if (fId.length <= 0) {
                return CreateRoundUtil.createGenericResponse<Fixture>(false, METHOD_NAME + " failure: id property is an empty string.", null);
            }
            return CreateRoundUtil.createGenericResponse<Fixture>(true, "", resp);
        } catch (error) {
            console.error(METHOD_NAME + " error", error);
            return CreateRoundUtil.createGenericResponse<Fixture>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async updateDraw(drawId: string, updateJson: string): Promise<GenericResponse<Draw>>  {
        const METHOD_NAME = "CreateRoundUtil.updateDraw";
        try {

            var respX = await fetchApi("draws/" + drawId, {
                method: 'PATCH',
                body: updateJson
            });
            var resp : Draw = respX.json;
            if (!resp) {
                return CreateRoundUtil.createGenericResponse<Draw>(false, METHOD_NAME + " failure: no response.", null);
            }
            var fId: string = resp.id;
            if (!fId) {
                return CreateRoundUtil.createGenericResponse<Draw>(false, METHOD_NAME + " failure: id property is missing in response.", null);
            }
            fId = fId.trim();
            if (fId.length <= 0) {
                return CreateRoundUtil.createGenericResponse<Draw>(false, METHOD_NAME + " failure: id property is an empty string.", null);
            }
            return CreateRoundUtil.createGenericResponse<Draw>(true, "", resp);
        } catch (error) {
            console.error(METHOD_NAME + " error", error);
            return CreateRoundUtil.createGenericResponse<Draw>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async sleep(msecsTimeout: number) : Promise<void> {
        return new Promise(resolve => setTimeout(resolve, msecsTimeout));       
    }
    static async getTeams(perPage : number = 100, page : number = 0, q : string | undefined = undefined) : Promise<GenericResponse<Team[]>> {
        var params = this.getQueryParams(perPage, page, q);
        var resp = await this.getApiItems<Team>("teams", params, q);
        return resp;
    }
    static addTeams(teams : Team[], otherTeams : Team[], sort: boolean){
        if (teams.length === 0){
            otherTeams.forEach(it => teams.push(it));
        } else {
            otherTeams.forEach(team => {
                var existing = teams.find(it => it.id === team.id);
                if (!existing){
                    teams.push(team);
                }
            });
        }

        if (sort){
            CreateRoundUtil.sortTeams(teams);
        }
    }
    static compareTeam(a: Team, b: Team){
        try {
            var comp = CreateRoundUtil.compareString(a.name, b.name);
            if (comp === 0) {
                var aId = parseInt(a.id);
                var bId = parseInt(b.id);
                if (isNaN(aId) || isNaN(bId)) {
                    comp = CreateRoundUtil.compareString(a.id, b.id);
                } else {
                    comp = aId - bId;
                }
            }
            return comp;
        
        } catch (error) {
            return 0;        
        }
    }
    static sortTeams(teams : Team[]) {
        if (teams.length < 2){
            return;
        }
        teams.sort(CreateRoundUtil.compareTeam);
    }
    static toFixtureListItem(fixture: Fixture) : FixtureListItem {
        var fixtureName: string = fixture.homeTeam.teamName + " v " + fixture.awayTeam.teamName;
        var fixture2: FixtureListItem = {
            id: fixture.id,
            name: fixtureName,
            title: fixtureName + " (" + CreateRoundUtil.toGameDateString(fixture.startingAt) + ")",
            startingAt: fixture.startingAt,
            leagueId: fixture.leagueId,
            leagueName: "Unknown " + fixture.leagueId,
            status: fixture.status,
            fixture: fixture,
            awayScore: fixture.awayTeam.score,
            createdAt: fixture.createdAt,
            homeScore: fixture.homeTeam.score,
            score: `${fixture.homeTeam.score}-${fixture.awayTeam.score}`,
            providerStatus: fixture.providerStatus
        };
        return fixture2;
    }
    static toFixtureListItems(fixtures: Fixture[]) : FixtureListItem[] {
        var fixtures2: FixtureListItem[] = [];
        fixtures.forEach((fixture: Fixture) => {
            var fixture2 : FixtureListItem = CreateRoundUtil.toFixtureListItem(fixture);
            fixtures2.push(fixture2);
        });
        return fixtures2;
    }
    static isBlank(s: string) : boolean {
        var s1 = s.trim();
        if (s1.length <= 0) {
            return true;
        }
        return false;
    }
    static toBoolean(s: string, defaultValue: boolean) : boolean {
        if (!s) {
            return defaultValue;
        }
        if (s === "1" || s.toLowerCase() === "true") {
            return true;
        }
        return false;
    }
}
export default CreateRoundUtil;