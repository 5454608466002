// src/MyForm.tsx
import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, FormLabel, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { getList, getListWithId, getObject } from '../api/dataProvider';
import { FixtureListItem, LeagueListItem, RoundFixtureInput, RoundFixtureItem } from './CreateRoundModels';
import CreateRoundUtil from './CreateRoundUtil';
import { QueryParams } from '../models/CouponShare';
import queryString from "query-string";
import FixtureChooserTable from './FixtureChooserTable';
import { Fixture } from '../RoundOverview/RoundOverviewModels';
//import RoundFixtureTable from './RoundFixtureTable';
//import {useForm} from 'react-hook-form'
// import SearchIcon from '@material-ui/icons/Search';
// import AddIcon from '@material-ui/icons/Add';

interface FixtureChooserState {
    // Define your form fields and their types here
    fromDate: string;
    fromTime: string;
    toDate: string;
    toTime: string;
    pageSize: string;
    fixtureId: string;
    fixtureName: string;
    leagueId: string;
    // ...
}


interface FixtureChooserProps {
    maxFixtures: number;
    onAdd: (fixture: FixtureListItem) => void;
}

const FixtureChooser: React.FC<FixtureChooserProps> = (props) => {
    // const timeFormat: string = "^([01]\\d|2[0-3]):[0-5]\\d$";
    // const timeInfoText: string = "HH:mm";
    const leaguePageSize = 1000;
    var defaultFromDate = CreateRoundUtil.addHours(new Date(), 1);
    var defaultToDate = CreateRoundUtil.addDays(defaultFromDate, 7);
    const [formData, setFormData] = useState<FixtureChooserState>({
        fromDate: CreateRoundUtil.getDateWithoutTimeString(defaultFromDate),
        fromTime: CreateRoundUtil.getTimeString(defaultFromDate),
        toDate: CreateRoundUtil.getDateWithoutTimeString(defaultToDate),
        toTime: CreateRoundUtil.getTimeString(defaultToDate),
        pageSize: "50",
        fixtureId: "",
        fixtureName: "",
        leagueId: ""
    });

    const [fromDateError, setFromDateError] = useState<string>("");
    const [fromTimeError, setFromTimeError] = useState<string>("");
    const [toDateError, setToDateError] = useState<string>("");
    const [toTimeError, setToTimeError] = useState<string>("");
    const [pageSizeError, setPageSizeError] = useState<string>("");
    const [fixtureNameError, setFixtureNameError] = useState<string>("");
    const [fixtures, setFixtures] = useState<FixtureListItem[]>([]);
    const [leagues, setLeagues] = useState<LeagueListItem[]>([]);
    const [leaguePage, setLeaguePage] = useState<number>(-1);
    const [leaguesMore, setLeaguesMore] = useState<boolean>(false);

    useEffect(() => {
        // CreateRoundUtil.getLeagues(100).then((resp) => {
        //     setLeagues(resp.items);
        // });
        getList('leagues', {
            pagination: { page: 0, perPage: leaguePageSize },
            filter: {},
            sort: { field: "id", order: "asc" }
        }).then((resp) => {
            var leagues1 = CreateRoundUtil.toLeagueListItems(resp.data);
            CreateRoundUtil.sortLeagues(leagues1);
            if (leagues1.length < leaguePageSize){
                setLeaguesMore(false);
            } else {
                setLeaguesMore(true);
            }
            setLeagues(leagues1);
            setLeaguePage(0);
         });
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Add form submission logic here
        //console.log(formData);
        var fromDate = CreateRoundUtil.parseDateX(formData.fromDate);
        var toDate = CreateRoundUtil.parseDateX(formData.toDate);
        var from = fromDate;
        var to = toDate;
        var pageSize = parseInt(formData.pageSize);

        var valid = true;
        var fromDateErr = "";
        var fromTimeErr = "";
        var toDateErr = "";
        var toTimeErr = "";
        var pageSizeErr = "";
        // const leagueFormatPattern = /(?<name>[\w\d\s]+)\s+\((?<location>[\w\d\s]+)\)$/;
        if (formData.fromTime.length > 0) {
            if (CreateRoundUtil.isValidTime(formData.fromTime)) {
                from = CreateRoundUtil.addTime(from, formData.fromTime);
            }
            else {
                valid = false;
                fromTimeErr = "Invalid time (HH:mm)";
            }
        }
        if (formData.toTime.length > 0) {
            if (CreateRoundUtil.isValidTime(formData.toTime)) {
                to = CreateRoundUtil.addTime(to, formData.toTime);
            }
            else {
                valid = false;
                toTimeErr = "Invalid time (HH:mm)";
            }
        }
        if (isNaN(pageSize)) {
            valid = false;
            pageSizeErr = "Invalid int";
        }
        if (fromDate > toDate) {
            valid = false;
            toDateErr = "To date is less than From date";
        } else if (from > to) {
            valid = false;
            toDateErr = "To datetime is less than From datetime";
        } else if (from === to) {
            if (formData.toTime.length === 0) {
                to = CreateRoundUtil.addDays(toDate, 1);
            } else if (toDateErr.length === 0 && toTimeErr.length === 0) {
                valid = false;
                toDateErr = "To datetime is equal From datetime";
            }
        }

        setFromDateError(fromDateErr);
        setFromTimeError(fromTimeErr);
        setToDateError(toDateErr);
        setToTimeError(toTimeErr);
        setPageSizeError(pageSizeErr);

        if (!valid) {
            return;
        }

        var fixturePattern = formData.fixtureName.length === 0 ? undefined : formData.fixtureName;
        var leagueId = formData.leagueId.length === 0 ? undefined : formData.leagueId;
        var params: QueryParams = {
            pagination: { page: 0, perPage: pageSize },
            filter: { status: "PENDING", fromAt: from, toAt: to, leagueId: leagueId, q: fixturePattern },
            sort: { field: "id", order: "desc" }
        };
        // const query = Object.assign({
        //     orderBy: `${params.sort?.field} ${params.sort?.order}`,
        //     pageSize: params.pagination?.perPage,
        //     page: params.pagination?.page,
        //     filter: JSON.stringify(params.filter),
        //     q: fixturePattern
        // });
        // const resource = "fixtures";

        // const url = `${resource}?${queryString.stringify(query)}`;

        // getObject(url).then((resp) => {
        //     var fixtures1: any = resp.data._embedded.fixtures;
        CreateRoundUtil.getApiItems<Fixture>("fixtures", params, fixturePattern).then((resp) => {
            var fixtures1 = resp.item;
            var fixtures2: FixtureListItem[] = [];
            if (!fixtures1) {
                setFixtures(fixtures2);
                return;
            }
            var leagueIds: number[] = [];
            fixtures1.forEach((fixture1: Fixture) => {
                // var fixtureName: string = fixture1.homeTeam.teamName + " v " + fixture1.awayTeam.teamName;
                // var fixture: FixtureListItem = {
                //     id: fixture1.id,
                //     name: fixtureName,
                //     title: fixtureName + " (" + CreateRoundUtil.toGameDateString(fixture1.startingAt) + ")",
                //     startingAt: fixture1.startingAt,
                //     leagueId: fixture1.leagueId,
                //     leagueName: "Unknown " + fixture1.leagueId,
                //     status: fixture1.status,
                //     fixture: fixture1
                // };
                var fixture = CreateRoundUtil.toFixtureListItem(fixture1);
                fixtures2.push(fixture);
                var league = leagues.find(l => l.id === fixture.leagueId);
                if (league) {
                    fixture.leagueName = league.title;
                } else {
                    var leagueId2: number = parseInt(fixture.leagueId);
                    if (!isNaN(leagueId2) && !leagueIds.includes(leagueId2)) {
                        leagueIds.push(leagueId2);
                    }
                }
            });
            if (leagueIds.length <= 0) {
                setFixtures(fixtures2);
                return;
            }
            getListWithId("leagues", leagueIds).then((leagueResp) => {
                var leagues1: any = leagueResp.data;
                if (leagues1 === null) {
                    setFixtures(fixtures2);
                    return;
                }

                var leagues2: LeagueListItem[] = CreateRoundUtil.toLeagueListItems(leagues1);

                if (leagues2.length > 0) {
                    CreateRoundUtil.addLeagues(leagues, leagues2, true);
                    var nonLeagueFixtures = fixtures2.filter((f) => f.leagueName === null || f.leagueName === "" || f.leagueName.startsWith("Unknown"));
                    nonLeagueFixtures.sort((a, b) => CreateRoundUtil.compareString(a.leagueId, b.leagueId));
                    var currentLeagueId: string = "";
                    var currentLeague: LeagueListItem | null | undefined = null;
                    nonLeagueFixtures.forEach((f) => {
                        if (currentLeagueId !== f.leagueId) {
                            currentLeagueId = f.leagueId;
                            currentLeague = leagues2.find(l => l.id === currentLeagueId);
                        }
                        if (currentLeague) {
                            f.leagueName = currentLeague.title;
                        }
                    });
                }
                setFixtures(fixtures2);

            })
        })
    };
    const onAdd = (item: FixtureListItem) => {
        if (props.onAdd) {
            props.onAdd(item);
        }
    };
    const onLeaguesMore = () => {
        var page = leaguePage + 1;
        getList('leagues', {
            pagination: { page: page, perPage: leaguePageSize },
            filter: {},
            sort: { field: "id", order: "asc" }
        }).then((resp) => {
            var leagues1 = CreateRoundUtil.toLeagueListItems(resp.data);
            CreateRoundUtil.addLeagues(leagues, leagues1, true);
            if (leagues1.length < leaguePageSize){
                setLeaguesMore(false);
            } else {
                setLeaguesMore(true);
            }
            setLeaguePage(page);
         });
     };
    var currentFixture = fixtures.find(f => f.id === formData.fixtureId);
    var league = leagues.find(l => l.id === formData.leagueId);
    var leagueText: string = league ? league.rawName : "";
     var leuageHelperText = leagueText;
     if (leuageHelperText.length > 0){
        leuageHelperText += "; ";
     }
     leuageHelperText += "Count:"+ leagues.length + "; Page:" + leaguePage + "; PageSize:" + leaguePageSize;

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Box>
                    {/* <FormLabel style={{ paddingRight: 2 }}>Date filter:</FormLabel> */}
                    <TextField
                        type="date"
                        name="fromDate"
                        label="From date"
                        value={formData.fromDate}
                        error={fromDateError && fromDateError.length > 0 ? true : false}
                        helperText={fromDateError}
                        onChange={handleChange}
                    />
                    <FormLabel style={{ padding: 2 }}> </FormLabel>
                    <TextField
                        name="fromTime"
                        label={CreateRoundUtil.timeInfoText}
                        value={formData.fromTime}
                        style={{ width: 50 }}
                        error={fromTimeError && fromTimeError.length ? true : false}
                        helperText={fromTimeError}
                        onChange={handleChange}
                    />
                    <span style={{ paddingLeft: 10, paddingRight: 20, textAlign: "center", verticalAlign: "center" }}></span>
                    <TextField
                        type="date"
                        name="toDate"
                        label="To date"
                        value={formData.toDate} error={toDateError && toDateError.length ? true : false}
                        helperText={toDateError}
                        onChange={handleChange}
                    />
                    <FormLabel style={{ padding: 2 }}> </FormLabel>
                    <TextField
                        name="toTime"
                        label={CreateRoundUtil.timeInfoText}
                        value={formData.toTime}
                        style={{ width: 50 }}
                        error={toTimeError && toTimeError.length ? true : false}
                        helperText={toTimeError}
                        onChange={handleChange} />
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">

                    <TextField
                        name="leagueId"
                        label="League"
                        select
                        value={formData.leagueId}
                        onChange={handleChange}
                        style={{ width: 500 }}
                        helperText={leuageHelperText}
                    >
                        <MenuItem key="" value="" />
                        {leagues.map(league => (
                            <MenuItem key={league.id} value={league.id}>
                                {league.title}
                            </MenuItem>
                        ))}
                    </TextField>
                    <span style={{ padding: 2 }}></span>
                    <Button variant="contained" color="secondary" size="small" disabled={!leaguesMore} onClick={e => onLeaguesMore()}>
                        More
                    </Button>
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">
                    <TextField
                        name="fixtureName"
                        label="Fixture name"
                        value={formData.fixtureName}
                        error={fixtureNameError && fixtureNameError.length > 0 ? true : false}
                        helperText={fixtureNameError && fixtureNameError.length > 0 ? fixtureNameError : "Character sequence of Fixture name"}
                        onChange={handleChange}
                    />
                </Box>
                <Box paddingTop={1} display="flex" alignItems="center">
                    {/* <FormLabel style={{ paddingRight: 2 }}>Page size:</FormLabel> */}
                    <TextField
                        name="pageSize" label="Page size" type="number" value={formData.pageSize} style={{ width: 100 }} error={pageSizeError && pageSizeError.length ? true : false} helperText={pageSizeError} onChange={handleChange} />
                    <FormLabel style={{ padding: 2 }}> </FormLabel>
                    <FormLabel style={{ padding: 2 }}> </FormLabel>
                    <Button variant="contained" color="primary" type="submit" size="small">
                        Search
                    </Button>
                    {/* <IconButton aria-label="searchFixtures" color="secondary" size="small" type="submit" >
                    <Tooltip title="Search Fixtures">
                        <SearchIcon />
                    </Tooltip>
                </IconButton> */}
                </Box>
            </form>
            {/* <Box>
                <TextField
                    label="Fixture"
                    select
                    name="fixtureId"
                    defaultValue={formData.fixtureId}
                    onChange={handleChange}
                    style={{width:500}}
                    helperText={currentFixture ? currentFixture.leagueName : ""}
                >
                    {fixtures.map(fixture => (
                        <MenuItem key={fixture.id} value={fixture.id}>
                            {fixture.title}
                        </MenuItem>
                    ))}
                </TextField>
                <Button onClick={e => onAdd()} variant="contained" color="primary" size="small">Add</Button>
            </Box> */}
            <Box paddingTop={2} />
            <FixtureChooserTable fixtures={fixtures} onAdd={onAdd} />
            {/* <Box border={2}>
                maxFixtures:{props.maxFixtures}
                formData:
                {JSON.stringify(formData)}
            </Box>
            <Box border={2}>
                Fixtures:
                {JSON.stringify(fixtures)}
            </Box> */}
        </div>

    );
};

export default FixtureChooser;
