import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, Datagrid, TextField, List,
    Toolbar, SaveButton, SimpleShowLayout, Show, SelectInput, PasswordInput, FunctionField
} from 'react-admin';

const MockOpPlayerEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);
export const MockOpPlayerEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<MockOpPlayerEditToolbar />}>
            <TextInput disabled source="id" />
            <TextInput disabled source="username" />
            <FunctionField disabled label="Balance" render={record => `${record.balance.amount} ${record.balance.currency}`} />
            <PasswordInput source="password" />
        </SimpleForm>
    </Edit>
);

export const MockOpPlayerShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="username" />
            <FunctionField label="Balance" render={record => `${record.balance.amount} ${record.balance.currency}`} />
        </SimpleShowLayout>
    </Show>
);

export const MockOpPlayerCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="username" />
            <PasswordInput source="password" />
            <SelectInput source="currency" choices={[
                { id: 'SEK', name: 'SEK' },
                { id: 'EUR', name: 'EUR' },
                { id: 'GBP', name: 'GBP' },
                { id: 'MAD', name: 'MAD' },
                { id: 'IRR', name: 'IRR' },
            ]} />
        </SimpleForm>
    </Create>
);

export const MockOpPlayerList = props => (
    <List {...props} pagination={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="username" label="Username" />
            <FunctionField label="Balance" render={record => `${record.balance.amount} ${record.balance.currency}`} />
        </Datagrid>
    </List>
);
