import React from 'react';
import clsx from "clsx";
import {withStyles} from "@material-ui/core";
import Leg from "./Leg";

const styles = ({
  root: {
  },
  legContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leg: {
  }
})

const Bet = ({className, classes, bet}) => {
  return (
    <div className={clsx(className, classes.root)}>
      {bet.legs.map((leg, index) => (
        <div key={index} className={classes.legContainer}>
            <Leg className={classes.leg} leg={leg}/>
        </div>
      ))}
      {bet.rows &&
      <div>{bet.rows.length} rows</div>
      }

    </div>
  )
}

export default withStyles(styles)(Bet);