import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, TableRow, TableCell, IconButton, makeStyles, Switch } from '@material-ui/core';
import { Draw, Fixture, RoundOverviewFixture } from './RoundOverviewModels';
import RofResult from './RofResult';
import RoundOverviewUtil from './RoundOverviewUtil';
import { GenericResponse } from '../CreateRound/CreateRoundModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { Link as RouterLink } from "react-router-dom";
import DrawDialog from './DrawDialog';
import RofInfoDialog from './RofInfoDialog';
import RofTitle from './RofTitle';

const useStyles = makeStyles({
    fixtureResult: {
      display: 'block',
      minWidth: '2em',
      minHeight: '2em',
      border: '1',
      fontSize: '20',
      fontWeight:'bold'
    }
});
interface RoundOverviewFixtureRowState {
  updating : boolean;
  drawStatusUpdate: string;
}  

interface RoundOverviewFixtureRowProps {
  fixture: RoundOverviewFixture;
  readOnly: boolean;
  onRefreshRound?: () => void;
}
const RoundOverviewFixtureRow: React.FC<RoundOverviewFixtureRowProps> = (props) => {
  const classes = useStyles();
  const [updateDrawResp, setUpdateDrawResp] = useState<GenericResponse<Draw>>({
    success:true,
    message: "",
    item: null
  });
  const [rowState, setRowState] = useState<RoundOverviewFixtureRowState>({
    updating:false,
    drawStatusUpdate: ""
  });

  const beginChangeDrawStatus = (status: string) => {
    setRowState({
      updating: true,
      drawStatusUpdate: status
    });
  };

  const endUpdating = () => {
    setRowState({
      updating: false,
      drawStatusUpdate: ""
    });
  };  
  const onChangeDrawStatus = (status: string) => {
    const methodName = "onChangeDrawStatus." + status;
    beginChangeDrawStatus(status);
    var updateJson = JSON.stringify({status: status});
    CreateRoundUtil.updateDraw(props.fixture.drawId, updateJson).then(resp => {
      setUpdateDrawResp(resp);
      if (resp.success){
        if (resp.item) {
          props.fixture.draw = resp.item;
        }
      } else {
        alert(resp.message);
      }
      endUpdating();
    }).catch(error => {
      setUpdateDrawResp(CreateRoundUtil.createGenericResponse<Draw>(false, methodName + " error: " + error, null))
      endUpdating();
    });
  }
  const onDrawClose = (draw: Draw | undefined) => {
    if (!draw) {
      return;
    }
    props.fixture.draw = draw;
    if (props.onRefreshRound) {
      props.onRefreshRound();
    }
  }
  const onFixtureChange = (fixture?: Fixture) => {
    if (!fixture) {
      return;
    }
    if (props.onRefreshRound) {
      props.onRefreshRound();
    }
  }


  var row = props.fixture;
  var willBeDrawnSwitch = null;
  var drawStatus = row.draw?.status;
  if (rowState.updating){
    if (rowState.drawStatusUpdate.length > 0){
      drawStatus = rowState.drawStatusUpdate;
    }
  }
  var enabledStatus =  !props.readOnly && RoundOverviewUtil.isEnabledDrawStatus(drawStatus);

  var drawDlg = null;
  if (drawStatus && enabledStatus) {
    var willBeDrawn = (drawStatus === RoundOverviewUtil.PUBLISHED_STATUS);
    var newStatus = willBeDrawn ? RoundOverviewUtil.PENDING_STATUS : RoundOverviewUtil.PUBLISHED_STATUS;
    willBeDrawnSwitch = (<Switch
      checked={willBeDrawn}
      onChange={(e: any) => onChangeDrawStatus(newStatus)}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={rowState.updating}
    />
    );

    if (willBeDrawn){
        drawDlg = (<DrawDialog fixture={row} open={false} onClose={onDrawClose} />);
    }

  }
  var drawInfo = null;
  var drawInfo2 = null;
  var draw = row.draw;
  if (draw) {
    drawInfo = `${draw.adminPickedOutcome} (${draw.status})`;
    // if (draw.outcomes && draw.outcomes.length > 0) {
    //   var drawText2 = "";
    //   draw.outcomes.forEach(o => {
    //       if (drawText2.length > 0) {
    //         drawText2 += ` | ${o.sign} (${o.n})`;
    //       } else {
    //         drawText2 = `${o.sign} (${o.n})`
    //       }
    //   });
    //   drawInfo2 = (<Box fontSize={10}>{drawText2}</Box>);    
    // }
  }
  return (
    <TableRow key={row.id} >
      <TableCell component="th" scope="row">
        {row.ordNo}
      </TableCell>
      <TableCell>
        {/* {row.name}
        <Box fontSize={10}>{CreateRoundUtil.toGameDateString(row.startingAt)} | {row.leagueName}</Box> */}
        <RofTitle fixture={row} editEnabled={true} onChange={onFixtureChange} />
      </TableCell>
      <TableCell>
        <RofResult fixture={row} />
      </TableCell>
      <TableCell>
        {drawInfo}
        {drawInfo2}
      </TableCell>
      <TableCell>
        {willBeDrawnSwitch}
      </TableCell>
      <TableCell>
        <Box display="flex" padding={0} margin="0" >
          <RofInfoDialog fixture={row} openButtonLabel="Info" />&nbsp;{drawDlg}
        </Box>
      </TableCell>
    </TableRow>
);
};

export default RoundOverviewFixtureRow;
