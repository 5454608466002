import React, { useEffect, useState } from "react";
import { RoundOverviewItem, TickerItem } from "./RoundOverviewModels";
import RoundOverviewUtil from "./RoundOverviewUtil";
import { Box, Button } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import TickerTable from "./TickerTable";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";

interface RoundOverviewTickerState {
    roundId: number;
    items: TickerItem[];
    more: boolean;
    lastId: number;
    refreshing: boolean;
}
interface RoundOverviewTickerProps {
    round: RoundOverviewItem;
}
const RoundOverviewTicker: React.FC<RoundOverviewTickerProps> = (props) => {
    const [roundState, setRoundState] = useState<RoundOverviewTickerState>({
        roundId: props.round.id,
        items: [],
        more: false,
        lastId: 0,
        refreshing: false

    });
    const [actionError, setActionError] = useState<string>("");
    const pageSize = 1000;
    const history = useHistory();   

    useEffect(() => {
        refreshRound();
    }, []);

    const refreshRound = () => {
        setActionError("");
        var rs : RoundOverviewTickerState = {
            roundId:props.round.id,
            items:[],
            more: false,
            lastId: 0,
            refreshing: false
        };
        // if (props.round.tickerItems) {
        //     rs.items = props.round.tickerItems;
        //     setRoundState(rs);
        //     return;
        // }
        if (props.round.id === 0) {
            setRoundState(rs);
            return;
        }
        roundState.refreshing = true;
        RoundOverviewUtil.getRoundTickerItems(props.round.id, pageSize, 0).then( resp => {
            if (!resp.success) {
                setActionError("Refresh error: " + resp.message);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    rs.items = listResponse.items;
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        rs.more = true;
                    }
                }           
            }
        }).catch( error => {
            setActionError("Refresh error (2): " + error);

        }).finally( () => {
            setRoundState(rs);
            roundState.refreshing = false;
        });
    };
    const onRefreshRound = () => {
        refreshRound();
    };
    const getMoreTicker = async () : Promise<GenericResponse<ListResponse<TickerItem>>> => {
        const METHOD_NAME = "getMoreTicker";
        try {
            return RoundOverviewUtil.getRoundTickerItems(props.round.id, pageSize, roundState.lastId);

        } catch (error) {
            console.error(`${METHOD_NAME} error.`, error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return CreateRoundUtil.createGenericResponse<ListResponse<TickerItem>>(false, message, null);
        }
    }
    const onMoreTicker = () : void => {
        if (roundState.lastId <= 0) {
            setActionError("More ticker failure: LastId was not set");
            return;
        }
        setActionError("");
        var ts : RoundOverviewTickerState = {
            roundId: roundState.roundId,
            items: roundState.items,
            more: false,
            lastId: 0,
            refreshing: false
        }
        roundState.refreshing = true;
        getMoreTicker().then(resp => {
            if (!resp.success) {
                setActionError(`MoreTicker.getTickerItems error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    for (const item of listResponse.items) {
                        ts.items.push(item);
                    }               
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].couponShareId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }           
            }
            setRoundState(ts);
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "MoreTicker.getTickerItems error (2)"));
        }).finally(() => {
            roundState.refreshing = false;
        });
    }
    var items1 = roundState.items;
    if (!roundState.refreshing && roundState.roundId !== props.round.id){
        roundState.roundId = props.round.id;
        roundState.items = [];
        items1 = [];
        refreshRound();
    }
    var readOnly = !RoundOverviewUtil.isEnabledStatus(props.round.status);

    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }

    var info = `Type: round | Id: ${roundState.roundId} | Count: ${roundState.items.length} | LastId: ${roundState.lastId} | More: ${roundState.more}`;
    info += ` | PageSize: ${pageSize}`;

    var moreTickerBtn = null;
    if (roundState.more && !roundState.refreshing) {
        moreTickerBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={roundState.refreshing}
            onClick={e => onMoreTicker()}>
            More
        </Button>);
    }

    return (
        <div>
            {actionErrorBox}
            <Box fontSize={10}>
                {info}{moreTickerBtn}
            </Box>
            <TickerTable items={items1} roundColumnsVisible={false} />
        </div>
    );
};

export default RoundOverviewTicker;

