import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RoundFixtureInput, RoundFixtureItem } from "./CreateRoundModels";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import RoundFixtureRow from './RoundFixtureRow';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

interface RoundFixtureTableProps {
    fixtures: RoundFixtureItem[];
    onChange? : (input : RoundFixtureInput) => void;
    onDelete? : (item : RoundFixtureItem) => void;
    onMoveUp? : (item : RoundFixtureItem) => void;
    onMoveDown? : (item : RoundFixtureItem) => void;
}

const RoundFixtureTable: React.FC<RoundFixtureTableProps> = (props) => {
     const classes = useStyles();
  
     const onChange = (input : RoundFixtureInput) => {
      if (props.onChange){
          props.onChange(input);
          return;
      }
      alert("RoundFixtureTable.onChange property not set.");
      };
    const onDelete = (item : RoundFixtureItem) => {
        if (props.onDelete){
            props.onDelete(item);
            return;
        }
        alert("RoundFixtureTable.onDelete property not set.");
    };
    const onMoveUp = (item : RoundFixtureItem) => {
        if (props.onMoveUp){
            props.onMoveUp(item);
            return;
        }
        alert("RoundFixtureTable.onMoveUp property not set.");
    };
    const onMoveDown = (item : RoundFixtureItem) => {
        if (props.onMoveDown){
            props.onMoveDown(item);
            return;
        }
        alert("RoundFixtureTable.onMoveDown property not set.");
    };
  
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Game no</TableCell>
              <TableCell width={100}>Action</TableCell>
              <TableCell>Game</TableCell>
              <TableCell>Odds</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fixtures.map((row) => (
                <RoundFixtureRow fixture={row} onChange={onChange} onDelete={onDelete} onMoveUp={onMoveUp} onMoveDown={onMoveDown} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default RoundFixtureTable;
  


  