import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Tab, Tabs, Box, Typography, Button, TextField, MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { Title } from "react-admin";
import { QueryParams } from "../models/CouponShare";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { ListResponse } from "../api/ApiNgModels";
import ApiNgUtil from "../api/ApiNgUtil";
import RefreshIcon from '@material-ui/icons/Refresh';
import AdminUtil from "./AdminUtil";
import SettingTable from "./SettingTable";
import { Setting } from "./AdminModels";

export interface SettingsPageState {
    q: string;
    pageSize: string;
}
const SettingsPage: React.FC = () => {
    const [listResponse, setListResponse] = useState<ListResponse<Setting>>(ApiNgUtil.emptyListResponse<Setting>(100));
    const [selectedItem, setSelectedItem] = useState<Setting | undefined>(undefined);
    const [formData, setFormData] = useState<SettingsPageState>({
        q: "",
        pageSize: "100",
    });
    const [actionError, setActionError] = useState<string>("");
    const history = useHistory();

    const toQueryParams = (state: SettingsPageState) : QueryParams => {
        const page = 0;
        const pageSize = parseInt(state.pageSize);
        var q : string | undefined = state.q.length === 0 ? undefined : state.q;
        var filterCount = 0;
        if (state.q.length > 0) {
            q = state.q;
            filterCount++;
        }
        var filter : any | undefined = undefined;
        if (filterCount > 0) {
            filter = {
                q: q
            };
        }
        var params : QueryParams = {
            pagination: { page: page, perPage: pageSize },
            filter: filter,
            //sort: { field: state.orderBy, order: state.orderDirection }
        }
        return params;
    };

    useEffect(() => {
        var params = toQueryParams(formData);
        AdminUtil.getSettings(params).then((resp) => {
            var errorMessage = "";
            if (resp.success) {
                if (resp.item) {
                    setListResponse(resp.item);
                } else {
                    var pageSize = params.pagination ? params.pagination.perPage : 100;
                    setListResponse(ApiNgUtil.emptyListResponse(pageSize));
                }
            } else  {
                var message = `Init Settings error: ${resp.message}`;
                errorMessage = message;
                setActionError(errorMessage);
            }
        }).catch(error =>{
            var message = CreateRoundUtil.getExceptionMessage(error, false, "Init Settings error");
            setActionError(message);
    
        });

    }, []);


    const onSelect = (item: Setting) => {
        setSelectedItem(item);
    };

    const handleChange = (name: string, value: any) : void => {
        var fd = { ...formData, [name]: value };
        setFormData(fd);
    };
    const onSearch = () : void => {
       try {
            setActionError("");
            var params = toQueryParams(formData);
            AdminUtil.getSettings(params).then((resp) => {
                if (resp.success) {
                    if (resp.item) {
                        setListResponse(resp.item);
                    } else {
                        var pageSize = params.pagination ? params.pagination.perPage : 100;
                        setListResponse(ApiNgUtil.emptyListResponse(pageSize));
                    }
                } else  {
                    var message = `Search error: ${resp.message}`;
                    setActionError(message);
                }
            }).catch(error =>{
                var message = CreateRoundUtil.getExceptionMessage(error, false, "Search error");
                setActionError(message);
        
            });

       } catch (error) {
        var message = CreateRoundUtil.getExceptionMessage(error, false, "Search error");
        setActionError(message);
       }
    };
 
    var qError = "";
    var pageSizeError = "";
    var tmpInt = 0;
    var valid = true;
    if (formData.pageSize.length === 0) {
        valid = false;
        pageSizeError = "Required int";
    } else {
        tmpInt = parseInt(formData.pageSize);
        if (isNaN(tmpInt)) {
            valid = false;
            pageSizeError = "Invalid int";
        } else if (tmpInt < 1) {
            pageSizeError = "Less than 1";
        }
    }

    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{actionError}</Box>);
    }

    var responseInfo = `Count: ${listResponse.size} of ${listResponse.totalSize} | Page: ${listResponse.page + 1} of ${listResponse.totalPages}`;
    var responseInfoBox =  (<Box fontSize={10}>{responseInfo}</Box>);;
    return (
        <Container maxWidth="xl">
            <Title title="Settings" />
            <Box paddingBottom={2}>
                <TextField
                        name="q"
                        label="Search pattern"
                        value={formData.q}
                        error={qError && qError.length > 0 ? true : false}
                        helperText={qError && qError.length > 0 ? qError : "Character sequence"}
                        onChange={e => handleChange("q", e.target.value)}
                />
                    <TextField
                        name="pageSize"
                        label="Page size"
                        type="number"
                        value={formData.pageSize}
                        style={{ width: 100 }}
                        error={pageSizeError && pageSizeError.length ? true : false}
                        helperText={pageSizeError}
                        onChange={e => handleChange("pageSize", e.target.value)}
                    />
                    <Button 
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!valid}
                        onClick={e => onSearch()}>
                        Search
                    </Button>
            </Box>
                {actionErrorBox}
                {responseInfoBox}
            <SettingTable items={listResponse.items} onSelect={onSelect} />

        </Container>
    );
};

export default SettingsPage;

