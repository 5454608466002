import VerboseSelectFormField from "./VerboseSelectFormField";
import React from "react";
import { Field } from "react-final-form";

const VerboseSelectInput = ({source, input, ...props}) =>
  <Field name={source} component={VerboseSelectFormField} {...props}/>


VerboseSelectInput.defaultProps = {
  addLabel: true
};

export default VerboseSelectInput;