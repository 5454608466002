import React from "react";
import { useEffect, useState } from 'react';
import { Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Dialog, DialogTitle, DialogContent, Button, Paper, Tooltip } from "@material-ui/core";
import { getObject } from "../api/dataProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Bet, Leg } from "../models/CouponDetail";
import { RoundOverviewItem, Round, RoundOverviewFixture } from "../RoundOverview/RoundOverviewModels";
import { GenericResponse, LeagueListItem } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { RoundType } from "../models/RoundType";
import RofTitle from "../RoundOverview/RofTitle";
import { PoolXMoney } from "../models/CouponShare";
import { ProPickFixture, ProPickSigns, getProTypes, getSuggestion } from "./propick/ProPick";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

export type ProPickItemTitleDisplay = 'price' | 'rowsPerShare';
interface ProPickDialogProps {
    open: boolean;
    openLabel?: string;
    onClose: (bet?: Bet) => void;
    round: Round;
    bet: Bet,
    fixtures: RoundOverviewFixture[];
    operatorStakePerRow: PoolXMoney;
    operatorFeePerRow: PoolXMoney;
    proPickName: string;
    itemDisplay: ProPickItemTitleDisplay
}

function toPropickFixtures(roFixtures: RoundOverviewFixture[]) : ProPickFixture[] {
    var ppFixtures : ProPickFixture[] = [];
    for (var i = 0; i < roFixtures.length; i++) {
        var roFixture = roFixtures[i];
        var outcomes = roFixture.draw?.outcomes ?? [];
        var ppSigns : ProPickSigns = {
            ONE: {
                rowsFraction: parseFloat(outcomes[0].probability)
            },
            X: {
                rowsFraction: parseFloat(outcomes[1].probability)
            },
            TWO: {
                rowsFraction:  parseFloat(outcomes[2].probability)
            }
        };
        var ppFixture : ProPickFixture = {
            id: parseInt(roFixture.id),
            name: roFixture.name,
            startingAt: roFixture.startingAt ?? new Date(),
            signs: ppSigns
        };
        ppFixtures.push(ppFixture);
    }
    return ppFixtures;
}
  
const ProPickDialog: React.FC<ProPickDialogProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [fixtures, setFixtures] = useState<ProPickFixture[]>(toPropickFixtures(props.fixtures));
    const [proTypes, setProTypes] = useState<any[]>(getProTypes(fixtures.length, props.operatorStakePerRow, props.operatorFeePerRow));
   
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const setProPickRows = (rows: Array<Array<string>>) : void => {

        var legs = fixtures.map((_fixture, index) => {
            let myLeg: Leg = { signs: rows[index] };
            return myLeg;
        });
        var bet : Bet = {
            id: "",
            legs: legs,
            rows: []
        };
        setOpen(false);
        props.onClose(bet);
    };

    
    const renderProPickItem = (protyp: any, index: number) : JSX.Element => {
        // <Button key={'propickBtn' + index} onClick={() => {
        //     getSuggestion(protyp, fixtures, setProPickRows);
        //     }} >{protyp.price}</Button>
        //  return (
        //     <Grid key={`propick_${index}`} item xs={6} >
        //         <Paper key={'propickBtn' + index}
        //             onClick={() => getSuggestion(protyp, fixtures, setProPickRows)}
        //         >{protyp.price}<Box fontSize={10}>{protyp.dif} | F: {protyp.numFull} | H: {protyp.numHalf}</Box>
        //         </Paper>
        //     </Grid>);
        var title : string = protyp.price;
        if (props.itemDisplay === "rowsPerShare") {
            title = protyp.numRows;
        }
        const tooltipText = `Rows per share: ${protyp.numRows} | Price: ${protyp.price} | Difficulty: ${protyp.dif} | Full covers: ${protyp.numFull} | Half covers: ${protyp.numHalf}`;
        return (
            <Grid key={`propick_${index}`} item xs={6} >
                <Tooltip title={tooltipText}>
                <Button key={'propickBtn' + index} variant="outlined" color="secondary"
                    onClick={() => getSuggestion(protyp, fixtures, setProPickRows)}
                >{title}
                </Button>
                </Tooltip>
            </Grid>);
    };

    var openLabel = props.openLabel && props.openLabel.length > 0 ? props.openLabel : "ProPick";
    var openButton = (<Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    {openLabel}
            </Button>);


    return (
        <div>
            {openButton}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">ProPick</DialogTitle>
            <DialogContent dividers>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        {proTypes.map((protyp, index) => renderProPickItem(protyp, index))}
                    </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onOk} color="primary" disabled={!saveEnabled}>
                    OK
                </Button>
            </DialogActions> */}
        </Dialog>
        </div>
    )

}

export default ProPickDialog;
