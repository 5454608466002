import {fetchUtils} from 'react-admin';
import {getAccessToken} from "./apiToken";

var apiUrl = process.env["REACT_APP_API_URL"]
export const fetchJson = fetchUtils.fetchJson;

export const fetchApiRaw = (url, options) => {
    return fetchJson(new URL(url, apiUrl + "/").href.replace(/\/$/, ""), options);
}

const fetchApi = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }
    const token = await getAccessToken();
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchApiRaw(url, options);
};

export default fetchApi;