import * as React from 'react';
import PropTypes from 'prop-types';
import {FormInput} from "react-admin";

const CompositeForm = ({className, style, basePath, record, resource, children, variant, margin}) =>
    <div style={style} className={className}>
        {React.Children.map(
            children,
            input =>
                input && (
                    <FormInput
                        basePath={basePath}
                        input={input}
                        record={record}
                        resource={resource}
                        variant={input.props.variant || variant}
                        margin={input.props.margin || margin}
                    />
                )
        )}
    </div>;

CompositeForm.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string,
    addLabel: PropTypes.bool
};

CompositeForm.defaultProps = {
    addLabel: false
}
CompositeForm.displayName = 'CompositeForm';

export default CompositeForm;