import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RoundFixtureRow from './RoundFixtureRow';
import { FixtureListItem } from './CreateRoundModels';
import FixtureChooserRow from './FixtureChooserRow';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date}) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
// const useStyles = makeStyles({
//     table: {
//       minWidth: 650,
//     },
// });
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

// interface FixtureChooserTableState{
//     orderBy: string;
//     order: string;
// }
interface FixtureChooserTableProps {
    fixtures: FixtureListItem[];
    onAdd? : (item : FixtureListItem) => void;
}
interface TableColDef {
    disablePadding: boolean;
    id: keyof FixtureListItem;
    label: string;
    numeric: boolean;
}
type Order = 'asc' | 'desc';

const FixtureChooserTable: React.FC<FixtureChooserTableProps> = (props) => {
    const classes = useStyles();
    // const [mainState, setMainState] = useState<FixtureChooserTableState>({
    //     orderBy: "startingAt",
    //     order: "asc",
    // });
    const [orderBy, setOrderBy] = React.useState<keyof FixtureListItem>('startingAt');
    const [order, setOrder] = React.useState<Order>('asc');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [resultFilter, setResultFilter] = useState<string>("");
 
    const columns: TableColDef[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Id" },
        { id: 'leagueName', numeric: false, disablePadding: false, label: 'League' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Fixture' },
        { id: 'startingAt', numeric: false, disablePadding: false, label: 'Starting at' },
    ];
    // const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FixtureListItem) => {
    //     const isAsc = orderBy === property && order === 'asc';
    //     setOrder(isAsc ? 'desc' : 'asc');
    //     setOrderBy(property);
    // };
    
    const onSort = (property: keyof FixtureListItem) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
      };
        const onAdd = (item : FixtureListItem) => {
        if (props.onAdd){
          props.onAdd(item);
          return;
      }
      alert("FixtureChooserTable.onAdd property not set.");
    };

    // var orderBy = mainState.orderBy;
    // var order = mainState.order;
    var fixtures = props.fixtures;
    if (resultFilter.length > 0){
        var resultFilterRegex = new RegExp(resultFilter, "i");
        var fixtures2 : FixtureListItem[] = [];
        fixtures.forEach(f => {
            if (f.name.indexOf(resultFilter) >= 0 || f.leagueName.indexOf(resultFilter) >= 0){
                fixtures2.push(f);
            } else if (resultFilterRegex.test(f.name) || resultFilterRegex.test(f.leagueName)){
                fixtures2.push(f);
            }
        });
        fixtures = fixtures2;
    }
    var pageFixtures = stableSort(fixtures, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, fixtures.length - page * rowsPerPage);
    return (
      // <Box component='form' onSubmit={handleSubmit}>
      <Paper>
             <Box>
            <TextField
                name="resultFilter"
                label="Result filter"
                value={resultFilter}
                style={{ width: 200 }}
                onChange={(e) => setResultFilter(e.target.value)}
            />

            </Box>
     <TableContainer>
        <Table 
            className={classes.table}
            aria-label="simple table"
            size={dense ? 'small' : 'medium'}
        >
          <TableHead>
            <TableRow>
                <TableCell></TableCell>
              {/* <TableCell>Id</TableCell>
              <TableCell>League</TableCell>
              <TableCell>Fixture</TableCell>
              <TableCell>Starting at</TableCell>
              <TableCell
                        key="id"
                        // align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === "headCell.id" ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === "id"}
                            direction={orderBy === "id" ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell> */}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={(e) => onSort(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}                    
            </TableRow>
          </TableHead>
          <TableBody>
            {pageFixtures.map((row) => (
                <FixtureChooserRow fixture={row} onAdd={onAdd} />
            ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={columns.length + 1} />
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={fixtures.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Paper>
  );
  };
  
  export default FixtureChooserTable;
 