import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import { FreebetOverviewItem, FreebetType } from "./FreebetModels";
import FreebetUtil from "./FreebetUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import FreebetTypeTable from "./FreebetTypeTable";
import { BaseItemState, OverviewCriteria } from "../Ticker2/Ticker2Models";
import Ticker2Util from "../Ticker2/Ticker2Util";
import EditFreebetTypeDialog from "./EditFreebetTypeDialog";

interface FreebetTypesViewState {
    limitInput: string;
    limit?: number;
    valid: boolean;
    limitError: string;
}
interface FreebetTypesViewItemState extends BaseItemState<FreebetType,number> {
}

const defaultLimit = 25;

function checkFormState(fs: FreebetTypesViewState)  {
    var valid = true;
    var limit = parseInt(fs.limitInput);
    var limitErr = "";
    if (fs.limitInput.length > 0) {
        if (isNaN(limit)) {
            valid = false;
            limitErr = "Invalid integer";
        } else if (limit < 1 || limit > 1000) {
            valid = false;
            limitErr = "Integer in range 1-1000";
        } else {
            fs.limit = limit;
        }
    }
    else {
        valid = false;
        limitErr = "Required";
    }

    fs.valid = valid;
    fs.limitError = limitErr;
}

function getInitialState() : FreebetTypesViewState {
    var s = sessionStorage.getItem("FreebetTypeViewState");
    var fs : FreebetTypesViewState | undefined = undefined;
    if (s && s.length > 0) {
        try {
            var fs1: FreebetTypesViewState = JSON.parse(s);
            if (fs1) {
                checkFormState(fs1);
                if (fs1.valid) {
                    fs = fs1;
                }
            }
        } catch {

        }
    }
    if (!fs) {
        fs = {
            limitInput: defaultLimit.toString(),
            limit: defaultLimit,
            valid: true,
            limitError: ""
            };
    }
    return fs;
};

var initialState = getInitialState();


const FreebetTypesView: React.FC = () => {  
    const [formState, setFormState] = useState<FreebetTypesViewState>(initialState);
    const [itemState, setItemState] = useState<FreebetTypesViewItemState>({
        items: [],
        more: false,
        lastId: 0,
        refreshing: false

    });
    const [actionError, setActionError] = useState<string>("");
    const pageSize = 1000;
    const history = useHistory();   

    useEffect(() => {
        refreshItems("Init");
    }, []);

    const handleChange = (name: string, value:any) =>{
        var fs = {...formState, [name]: value};
        checkFormState(fs);
        setFormState(fs);
    }
    
    const refreshItems = (caller: string) => {
        if (itemState.refreshing) {
            return;
        }
        setActionError("");
        if (caller === "Refresh") {
            initialState = formState;
        }
        var rs : FreebetTypesViewItemState = {
            items:[],
            more: false,
            lastId: 0,
            refreshing: false
        };
        var lastId = caller === "onMore" ? itemState.lastId : 0;
        if (lastId > 0) {
            rs.items = itemState.items;
        }
        var pageSize = formState.limit ? formState.limit : defaultLimit;
        var success = false;
        itemState.refreshing = true;
        FreebetUtil.getFreebetTypes(pageSize, lastId).then( resp => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId > 0) {
                        for (const item of listResponse.items) {
                            rs.items.push(item);
                        }
                    } else {
                        rs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        rs.more = true;
                    }
                }           
            }
            success = true;
        }).catch( error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            if (success || lastId <= 0) {
                setItemState(rs);
            }
            itemState.refreshing = false;
        });
    };
    const onRefresh = () => {
        refreshItems("Refresh");
    };
    const onMore = () : void => {
        if (itemState.lastId <= 0) {
            setActionError("More failure: LastId was not set");
            return;
        }
        refreshItems("onMore");
    }
    const onCreate = () => {
        history.push('editFreebetType');
    };
    const onCloseCreateFreebetType = (freebetType?: FreebetType) => {
        if (freebetType) {
            if (itemState.more) {
                refreshItems("onCreateFreebetType");
            } else {
                var its = {...itemState, refreshing: false};
                its.items.push(freebetType);
                its.lastId = freebetType.id;
                setItemState(its);
            }
        }

    };

    var items1 = itemState.items;

    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }

    var info = Ticker2Util.toItemStateString(itemState);
    var moreBtn = null;
    if (itemState.more && !itemState.refreshing) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={itemState.refreshing}
            onClick={e => onMore()}>
            More
        </Button>);
    }

    return (
        <div>
            <Box paddingBottom={1}>
                <Button
                    component={RouterLink}
                    variant="contained"
                    color="secondary"
                    size="small"
                    //onClick={e => onCreate()}
                    to={"editFreebetType"}
                    >
                    Create Freebet Type
                </Button>&nbsp;
                {/* <EditFreebetTypeDialog onClose={onCloseCreateFreebetType} open={false} /> */}
                <TextField
                    name="limit"
                    label="Page size"
                    type="number"
                    size="small"
                    value={formState.limitInput}
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    error={formState.limitError && formState.limitError.length ? true : false}
                    helperText={formState.limitError}
                    onChange={e => handleChange("limitInput", e.target.value)}
                />&nbsp;
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={!formState.valid || itemState.refreshing}
                    onClick={e => onRefresh()}>
                    Refresh
                </Button>&nbsp;
            </Box>
            {actionErrorBox}
            <Box fontSize={10}>
                {info}{moreBtn}
            </Box>
            <FreebetTypeTable items={items1} />
        </div>
    );
};

export default FreebetTypesView;

