import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { InvokeApiOptions } from "./ExternalApiModels";
import ExternalApiUtil from "./ExternalApiUtil";
import { MockopPlayerResult, SignInMockopResult } from "./MockopApiModels";

class MockopApiUtil {
    static async signInMockopApi (userName: string, password : string ) : Promise<GenericResponse<SignInMockopResult>> {
        const METHOD_NAME = "MockopApiUtil.signInMockopApi";
        try {
            var baseUrl = process.env["REACT_APP_MOCKOP_API_URL"];
            var url = baseUrl + "/auth";
            var mockopAuthData = {
                username: userName,
                password: password
            };
            var body = JSON.stringify(mockopAuthData);
            var invokeOptions : InvokeApiOptions = {
                method: "POST",
                body: body
            };
            var invokeResp = await ExternalApiUtil.invokeApi(url, invokeOptions);
            if (!invokeResp.success) {
                return CreateRoundUtil.createGenericResponse<SignInMockopResult>(false, invokeResp.message, null );
            }
            return CreateRoundUtil.createGenericResponse<SignInMockopResult>(true, "", invokeResp.json);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<SignInMockopResult>(false, METHOD_NAME + " error: " + error, null);
        }
        
    };

    static async getMockopPlayerInfo (operatorPlayerId: string, mockopToken: string ) : Promise<GenericResponse<MockopPlayerResult>> {
        const METHOD_NAME = "MockopApiUtil.getPlayerRound";
        try {
            var baseUrl = process.env["REACT_APP_MOCKOP_API_URL"];
            var url = baseUrl + "/players/" + operatorPlayerId;
            var invokeOptions: InvokeApiOptions = {
                method: "GET"
            };
            invokeOptions.headers = new Headers();
            invokeOptions.headers.set("Authorization", `Bearer ${mockopToken}`);

            var invokeResp = await ExternalApiUtil.invokeApi(url, invokeOptions);
            if (!invokeResp.success) {
                return CreateRoundUtil.createGenericResponse<MockopPlayerResult>(false, invokeResp.message, null);
            }
            return CreateRoundUtil.createGenericResponse<MockopPlayerResult>(true, "", invokeResp.json);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<MockopPlayerResult>(false, METHOD_NAME + " error: " + error, null);
        }
        
    };
}

export default MockopApiUtil;