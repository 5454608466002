import React, { useState,useEffect } from 'react';
import { Container,TextField,Button, MenuItem, Box, Switch, FormControlLabel } from "@material-ui/core";
import { RoundOverviewItem } from '../RoundOverview/RoundOverviewModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { ListResponse } from '../api/ApiNgModels';

interface SelectRoundStepState {
    roundId: number;
    round?: RoundOverviewItem;
    from: Date;
    to: Date;
    pageSize: number;
}
interface SelectRoundStepItemState {
    items: RoundOverviewItem[];
    error: string;
    refreshing: boolean;
}
interface SelectRoundStepProps {
    selectedId: number;
    onSelect : (item? : RoundOverviewItem) => void;
}

const SelectRoundStep: React.FC<SelectRoundStepProps> = (props: SelectRoundStepProps) => {
    const [formState, setFormState] = useState<SelectRoundStepState>({
        roundId: 0,
        from: CreateRoundUtil.addHours(new Date(), 0.5),
        to: CreateRoundUtil.addDays(new Date(), 365),
        pageSize: 25
    });
    const [itemState, setItemState] = useState<SelectRoundStepItemState>({
        items: [],
        error: "",
        refreshing: false
    });

    useEffect(() => {
        search("Init");
    }, []);

    const search = (caller: string) : void => {
        var fs : SelectRoundStepItemState = {
            items: [],
            error: "",
            refreshing: false
        };
        itemState.refreshing = true;
        RoundOverviewUtil.getRoundOverviews(formState.from, formState.to, formState.pageSize).then(resp => {
            if (!resp.success) {
                fs.error = `${caller} error: ${resp.message}`;
                return;
            }
            var items = resp.item?.items;
            if (items) {
                fs.items = items;
            }
        }).catch(error => {
            fs.error = CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`);
        }).finally( () => {
            itemState.refreshing = false;
            setItemState(fs);
        });
    };
    const handleChange = (name: string, value: any) : void => {
        var fs = { ...formState, [name]: value };
        if (name === "roundId") {
            var roundId = parseInt(value);
            var round = itemState.items.find(it => it.id === roundId);
            fs.round = round;
            if (props.onSelect) {
                props.onSelect(round);
            }
        }
        setFormState(fs);
    };
    var round = formState.round;
    var searchErrorBox = null;
    if (itemState.error.length > 0) {
        searchErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{itemState.error}</Box>);  
    }
    var searchInfoText = `Refreshing:  ${itemState.refreshing} | Count: ${itemState.items.length} | PageSize: ${formState.pageSize} | From: ${formState.from.toISOString()} | To: ${formState.to.toISOString()}`;
    var searchInfoBox = (<Box fontSize={10}>{searchInfoText}</Box>);
    var roundInfoBox = null;
    if (round) {
        var roundInfoText = `Round - Id: ${round.id} | Type: ${round.roundTypeName} | Deadline: ${CreateRoundUtil.toGameDateString(round.deadline)} |Status: ${round.status} | Visible: ${round.visible} | Ext turnover: ${round.extTurnover}`;
        roundInfoBox = (<Box fontSize={10}>{roundInfoText}</Box>);
    }
    return (
        <div>
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label="Round"
                select
                name="roundId"
                value={formState.roundId === 0 ? "" : formState.roundId.toString()}
                onChange={e => handleChange("roundId", e.target.value)}
                style={{width:500}}
                error={!round}
                helperText={round ? "" : "Required"}
            >
                {itemState.items.map(round => (
                <MenuItem key={`round_${round.id}`} value={round.id}>
                    {RoundOverviewUtil.getRoundTitle(round)}
                </MenuItem>
                ))}
            </TextField>
        </Box>
        {searchErrorBox}
        {searchInfoBox}
        {roundInfoBox}
      </div>
  );
};

export default SelectRoundStep;

