import React, { useEffect, useState } from 'react';
import { Box, TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { FixtureListItem, OddsInfo, RoundFixtureInput, RoundFixtureItem } from "./CreateRoundModels";
import AddIcon from '@material-ui/icons/Add';
import CreateRoundUtil from './CreateRoundUtil';


interface FixtureChooserRowProps {
  fixture: FixtureListItem;
  onAdd?: (item: FixtureListItem) => void;
}
const FixtureChooserRow: React.FC<FixtureChooserRowProps> = (props) => {

  const onAdd = () => {
    if (props.onAdd) {
      props.onAdd(props.fixture);
      return;
    }
    alert("RoundFixtureRow.onAdd property not set.");
  };
  
  var row = props.fixture;
  return (
    <TableRow key={row.id} >
      <TableCell component="th" scope="row">
        <IconButton aria-label="add" color="secondary" size="small" onClick={(e: any) => onAdd()} >
            <Tooltip title="Add Fixture">
                <AddIcon />
            </Tooltip>
        </IconButton>
      </TableCell>
      <TableCell>
        {row.id}
      </TableCell>
      <TableCell>
        {row.leagueName}
      </TableCell>
      <TableCell>
        {row.name}
      </TableCell>
      <TableCell>
        {CreateRoundUtil.toGameDateString(row.startingAt)}
        {/* <Box>
            props: {JSON.stringify(props)}
          </Box> */}
      </TableCell>
    </TableRow>
);
};

export default FixtureChooserRow;
