import React, {useState} from 'react';
import './App.css';
import {Admin, defaultTheme, Resource} from "react-admin";
import MyLayout from "./layout";
import dataProvider from "./dataProvider";
import {TeamCreate, TeamEdit, TeamList} from "./resources/teams";
import {FixtureCreate, FixtureEdit, FixtureList} from "./resources/fixtures";
import {RoundTypeCreate, RoundTypeEdit, RoundTypeList} from "./resources/roundTypes";
import {RoundCreate, RoundEdit, RoundList} from "./resources/rounds";
import {AccountTransactionEdit, AccountTransactionList} from "./resources/accountTransactions";
import {CouponEdit, CouponList} from "./resources/coupons";
import {CouponShareEdit, CouponShareList} from "./resources/couponShares";
import {PlayerEdit, PlayerList} from "./resources/players";
import MyLoginPage from "./MyLoginPage";
import authProvider from "./authProvider";
import {UserEdit, UserList} from "./resources/users";
import {LeagueEdit, LeagueList} from "./resources/leagues";
import {SeasonEdit, SeasonList} from "./resources/seasons";
import {GameRoundEdit, GameRoundList} from "./resources/gameRound";
import {AgentCreate, AgentEdit, AgentList} from "./resources/agents";
import {SettlementEdit, SettlementList} from "./resources/settlements";
import {ReducedSystemEdit, ReducedSystemList} from "./resources/reducedSystems";
import {SyndicateList, SyndicateEdit} from './resources/syndicates';
import {SyndicateShareList, SyndicateShareShow} from './resources/syndicateShares';
import {DrawCreate, DrawEdit, DrawList} from "./resources/draws";
import {AuditLogEdit, AuditLogList} from "./resources/auditLogs";
import { TransactionalRequestEdit, TransactionalRequestList } from './resources/transactionalRequests';
import {MockOpPlayerCreate, MockOpPlayerEdit, MockOpPlayerList, MockOpPlayerShow} from "./resources/mockOpPlayers";
import {RoundTypeRolloverFundsEdit, RoundTypeRolloverFundsList} from "./resources/roundTypeRolloverFunds";
import {
  RoundPromotionValuesCreate,
  RoundPromotionValuesEdit,
  RoundPromotionValuesList
} from "./resources/roundPromotionValues";

import customRoutes from './routes';
import {FixtureInformationValueList, FixtureInformationValuesCreate} from "./resources/fixtureInformationValues";

const readEnvKey = () => {
  const domain = process && process.env && process.env["REACT_APP_FIREBASE_DOMAIN"];
  if (domain) {
    switch (domain) {
      case "onextwo-dev.firebaseapp.com":
        return 'onextwo-dev'
      case "onextwo-demo-e2ea2.firebaseapp.com":
        return 'onextwo-demo'
      case "onextwo-prod-81c24.firebaseapp.com":
        return 'onextwo-prod'
      default:
        return 'onextwo-unknown'
    }
  }
  return 'onextwo-unknown'
}

const pickEnvColour = (key) => {
  if (key) {
    switch (key) {
      case "onextwo-dev":
        return '#33691e'
      case "onextwo-demo":
        return '#f57f17'
      case "onextwo-prod":
        return '#b71c1c'
      default:
        return '#0d47a1'
    }
  }
  return '#0d47a1'
}

const getTheme = (key) => {
  const secondaryColour = pickEnvColour(key)
  return {
    ...defaultTheme,
    palette: {
      secondary: {
        main: secondaryColour,
      },
    }
  };
}

const App = () => {
  const [envKey, setEnvKey] = useState(readEnvKey);
  const [theme, setTheme] = useState(getTheme(envKey));

  return (
    <Admin layout={MyLayout} dataProvider={dataProvider} loginPage={MyLoginPage} authProvider={authProvider}
           customRoutes={customRoutes} theme={theme}>
      <Resource name="rounds" list={RoundList} edit={RoundEdit} create={RoundCreate}/>
      <Resource name="roundTypes" options={{label: 'Round Types'}} list={RoundTypeList} edit={RoundTypeEdit}
                create={RoundTypeCreate}/>
      <Resource name="fixtureInformationValues" list={FixtureInformationValueList}
                create={FixtureInformationValuesCreate} options={{label: 'Fixture Information Values'}}/>

      <Resource name="roundTypeRolloverFunds" options={{label: 'Round Type Rollover Funds'}}
                list={RoundTypeRolloverFundsList} edit={RoundTypeRolloverFundsEdit}/>
      <Resource name="roundPromotionValues" list={RoundPromotionValuesList} create={RoundPromotionValuesCreate}
                edit={RoundPromotionValuesEdit}/>
      <Resource name="draws" list={DrawList} edit={DrawEdit} create={DrawCreate}/>
      <Resource name="reducedSystems" options={{label: 'Reduced Systems'}} list={ReducedSystemList}
                edit={ReducedSystemEdit}
      />
      <Resource name="settlements" list={SettlementList} edit={SettlementEdit}/>
      <Resource name="transactionalRequests" list={TransactionalRequestList} edit={TransactionalRequestEdit}
                options={{label: 'Transactional Requests'}}/>
      <Resource name="transactionalRequestExecutions"/>


      <Resource name="coupons" list={CouponList} edit={CouponEdit}/>
      <Resource name="couponDetails"/>
      <Resource name="users" list={UserList} edit={UserEdit}/>
      <Resource name="auditLogs" list={AuditLogList} edit={AuditLogEdit} options={{label: 'Audit Logs'}}/>
      <Resource name="roles"/>
      <Resource name="syndicates" list={SyndicateList} edit={SyndicateEdit}/>
      <Resource name="syndicateShares" list={SyndicateShareList} show={SyndicateShareShow}
                options={{label: 'Syndicate Shares'}}/>
      <Resource name="agents" list={AgentList} edit={AgentEdit} create={AgentCreate}/>
      <Resource name="couponShares" options={{label: 'Coupon Shares'}} list={CouponShareList} edit={CouponShareEdit}/>
      <Resource name="fixtures" list={FixtureList} edit={FixtureEdit} create={FixtureCreate}/>
      <Resource name="teams" list={TeamList} edit={TeamEdit} create={TeamCreate}/>
      <Resource name="leagues" list={LeagueList} edit={LeagueEdit}/>
      <Resource name="seasons" list={SeasonList} edit={SeasonEdit}/>
      <Resource name="gameRounds" options={{label: 'Game Rounds'}} list={GameRoundList} edit={GameRoundEdit}/>
      <Resource name="accountTransactions" options={{label: 'Transactions'}} list={AccountTransactionList}
                edit={AccountTransactionEdit}/>
      <Resource name="players" list={PlayerList} edit={PlayerEdit}/>
      <Resource name="mockOpPlayers" show={MockOpPlayerShow} list={MockOpPlayerList} edit={MockOpPlayerEdit}
                create={MockOpPlayerCreate}/>
    </Admin>);
  
};

export default App;
