import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { BaseTickerOverviewItem, OverviewCriteria, RoundTickerOverviewItem } from "./Ticker2Models";
import Ticker2Util from "./Ticker2Util";
import RoundOverviewTable from "./RoundOverviewTable";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";


interface TickerOverviewState<TItem extends BaseTickerOverviewItem> {
    criteria: OverviewCriteria;
    items: TItem[];
    more: boolean;
    lastId: string;
    refreshing: boolean;
}
interface TickerOverviewStateInfo {
    type: string;
    state: any;
    timestamp: Date;
}

var overviewStateInfos : TickerOverviewStateInfo[] = [];
function updateOverviewStateInfo(type: string, state: any) {
    var info = overviewStateInfos.find(it => it.type === type);
    if (!info) {
        info = {
            type: type,
            state: state,
            timestamp: new Date()
        };
        overviewStateInfos.push(info);
        return;
    }
    info.state= state;
    info.timestamp = new Date();
}
function getOverviewStateInfo(type: string) : TickerOverviewStateInfo | undefined {
    var info = overviewStateInfos.find(it => it.type === type);
    return info;
}

interface TickerOverviewProps<TItem extends BaseTickerOverviewItem> {
    type: string,
    getItems: (criteria: OverviewCriteria, lastId: string) => Promise<GenericResponse<ListResponse<TItem>>>;
    getId: (item: TItem) => string;
    criteria: OverviewCriteria;
    onSelect: (item: TItem) => void;
    onDeselect: () => void;
    renderTable: (items: TItem[], onSelect: (item: TItem) => void, onDeselect: () => void) => JSX.Element;
    moreDisabled?: boolean;
}
function TickerOverview<TItem extends BaseTickerOverviewItem>(props : TickerOverviewProps<TItem>) {
    const fromDays = -14;
    const toDays = 30;
    const [formState, setFormState] = useState<TickerOverviewState<TItem>>({
        criteria: props.criteria,
        items: [],
        more: false,
        lastId: "",
        refreshing: false
    });
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [actionError, setActionError] = useState<string>("");
    const history = useHistory();   

    useEffect(() => {
        if (initFromStore()) {
            return;
        }
        refreshItems("Init");
    }, []);

    const initFromStore = () : boolean => {
        var info = getOverviewStateInfo(props.type);
        if (!info || !info.state) {
            return false;
        }
        try {
            var fs : TickerOverviewState<TItem> = info.state;
            if (!fs.criteria || criteriaChanged(fs)) {
                return false;
            }
            setFormState(fs);
            return true;
        } catch (error) {

        }
        return false;
    }

    const refreshItems = (caller: string) => {
        if (refreshing || formState.refreshing) {
            return;
        }
        setActionError("");
        var criteria = props.criteria;
        var fs : TickerOverviewState<TItem> = {
            criteria: criteria,
            items: [],
            more: false,
            lastId: "",
            refreshing: false
        }
        if (!criteria) {
            setFormState(fs);
            return;
        }
        var lastId = caller === "onMore" ? formState.lastId : "";
        if (lastId.length > 0) {
            fs.items = formState.items;
        }
        formState.refreshing = true;
        setRefreshing(true);
        var success = false;
        props.getItems(criteria, lastId).then((resp) => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId.length > 0) {
                        for (const item of listResponse.items) {
                            fs.items.push(item);
                        }
                    } else {
                        fs.items = listResponse.items;
                    }
                    if (fs.items.length > 0) {
                        fs.lastId = props.getId(fs.items[fs.items.length - 1]);
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        fs.more = true;
                    }
                }
            
            }
            success = true;
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally(() => {
            formState.refreshing = false;
            if (success || lastId.length <= 0) {
                setFormState(fs);
                updateOverviewStateInfo(props.type, fs);
            }
            setRefreshing(false);
        });
    };
    const criteriaChanged = (fs: TickerOverviewState<TItem>) : boolean => {
        return Ticker2Util.criteriaChanged(fs.criteria, props.criteria);
    };
    const onSelect = (item : TItem) => {
        if (props.onSelect){
          props.onSelect(item);
        }
    };
    const onDeselect = () => {
        if (props.onDeselect){
          props.onDeselect();
        }
    };
    const onMore = () : void => {
        if (formState.lastId.length <= 0) {
            setActionError("More failure: LastId was not set");
            return;
        }
        refreshItems("onMore");
    }

    if (!refreshing && !formState.refreshing && criteriaChanged(formState)) {
        refreshItems("CriteriaChange");
    }
    
    var info = `Refreshing: ${refreshing} | Count: ${formState.items.length} | LastId: ${formState.lastId} | More: ${formState.more}`;
    info += ` | PageSize: ${props.criteria?.pageSize} | From: ${props.criteria?.from.toISOString()} | To: ${props.criteria?.to.toISOString()}`;
    var info2 = Ticker2Util.sumTickerOverviewsToString(formState.items, RoundOverviewUtil.CURRENCY_EUR);
    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }
    var moreBtn = null;
    if (!props.moreDisabled && formState.more && !formState.refreshing) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={formState.refreshing}
            onClick={e => onMore()}>
            More
        </Button>);
    }
    return (
        <div>
            {actionErrorBox}
            <Box fontSize={10}>
                {info}{moreBtn}
            </Box>
            <Box fontSize={10}>
                {info2}
            </Box>
            {props.renderTable(formState.items, onSelect, onDeselect)}
        </div>
    );
};

export default TickerOverview;

