import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, TextField, Box, MenuItem, Container, FormControlLabel, Switch } from '@material-ui/core';
import { Freebet, FreebetCampaign, FreebetCampaignUpdate, FreebetOverviewItem, FreebetType, FreebetUpdate, Player } from "./FreebetModels";
import FreebetUtil from "./FreebetUtil";
import { Title } from "react-admin";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import AdminUtil from "../Admin/AdminUtil";
import { BaseItemState } from "../Ticker2/Ticker2Models";
import { Operator, OperatorSetting } from "../Admin/AdminModels";
import OperatorSelect from "../Components/OperatorSelect";
import DateTimePickerX, { DateTimePickerXValue, getDateTimePickerXValue } from "../CreateFixture/DateTimePickerX";
import FreebetTypeSelect from "./FreebetTypeSelect";
import { GenericResponse } from "../CreateRound/CreateRoundModels";

interface FreebetValue {
    id: number;
    freebetCampaignId: number;
    playerId: number;
    validFrom: Date;
    validTo: Date;
    // couponShareId?: number;
    // externalId: string;
}

interface EditFreebetState {
    item?: Freebet;
    campaign?: FreebetOverviewItem;
    id: number;
    freebetCampaignId: number;
    playerId: string;
    validFrom: DateTimePickerXValue;
    validTo: DateTimePickerXValue;
    // couponShareId: string;
    // externalId: string;
    valid: boolean;
    freebetCampaignIdError: string;
    playerIdError: string;
    validFromError: string;
    validToError: string;
    // couponShareIdError: string;
    // externalIdError: string;
    value?: FreebetValue;
    update?: FreebetUpdate;
}

interface PlayerRefresh {
    playerId: string;
    response: GenericResponse<Player>;
}
function checkState(state : EditFreebetState) {
    var valid = true;
    var freebetCampaignId = state.freebetCampaignId;
    var playerId = parseInt(state.playerId);
    var validFrom = state.validFrom.dateTime ? state.validFrom.dateTime : new Date();
    var validTo = state.validTo.dateTime ? state.validTo.dateTime : new Date();
    // var couponShareId : number | undefined = parseInt(state.couponShareId);
    
    var freebetCampaignErr = "";
    var playerErr = "";
    var validFromErr = "";
    var validToErr = "";
    // var couponShareIdErr = "";
    //  var externalIdErr = "";
    if (state.freebetCampaignId <= 0) {
        valid = false;
        freebetCampaignErr = "Required";
    }
    if (state.playerId.length === 0) {
        valid = false;
        playerErr = "Required";
    } else {
        if (isNaN(playerId)) {
            valid = false;
            playerErr = "Invalid int"
        } else if (playerId <= 0) {
            valid = false;
            playerErr = "Less than or equal to 0";
        }
    }

    if (!state.validFrom.dateTime) {
        valid = false;
        validFromErr = "Required";
    } else if (state.campaign && validFrom < state.campaign.validFrom) {
        valid = false;
        validFromErr = "Less than campaign's ValidFrom";
    }

    if (!state.validTo.dateTime) {
        valid = false;
        validToErr = "Required";
    }
    if (validFromErr.length <=0 && validToErr.length <= 0) {
        if (validTo < validFrom) {
            valid = false;
            validToErr = "Less than ValidTo";
        }
    }

    state.valid = valid;
    state.freebetCampaignIdError = freebetCampaignErr;
    state.playerIdError = playerErr;
    state.validFromError = validFromErr;
    state.validToError = validToErr;

    if (valid) {
        var item = state.item;
        var value : FreebetValue = {
            freebetCampaignId: freebetCampaignId,
            id: item ? item.id : 0,
            playerId: playerId,
            validFrom: validFrom,
            validTo: validTo
        };
        state.value = value;

        var update : FreebetUpdate | undefined = { };
        var changeCount = 0;
        if (item) {
            if (value.validFrom !== item.validFrom) {
                update.validFrom = value.validFrom;
                changeCount++;
            }
            if (value.validTo !== item.validTo) {
                update.validTo = value.validTo;
                changeCount++;
            }
            if (changeCount <= 0) {
                update = undefined;
            }
        } else {
            update.freebetCampaignId = value.freebetCampaignId;
            update.playerId = value.playerId;
            update.validFrom = value.validFrom;
            update.validTo = value.validTo;
        }
        state.update = update;
    } else {
        state.value = undefined;
        state.update = undefined;
    }
}
function updateState(state: EditFreebetState, item: Freebet) {
    state.item = item;
    state.id = item.id;
    state.freebetCampaignId = item.freebetCampaignId;
    state.playerId = item.playerId.toString();
    state.validFrom = getDateTimePickerXValue(item.validFrom);
    state.validTo = getDateTimePickerXValue(item.validTo);
    checkState(state);
}
function getDefaultValidFrom() : Date {
    var validFrom = CreateRoundUtil.addHours(new Date(), 1);
    var roundedValidFrom = CreateRoundUtil.getDateWithoutTime(validFrom);
    var minutes = validFrom.getMinutes();
    if (minutes === 0){
      roundedValidFrom = CreateRoundUtil.addHours(roundedValidFrom, validFrom.getHours());
    } else {
      roundedValidFrom.setHours(validFrom.getHours() + 1, 0);
    }
    return roundedValidFrom;
}
function getDefaultValidTo(validFrom: Date) : Date {
    return CreateRoundUtil.addDays(validFrom, 7);
}
function getInitialState() : EditFreebetState {
    var validFrom = getDefaultValidFrom();
    var validTo = getDefaultValidTo(validFrom);
    var state : EditFreebetState = {
        id: 0,
        freebetCampaignId: 0,
        playerId: "",
        validFrom: getDateTimePickerXValue(validFrom),
        validTo: getDateTimePickerXValue(validTo),
        valid: false,
        freebetCampaignIdError: "",
        playerIdError: "",
        validFromError: "",
        validToError: "",
    };
    checkState(state);
    return state;
}

const EditFreebet: React.FC = (props) => {
    const location = useLocation();
    const history = useHistory();    
   
    const [formState, setFormState] = useState<EditFreebetState>(getInitialState());
    const [playerRefresh, setPlayerRefresh] = useState<PlayerRefresh | undefined>();
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    const [initErr, setInitErr] = useState<string>("");
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [freebetEnabledOperators, setFreebetEnabledOperators] = useState<Map<string, boolean>>(new Map<string, boolean>());

    useEffect(() => {
        initData().then(result => {
        });
    }, []);
    const compareOperatorSetting = (x: OperatorSetting, y: OperatorSetting) : number => {
        var comp = x.operatorId.localeCompare(y.operatorId);
        if (comp !== 0) {
            return comp;
        }
        comp = x.settingName.localeCompare(y.settingName);
        if (comp !== 0) {
            return comp;
        }
        comp = x.validFrom.getDate() - y.validFrom.getDate();
        if (comp !== 0) {
            return comp;
        }
        return x.id - y.id;
    }
    const getFreebetCampaign = async (freebetCampaignId: number) : Promise<FreebetOverviewItem | undefined> => {
        var freebetOverviewResp = await FreebetUtil.getFreebetOverview(freebetCampaignId);
        if (!freebetOverviewResp.success) {
            setInitErr(`Get FreebetCampaign error [id:${freebetCampaignId}]: ${freebetOverviewResp.message}`);
            return undefined;
        }
        if (!freebetOverviewResp.success) {
            setInitErr(`Get FreebetCampaign error [id:${freebetCampaignId}]: ${freebetOverviewResp.message}`);
            return undefined;
        }
        var campaign = freebetOverviewResp.item;
        if (!campaign) {
            setInitErr(`FreebetCampaign not found [id:${freebetCampaignId}]`);
            return undefined;
        }
        return campaign;
    }
    const checkFreebetCampaign = (campaign: FreebetOverviewItem) : boolean => {
        if (!campaign.enabled) {
            setInitErr("FreebetCampaign is not enabled");
            return false;
        }
        var now = new Date();
        // if (campaign.validFrom > now || campaign.validTo < now) {
        //     setInitErr("FreebetCampaign has invalid period.");
        //     return false;
        // }
        if (campaign.validTo < now) {
            setInitErr("FreebetCampaign valid period has expired.");
            return false;
        }
        if (campaign.currentNumberOfFreebets >= campaign.maxNumberOfFreebets) {
            setInitErr(`FreebetCampaign reached maximum number of freebets [Current:${campaign.currentNumberOfFreebets}] [Max:${campaign.maxNumberOfFreebets}].`)
            return false;
        }
        return true;
    }

    const initData = async () : Promise<number> => {
        var initCount = 0;
        setInitErr("Initializing...");
        setRefreshing(true);
        try {   
            var pos = location.pathname.lastIndexOf("/");
            if (pos <= 0) {
                if (!location.search || location.search.length <= 0) {
                    setInitErr("freebetCampaignId param is missing (no params)");
                    return initCount;
                }
                let params = new URLSearchParams(location.search);
                var sCampaignId = params.get("freebetCampaignId");
                if (!sCampaignId || sCampaignId.length <= 0) {
                    setInitErr("freebetCampaignId param is missing or has no value");
                    return initCount;
                }
                var campaignId = parseInt(sCampaignId);
                if (isNaN(campaignId)) {
                    setInitErr("freebetCampaignId param is not a number");
                    return initCount;
                }
                if (campaignId <= 0) {
                    setInitErr("freebetCampaignId param is less than or equal to zero.");
                    return initCount;
                }
                var campaign = await getFreebetCampaign(campaignId);
                if (!campaign) {
                    return initCount;
                }
                var fs = {...formState, id: 0};
                fs.campaign = campaign;
                fs.freebetCampaignId = campaign.freebetCampaignId;
                fs.validFrom.dateTime = campaign.validFrom;
                if (fs.validTo.dateTime && fs.validTo.dateTime < campaign.validTo) {
                    fs.validTo.dateTime = campaign.validTo;
                }
                setFormState(fs);
                if (!checkFreebetCampaign(campaign)) {
                    return initCount;
                }
                setInitErr("");
                return initCount;
            }
            var idString = location.pathname.substring(pos + 1);
            var id = parseInt(idString);
            if (isNaN(id) || id <= 0) {
                setInitErr(`FreebetId param is invalid '${idString}'.`);
                return initCount;
            }
            var fs = {...formState, id: id};
            var resp = await FreebetUtil.getFreebet(id);
            if (!resp.success) {
                setInitErr(`Init error: ${resp.message}`);
                return initCount;
            }
            var freebet = resp.item;
            if (freebet) {
                initCount++;
                var campaign = await getFreebetCampaign(freebet.freebetCampaignId);
                fs.campaign = campaign;
                updateState(fs, freebet);
                if (campaign) {
                    setInitErr("") ;
                }
                if (freebet.couponShareId && freebet.couponShareId > 0) {
                    setInitErr(`Freebet has been used and cannot be changed [CouponShareId:${freebet.couponShareId}].`);
                }
            } else {
                setInitErr("Freebet not found.")
                return initCount;
            }
            setFormState(fs);
        } catch (error) {
            console.error("Init error.", error);
            setInitErr(`Init error: ${error}`);
        } finally {
            setRefreshing(false);
        }

        return initCount;
    }
    const handleChange = (name: string, value:any) => {
        var fs = {...formState, [name]: value};
        checkState(fs);
        setFormState(fs);
    }
    const onOperatorChange = (item?: Operator) : void => {
        var fs = {...formState, ["operatorId"]: item ? item.id : ""};
        checkState(fs);
        setFormState(fs);
    }
    const onFreebetTypeChange = (item?: FreebetType) : void => {
        var fs = {...formState, ["freebetTypeId"]: item ? item.id : 0};
        checkState(fs);
        setFormState(fs);
    }
    const onValidFromChange = (value: DateTimePickerXValue) : void => {
        var fs = {...formState, ["validFrom"]: value};
        checkState(fs);
        setFormState(fs);
    }
    const onValidToChange = (value: DateTimePickerXValue) : void => {
        var fs = {...formState, ["validTo"]: value};
        checkState(fs);
        setFormState(fs);
    }
    const isFreebetEnabled = (item: Operator) : boolean => {
        var enabled = freebetEnabledOperators.get(item.id);
        if (enabled) {
            return true;
        }
        return false;
    }
    const onRefreshPlayer = () => {
        const METHOD_NAME = "RefreshPlayer";
        
        var playerId = parseInt(formState.playerId);
        if (isNaN(playerId)) {
            setPlayerRefresh({ playerId: formState.playerId, response: CreateRoundUtil.createGenericResponse<Player>(false, "PlayerId is invalid int", null)});
            return;
        }
        if (playerId <= 0) {
            setPlayerRefresh({ playerId: formState.playerId, response: CreateRoundUtil.createGenericResponse<Player>(false, "PlayerId is less than or equal to 0", null)});
            return;
        }
        FreebetUtil.getPlayer(playerId).then(resp => {
            setPlayerRefresh({ playerId: formState.playerId, response: resp});
        }).catch(error => {
            setPlayerRefresh({ playerId: formState.playerId, response: CreateRoundUtil.createGenericResponse<Player>(false, CreateRoundUtil.getExceptionMessage(error, false, "GetPlayer error"), null)});
        });           
    };

    const onSave = () => {
        const METHOD_NAME = "SaveFreebet";
        
        setSaveError("");
        setSaveStatus(0);
        if (!formState.valid) {
            setSaveError("Input is not valid");
            return;
        }
        if (!formState.update) {
            setSaveError("No changes");
            return;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify(formState.update);
        FreebetUtil.saveFreebet(formState.id, saveJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var item = resp.item;
                if (item) {
                    if (formState.id === 0) {
                        history.push(`editFreebet/${item.id}`);
                        return;
                    }
                    var fs = {...formState, id: item.id};
                    updateState(fs, item);
                    setFormState(fs);
                    return;
                } 
                setSaveError(`${METHOD_NAME} failure: FreebetCampaign was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });
           
    };

    var saveEnabled = false;
    var freebet = formState.item;
    var campaign = formState.campaign;
    if (formState.valid && formState.update && !refreshing && saveStatus === 0) {
        saveEnabled = true;
    }
    var initErrorBox = null;
    if (initErr && initErr.length) {
        initErrorBox = (<Box marginTop={3} color="red">{initErr}</Box>);
        saveEnabled = false;
    }

    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    var title = freebet ? `Edit Freebet #${freebet.id}` : "Create Freebet";
    var isEdit = freebet ? true : false;

    var freebetCampaignName = formState.freebetCampaignId.toString();
    var freebetCampaignInfo = "";
    var freebetCampaignInfoBox = null;
    if (campaign) {
        freebetCampaignName = `${campaign.freebetCampaignName}`;
        freebetCampaignInfo = `Id: ${campaign.freebetCampaignId} | OperatorId: ${campaign.operatorId} | Freebets: ${campaign.currentNumberOfFreebets} of ${campaign.maxNumberOfFreebets}`;
        freebetCampaignInfo += ` | Valid: ${CreateRoundUtil.toGameDateString(campaign.validFrom)} -- ${CreateRoundUtil.toGameDateString(campaign.validTo)} | Enabled: ${campaign.enabled}`;
    } else if (freebet) {
        freebetCampaignName = `${freebet.freebetCampaignName}`;
        freebetCampaignInfo = `Id: ${freebet.freebetCampaignId} | OperatorId: ${freebet.operatorId}`;
    }
    if (freebetCampaignInfo.length > 0) {
        freebetCampaignInfoBox = (<Box fontSize={10}>{freebetCampaignInfo}</Box>);
    }
    var playerName = formState.playerId;
    var playerInfo = null;
    var playerInfoBox = null;
    var playerRefreshId = playerRefresh ? playerRefresh.playerId : "";
    var playerResp = playerRefresh?.response;
    var player : Player | undefined | null = playerResp?.item;
    var playerErr = formState.playerIdError;
    var refreshPlayerEnabled = false;
    var refreshPlayerBtn = null;
    if (formState.playerId && formState.playerId.length > 0) {
        if (freebet && freebet.playerId.toString() === formState.playerId) {
            playerName = freebet.playerId.toString();
            playerInfo = `OperatorId: ${freebet.operatorId} | ExternalId: ${freebet.operatorPlayerId} | Notes: ${freebet.playerNotes ? freebet.playerNotes : ""}`;
        } else if (player && player.id.toString() === formState.playerId) {
            playerInfo = `OperatorId: ${player.operatorId} | ExternalId: ${player.operatorPlayerId} | Notes: ${player.notes ? player.notes : ""}`;
            if (playerErr.length === 0) {
                if (campaign && campaign.operatorId !== player.operatorId) {
                    playerErr = "Campaign/player operator diff";
                    saveEnabled = false;
                }
            }
        } else if (playerResp && playerRefreshId === formState.playerId) {
            if (!playerResp.success) {
                playerInfo = `Get player response error: ${playerResp.message}`;
            } else if (!player) {
                playerInfo = `Player not found`;
            }
            refreshPlayerEnabled = true;
        } else {
            playerInfo = `Player is not refreshed`;
            refreshPlayerEnabled = true;
        }
    }
    if (playerInfo) {
        playerInfoBox = (<Box fontSize={10}>{playerInfo}</Box>);
    }
    if (refreshPlayerEnabled) {
        refreshPlayerBtn = (<Button onClick={e => onRefreshPlayer()} color="secondary" variant="outlined" size="small">
                            Refresh
                        </Button>);
    }
    var couponShareInfoBox = null;
//    if (isEdit && freebet && ((freebet.couponShareId && freebet.couponShareId > 0) || (freebet.externalId && freebet.externalId.length > 0))) {
    if (isEdit && freebet) {
        var couponShareInfo = `Coupon share info - Id: ${freebet.couponShareId} | ExternalId: ${freebet.externalId}`;
        couponShareInfoBox = (<Box  marginTop={2} fontSize={12}>{couponShareInfo}</Box>);
    }
    return (
        <Container maxWidth="xl">
            <Title title={title} />
            <Box>
                <TextField
                    label="Id"
                    name="id"
                    value={formState.id}
                    helperText={formState.item ? `CreatedAt: ${CreateRoundUtil.toGameDateString(formState.item.createdAt)}` : ""}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            <Box marginTop={2}>
                <TextField
                    label="Freebet Campaign"
                    name="freebetCampaignId"
                    value={freebetCampaignName}
                    style={{width:400}}
                    InputProps={{
                        readOnly: true, disableUnderline: true
                        }}
                />
            </Box>
            {freebetCampaignInfoBox}
            <Box marginTop={2} display="flex" alignItems="center">
                <TextField
                    autoFocus={!isEdit}
                    label="Player Id"
                    name="playerId"
                    value={formState.playerId}
                    onChange={e => handleChange("playerId", e.target.value)}
                    helperText={playerErr}
                    error={playerErr.length > 0}
                    InputProps={{
                        readOnly: isEdit, disableUnderline: isEdit
                        }}
                />&nbsp;&nbsp;{refreshPlayerBtn}
            </Box>
            {playerInfoBox}
            <Box marginTop={2} display="flex" alignItems="center">
                <DateTimePickerX 
                    autoFocus={isEdit}
                    label="Valid from"
                    value={formState.validFrom}
                    onChange={onValidFromChange}
                    errorText={formState.validFromError}
                /><span style={{width: 20}} />
                <DateTimePickerX 
                    label="Valid to"
                    value={formState.validTo}
                    onChange={onValidToChange}
                    errorText={formState.validToError}
                />
            </Box>
            {couponShareInfoBox}
            {initErrorBox} 
            <Box marginTop={2}>
                <Button onClick={e => onSave()} color="primary" variant="contained" disabled={!saveEnabled}>
                    Save
                </Button>
            </Box>
            {saveErrorBox} 
            <Box>
            <Button
                component={RouterLink}
                color="secondary"
                size="small"
                style={{textTransform:"none"}}
                to={`/editFreebet?freebetCampaignId=${formState.freebetCampaignId}`}
                disabled={formState.id <= 0}
                >
                Create Freebet
            </Button>&nbsp;|&nbsp;
            <Button
                    component={RouterLink}
                    color="secondary"
                    size="small"
                    style={{textTransform:"none"}}
                    to={"/freebetOverview"}
                    >
                    Goto Freebet Overview
                </Button>
            </Box>
        </Container>
    );
};

export default EditFreebet;
