import React, { useState,useEffect } from 'react';
import { Container,TextField,Button, MenuItem, Box, Switch, FormControlLabel } from "@material-ui/core";
import { RoundOverviewItem } from '../RoundOverview/RoundOverviewModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { ListResponse } from '../api/ApiNgModels';
import { Bet } from '../models/CouponDetail';
import { SyndicateInstance } from './CreateSyndicateModels';

interface FinishStepProps {
    round: RoundOverviewItem;
    bet: Bet;
    rowCount: number;
    instances: SyndicateInstance[]
}

const FinishStep: React.FC<FinishStepProps> = (props: FinishStepProps) => {

    return (
        <Box>
            {`${props.instances.length} syndicates will be created for round '${RoundOverviewUtil.getRoundTitle(props.round)}', with ${props.rowCount} rows each. Click 'Finish' to proceed.`}
      </Box>
  );
};

export default FinishStep;

