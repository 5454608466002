import React from "react";
import { useEffect, useState } from 'react';
import { CouponShare } from "../models/CouponShare";
import dayjs from 'dayjs';
import { AppBar, Box, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Theme, Typography, makeStyles } from "@material-ui/core";
import { getList, getListByPost } from "../api/dataProvider";
import { BetweenDates, DateIntervals, Roundbetsandresults } from "../models/Roundbetsandresults";
import SlcAggregatedPerType from "./SlcAggregatedPerType";
import SlcAggregatedPerMonth from "./SlcAggregatedPerMonth";
import SlcReport from "./SlcReport";



function SlcReports() {

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
      };

      interface TabPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
      }

      function a11yProps(index: any) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const useStyles = makeStyles((theme: Theme) => ({
        root: {
          flexGrow: 1,
          backgroundColor: theme.palette.background.paper,
        },
      }));

      const classes = useStyles();

      function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div  className={classes.root}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

    return (
<div>
      <AppBar position="static">
  <Tabs
     value={value}
     onChange={handleChange}
    indicatorColor="primary"
    textColor="primary"
    centered
  >
    <Tab label="Report"  {...a11yProps(0)}/>
    <Tab label="Aggregated Per Type"  {...a11yProps(1)}/>
    <Tab label="Aggregated Per Month"  {...a11yProps(2)}/>
  </Tabs>
  </AppBar>
  <TabPanel value={value} index={0}>
      <SlcReport/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SlcAggregatedPerType/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <SlcAggregatedPerMonth/>
      </TabPanel>
</div>

  
    );

}

export default SlcReports