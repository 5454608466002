import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SignBox from "./SignBox";
import clsx from "clsx";
import signs from "./signs";

const useStyles = makeStyles(theme => ({
  root: {
    width: '10em',
    height: '1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    verticalAlign: 'center'
  }
}))

const SignField = ({className, input: {onChange, value}}) => {
  const classes = useStyles();

  return <div className={clsx(className, classes.root)}>
    {Object.entries(signs).map(([sign, label]) =>
      <SignBox sign={label} value={value === sign} onChange={_ => {
        onChange(sign)
      }}/>
      )}
  </div>;
}

export default SignField;