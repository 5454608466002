import React from 'react';
import { TextField,Box } from "@material-ui/core";
import { Round } from '../RoundOverview/RoundOverviewModels';
import { RoundType } from '../models/RoundType';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';

interface RoundInfoProps {
  round: Round;
  roundType: RoundType;
}
const RoundInfo: React.FC<RoundInfoProps> = (props) => {


  var roundTypeDefJson: string = ''
  var nFixtures: number = 0;
  var roundTypeName: string | null = "";
  var roundType = props.roundType;
  if (roundType) {
    roundTypeDefJson = JSON.stringify(roundType);
    nFixtures = roundType?.parameters.nFixtures;
    roundTypeName = roundType?.parameters?.name;
    if (!roundTypeName) {
      roundTypeName = "";
    }
  }

    return (
        <Box>
            <TextField
                label="Round Type"
                name="roundTypeName"
                value={roundTypeName}
                helperText={"id:" + props.roundType?.id + "; " + props.roundType?.parameters.nFixtures.toString() + " fixtures"}
                InputProps={{
                readOnly: true,
                }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TextField
                label="Round"
                name="roundId"
                value={props.round?.id}
                helperText={"Open To:" + CreateRoundUtil.toGameDateString(props.round?.openTo) + "; Status:" + props.round?.status}
                InputProps={{
                readOnly: true,
                }}
            />
        </Box>
    );
};

export default RoundInfo;
