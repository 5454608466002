import {Form, FormSpy} from "react-final-form";
import React from "react";

const LocalFilter = ({children, setFilters, initialValues}) =>
  <Form onSubmit={() => undefined} initialValues={initialValues} render={
    props =>
      <div style={{display: 'flex', alignContent: 'baseline'}}>
        <FormSpy
          subscription={{values: true}}
          onChange={({values }) => {
            setFilters(values);
          }}/>
        {React.Children.map(children, c => <div style={{marginRight: '16px'}}>{c}</div>)}
      </div>
  }>
  </Form>;

export default LocalFilter;
