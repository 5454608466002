import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  DateField,
  List,
  SelectField,
  NumberField,
  NumberInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  FormTab,
  TextInput, ReferenceArrayInput, CheckboxGroupInput
} from 'react-admin';
import { Field } from 'react-final-form';
import AmountInput from "../../fields/AmountInput";
import AmountField from "../../fields/AmountField";
import PercentageInput from "../../fields/PercentageInput";
import VerboseSelectFormField from "../../components/VerboseSelectFormField";
import UnitsInput from "../../fields/UnitsInput";
import {InputAdornment} from "@material-ui/core";
import GuaranteeInput from "./GuaranteeInput";

const formChoices = [
        { id: 'ONE_X_TWO', name: '1X2'}
];

const outcomeDecisionMethodChoices = [
    { id: 'TYPE_UNKNOWN', name: 'TYPE_UNKNOWN' },
    { id: 'RANDOM_NUMBER', name: 'RANDOM_NUMBER' },
    { id: 'ADMIN_CHOICE', name: 'ADMIN_CHOICE' },
];

const adminPickedOutcomeChoices = [
    { id: 'UNKNOWN', name: 'UNKNOWN' },
    { id: 'ONE', name: 'ONE' },
    { id: 'X', name: 'X' },
    { id: 'TWO', name: 'TWO' },
];

export const RoundTypeFormInput = props =>
    <SelectInput {...props} choices={formChoices} />;

export const OutcomeDecisionMethodInput = props =>
    <SelectInput {...props} choices={outcomeDecisionMethodChoices} />;

export const AdminPickedOutcomeInput = props =>
    <SelectInput {...props} choices={adminPickedOutcomeChoices} />;


export const RoundTypeFormField = props =>
    <SelectField {...props} choices={formChoices} />;

const paybackTargetChoices = [
  { id: 'DISTRIBUTE_TOP', name: 'Top winner', desc: 'Distribute to top group with winner, otherwise to fund if no winners'},
  { id: 'DISTRIBUTE_NEXT_TOP', name: 'Next top group', desc: 'Distribute to top group in next round'},
  { id: 'DISTRIBUTE', name: 'Winners', desc: 'Distribute proportionally to groups with winners, otherwise to fund if no winners'},
];

// eslint-disable-next-line no-unused-vars
const PaybackTargetField = props =>
  <SelectField {...props} choices={paybackTargetChoices} />;

const PaybackTargetInput = ({source, ...props}) =>
  <Field name={source} component={VerboseSelectFormField} choices={paybackTargetChoices} {...props}/>;

export const RoundTypeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <RoundTypeParametersInput source={"parameters"} inEditView={false}/>
        </SimpleForm>
    </Create>
);

export const RoundTypeParametersInput = ({record, source, inEditView, ...props}) =>
    <FormTab label={""} {...props}>
        <TextInput source={`${source}.name`} label={'Name'}/>
        <RoundTypeFormInput source={`${source}.form`} label={'Form'}/>
        {inEditView ? <AmountField source={`${source}.unitStake`} label={'Unit Stake'}/> :
            <AmountInput source={`${source}.unitStake`} label={'Unit Stake'}/>}

        <NumberInput source={`${source}.nFixtures`} label={'Number of fixtures'}/>
        <PercentageInput source={`${source}.payback`} label={'Payback fraction'}/>
        <PercentageInput source={`${source}.fund`} label={'Fund fraction'}/>
        <GuaranteeInput source={`${source}.allCorrectSoleWinner`} label={"Guarantee for sole winner on all correct"}/>
        <PaybackTargetInput source={`${source}.unpaidTarget`} label={'Target for unpaid winnings'}/>
        <UnitsInput source={`${source}.minWinUnits`} label={'Minimal winning'}/>
        <NumberInput source={`${source}.winPrecision`} label={'Precision on wins'} options={{
          InputProps:{
            endAdornment: <InputAdornment position="end">decimals</InputAdornment>,
          }
        }}/>
        <ArrayInput source={`${source}.paybackDistribution`} label={'Payback distribution'}>
            <SimpleFormIterator>
                <PercentageInput source="" label={'Payback fraction'}/>
            </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput fullWidth reference={'reducedSystems'} source={`${source}.reducedSystemIds`} label={'Reduced systems available'}>
          <CheckboxGroupInput source={'id'}/>
        </ReferenceArrayInput>
    </FormTab>


export const RoundTypeEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <RoundTypeParametersInput source={"parameters"} inEditView={true}/>
        </SimpleForm>
    </Edit>
);

export const RoundTypeList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="parameters.name" label={'Name'}/>
            <DateField source="createdAt" showTime/>
            <RoundTypeFormField source={"parameters.form"} label={'Form'}/>
            <NumberField source={"parameters.nFixtures"} label={'Number of fixtures'}/>
            <AmountField source={'parameters.unitStake'} label={'Unit Stake'}/>
            <NumberField source={"parameters.payback"} step={0.01} label={'Payback fraction'}/>
        </Datagrid>
    </List>
);

export const RoundTypeReferenceInput = props =>
    <ReferenceInput label={'Round Type'}  reference="roundTypes" {...props} >
        <AutocompleteInput optionText="parameters.name" emptyValue={""}/>
    </ReferenceInput>;

export const RoundTypeReferenceField = props =>
    <ReferenceField reference="roundTypes" {...props} >
        <TextField source="parameters.name" />
    </ReferenceField>;
