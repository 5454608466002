import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Tab, Tabs, Box, Typography, Button, TextField, MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { Title } from "react-admin";
import { League, LeagueStat } from "./LeagueModels";
import LeagueUtil from "./LeagueUtil";
import LeagueTable from "./LeagueTable";
import { QueryParams } from "../models/CouponShare";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import { ListResponse } from "../api/ApiNgModels";
import ApiNgUtil from "../api/ApiNgUtil";
import RefreshIcon from '@material-ui/icons/Refresh';
import LeagueOverviewFixtures from "./LeagueOverviewFixtures";

interface TabPanelProps {
    children: React.ReactNode;
    value: number;
    index: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// export interface LeagueOverviewState {
//     leagueName: string;
//     enabled?: boolean;
//     pageSize: number;
//     page: number;
//     orderBy: string;
//     orderDirection: string;
// }
export interface LeagueOverviewState {
    leagueName: string;
    enabled: string;
    pageSize: string;
    page: string;
    orderBy: string;
    orderDirection: string;
}
const LeagueOverview: React.FC = () => {
    const [leaguesResponse, setLeaguesResponse] = useState<ListResponse<League>>(ApiNgUtil.emptyListResponse<League>(100));
    const [selectedLeague, setSelectedLeague] = useState<League | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [formData, setFormData] = useState<LeagueOverviewState>({
        leagueName: "",
        enabled: "*",
        pageSize: "100",
        page: "1",
        orderBy: "id",
        orderDirection: "asc"      
    });
    const [actionError, setActionError] = useState<string>("");
    const [leagueStat, setLeagueStat] = useState<LeagueStat | null>(null);
    const history = useHistory();

    const toQueryParams = (state: LeagueOverviewState) : QueryParams => {
        const page = parseInt(state.page) - 1;
        const pageSize = parseInt(state.pageSize);
        var enabled : boolean | undefined = undefined;
        if (state.enabled === "true") {
            enabled = true;
        } else if (state.enabled === "false") {
            enabled = false;
        }
        var q : string | undefined = state.leagueName.length === 0 ? undefined : state.leagueName;
        var params : QueryParams = {
            pagination: { page: page, perPage: pageSize },
            filter: { q: q, enabled:enabled },
            sort: { field: state.orderBy, order: state.orderDirection }
        }
        return params;
    };

    useEffect(() => {
        var params = toQueryParams(formData);
        LeagueUtil.getLeagues(params).then((resp) => {
            var errorMessage = "";
            if (resp.success) {
                if (resp.item) {
                    setLeaguesResponse(resp.item);
                } else {
                    var pageSize = params.pagination ? params.pagination.perPage : 100;
                    setLeaguesResponse(ApiNgUtil.emptyListResponse(pageSize));
                }
            } else  {
                var message = `Init leagues error: ${resp.message}`;
                errorMessage = message;
                setActionError(errorMessage);
            }
            LeagueUtil.getLeagueStat().then(leagueStatResp => {
                if (leagueStatResp.success) {
                    if (leagueStatResp.item) {
                        setLeagueStat(leagueStatResp.item);
                    } else {
                        var message = "Init stat failure: no data";
                        if (errorMessage.length == 0) {
                            errorMessage = message;
                        } else {
                            errorMessage = errorMessage + " | " + message;
                        }
                        setActionError(errorMessage);
                    }
                } else  {
                    var message = `Init stat error: ${leagueStatResp.message}`;
                    if (errorMessage.length == 0) {
                        errorMessage = message;
                    } else {
                        errorMessage = errorMessage + " | " + message;
                    }
                    setActionError(errorMessage);
                }
    
            }).catch(error =>{
                var message = CreateRoundUtil.getExceptionMessage(error, false, "Init stat error");
                if (errorMessage.length == 0) {
                    errorMessage = message;
                } else {
                    errorMessage = errorMessage = errorMessage + " | " + message;
                }
                setActionError(errorMessage);
            });
        }).catch(error =>{
            var message = CreateRoundUtil.getExceptionMessage(error, false, "Init leagues error");
            setActionError(message);
    
        });

    }, []);


    const onSelect = (item: League) => {
        setSelectedLeague(item);
    };

    const handleChange = (name: string, value: any) : void => {
        var fd = { ...formData, [name]: value };
        setFormData(fd);
    };
    const onSearch = () : void => {
       try {
        setActionError("");
        var params = toQueryParams(formData);
            LeagueUtil.getLeagues(params).then((resp) => {
                if (resp.success) {
                    if (resp.item) {
                        setLeaguesResponse(resp.item);
                    } else {
                        var pageSize = params.pagination ? params.pagination.perPage : 100;
                        setLeaguesResponse(ApiNgUtil.emptyListResponse(pageSize));
                    }
                } else  {
                    var message = `Search error: ${resp.message}`;
                    setActionError(message);
                }
            }).catch(error =>{
                var message = CreateRoundUtil.getExceptionMessage(error, false, "Search error");
                setActionError(message);
        
            });

       } catch (error) {
        var message = CreateRoundUtil.getExceptionMessage(error, false, "Search error");
        setActionError(message);
       }
    };
    const onRefreshStat = () : void => {
        try {
            setActionError("");
            LeagueUtil.getLeagueStat().then(resp => {
                if (resp.success) {
                    if (resp.item) {
                        setLeagueStat(resp.item);
                    } else {
                        var message = "Refresh stat failure: no data";
                        setActionError(message);
                    }
                } else  {
                    var message = `Refresh stat error: ${resp.message}`;
                    setActionError(message);
                }
    
            }).catch(error =>{
                var message = CreateRoundUtil.getExceptionMessage(error, false, "Refresh stat error");
                setActionError(message);
            });
 
        } catch (error) {
         var message = CreateRoundUtil.getExceptionMessage(error, false, "Refresh stat error");
         setActionError(message);
        }
     };
 
    var leagueNameError = "";
    var pageError = "";
    var pageSizeError = "";
    var tmpInt = 0;
    var valid = true;
    if (formData.page.length === 0) {
        valid = false;
        pageError = "Required int";
    } else {
        tmpInt = parseInt(formData.page);
        if (isNaN(tmpInt)) {
            valid = false;
            pageError = "Invalid int";
        } else if (tmpInt < 1) {
            valid = false;
            pageError = "Less than 1";
        }
    }
    if (formData.pageSize.length === 0) {
        valid = false;
        pageSizeError = "Required int";
    } else {
        tmpInt = parseInt(formData.pageSize);
        if (isNaN(tmpInt)) {
            valid = false;
            pageSizeError = "Invalid int";
        } else if (tmpInt < 1) {
            pageSizeError = "Less than 1";
        }
    }

    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{actionError}</Box>);
    }

    var enabledCount = 0;
    if (leaguesResponse.items) {
        leaguesResponse.items.forEach(it => {
            if (it.enabled) {
                enabledCount++;
            }
        })
    }
    var responseInfo = `Count: ${leaguesResponse.size} of ${leaguesResponse.totalSize} | Page: ${leaguesResponse.page + 1} of ${leaguesResponse.totalPages} | EnabledCount: ${enabledCount}`;
    var responseInfoBox =  (<Box fontSize={10}>{responseInfo}</Box>);;
    var statBox = null;
    var statInfo = null;
    if (leagueStat) {
        statInfo = `Statistics - Count: ${leagueStat.count} | EnabledCount: ${leagueStat.enabledCount}`;
    } else {
        statInfo = "Statistics - No data";
    }
    return (
        <Container maxWidth="xl">
            <Title title="League Overview" />
            <Box paddingBottom={2}>
                <TextField
                        name="leagueName"
                        label="League pattern"
                        value={formData.leagueName}
                        error={leagueNameError && leagueNameError.length > 0 ? true : false}
                        helperText={leagueNameError && leagueNameError.length > 0 ? leagueNameError : "Character sequence of League name"}
                        onChange={e => handleChange("leagueName", e.target.value)}
                />
                    <TextField
                        name="enabled"
                        label="Enabled"
                        select
                        value={formData.enabled}
                        onChange={e => handleChange("enabled", e.target.value)}
                        style={{ width: 100 }}
                    >
                        <MenuItem key="*" value="*">(All)</MenuItem>
                        <MenuItem key="false" value="false">false</MenuItem>
                        <MenuItem key="true" value="true">true</MenuItem>
                    </TextField>
                    <TextField
                        name="pageSize"
                        label="Page size"
                        type="number"
                        value={formData.pageSize}
                        style={{ width: 100 }}
                        error={pageSizeError && pageSizeError.length ? true : false}
                        helperText={pageSizeError}
                        onChange={e => handleChange("pageSize", e.target.value)}
                    />
                    <Button 
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!valid}
                        onClick={e => onSearch()}>
                        Search
                    </Button>
            </Box>
            {/* <Box paddingBottom={1}>
                <TextField
                        name="orderBy"
                        label="Order by"
                        select
                        value={formData.orderBy}
                        onChange={e => handleChange("orderBy", e.target.value)}
                        style={{ width: 100 }}
                    >
                        <MenuItem key="id" value="id">Id</MenuItem>
                        <MenuItem key="name" value="name">Name</MenuItem>
                        <MenuItem key="enabled" value="enabled">Enabled</MenuItem>
                </TextField>
                <TextField
                        name="orderDirection"
                        label="Order dir"
                        select
                        value={formData.orderDirection}
                        onChange={e => handleChange("orderDirection", e.target.value)}
                        style={{ width: 100 }}
                    >
                        <MenuItem key="asc" value="asc">asc</MenuItem>
                        <MenuItem key="desc" value="desc">desc</MenuItem>
                </TextField>
                <TextField
                    name="pageSize"
                    label="Page size"
                    type="number"
                    value={formData.pageSize}
                    style={{ width: 100 }}
                    error={pageSizeError && pageSizeError.length ? true : false}
                    helperText={pageSizeError}
                    onChange={e => handleChange("pageSize", e.target.value)}
                />
                <TextField
                    name="page"
                    label="Page"
                    type="number"
                    value={formData.page}
                    style={{ width: 100 }}
                    error={pageError && pageError.length ? true : false}
                    helperText={pageError}
                    onChange={e => handleChange("page", e.target.value)}
                />
                <Button 
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!valid}
                    onClick={e => onSearch()}>
                    Search
                </Button>&nbsp;
                </Box> */}
                {actionErrorBox}
                {responseInfoBox}
                <Box fontSize={10}>
                    {statInfo}
                    <IconButton aria-label="refreshStat" color="secondary" size="small" onClick={(e: any) => onRefreshStat()} >
                        <Tooltip title="Refresh statistics">
                            <RefreshIcon />
                        </Tooltip>
                    </IconButton>

                </Box>
            <LeagueTable leagues={leaguesResponse.items} onSelect={onSelect} />
            <div>
                <Tabs value={selectedTab}
                    onChange={(ev, value) => { setSelectedTab(value) }}
                >
                    <Tab label="Fixtures" />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <LeagueOverviewFixtures leagueId={selectedLeague ? selectedLeague.id : 0} leagueName={selectedLeague ? selectedLeague.title : ""} />
                </TabPanel>
            </div>

        </Container>
    );
};

export default LeagueOverview;

