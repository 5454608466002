import { getAccessToken } from "../../apiToken";
import InvokeApiMethods, { InvokeApiOptions, InvokeApiResponse } from "../ExternalApi/ExternalApiModels";
import ExternalApiUtil from "../ExternalApi/ExternalApiUtil";
import { QueryParams } from "../models/CouponShare";
import queryString from "query-string";
import { EmbeddedListResponse, ListResponse } from "./ApiNgModels";
import fetchApi from "../../fetchApi";
import { GenericResponse } from "../CreateRound/CreateRoundModels";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import ExternalApiModels from "../ExternalApi/ExternalApiModels";

interface Api2Props {
    baseUrl? : string;
}
const props : Api2Props = {
    baseUrl: process.env["REACT_APP_API_NG_URL"]
}
class ApiNgUtil {
    static async invokeApi(resourceUrl: string, options: InvokeApiOptions) : Promise<InvokeApiResponse> {
        if (!options) {
            options = {
                headers: new Headers()
            };
        } 
        if (!options.headers) {
            options.headers = new Headers();
        }
        const token = await getAccessToken();
        if (token) {
          options.headers.set('Authorization', `Bearer ${token}`);
        }
        const baseUrl = props.baseUrl;
        let url: string = `${baseUrl}/${resourceUrl}`;
        var response = await ExternalApiUtil.invokeApi(url, options);
        return response;
    }
    static async invokeApi2(resourceUrl: string, method?: string, body?: string) : Promise<InvokeApiResponse> {
        var options : InvokeApiOptions = {
            method: method,
            body: body,
            headers: new Headers()
        }
        
        const token = await getAccessToken();
        if (token) {
          options.headers?.set('Authorization', `Bearer ${token}`);
        }
        const baseUrl = props.baseUrl;
        let url: string = `${baseUrl}/${resourceUrl}`;
        
        var response = await ExternalApiUtil.invokeApi(url, options);
        return response;
    }
    static async invokeApi3(resourceUrl: string, method?: string, body?: string) : Promise<InvokeApiResponse> {
        const METHOD_NAME = "ApiNgUtil.invokeApi3";
        try {
        
            const token = await getAccessToken();
            let url: string = `${props.baseUrl}/${resourceUrl}`;

            const innerResponse = await fetch(url, {
                method: method,
                body: body,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!innerResponse.ok) {
                var message = await ApiNgUtil.getResponseError(innerResponse);
                return {
                    success: false,
                    message: message,
                    status: innerResponse.status
                };
            }
            if (innerResponse.status === 204) {
                return {
                    success: true,
                    message: innerResponse.statusText,
                    status: innerResponse.status
                };
            }
            var json = await innerResponse.json();
            var response : InvokeApiResponse = {
                success: true,
                message:"",
                json: json,
                status: innerResponse.status
            }
            return response;
        } catch (error) {
            console.error(`${METHOD_NAME} error [ResourceUrl:${resourceUrl}].`, error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return {
                success: false,
                message: message
            };
        }
    }
    // static async getList2<T>(resource: string, params: QueryParams) : Promise<GenericResponse<ListResponse<T>>> { 
    //     const METHOD_NAME = "ApiNgUtil.getList2";
    //     try {
    //         const baseUrl = props.baseUrl;
    //         const query = Object.assign({
    //             orderBy: `${params.sort?.field} ${params.sort?.order}`,
    //             pageSize: params.pagination?.perPage,
    //             page: params.pagination?.page,
    //             filter: JSON.stringify(params.filter)
    //         });
    //         const resourceUrl = `${resource}?${queryString.stringify(query)}`;
    //         var innerResponse = await this.invokeApi3(resourceUrl, ExternalApiModels.GET());
    //         if (!innerResponse.success) {
    //             return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, innerResponse.message, null);
    //         }
    //         var inner : EmbeddedListResponse = innerResponse.json;
    //         if (!inner) {
    //             return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, "No response", null);
    //         }
    //         var resp : ListResponse<T> = ApiNgUtil.toListResponse<T>(inner, resource);
    //         return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, resp.message ? resp.message : "", resp);
    //     } catch (error) {
    //         //var errorJson = JSON.stringify(error);
    //         console.error(`${METHOD_NAME} error [Resource:${resource}].`, error);
    //         var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
    //         return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, message, null);
    //     }
    // }

    static async getList<T>(resource: string, params?: QueryParams) : Promise<GenericResponse<ListResponse<T>>> {
        const METHOD_NAME = "ApiNgUtil.getList";
        try {
            var resourceUrl = resource;
            if (params) {
                var orderBy = undefined;
                if (params.sort?.field && params.sort?.field.length > 0) {
                    orderBy = params.sort?.field;
                    if (params.sort?.order && params.sort?.order.length > 0) {
                        orderBy += ` ${params.sort?.order}`
                    }
                }
                const query = Object.assign({
                    orderBy: orderBy,
                    pageSize: params.pagination?.perPage,
                    page: params.pagination?.page,
                    filter: JSON.stringify(params.filter)
                });
                resourceUrl = `${resource}?${queryString.stringify(query)}`;
            }
            var innerResponse = await ApiNgUtil.invokeApi2(resourceUrl, InvokeApiMethods.GET());
            if (!innerResponse.success) {
                return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, innerResponse.message, null);
            }
            var inner : EmbeddedListResponse = innerResponse.json;
            if (!inner) {
                return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, "No response", null);
            }
            var resp : ListResponse<T> = ApiNgUtil.toListResponse<T>(inner, resource);
            return CreateRoundUtil.createGenericResponse<ListResponse<T>>(resp.success, resp.message ? resp.message : "", resp);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return {
                success: false,
                message: CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error"),
                item: null
            };            

        }
    }
    static async getListByUrl<T>(resourceUrl: string, resourceName: string) : Promise<GenericResponse<ListResponse<T>>> {
        const METHOD_NAME = "ApiNgUtil.getListByUrl";
        try {
            var innerResponse = await ApiNgUtil.invokeApi2(resourceUrl, InvokeApiMethods.GET());
            if (!innerResponse.success) {
                return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, innerResponse.message, null);
            }
            var inner : EmbeddedListResponse = innerResponse.json;
            if (!inner) {
                return CreateRoundUtil.createGenericResponse<ListResponse<T>>(false, "No response", null);
            }
            var resp : ListResponse<T> = ApiNgUtil.toListResponse<T>(inner, resourceName);
            return CreateRoundUtil.createGenericResponse<ListResponse<T>>(resp.success, resp.message ? resp.message : "", resp);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return {
                success: false,
                message: CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error"),
                item: null
            };            

        }
    }
    static async getObject<T>(resourceUrl: string) : Promise<GenericResponse<T>> {
        const METHOD_NAME = "ApiNgUtil.getObject";
        try {
            var innerResponse = await ApiNgUtil.invokeApi3(resourceUrl, InvokeApiMethods.GET());
            if (!innerResponse.success) {
                return CreateRoundUtil.createGenericResponse<T>(false, innerResponse.message, null);
            }
            var json = innerResponse.json;
            var obj : T | null = json ? json : null;
            return CreateRoundUtil.createGenericResponse<T>(true, innerResponse.message, obj);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return CreateRoundUtil.createGenericResponse<T>(false, message, null);
        }
    }
    static async getObjectWithBody<T>(resourceUrl: string, method: string, body: string) : Promise<GenericResponse<T>> {
        const METHOD_NAME = "ApiNgUtil.getObjectWithPost";
        try {
            var innerResponse = await ApiNgUtil.invokeApi2(resourceUrl, method, body);
            if (!innerResponse.success) {
                return CreateRoundUtil.createGenericResponse<T>(false, innerResponse.message, null);
            }
            var json = innerResponse.json;
            var obj : T | null = json ? json : null;
            return CreateRoundUtil.createGenericResponse<T>(true, innerResponse.message, obj);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return CreateRoundUtil.createGenericResponse<T>(false, message, null);
        }
    }

    static async getResponseError(response : Response) : Promise<string> {
        var text = await response.text();
        if (text && text.length > 100) {
            text = text.substring(0, 97) + "...";
        }
        var message = `Fetch error [Message:${text}] [Status:${response.status}] [StatusText:${response.statusText}]`
        return message;
    }
    static emptyListResponse<T>(pageSize: number) : ListResponse<T> {
        var items : T[] = [];
        return {
            success: true,
            message: "",
            items: items,
            pageSize: pageSize,
            page: 0,
            totalSize: 0,
            totalPages: 0,
            size: 0
        };
    }
    static toListResponse<T>(inner: EmbeddedListResponse, resource: string) : ListResponse<T> {
        var items : any | undefined = undefined;
        var embedded = inner._embedded;
        if (embedded) {
            items = embedded[resource];
            if (!items) {
                var resourceLower = resource.toLowerCase();
                var resourceKey = Object.keys(embedded).find(key => key.toLowerCase() === resourceLower);
                if (resourceKey) {
                    items = embedded[resourceKey];
                }
            }
        }
        var resp : ListResponse<T> = {
            success: inner.success,
            message: inner.message,
            items: items ? items : [],
            pageSize: inner.pageSize,
            page: inner.page,
            totalSize: inner.totalSize,
            totalPages: inner.totalPages,
            size: inner.size
        };
        return resp;
    }
}
export default ApiNgUtil;