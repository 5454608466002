import React from 'react';
import {
    Datagrid, TextField, DateField, List,
    ReferenceField, SimpleForm, Edit, FormDataConsumer,
    useRefresh, useNotify, Filter, TextInput, CheckboxGroupInput,
} from 'react-admin';
import {RoundReferenceField} from "../rounds";
import {CouponReferenceField} from "../coupons";
import RoundAmountField from "../rounds/RoundAmountField";
import {PlayerReferenceField} from "../players";
import AmountField from "../../fields/AmountField";
import fetchApi from "../../fetchApi";
import Button from "@material-ui/core/Button";

const statusChoices = [
    { id: 'CANCELED', name: 'Pending', desc: 'Fully editable and in progress, not visible anywhere'},
];

export const CouponShareGrid = props => (
  <Datagrid rowClick={'edit'} {...props}>
    <TextField source="id" />
    <DateField source="createdAt" showTime/>
    <TextField source="status"/>
    <RoundReferenceField source="roundId" />
    <CouponReferenceField source="couponId"/>
    <TextField source="operatorId" />
    <TextField source="operatorChannel" />
    <PlayerReferenceField source={"playerId"}/>
    <RoundAmountField source="stake" />
    <AmountField source="operatorStake" />
    <RoundAmountField source="result.result" label={"Result"}/>
    <AmountField source="result.operatorResult" label={"Operator Result"}/>
  </Datagrid>
);

const MultiCouponShareStatusInput = props =>
    <CheckboxGroupInput {...props} choices={statusChoices} />;

const CouponShareFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="id" alwaysOn />
    </Filter>
);

export const CouponShareList = props => (
    <List sort={{ field: 'createdAt', order: 'desc'}} filters={<CouponShareFilter/>} filterDefaultValues={{}} {...props}>
      <CouponShareGrid/>
    </List>
);

const cancelCouponShare = async (shareId, refresh, notify) => {
    const res = await fetchApi(`/extended/couponShares/cancel/${shareId}`, {
        method: 'POST',
    }).then( async (json) => {
        // wait for propagation of events (eventual consistency)
        await new Promise(r => setTimeout(r, 1000));
        return ({
            data: json,
        })
    }).catch(err => {
        notify(err)
    })

    notify('Cancelled share')
    refresh()
}

export const CouponShareEdit = props => {
const refresh = useRefresh();
const notify = useNotify();
return (
  <Edit {...props}>
      <SimpleForm>
          <TextField source="id" />
          <DateField source="createdAt" showTime/>
          <TextField source="status"/>
          <RoundReferenceField source="roundId" addLabel={true}/>
          <CouponReferenceField source="couponId" addLabel={true}/>
          <TextField source="operatorId" />
          <TextField source="operatorChannel" />
          <PlayerReferenceField source={"playerId"} addLabel={true}/>
          <RoundAmountField source="stake" />
          <AmountField source="operatorStake" />
          <RoundAmountField source="result.result" label={"Result"}/>
          <FormDataConsumer>
              {({ formData, ...rest }) => {
                  if (formData.status === 0) {
                      return <Button type={'button'} onClick={() => cancelCouponShare(formData.id, refresh, notify)} variant={'outlined'}>Cancel Share</Button>
                  }

                  return <div/>
                }
              }
          </FormDataConsumer>
      </SimpleForm>
  </Edit>
)};

export const CouponShareReferenceField = props =>
    <ReferenceField reference="couponShares" {...props} >
        <TextField source="id" />
    </ReferenceField>;
