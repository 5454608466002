import React, { useState } from "react";
import { Button, TextField, Box, MenuItem, FormLabel } from '@material-ui/core';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { Team } from "../RoundOverview/RoundOverviewModels";
import { GenericResponse } from "../CreateRound/CreateRoundModels";

export interface DateTimePickerXValue {
    dateTime?: Date;
    dateString: string;
    timeString: string;
}
export function getDateTimePickerXValue(dateTime: Date) : DateTimePickerXValue {
    return { dateTime: dateTime, dateString: "", timeString: ""};
}
interface DateTimePickerXProps {
    label: string;
    value: DateTimePickerXValue;
    disabled?: boolean;
    onChange: (value: DateTimePickerXValue) => void;
    errorText?: string;
    autoFocus?: boolean;
}
interface DateTimePickerXState {
    date: string;
    time: string;
    dateTime?: Date;
    dateErr: string;
    timeErr: string;
}


const DateTimePickerX: React.FC<DateTimePickerXProps> = (props) => {
    const getDefaultFormState = () : DateTimePickerXState => {
        var defaultDate = new Date();
        var value = props.value;
        if (value.dateTime) {
            defaultDate = value.dateTime;
        }
        var dateString = CreateRoundUtil.getDateWithoutTimeString(defaultDate);
        var timeString = CreateRoundUtil.getTimeString(defaultDate);
        return {
            date: dateString,
            time: timeString,
            dateTime: defaultDate,
            dateErr: "",
            timeErr: ""
        };
    }
    
    const [formState, setFormState] = useState<DateTimePickerXState>(getDefaultFormState());
 
   
    const checkState = (fs: DateTimePickerXState, triggerChange: boolean) =>{
        var valid = true;
        var date = CreateRoundUtil.parseDateX(fs.date);
        var dateTime = date;
        var dateErr = "";
        var timeErr = "";
        if (fs.date.length > 0) {
            var dateNumber = date.getDate();
            if (isNaN(dateNumber)) {
                valid = false;
                dateErr = "Invalid date";
            }
        }
        else {
            valid = false;
            dateErr = "Required";
        }
        if (fs.time.length > 0) {
            if (CreateRoundUtil.isValidTime(fs.time)) {
                dateTime = CreateRoundUtil.addTime(dateTime, fs.time);
            }
            else {
                valid = false;
                timeErr = "Invalid time (HH:mm)";
            }
        }
        else {
            valid = false;
            timeErr = "Required (HH:mm)";
        }
        if (valid) {
             fs.dateTime = dateTime;
        } else {
            fs.dateTime = undefined;
        }
        if (triggerChange) {
            if (props.onChange) {
                props.onChange({ dateTime: fs.dateTime, dateString: fs.date, timeString: fs.time });
            }
        }
        fs.dateErr = dateErr;
        fs.timeErr = timeErr;
    }
    const handleChange = (name: string, value:string) =>{
        var fs = {...formState, [name]: value};
        checkState(fs, true);
        setFormState(fs);
    }
    const checkValueChange = () : boolean => {
        var value = props.value;
        if (!value) {
            return false;
        }
        if (value.dateTime) {
            if (value.dateTime !== formState.dateTime) {
                var fs = getDefaultFormState();
                setFormState(fs);
                return true;
            }
            return false;
        }
        if (value.dateString && value.dateString.length > 0 && value.timeString && value.timeString.length > 0) {
            if (value.dateString !== formState.date || value.timeString !== formState.time) {
                var fs = {...formState, ["date"]: value.dateString ? value.dateString : formState.date};
                fs.time = value.timeString ? value.timeString : formState.time;
                checkState(fs, false);
                setFormState(fs);
                return true;
            }
        }
        return false;
    }
    var changed = checkValueChange();
    var dateErr = formState.dateErr;
    if (formState.dateErr.length === 0 && formState.timeErr.length === 0 && props.errorText && props.errorText.length > 0) {
        dateErr = props.errorText;
    }
    var disabledX = (props.disabled !== undefined && props.disabled === true);
    return (
        <div>
           <TextField
                type="date"
                name="date"
                value={formState.date}
                label={props.label}
                disabled={disabledX}
                InputLabelProps={{shrink: true}}
                error={dateErr.length > 0}
                helperText={dateErr}
                onChange={e => handleChange("date", e.target.value)}
                autoFocus={props.autoFocus}
            />
            <FormLabel style={{ padding: 2 }}></FormLabel>
            <TextField
                name="time"
                value={formState.time}
                label={CreateRoundUtil.timeInfoText}
                disabled={disabledX}
                style={{ width: 40 }}
                InputLabelProps={{shrink: true}}
                error={formState.timeErr && formState.timeErr.length ? true : false}
                helperText={formState.timeErr}
                onChange={e => handleChange("time", e.target.value)}
            />
        </div>
    );
};

export default DateTimePickerX;
