import React from 'react';
import { formatToCurrency } from '../../common/helpers/CurrencyHelpers';
import {fetchUtils} from 'react-admin';
import { PoolXMoney } from '../../models/CouponShare';
const fetchJson = fetchUtils.fetchJson;

export interface ProPickType {
  numRows: number;
  price: string;
  dif: string;
  numFull: number;
  numHalf: number;
}
export interface ProPickSign {
  rowsFraction: number;
}

export interface ProPickSigns {
  ONE: ProPickSign;
  TWO: ProPickSign;
  X: ProPickSign;
}
export interface ProPickFixture {
  id: number;
  name: string;
  startingAt: Date;
  signs: ProPickSigns;
}
export interface ProPickFixtureProbabilities {
  ONE: number;
  TWO: number;
  X: number;
}
export interface ProPickSuggestionFixture {
  ID: string;
  Probabilities: ProPickFixtureProbabilities;
}
export interface ProPickSuggestionSettings {
  NumHalfCovers: number;
  NumFullCovers: number;
  ProbabilitySpanFloor: number;
  ProbabilitySpanCeiling: number;
}
export interface ProPickSuggestionRequest {
  Fixtures: ProPickSuggestionFixture[];
  Settings: ProPickSuggestionSettings;
}
export interface ProPickSuggestedBet {
  FixtureID: string;
  Signs: string[];
  Probability: number;
}
export interface ProPickSuggestionResponse {
  SuggestedBets: ProPickSuggestedBet[];
}


export function getRemoteSuggestion(request: ProPickSuggestionRequest) {
  const url = "https://onextwo-propick-mwkingfyqa-ew.a.run.app/suggestion";

  const inputData = JSON.stringify(request);
  return fetchJson(url, {
    method: 'POST',
    body: inputData,
  }).then(response => response.json);
}
export function getProPickText(numRows: number, operatorStakePerRow: PoolXMoney, operatorFeePerRow: PoolXMoney) : string {

  var betAmount = Math.round(numRows * (operatorStakePerRow.amount + operatorFeePerRow.amount) * 10) / 10;
  return formatToCurrency(betAmount, operatorStakePerRow.currency);
  //return formatStringCurrency(Math.round(numRows * (Number(operatorStakePerRow.amount) + Number(operatorFeePerRow.amount)) * 10) / 10, operatorStakePerRow.currency, t, isCurrencyWithWholeNumbers(operatorStakePerRow.currency));
}

export function getProTypes(numGames: number, operatorStakePerRow: PoolXMoney, operatorFeePerRow: PoolXMoney) : ProPickType[] {
  let proTypes : ProPickType[] = [];
  let numRows = 6;
  if (numGames >= 3) {
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 1, numHalf: 1 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 1, numHalf: 1 });
  }
  if (numGames > 3) {
    numRows = 36;
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 2, numHalf: 2 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 2, numHalf: 2 });
  }
  if (numGames > 5) {
    numRows = 108;
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 3, numHalf: 2 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 3, numHalf: 2 });
  }
  if (numGames > 7) {
    numRows = 192;
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 1, numHalf: 6 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 1, numHalf: 6 });

    numRows = 648;
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 4, numHalf: 3 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 4, numHalf: 3 });
  }

  if (numGames > 9) {
    numRows = 972;
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 5, numHalf: 2 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 5, numHalf: 2 });

    numRows = 1944;
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Easy', numFull: 5, numHalf: 3 });
    proTypes.push({ numRows: numRows, price: getProPickText(numRows, operatorStakePerRow, operatorFeePerRow), dif: 'Hard', numFull: 5, numHalf: 3 });
  }

  return proTypes;
}

export function getSuggestion(protype: ProPickType, fixtures: ProPickFixture[], setProPickRows: (rows: Array<Array<string>>) => void) {
  let floor = 0.5;
  let ceiling = 1.0;

  if (protype.dif === 'Hard') {
    floor = 0;
    ceiling = 0.5;
  }

  let request : ProPickSuggestionRequest = {
    Fixtures: [],
    Settings: {
      NumHalfCovers: protype.numHalf,
      NumFullCovers: protype.numFull,
      ProbabilitySpanFloor: floor,
      ProbabilitySpanCeiling: ceiling
    }
  }

  let index = 0;
  for (var fixt in fixtures) {
    let fixture = fixtures[fixt];
    let one = 0.33;
    let two = 0.33;
    let x = 0.33;
    if (fixture.signs != null) {
      one = fixture.signs.ONE.rowsFraction;
      two = fixture.signs.TWO.rowsFraction;
      x = fixture.signs.X.rowsFraction;
    }
    request.Fixtures.push({ ID: index.toString(), Probabilities: { ONE: one, TWO: two, X: x } })
    index++;
  }

  getRemoteSuggestion(request).then((resp: ProPickSuggestionResponse) => {

    let signs = [];
    for (var rowIndex in resp.SuggestedBets) {
      let suggestedBet = resp.SuggestedBets[rowIndex];
      let index = parseInt(suggestedBet.FixtureID);
      signs[index] = suggestedBet.Signs;
    }
    setProPickRows(signs);
  }
  );
}

