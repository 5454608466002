import * as React from "react";

export const formatAmount = (amount) =>
    (amount === null || amount === undefined || amount.currency === "") ?
        "" :
        (
            amount.currency === "USDT" ?
                "USDT " + Intl.NumberFormat(undefined).format(amount.amount)
                :
                new Intl.NumberFormat(undefined, { style: 'currency', currency: amount.currency }).format(amount.amount)
        )
    ;

export default ({ amount, style }) =>
    <span style={style}>{formatAmount(amount)}</span>;
