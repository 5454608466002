import React from 'react';
import Bet from "./Bet";
import PaginatedArrayField from "../../components/PaginatedArrayField";
import {Pagination} from 'react-admin';

const BetsGrid = ({data}) =>
  <div style={{display:'flex', flexDirection: 'row'}}>
    {data.map((bet, index) =>
      <div style={{width: '8em'}}>
        <Bet bet={bet}/>
      </div>
    )}
  </div>

const RoundCoupon = ({coupon}) =>
  <div>
    <PaginatedArrayField source={"bets"} record={coupon} perPage={10} pagination={<Pagination/>}>
      <BetsGrid/>
    </PaginatedArrayField>
  </div>;

export default RoundCoupon;