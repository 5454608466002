import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import SignBox from "./SignBox";
import clsx from "clsx";
import signs from "./signs";

const useStyles = makeStyles(theme => ({
  root: {
    width: '6em',
    height: '1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    verticalAlign: 'center'
  },
  sign: {
    cursor: 'default'
  }
}))

const Outcome = ({className, input: {onChange, value}}) => {
  const classes = useStyles();

  return <div className={clsx(className, classes.root)}>
    {Object.entries(signs).map(([sign, label]) =>
    sign !== "UNKNOWN" && <SignBox className={classes.sign} sign={label} value={value === sign} onChange={() => undefined}/>
      )}
  </div>;
}

export default Outcome;