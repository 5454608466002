import React, { useState,useEffect } from 'react';
import { Container,TextField,Button, MenuItem, Box, Switch, FormControlLabel } from "@material-ui/core";
import { RoundCurrency, RoundOverviewItem } from '../RoundOverview/RoundOverviewModels';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { ListResponse } from '../api/ApiNgModels';
import { SyndicateInstanceContext, SyndicateInstance } from './CreateSyndicateModels';
import { AgentOverviewFilter, AgentOverviewItem, AgentPlayerOverviewItem, SyndicateOverviewItem } from '../AgentOverview/AgentModels';
import { BaseItemState } from '../Ticker2/Ticker2Models';
import { Operator, OperatorFilter } from '../Admin/AdminModels';
import AdminUtil from '../Admin/AdminUtil';
import { QueryParams } from '../models/CouponShare';
import AgentUtil from '../AgentOverview/AgentUtil';
import SyndicateInstanceTable from './SyndicateInstanceTable';
import Ticker2Util from '../Ticker2/Ticker2Util';
import { formatNumber } from '../common/helpers/CurrencyHelpers';

interface SelectSyndicateInstancesState {
    operatorId: string;
    operator?: Operator
    agentId: number;
    agent?: AgentOverviewItem;
    playerId: number;
    player?: AgentPlayerOverviewItem;
    currencySource: string;
    currencies: string[];
    currency: string;
    syndicateName: string;
    targetSharesInput: string;
    targetShares?: number;
    from: Date;
    to: Date;
    pageSize: number;
    changeNo: number
}
interface OperatorItemState extends BaseItemState<Operator, string> {
}
interface AgentItemState extends BaseItemState<AgentOverviewItem, number> {
    operatorId: string;
}

interface AgentPlayerItemState extends BaseItemState<AgentPlayerOverviewItem,number> {
    agentId: number;
}
interface SyndicateItemState extends BaseItemState<SyndicateOverviewItem,number> {
    agentId: number;
}

interface CurrencyItemState extends BaseItemState<string,string> {
    agentId: number;
    playerId: number;
    allItems: string[];
}
interface AllCurrencyItemState extends BaseItemState<string,string> {
    currencies: RoundCurrency[];
}
interface SelectSyndicateInstancesStepProps {
    context: SyndicateInstanceContext;
    onChange : (context : SyndicateInstanceContext) => void;
}

const CURRENCY_SOURCE_PLAYER = "Player";
const CURRENCY_SOURCE_AGENT = "Agent";
const CURRENCY_SOURCE_ALL = "All";

const CURRENCY_SOURCES = [CURRENCY_SOURCE_PLAYER, CURRENCY_SOURCE_AGENT, CURRENCY_SOURCE_ALL];

const SelectSyndicateInstancesStep: React.FC<SelectSyndicateInstancesStepProps> = (props: SelectSyndicateInstancesStepProps) => {
    const [formState, setFormState] = useState<SelectSyndicateInstancesState>({
        operatorId: "",
        agentId: 0,
        playerId: 0,
        currencySource: CURRENCY_SOURCE_PLAYER,
        currencies: [],
        currency: "",
        syndicateName: "",
        targetSharesInput: "10",
        targetShares: 10,
        from: CreateRoundUtil.addDays(new Date(), -365),
        to: CreateRoundUtil.addDays(new Date(), 365),
        pageSize: 50,
        changeNo: 0
    });
    const [actionError, setActionError] = useState<string>("");

    const [operatorItemState, setOperatorItemState] = useState<OperatorItemState>({
        items: [],
        lastId: "",
        more: false,
        refreshing: false
    });
    const [agentItemState, setAgentItemState] = useState<AgentItemState>({
        items: [],
        lastId: 0,
        more: false,
        refreshing: false,
        operatorId: ""
    });

    const [playerItemState, setPlayerItemState] = useState<AgentPlayerItemState>({
        items: [],
        lastId: 0,
        more: false,
        refreshing: false,
        agentId: 0
    });
    const [syndicateItemState, setSyndicateItemState] = useState<SyndicateItemState>({
        items: [],
        lastId: 0,
        more: false,
        refreshing: false,
        agentId: 0
    });
    const [currencyItemState, setCurrencyItemState] = useState<CurrencyItemState>({
        items: [],
        lastId: "",
        more: false,
        refreshing: false,
        agentId: 0,
        playerId: 0,
        allItems: []
    });
    const [allCurrencyItemState, setAllCurrencyItemState] = useState<AllCurrencyItemState>({
        items: [],
        lastId: "",
        more: false,
        refreshing: false,
        currencies: []
    });

    useEffect(() => {
        refreshOperators("InitOperators");
        refreshAgents("InitAgents");
        refreshAllCurrencies("InitAllCurrencies")
    }, []);

    const refreshOperators = (caller: string) : void => {
        var fs : OperatorItemState = {
            items: [],
            lastId: "",
            more: false,
            refreshing: false
        };
        var filter : OperatorFilter = {
            lastId: fs.lastId
        };
        var lastId = caller === "onMoreOperator" ? operatorItemState.lastId : "";
        if (lastId.length > 0) {
            fs.items = operatorItemState.items;
        }
        var params : QueryParams = {
            pagination: { page: 0, perPage: formState.pageSize },
            filter: filter
            //sort: { field: state.orderBy, order: state.orderDirection }
        }
        setActionError("");
        operatorItemState.refreshing = true;
        var success = false;
        AdminUtil.getOperators(params).then(resp => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId.length > 0) {
                        for (const item of listResponse.items) {
                            fs.items.push(item);
                        }
                    } else {
                        fs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        fs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        fs.more = true;
                    }
                }           
            }
            success = true;
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            operatorItemState.refreshing = false;
            if (success || lastId.length <= 0) {
                setOperatorItemState(fs);
            }
        });
    };
    const onMoreOperator = () : void => {
        if (operatorItemState.lastId.length <= 0) {
            setActionError("Operator more failure: LastId was not set");
            return;
        }
        refreshOperators("onMoreOperator");
    }
    const refreshAgents = (caller: string) : void => {
        var fs : AgentItemState = {
            items: [],
            lastId: 0,
            more: false,
            refreshing: false,
            operatorId: formState.operatorId
        };
        var lastId = caller === "onMoreAgent" ? agentItemState.lastId : 0;
        if (lastId > 0) {
            fs.items = agentItemState.items;
        }
        var filter : AgentOverviewFilter | undefined = undefined;
        if (formState.operatorId.length > 0) {
            filter = {
                operatorId: formState.operatorId
            }
        }
        setActionError("");
        agentItemState.refreshing = true;
        var success = false;
        AgentUtil.getAgentOverviews(formState.pageSize, formState.from, formState.to, lastId, filter).then(resp => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId > 0) {
                        for (const item of listResponse.items) {
                            fs.items.push(item);
                        }
                    } else {
                        fs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        fs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        fs.more = true;
                    }
                    if (listResponse.items.length > 0 && fs.items.length > 1) {
                        fs.items.sort(AgentUtil.compareAgentOverviewItemByOperatorAgent)
                    }
                }           
            }
            success = true;
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            agentItemState.refreshing = false;
            if (success || lastId <= 0) {
                setAgentItemState(fs);
            }
        });
    };
    const onMoreAgent = () : void => {
        if (agentItemState.lastId <= 0) {
            setActionError("Agent more failure: LastId was not set");
            return;
        }
        refreshAgents("onMoreAgent");
    }
    const refreshPlayers = (caller: string) : void => {
        var fs : AgentPlayerItemState = {
            items: [],
            lastId: 0,
            more: false,
            refreshing: false,
            agentId: formState.agentId
        };
        var lastId = caller === "onMorePlayer" ? playerItemState.lastId : 0;
        if (lastId > 0) {
            fs.items = playerItemState.items;
        }
        setActionError("");
        playerItemState.refreshing = true;
        var success = false;
        AgentUtil.getAgentPlayerOverviews(formState.agentId, formState.pageSize, undefined, undefined, lastId).then(resp => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId > 0) {
                        for (const item of listResponse.items) {
                            fs.items.push(item);
                        }
                    } else {
                        fs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        fs.lastId = listResponse.items[listResponse.items.length - 1].playerId;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        fs.more = true;
                    }
                }           
            }
            success = true;
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            if (success || lastId <= 0) {
                setPlayerItemState(fs);
            }
            playerItemState.refreshing = false;
        });
    };
    const onMorePlayer = () : void => {
        if (playerItemState.lastId <= 0) {
            setActionError("Player more failure: LastId was not set");
            return;
        }
        refreshPlayers("onMorePlayer");
    }

    const refreshSyndicates = (caller: string) : void => {
        var fs : SyndicateItemState = {
            items: [],
            lastId: 0,
            more: false,
            refreshing: false,
            agentId: formState.agentId
        };
        var lastId = caller === "onMoreSyndicate" ? syndicateItemState.lastId : 0;
        if (lastId > 0) {
            fs.items = syndicateItemState.items;
        }
        setActionError("");
        syndicateItemState.refreshing = true;
        var success = false;
        AgentUtil.getSyndicateOverviews(formState.agentId, formState.pageSize, undefined, undefined, lastId).then(resp => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    if (lastId > 0) {
                        for (const item of listResponse.items) {
                            fs.items.push(item);
                        }
                    } else {
                        fs.items = listResponse.items;
                    }
                    if (listResponse.items.length > 0) {
                        fs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        fs.more = true;
                    }
                }           
            }
            success = true;
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            syndicateItemState.refreshing = false;
            if (success || lastId <= 0) {
                setSyndicateItemState(fs);
            }
        });
    };
    const onMoreSyndicate = () : void => {
        if (syndicateItemState.lastId <= 0) {
            setActionError("Syndicate more failure: LastId was not set");
            return;
        }
        refreshSyndicates("onMoreSyndicate");
    }
    const refreshCurrencies = (caller: string) : void => {
        var fs : CurrencyItemState = {
            items: [],
            lastId: "",
            more: false,
            refreshing: false,
            agentId: formState.agentId,
            playerId: formState.playerId,
            allItems: []
        };
       
        if (formState.agentId <= 0 || formState.playerId <= 0 || syndicateItemState.agentId != formState.agentId || syndicateItemState.items.length <= 0) {
            setCurrencyItemState(fs);
            return;
        }
        setActionError("");
        var success = false;
        currencyItemState.refreshing = true;
        try {
            fs.items = syndicateItemState.items.filter(it => it.playerId === formState.playerId).map(it => it.shareCurrency).filter((value, index, array) => array.indexOf(value) === index);
            fs.items.sort((a, b) => a.localeCompare(b));
            fs.allItems = syndicateItemState.items.map(it => it.shareCurrency).filter((value, index, array) => array.indexOf(value) === index)
            fs.allItems.sort((a, b) => a.localeCompare(b));
            success = true;
        } catch(error) {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        } finally {
            setCurrencyItemState(fs);         
        }
    };
    const refreshAllCurrencies = (caller: string) : void => {
        var fs : AllCurrencyItemState = {
            items: [],
            lastId: "",
            more: false,
            refreshing: false,
            currencies: []
        };
        allCurrencyItemState.refreshing = true;
        var success = false;
        const roundId = props.context.round ? props.context.round.id : 0;
        if (roundId === 0) {
            Ticker2Util.getTickerItems(100, formState.from, formState.to, 0).then((resp) => {
                if (!resp.success) {
                    setActionError(`${caller}.Ticker error: ${resp.message}`);
                    return;
                }
                var listResponse = resp.item;
                if (listResponse) {
                    if (listResponse.items) {
                        //map, distinct currencies
                        fs.items = listResponse.items.map(it => it.currency).filter((value, index, array) => array.indexOf(value) === index);
                        fs.items.sort((a, b) => a.localeCompare(b));
                    }
                }
            }).catch(error => {
                setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller}.Ticker error`));
            }).finally( () => {
                allCurrencyItemState.refreshing = false;
                setAllCurrencyItemState(fs);
            });
        } else {
            RoundOverviewUtil.getRoundCurrencies(roundId).then((resp) => {
                if (!resp.success) {
                    setActionError(`${caller} error: ${resp.message}`);
                    return;
                }
                var items = resp.item;
                if (items) {
                    fs.currencies = items;
                    //map, distinct currencies
                    fs.items = items.map(it => it.currencyCode).filter((value, index, array) => array.indexOf(value) === index);
                    fs.items.sort((a, b) => a.localeCompare(b));
                }
            }).catch(error => {
                setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
            }).finally( () => {
                allCurrencyItemState.refreshing = false;
                setAllCurrencyItemState(fs);
            });
        }
    };

    const getAllCurrencies = () : string[] => {
        if (allCurrencyItemState.currencies && allCurrencyItemState.currencies.length > 0) {
            return allCurrencyItemState.items;
        }
        const baseCurrencies : string[] = [RoundOverviewUtil.CURRENCY_EUR, "SEK", "USD", "NOK", "GBP"];
        var allCurrencies : string[] = [];
        for (var i = 0; i < allCurrencyItemState.items.length; i++) {
            var currency = allCurrencyItemState.items[i];
            allCurrencies.push(currency);
        }
        for (var i = 0; i < currencyItemState.allItems.length; i++) {
            var currency = currencyItemState.allItems[i];
            allCurrencies.push(currency);
        }
        for (var i = 0; i < baseCurrencies.length; i++) {
            var currency = baseCurrencies[i];
            allCurrencies.push(currency);
        }

        //distinct
        allCurrencies = allCurrencies.filter((value, index, array) => array.indexOf(value) === index);
        allCurrencies.sort((a, b) => a.localeCompare(b));
        return allCurrencies;
    }
    const handleChange = (name: string, value: any) : void => {
        var fs = { ...formState, [name]: value };
        if (name === "operatorId") {
            var operator = operatorItemState.items.find(it => it.id === fs.operatorId);
            fs.operator = operator;
            if (fs.operator && fs.agent && fs.agent.operatorId !== fs.operatorId) {
                fs.agentId = 0;
                fs.agent = undefined;
                fs.playerId = 0;
                fs.player = undefined;
            }
        } else if (name === "agentId") {
            var agent = agentItemState.items.find(it => it.id === fs.agentId);
            fs.agent = agent;
            if (fs.agent && fs.player && fs.player.agentId !== fs.agentId) {
                fs.playerId = 0;
                fs.player = undefined;
            }

        } else if (name === "playerId") {
            var player = playerItemState.items.find(it => it.playerId === fs.playerId);
            fs.player = player;
        }  else if (name === "currencySource") {
            switch (value) {
                case CURRENCY_SOURCE_PLAYER:
                    fs.currencies = currencyItemState.items;
                    break;
                case CURRENCY_SOURCE_AGENT:
                    fs.currencies = currencyItemState.allItems;
                    break;
                default:
                    //Should be all currencies for round
                    fs.currencies = getAllCurrencies();
                    break;
            }
            if (!fs.currencies.includes(fs.currency)) {
                fs.currency = "";
            }
        } else if (name == "targetSharesInput") {
            if (value && value.length > 0) {
                fs.targetShares = parseInt(value);
            } else {
                fs.targetShares = undefined;
            }
        }
        setFormState(fs);
    };

    const onAdd = () : void => {
        if (!formState.player) {
            setActionError("Add error: player not set");
            return;
        }
        var targetShares = formState.targetShares ? formState.targetShares : 0;
        if (targetShares <= 0) {
            setActionError("Add error: invalid 'Target shares'");
            return;
        }
        var player : AgentPlayerOverviewItem = formState.player;
        var stakePerShare : number | undefined = undefined;
        var operator = operatorItemState.items.find(it => it.id === player.operatorId);
        var feeRatio = 0;
        if (operator && operator.feeRatio && operator.feeRatio.length > 0) {
            feeRatio = parseFloat(operator.feeRatio);
        }
        var roundCurrency : RoundCurrency | undefined = allCurrencyItemState.currencies.find(it => it.currencyCode === formState.currency);
        if (roundCurrency) {
            stakePerShare = props.context.rowCount * roundCurrency.currencyUnits / roundCurrency.stakeUnits;
            if (feeRatio > 0) {
                stakePerShare *= (1 + feeRatio);
            }
        }
        var agent = formState.agent;
        var creator : SyndicateInstance = {
            agentId: player.agentId,
            agentName: player.agentName,
            currency: formState.currency,
            operatorId: player.operatorId,
            playerId: player.playerId,
            targetShares: targetShares,
            syndicateName: formState.syndicateName,
            rows: props.context.rowCount,
            stakePerShare: stakePerShare,
            agentFeePercentage: agent ? agent.feePercentage : undefined
        };
        var existingCreator = props.context.instances.find(it => it.agentId === player.agentId && it.playerId === player.playerId);
        if (existingCreator) {
            setActionError("Add error: player already added");
            return;
        }
        props.context.instances.push(creator);
        props.context.error = "";
        if (props.onChange) {
            props.onChange(props.context);
        }
        var fs = { ...formState, ["changeNo"]: formState.changeNo + 1 };
        setFormState(fs);
    };

    const onDeleteInstance = (instance: SyndicateInstance) => {
        var idx = props.context.instances.indexOf(instance);
        if (idx < 0) {
            var existingInstance = props.context.instances.find(it => it.agentId === instance.agentId && it.playerId === instance.playerId);
            if (!existingInstance) {
                return;
            }
            idx = props.context.instances.indexOf(existingInstance);
        }
        if (idx < 0) {
            return;
        }
        props.context.instances.splice(idx, 1);
        if (props.context.instances.length <= 0) {
            props.context.error = "No instances selected";
        } else {
            props.context.error = "";
        }
        if (props.onChange) {
            props.onChange(props.context);
        }
        var fs = { ...formState, ["changeNo"]: formState.changeNo + 1 };
        setFormState(fs);
    }
    var refreshing = operatorItemState.refreshing || agentItemState.refreshing || playerItemState.refreshing || syndicateItemState.refreshing;
    if (formState.operatorId !== agentItemState.operatorId && !refreshing) {
        refreshAgents("OperatorChanged");
    } else if (formState.agentId !== playerItemState.agentId && !refreshing) {
        refreshPlayers("AgentChanged_Players");
    } else if (formState.agentId !== syndicateItemState.agentId && !refreshing) {
        refreshSyndicates("AgentChanged_Syndicates");
    } else if (formState.agentId === syndicateItemState.agentId && (formState.agentId !== currencyItemState.agentId || formState.playerId !== currencyItemState.playerId) && !refreshing) {
        refreshCurrencies("AgentPlayerChanged_Currencies");
    }
    var operator = formState.operator;
    var agent = formState.agent;
    var player = formState.player;

    var enabledOperators : Operator[] = operatorItemState.items;
    if (enabledOperators.length > 0) {
        enabledOperators = enabledOperators.filter(it => it.agentSyndicateActive);
    }
    var currencies : string[] = [];
    switch (formState.currencySource) {
        case CURRENCY_SOURCE_PLAYER:
            currencies = currencyItemState.items;
            break;
        case CURRENCY_SOURCE_AGENT:
            currencies = currencyItemState.allItems;
            break;
        default:
            //Should be all currencies for round
            currencies = getAllCurrencies();
            break;
    }

    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box fontSize={10} color={RoundOverviewUtil.INVALID_COLOR}>{actionError}</Box>);  
    }
    
    var operatorItemInfoText = `Operator state - Refreshing:  ${operatorItemState.refreshing} | Count: ${operatorItemState.items.length} | PageSize: ${formState.pageSize} | AgentSyndicateActiveCount: ${enabledOperators.length}`;
    var operatorItemInfoBox = (<Box fontSize={10}>{operatorItemInfoText}</Box>);
    var operatorInfoBox = null;
    if (operator) {
        var operatorInfoText = `Operator - Id: ${operator.id} | Name: ${operator.name}`;
        operatorInfoBox = (<Box fontSize={10}>{operatorInfoText}</Box>);
    }
    var moreOperatorBtn = null;
    if (operatorItemState.more && !operatorItemState.refreshing) {
        moreOperatorBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={refreshing}
            onClick={e => onMoreOperator()}>
            More
        </Button>);
    }
    
    var agentItemInfoText = `Agent state - Refreshing:  ${agentItemState.refreshing} | Count: ${agentItemState.items.length} | PageSize: ${formState.pageSize}`;
    var agentItemInfoBox = (<Box fontSize={10}>{agentItemInfoText}</Box>);
    var agentInfoBox = null;
    if (agent) {
        var agentInfoText = `Agent - Id: ${agent.id} | Name: ${agent.name} | OperatorId: ${agent.operatorId} | Fee ratio: ${formatNumber(agent.feePercentage, 4)}`;
        agentInfoBox = (<Box fontSize={10}>{agentInfoText}</Box>);
    }
    var moreAgentBtn = null;
    if (agentItemState.more && !agentItemState.refreshing) {
        moreAgentBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={refreshing}
            onClick={e => onMoreAgent()}>
            More
        </Button>);
    }

    var playerItemInfoText = `Player state - Refreshing:  ${playerItemState.refreshing} | Count: ${playerItemState.items.length} | PageSize: ${formState.pageSize}`;
    var playerItemInfoBox = (<Box fontSize={10}>{playerItemInfoText}</Box>);
    var playerInfoBox = null;
    if (player) {
        var playerInfoText = `Player - PlayerId: ${player.playerId} | Agent: ${player.operatorId}/${player.agentName}`;
        playerInfoBox = (<Box fontSize={10}>{playerInfoText}</Box>);
    }
    var moreAgentBtn = null;
    if (agentItemState.more && !agentItemState.refreshing) {
        moreAgentBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={refreshing}
            onClick={e => onMoreAgent()}>
            More
        </Button>);
    }

    var valid = true;
    var agentErr = "";
    var playerErr = "";
    var currencyErr = "";
    var syndicateNameErr = "";
    var targetSharesErr = "";
    if (!formState.agent) {
        valid = false;
        agentErr = "Required";
    } else {
        var agentOperator = operatorItemState.items.find(it => it.id === formState.agent?.operatorId);
        if (!agentOperator) {
            valid = false;
            agentErr = "Agent operator does not exist"
        } else if (!agentOperator.agentSyndicateActive) {
            agentErr = "Agent operator has not AgentSyndicate activated";
        }
    }
    if (!formState.player) {
        valid = false;
        playerErr = "Required";
    } else if (formState.agent && formState.player.agentId !== formState.agent.id) {
        valid = false;
        playerErr = "Player does not belong to specified agent";
    }
    if (formState.currency.length <= 0) {
        valid = false;
        currencyErr = "Required";
    }
    if (formState.syndicateName.length <= 0) {
        valid = false;
        syndicateNameErr = "Required";
    }
    if (formState.targetSharesInput.length <= 0) {
        valid = false;
        targetSharesErr = "Required";
    }
    if (formState.targetSharesInput.length <= 0 || formState.targetShares === undefined) {
        valid = false;
        targetSharesErr = "Required";
    } else if (isNaN(formState.targetShares)) {
        valid = false;
        targetSharesErr = "Invalid int";
    } else if (formState.targetShares < 1 || formState.targetShares > 100) {
        valid = false;
        targetSharesErr = "Must be in range 1-100";
    }
return (
        <div>
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label="Operator"
                select
                name="operatorId"
                value={formState.operatorId}
                onChange={e => handleChange("operatorId", e.target.value)}
                style={{width:300}}
            >
                <MenuItem key={"op_wc"} value={""}>
                    {"(All)"}
                </MenuItem>
                {enabledOperators.map(op => (
                <MenuItem key={`op_${op.id}`} value={op.id}>
                    {op.id}
                </MenuItem>
                ))}
            </TextField>
            &nbsp;
            {moreOperatorBtn}
        </Box>
        {operatorItemInfoBox}
        {operatorInfoBox}
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label="Agent"
                select
                name="agentId"
                value={formState.agentId <= 0 ? "" : formState.agentId.toString()}
                onChange={e => handleChange("agentId", e.target.value)}
                style={{width:400}}
                error={agentErr.length > 0}
                helperText={agentErr}
            >
                {agentItemState.items.map(agent => (
                <MenuItem key={`agent_${agent.id}`} value={agent.id}>
                    {`${agent.operatorId}/${agent.name}`}
                </MenuItem>
                ))}
            </TextField>
            &nbsp;
            {moreAgentBtn}
        </Box>
        {agentItemInfoBox}
        {agentInfoBox}
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label="Player"
                select
                name="playerId"
                value={formState.playerId <= 0 ? "" : formState.playerId.toString()}
                onChange={e => handleChange("playerId", e.target.value)}
                style={{width:400}}
                error={playerErr.length > 0}
                helperText={playerErr}
            >
                {playerItemState.items.map(player => (
                <MenuItem key={`player_${player.playerId}`} value={player.playerId}>
                    {player.playerNotes ? `${player.playerId} (${player.playerNotes})` : player.playerId}
                </MenuItem>
                ))}
            </TextField>
            &nbsp;
        </Box>
        {playerItemInfoBox}
        {playerInfoBox}
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label="Currency source"
                select
                name="currencySource"
                value={formState.currencySource}
                onChange={e => handleChange("currencySource", e.target.value)}
                style={{width:200}}
            >
                {CURRENCY_SOURCES.map(curSrc => (
                <MenuItem key={`cursrc_${curSrc}`} value={curSrc}>
                    {curSrc}
                </MenuItem>
                ))}
            </TextField>&nbsp;
            <TextField
                label="Currency"
                select
                name="currency"
                value={formState.currency}
                onChange={e => handleChange("currency", e.target.value)}
                style={{width:100}}
                error={currencyErr.length > 0}
                helperText={currencyErr}
            >
                {currencies.map(currency => (
                <MenuItem key={`cur_${currency}`} value={currency}>
                    {currency}
                </MenuItem>
                ))}
            </TextField>
            &nbsp;
        </Box>
        <Box marginTop={1} display="flex" alignItems="center">
            <TextField
                label="Syndicate name"
                name="syndicateName"
                value={formState.syndicateName}
                InputLabelProps={{shrink: true}}
                style={{width:300}}
                onChange={e => handleChange("syndicateName", e.target.value)}
                error={syndicateNameErr.length > 0}
                helperText={syndicateNameErr}
            />&nbsp;
            <TextField
                label="Target shares"
                name="targetSharesInput"
                type="number"
                value={formState.targetSharesInput}
                style={{ width: 100 }}
                InputLabelProps={{shrink: true}}
                onChange={e => handleChange("targetSharesInput", e.target.value)}
                error={targetSharesErr.length > 0}
                helperText={targetSharesErr}
            />
        </Box>
        {actionErrorBox}
        <Box marginTop={1} marginBottom={1}>
            <Button 
                variant="contained"
                color="secondary"
                size="small"
                disabled={!valid || refreshing}
                onClick={e => onAdd()}>
                Add
            </Button>
        </Box>
        <SyndicateInstanceTable items={props.context.instances} onDelete={onDeleteInstance} />
      </div>
  );
};

export default SelectSyndicateInstancesStep;

