import {makeStyles} from "@material-ui/core/styles";
import {includes} from "lodash";
import React from "react";
import clsx from "clsx";
import signs from "../../sign/signs";
import SignBox from "../../sign/SignBox";

const useStyles = makeStyles(theme => ({
  root: {
    width: '6em',
    height: '1.5em',
    display: 'flex',
    justifyContent: 'space-between',
    verticalAlign: 'center'
  },
  correct: {
    boxShadow: '0px 0px 0px 4px yellow inset'
  }
}))

const Leg = ({leg, className}) => {
  const classes = useStyles();

  const LegOutcome = ({sign, label}) =>
    <SignBox className={clsx({
      [classes.correct]: leg.correctSign === sign,
    })} sign={label} value={includes(leg.signs, sign)} onChange={() => undefined}/>

  return <div className={clsx(className, classes.root)}>
    {Object.entries(signs).filter(([sign, label]) => sign !== "UNKNOWN").map(([sign, label]) => <LegOutcome key={sign} sign={sign} label={label}/>)}
  </div>;
};

export default Leg;